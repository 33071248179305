import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Header, Item, Select } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import { SelectHighPriority, SelectionTypes } from '../WizardConstants';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
import useWizardPartInputForIntendedUse from './useWizardPartInputForIntendedUse';

/**
 *@returns {JSX.Element} HIGH PRIORITY REQUEST COMPONENT
 */
const WizardIntendedUseHighPriorityRequest = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const IntendedUseUpdate = useWizardPartInputForIntendedUse(); // Function that populates the WizardInput -partInput properties
  const { wizardInput, currentPart, identicalIntendedUse } =
    useContext(WizardContext);
  const wizPart = wizardInput.partInput;

  return (
    <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
      <Item id="WizardIntendedUseAssignHighPriority">
        <Item.Content>
          <Item.Header>{t('intendedUsePart.intendedUseTitle')}</Item.Header>
          <Item.Extra
            style={{
              fontSize: '18px',
              display: 'inline',
            }}
          >
            {t('intendedUsePart.priority')}
          </Item.Extra>
          {!identicalIntendedUse && (
            <Header size="small">
              {`${
                wizardInput.partInput[currentPart - 1]?.name || ''
              } ${currentPart} / ${wizardInput.partInput.length}`}
            </Header>
          )}
          <Item.Description>
            <Form
              id="WizardIntendedUseAssignHighPriorityForm"
              style={{ width: '65%', marginTop: '40px' }}
            >
              <WizardFormFieldLabel
                nameText={t('intendedUsePart.highPriority')}
                infoText={t('intendedUsePart.highPriorityInfo')}
                htmlFor="highPriorityRequest"
              />
              <Form.Field
                name="highPriorityRequest"
                control={Select}
                options={SelectHighPriority()}
                value={
                  wizPart[currentPart - 1]?.highPriorityRequest !== undefined
                    ? wizPart[currentPart - 1].highPriorityRequest
                    : ''
                }
                placeholder={t('pleaseSelect')}
                onChange={(
                  _e: ChangeEvent,
                  { value, name }: SelectionTypes
                ) => {
                  IntendedUseUpdate(value, name);
                }}
              />
            </Form>
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default WizardIntendedUseHighPriorityRequest;
