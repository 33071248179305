import React, { HTMLAttributes, ReactElement } from 'react';
import { Popup } from 'semantic-ui-react';
import InfoCircleBlueIcon from '../wizard/InfoCircleBlueIcon';

interface InfoIconTooltipProps extends HTMLAttributes<HTMLDivElement> {
  infoText?: string | ReactElement;
  on?: 'hover' | 'click';
  children?: ReactElement;
  styleClass?: string;
  wide?: boolean | 'very';
}

/**
 * Info Icon with Tooltip, accepts children(optional) which can then be part of the popup trigger.
 * All standard HTML Div atributes can be used, to customize the container.
 *
 * @param {string}  infoText a string to be displayed within the tooltip.
 * @returns {boolean} ReactElement(if provided) followed by the info icon, triggering the popup (tooltip)
 */
const InfoIconTooltip = ({
  infoText = '',
  on = 'click',
  children,
  styleClass,
  wide = false,
  ...props
}: InfoIconTooltipProps): JSX.Element => {
  const className = `info-icon-tooltip-trigger ${
    children ? 'with-children' : 'standalone'
  }`;

  return (
    <Popup
      id="InfoIconTooltip"
      on={on}
      className={styleClass}
      content={infoText}
      hideOnScroll
      wide={wide}
      trigger={
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={className} {...props}>
          {children}
          <InfoCircleBlueIcon />
        </div>
      }
    />
  );
};

export default InfoIconTooltip;
