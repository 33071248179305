import { useQuery } from '@apollo/client/react/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { FileData } from '../../../../../../graphql/generated/graphql';
import { GET_ADDITIONAL_DATA_FOR_FILE } from '../../../../../../graphql/queries/ArtifactoryQuerys';
import CopyPopup from '../../../../productsList/listComponents/CopyPopup';
import { Columns } from '../../../../productsList/listComponents/ProductListTypes';
import OverflowPopup from '../../../../productsList/listComponents/columnComponents/OverflowPopup';
import { SetAdditionalData } from '../customHooks/useTableSorting';
import ColumnNames from '../helper/constants';
import { formatBytes } from '../helper/logics';
import InfoIconTooltip from '../../../../../ui/InfoIconTooltip';
import ActionsElement from './columnComponents/ActionsElement';
import DateElement from './columnComponents/DateElement';

type PropsType = {
  columns: Columns;
  file: FileData;
  versionId: string;
  dispatch: React.Dispatch<SetAdditionalData>;
};

/**
 *
 * @param {object} props contains columns, file and versionId and dispatching data to the sorter.
 * @returns {JSX.Element} StorageListElement.
 */
const StorageListElement = (props: PropsType): JSX.Element => {
  const { columns, file, versionId, dispatch } = props;
  const [text] = useTranslation('productStorage');
  const { data: additionalData, refetch: refetchAdditionalData } = useQuery(
    GET_ADDITIONAL_DATA_FOR_FILE,
    {
      variables: { versionId, filename: file.filename },
    }
  );
  const additionalDataForFile = additionalData?.GetAdditionalDataForFile;

  useEffect(() => {
    if (additionalData) {
      dispatch({
        type: 'SET_ADDITIONAL_DATA',
        // eslint-disable-next-line
        payload: { [file.filename!]: additionalData.GetAdditionalDataForFile },
      });
    }
    // eslint-disable-next-line
  }, [additionalData, dispatch]);

  return (
    <Table.Row
      id={`StorageListElement${file.checksums?.sha256}`}
      key={file.checksums?.sha256}
    >
      {columns.map((column) => {
        return (
          <Table.Cell
            key={column.name}
            className={column.name}
            width={column.width}
          >
            {file &&
              additionalData &&
              (() => {
                switch (column.name) {
                  case ColumnNames.FileName:
                    return (
                      <div className={ColumnNames.FileName}>
                        <OverflowPopup content={file.filename || '-'} />
                        {additionalDataForFile.info !== '' && (
                          <InfoIconTooltip
                            styleClass="StorageFileTextFormatter"
                            infoText={additionalDataForFile.info}
                          />
                        )}
                      </div>
                    );
                  case ColumnNames.Category:
                    return (
                      <OverflowPopup
                        content={
                          text(
                            `categoryOptions.${additionalDataForFile.type}`
                          ) || '-'
                        }
                      />
                    );
                  case ColumnNames.UploadedBy:
                    return <span>{additionalDataForFile.userName}</span>;
                  case ColumnNames.UploadedAt:
                    return <DateElement date={file.created} />;
                  case ColumnNames.FileSize:
                    return (
                      <OverflowPopup
                        content={formatBytes(file.size as number) || '-'}
                      />
                    );
                  case ColumnNames.Downloads:
                    return <span>{additionalDataForFile.downloadCount}</span>;
                  case ColumnNames.Sha256:
                    return (
                      <OverflowPopup
                        content={
                          file.checksums?.sha256 ? file.checksums.sha256 : '-'
                        }
                        trigger={
                          <>
                            <CopyPopup
                              elementId={file.checksums?.sha256 || null}
                            />
                            <span>
                              {file.checksums?.sha256
                                ? file.checksums.sha256
                                : '-'}
                            </span>
                          </>
                        }
                      />
                    );
                  case ColumnNames.Actions:
                    return (
                      <ActionsElement
                        url={file.path || ''}
                        versionId={versionId}
                        filename={file.filename || ''}
                        fileType={additionalDataForFile.type}
                        refetchAdditionalData={refetchAdditionalData}
                      />
                    );
                  default:
                    return null;
                }
              })()}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
};

export default StorageListElement;
