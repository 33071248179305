import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
  SelectedRoleContext,
  UserContext,
} from '../../../../../context/UserContext';
import {
  Version,
  useAuthorizationsQuery,
} from '../../../../../graphql/generated/graphql';
import { UPDATE_LEGAL_REVIEWER } from '../../../../../graphql/mutations/VersionMutations';
import {
  PRODUCTS_LIST,
  VERSION_REVIEWERS_LIST,
} from '../../../../../graphql/queries/ProductQuerys';
import OverflowPopup from './OverflowPopup';

type PropsType = {
  version: Version;
};

/**
 * Shows the name of the legalReviewer and a button to assign the current user as legalReviewer
 * Only versions have LegalReviewers, products do not.
 *
 * @param {PropsType} props version
 * @returns {JSX.Element} current legalReviewer & link-button that assigns the current user as LegalReviewer
 */
const LegalElement = (props: PropsType): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const { version } = props;
  const { id: userId } = useContext(UserContext);
  const { selectedRole } = useContext(SelectedRoleContext);

  const { data } = useAuthorizationsQuery({
    variables: { versionId: version.id, selectedRole },
  });
  const authorized = data?.Authorizations?.includes('UpdateLegalReviewer');
  const showAssignSelfLegal =
    authorized && version?.legalReviewer?.id !== userId;

  const [assignSelfLegal] = useMutation(UPDATE_LEGAL_REVIEWER, {
    variables: {
      versionId: version.id,
      legalId: userId,
    },
    refetchQueries: [PRODUCTS_LIST, VERSION_REVIEWERS_LIST],
  });

  return (
    <OverflowPopup
      content={
        <Item id="LegalElement" className="flex-column overflow">
          {version.legalReviewer?.name || t('tableElements.notAssigned')}
          {showAssignSelfLegal && (
            <Item.Extra
              id="AssignSelfLegal"
              className="ui clickable"
              onClick={assignSelfLegal}
            >
              {t('tableElements.assignMe')}
            </Item.Extra>
          )}
        </Item>
      }
    />
  );
};

export default LegalElement;
