/**
 * type for objects used for sorting and filtering
 */
export type collatingElementType = Record<string, string | number | null>;

/**
 * ResolverStatus as supplied by the backend
 */
export enum ResolverStatus {
  RUNNING = 'running',
  CANCELED = 'canceled',
  FINISHED = 'finished',
}

/**
 * Dropdown options for editing Project Parts
 */
export type dropdownOptionsForEditProjectParts = {
  key: string;
  text: string;
  value: string;
};
/**
 * Search for products parts element-parts options
 */
export interface ProductPartsOption {
  partId: string;
  partName: string;
  partVersionId: string;
  partVersionName: string;
}
// TODO: 🎈check why 2 same type objects!
/**
 * Edit product parts object for listing open session parts
 */
export interface EditParts {
  partId: string;
  partName: string;
  partVersionId: string;
  partVersionName: string;
}
/**
 *
 */
export const ProcessStatus = [
  'INCREATION',
  'INPROGRESS',
  'SUPPLIERINPUT',
  'OSOREQUESTED',
  'LEGALCHECK',
  'REWORK',
  'LEGALCHECKDONE',
  'SUPPLIERTODOS',
  'COMPLETED',
  'SUPPLIERREPORT',
  'REPORT',
];

/**
 *enum type for source code use
 */
export enum ProcessStatusType {
  INCREATION = 0,
  INPROGRESS = 1,
  SUPPLIERINPUT = 2,
  OSOREQUESTED = 3,
  LEGALCHECK = 4,
  REWORK = 5,
  LEGALCHECKDONE = 6,
  SUPPLIERTODOS = 7,
  COMPLETED = 8,
  SUPPLIERREPORT = 9,
  REPORT = 10,
  STATEINDEPENDENT,
}
