import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import { Product, Role } from '../../../../../graphql/generated/graphql';

type PropsType = {
  product?: Product;
  role: Role;
};

/**
 * list element of products list
 *
 * @param {PropsType} props takes in Product
 * Checks number of versions for given product and how many of them are assigned to reviewer (OSO or Legal)
 * @returns {JSX.Element} table row containing table cells with the according values.
 */
const ReviewersOverview = (props: PropsType): JSX.Element => {
  const { product, role } = props;
  const { t } = useTranslation('dashboard');

  const assignedVersions = product?.versions.filter((version) => {
    if (role === Role.legal) return version.legalReviewer;
    if (role === Role.oso) return version.osoReviewer;
    return false;
  });
  const assignedCount = assignedVersions?.length;
  const totalCount = product?.versions.length;
  const displayString = `${assignedCount}/${totalCount}`;

  return (
    <Popup
      id="reviewerOverviewPopup"
      trigger={<span>{displayString}</span>}
      content={t('tableElements.reviewerOverview', {
        assignedCount,
        totalCount,
      })}
      on={['hover']}
      inverted
    />
  );
};

export default ReviewersOverview;
