import { OperationBundle } from "./types";

/**
 * Waits for a while after the last request is added to ensure that all failed requests are accumulated.
 * 
 * @param {Array} pendingRequests array of pending requests
 */
const waitForRequestsToSettle = (pendingRequests: OperationBundle[]): Promise<void> => {
  return new Promise(resolve => {
    const checkInterval = setInterval(() => {
      const latestTimestamp = Math.max(...pendingRequests.map(req => req.operation.getContext().timestamp));
      const currentTime = Date.now();
      if (currentTime - latestTimestamp >= 1000) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 200);
  });
};

export default waitForRequestsToSettle