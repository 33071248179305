import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import {
  selectedDist,
  WizardContextInput,
} from '../components/wizard/WizardConstants';
import WizardFooter from '../components/wizard/WizardFooter';
import WizardHeader from '../components/wizard/WizardHeader';
import WizardMainSegment from '../components/wizard/WizardMainSegment';
import WizardContext from '../context/WizardContext';

/**
 * Wizard to create a new product.
 *
 * @returns {JSX.Element} NewProductWizard
 */
const NewProductWizard = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const [wizardInput, setWizardInput] = useState({
    productInput: {},
    partInput: [{}],
  } as WizardContextInput);
  const [partCount, setPartCount] = useState(1);
  const [partValidated, setPartValidated] = useState(false);
  const [currentPart, setCurrentPart] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [stepComplete, setStepComplete] = useState(false);
  // first step -productType
  const [type, setType] = useState({
    key: '',
    text: '',
    value: '',
  });
  // second part- enabling optional transfer input fileds
  const [transPass, setTransPass] = useState('');
  // third step-productStructure => Empty project / ProjectWithParts / Product
  const [productStructure, setProductStructure] = useState<number>();
  const [includedParts, setIncludedParts] = useState([
    { id: 0, checked: false, name: 'Frontend' },
    { id: 1, checked: false, name: 'Backend' },
    { id: 2, checked: false, name: 'Database' },
    { id: 3, checked: false, name: 'Client' },
    { id: 4, checked: false, name: `${t('parts.custom.label')}  #1` },
    { id: 5, checked: false, name: `${t('parts.custom.label')}  #2` },
    { id: 6, checked: false, name: `${t('parts.custom.label')}  #3` },
  ]);
  const [chosenDist, setChosenDist] = useState<selectedDist[]>([]);
  const [identicalTeam, setIdenticalTeam] = useState<boolean>();
  const [identicalIntendedUse, setIdenticalIntendedUse] = useState<boolean>();
  const [openTeamModal, setOpenTeamModal] = useState<[boolean, string]>([
    false,
    '',
  ]);
  const wizardContextValue = useMemo(
    () => ({
      wizardInput,
      setWizardInput,
      partCount,
      setPartCount,
      partValidated,
      setPartValidated,
      currentPart,
      setCurrentPart,
      currentStep,
      setCurrentStep,
      stepComplete,
      setStepComplete,
      productStructure,
      setProductStructure,
      transPass,
      setTransPass,
      identicalTeam,
      setIdenticalTeam,
      chosenDist,
      setChosenDist,
      identicalIntendedUse,
      setIdenticalIntendedUse,
      includedParts,
      setIncludedParts,
      type,
      setType,
      openTeamModal,
      setOpenTeamModal,
    }),
    [
      chosenDist,
      currentPart,
      currentStep,
      identicalIntendedUse,
      identicalTeam,
      includedParts,
      openTeamModal,
      partCount,
      partValidated,
      productStructure,
      stepComplete,
      transPass,
      type,
      wizardInput,
    ]
  );
  return (
    <WizardContext.Provider value={wizardContextValue}>
      <Segment.Group>
        <WizardHeader />
        <WizardMainSegment />
        <WizardFooter />
      </Segment.Group>
    </WizardContext.Provider>
  );
};

export default NewProductWizard;
