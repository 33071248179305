/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

/**
 * Set Verification Status of a License for a Product
 */
export const VERIFY_LICENSE = gql`
  mutation VerifyLicense(
    $dependencyId: ID!,
    $subcomponentId: ID!,
    $verified: Boolean!,
    $licenseTextVerificationChecksum: String!
  ) {
    VerifyLicense(
      dependencyId: $dependencyId,
      subcomponentId: $subcomponentId,
      verified: $verified,
      licenseTextVerificationChecksum: $licenseTextVerificationChecksum,
    )
  }
`;

/**
 * Changes license details
 */
export const UPDATE_LICENSE = gql`
  mutation UpdateLicense(
    $licenseInput: UpdateLicenseInput!,
  ) {
    UpdateLicense(
      licenseInput: $licenseInput,
    )
  }
`;