import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Header,
  Label,
  Icon,
  Menu,
  Segment,
  SemanticCOLORS,
} from 'semantic-ui-react';
import { UserContext } from '../../context/UserContext';
import WizardContext from '../../context/WizardContext';
import { ProductType } from '../../graphql/generated/graphql';

/**
 * Header for the create new Product Wizard.
 *
 * @returns {JSX.Element} WizardHeader.
 */
const WizardHeader = (): JSX.Element => {
  const [t, i18n] = useTranslation('newProductWizard');
  const {
    currentStep,
    stepComplete,
    wizardInput,
    currentPart,
    identicalIntendedUse,
    identicalTeam,
    partValidated,
    productStructure,
  } = useContext(WizardContext);
  const { name } = useContext(UserContext);
  const { type, name: productName } = wizardInput.productInput;
  const getColor = (step: number): SemanticCOLORS => {
    if (step < currentStep) {
      return 'green';
    }
    if (step === currentStep) {
      if (currentPart === wizardInput.partInput.length && partValidated) {
        return 'green';
      }
      return stepComplete ? 'green' : 'blue';
    }
    return 'grey';
  };

  const isDisabled = productStructure === 1 ? 'disabledColumn' : ''; // className to make step appear disabled
  return (
    <Segment id="WizardHeader" className="WizardHeader" basic raised>
      <Grid>
        <Grid.Row centered style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Menu fluid widths={2} secondary id="WizardHeaderTopMenu">
            <div style={{ position: 'absolute', left: 50 }}>
              <Menu.Item />
            </div>
            <Menu.Item style={{ padding: 5 }}>
              <Header
                textAlign="center"
                content={t('wizardHeader.createNewProduct')}
              />
            </Menu.Item>
            <div style={{ position: 'absolute', right: 50 }}>
              <Menu.Item position="right" style={{ paddingTop: 20 }}>
                <span style={{ whiteSpace: 'nowrap', paddingRight: 30 }}>
                  <Header
                    style={{ marginBottom: 0 }}
                    as="h5"
                    textAlign="right"
                    disabled
                  >
                    {t('wizardHeader.requester')}
                  </Header>
                  <Header style={{ marginTop: 0 }} textAlign="right" as="h5">
                    {name}
                    <Header.Subheader style={{ marginTop: 3 }}>
                      {type?.map((tp: ProductType) => (
                        <b id={`type_${tp}`} key={`type_${tp}_key`}>
                          {tp} /{' '}
                        </b>
                      ))}
                      {productName || ''}
                    </Header.Subheader>
                  </Header>
                </span>
                <span>
                  <Icon
                    style={{ position: 'relative', zIndex: 99 }}
                    size="large"
                    name="question circle"
                    id={`WizardGuideIcon${currentStep}-${i18n.language}`}
                  />
                </span>
              </Menu.Item>
            </div>
          </Menu>
        </Grid.Row>
        <Grid.Row centered style={{ paddingTop: 0 }}>
          <Grid columns="equal">
            <Grid.Column>
              <Grid columns={1} centered>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Label
                    style={{ marginRight: 40, marginLeft: 40 }}
                    id="WizardHeaderStep1Segment"
                    circular
                    color={getColor(1)}
                  >
                    1
                  </Label>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 7 }}>
                  <div>{t('wizardHeader.type')}</div>
                </Grid.Row>
                <Grid.Row style={{ padding: '0' }} />
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid columns={1} centered>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Label
                    style={{ marginRight: 40, marginLeft: 40 }}
                    id="WizardHeaderStep2Segment"
                    circular
                    color={getColor(2)}
                  >
                    2
                  </Label>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 7 }}>
                  <div>{t('wizardHeader.details')}</div>
                </Grid.Row>
                <Grid.Row style={{ padding: '0' }} />
              </Grid>
            </Grid.Column>
            <Grid.Column className={isDisabled}>
              <Grid columns={1} centered>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Label
                    style={{ marginRight: 40, marginLeft: 40 }}
                    id="WizardHeaderStep3Segment"
                    circular
                    color={getColor(3)}
                  >
                    3
                  </Label>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 7 }}>
                  <div>{t('wizardHeader.parts')}</div>
                </Grid.Row>
                <Grid.Row style={{ padding: '0' }} />
              </Grid>
            </Grid.Column>
            <Grid.Column className={isDisabled}>
              <Grid columns={1} centered>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Label
                    style={{ marginRight: 40, marginLeft: 40 }}
                    id="WizardHeaderStep4Segment"
                    circular
                    color={getColor(4)}
                  >
                    4
                  </Label>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 5 }}>
                  <div>{t('wizardHeader.intendedUse')}</div>
                </Grid.Row>
                {currentStep === 4 && !identicalIntendedUse ? (
                  <Grid.Row style={{ padding: '0 0 5px 0' }}>
                    <div>
                      {`${
                        wizardInput.partInput[currentPart - 1]?.name || ''
                      } ${currentPart} / ${wizardInput.partInput.length}`}
                    </div>
                  </Grid.Row>
                ) : (
                  <Grid.Row style={{ padding: '0' }} />
                )}
              </Grid>
            </Grid.Column>
            <Grid.Column className={isDisabled}>
              <Grid columns={1} centered>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Label
                    style={{ marginRight: 40, marginLeft: 40 }}
                    id="WizardHeaderStep5Segment"
                    circular
                    color={getColor(5)}
                  >
                    5
                  </Label>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: 5 }}>
                  <div>{t('wizardHeader.team')}</div>
                </Grid.Row>
                {currentStep === 5 && !identicalTeam ? (
                  <Grid.Row style={{ padding: '0 0 5px 0' }}>
                    <div>
                      {`${
                        wizardInput.partInput[currentPart - 1]?.name || ''
                      } ${currentPart} / ${wizardInput.partInput.length}`}
                    </div>
                  </Grid.Row>
                ) : (
                  <Grid.Row style={{ padding: '0' }} />
                )}
              </Grid>
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default WizardHeader;
