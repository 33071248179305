/* eslint-disable enforce-ids-in-jsx/unique-ids */ // it does not recognize that the ids are actually unique 🤯
import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label } from 'semantic-ui-react';
import TodosInfoPopup from './ToDoInfoPopup';
import CommentsModalWindow from '../comments/CommentsModalWindow';
import { CommentsModalContext } from '../requirements/helper/Constants';
import { OpenCommentsModalState } from '../comments/helper/types';

interface TodosInfoPopupProps {
  toDoId: string;
  toDoName: string;
  versionId: string | undefined;
  isAlternative?: boolean;
  isInternal?: boolean;
  style?: CSSProperties;
}

/**
 * TodosBadgesIcons Component
 *
 * Component that holds info icon, comments and alternative or internal badges.
 *
 * @param {string} [todoId] - ID of ToDo or Task.
 * @returns {JSX.Element} The rendered `TodosBadgesIcons` component.
 */
const ToDoBadgesIcons = ({
  toDoId,
  toDoName,
  versionId,
  isAlternative,
  isInternal,
  style,
}: TodosInfoPopupProps): JSX.Element => {
  const [t] = useTranslation(['todos']);
  const [openCommentsModal, setOpenCommentsModal] =
    useState<OpenCommentsModalState>([false, null]);
  return (
    <>
      <CommentsModalWindow
        openCommentsModal={openCommentsModal}
        setOpenCommentsModal={setOpenCommentsModal}
        elementName={toDoName}
        refersToId={toDoId}
      />
      <div style={style} className="icons_badges_container">
        <TodosInfoPopup versionId={versionId} toDoId={toDoId} />
        <Icon
          id={`taskComment_${toDoId}`}
          name="comment outline"
          color="blue"
          link
          onClick={() =>
            setOpenCommentsModal([true, CommentsModalContext.Todos])
          }
        />
        {isInternal && (
          <Label
            id={`labelInternal_${toDoId}`}
            className="internal_badge"
            color="orange"
            content={t('internal').toUpperCase()}
          />
        )}
        {isAlternative && (
          <Label
            id={`labelAlternative_${toDoId}`}
            className="alternative_badge"
            color="grey"
            content={t('alternative').toUpperCase()}
          />
        )}
      </div>
    </>
  );
};

export default ToDoBadgesIcons;
