import React, { ChangeEvent, useContext } from 'react';
import { Form, Select, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  OptionTypes,
  transferCompanyOptions,
  TransferOrPass,
} from '../WizardConstants';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
import WizardContext from '../../../context/WizardContext';
import { CreateProductInput } from '../../../graphql/generated/graphql';

/**
 *@returns {JSX.Element} Wizard product Details Transfer inputs on option
 */
const WizardProductTransferInput = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const { setWizardInput, wizardInput, transPass, setTransPass } =
    useContext(WizardContext);
  const wizProduct = wizardInput.productInput;

  const UpdateWizardInput = (input: string | boolean, detail: string) => {
    setWizardInput({
      ...wizardInput,
      productInput: {
        ...wizProduct,
        [detail]: input,
      } as CreateProductInput,
    });
  };

  const eraseOnPassNo = (passOrNot: string) => {
    if (passOrNot === 'noPass') {
      setWizardInput({
        ...wizardInput,
        productInput: {
          ...wizProduct,
          transferContactPerson: '',
          transferExternalId: '',
          transferGroupCompany: '',
        } as CreateProductInput,
      });
    }
  };

  return (
    <>
      <WizardFormFieldLabel
        nameText={t('productDetails.transferOrPass')}
        infoText={t('productDetails.transferOrPass-info')}
        htmlFor="transferOrPass"
      />
      <Form.Field
        required
        id="transferOrPass"
        control={Select}
        options={TransferOrPass()}
        value={transPass}
        onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
          setTransPass(value); // enabling optional inputs
          eraseOnPassNo(value); // if noPass is selected it erases the optionals
        }}
        placeholder={t('pleaseSelect')}
      />
      {/* ON Transfer / Pass "YES" Other three Inputs are enabled */}
      {transPass === 'pass' && (
        <>
          <WizardFormFieldLabel
            nameText={t('productDetails.uniqueIdentifier')}
            infoText={t('productDetails.uniqueIdentifier-info')}
            htmlFor="transferExternalId"
          />
          <Form.Field
            control={Input}
            id="transferExternalId"
            name="transferExternalId"
            value={wizProduct?.transferExternalId || ''}
            placeholder={t('productDetails.uniqueIdentifier')}
            onChange={(_e: ChangeEvent, { value, name }: OptionTypes) => {
              UpdateWizardInput(value, name);
            }}
            error={
              wizProduct?.transferExternalId &&
              wizProduct.transferExternalId.trim().length < 2
                ? t('productDetails.error.transferExternalId')
                : null
            }
          />
          <WizardFormFieldLabel
            nameText={t('productDetails.groupCompany')}
            infoText={t('productDetails.groupCompany-info')}
            htmlFor="transferGroupCompany"
          />
          <Form.Field
            required
            control={Select}
            id="transferGroupCompany"
            name="transferGroupCompany"
            options={transferCompanyOptions}
            value={wizProduct?.transferGroupCompany || ''}
            placeholder={t('productDetails.groupCompany')}
            onChange={(_e: ChangeEvent, { value, name }: OptionTypes) => {
              UpdateWizardInput(value, name);
            }}
          />
          <WizardFormFieldLabel
            nameText={t('productDetails.contactPerson')}
            infoText={t('productDetails.contactPerson-info')}
            htmlFor="transferContactPerson"
          />
          <Form.Field
            control={Input}
            id="transferContactPerson"
            name="transferContactPerson"
            value={wizProduct?.transferContactPerson || ''}
            placeholder={t('productDetails.contactPerson')}
            onChange={(_e: ChangeEvent, { value, name }: OptionTypes) => {
              UpdateWizardInput(value, name);
            }}
            error={
              wizProduct?.transferContactPerson &&
              wizProduct.transferContactPerson.trim().length < 2
                ? t('productDetails.error.transferContactPerson')
                : null
            }
          />
        </>
      )}
    </>
  );
};

export default WizardProductTransferInput;
