import { TFunction } from 'i18next';

/**
 * @param {string} newVersionName - current name input value
 * @param {string[]} productVersionNames - array of existing version names in lowercase
 * @param { TFunction } text - i18n translation function
 * @returns {{}} returns possible error message and boolean if name is valid
 */
const useCheckIfVersionNameIsValid = (
  newVersionName: string | undefined,
  productVersionNames: string[],
  text: TFunction
): { errorMessage: string; isValid: boolean } => {
  const returnObject = {
    errorMessage: '',
    isValid: true,
  };

  if (!newVersionName) {
    returnObject.errorMessage = text('newVersionModal.longerThanThree', {
      ns: 'dashboard',
    });
    returnObject.isValid = false;
    return returnObject;
  }
  if (!newVersionName) {
    returnObject.errorMessage = '';
    returnObject.isValid = false;
    return returnObject;
  }
  if (newVersionName?.trim().length < 3) {
    returnObject.errorMessage = text('newVersionModal.longerThanThree', {
      ns: 'dashboard',
    });
    returnObject.isValid = false;
    return returnObject;
  }
  if (productVersionNames.includes(newVersionName.toLowerCase())) {
    returnObject.errorMessage = text('newVersionModal.versionNameExists', {
      ns: 'dashboard',
    });
    returnObject.isValid = false;
    return returnObject;
  }
  return returnObject;
};
export default useCheckIfVersionNameIsValid;
