import React from 'react';
import {
  selectedDist,
  WizardContextInput,
} from '../components/wizard/WizardConstants';

const WizardContext = React.createContext<{
  wizardInput: WizardContextInput;
  setWizardInput: (param: WizardContextInput) => void;
  partCount: number;
  setPartCount: (param: number) => void;
  partValidated: boolean;
  setPartValidated: (param: boolean) => void;
  currentPart: number;
  setCurrentPart: (param: number) => void;
  currentStep: number;
  setCurrentStep: (param: number) => void;
  stepComplete: boolean;
  setStepComplete: (param: boolean) => void;
  type: { key: string; value: string; text: string };
  setType: (param: { key: string; value: string; text: string }) => void;
  productStructure: number | undefined;
  setProductStructure: (param: number) => void;
  transPass: string;
  setTransPass: (param: string) => void;
  chosenDist: Array<selectedDist>;
  setChosenDist: (param: Array<selectedDist>) => void;
  identicalTeam: boolean | undefined;
  setIdenticalTeam: (param: boolean | undefined) => void;
  identicalIntendedUse: boolean | undefined;
  setIdenticalIntendedUse: (param: boolean | undefined) => void;
  includedParts: { id: number; checked: boolean; name: string }[];
  setIncludedParts: (
    param: {
      id: number;
      checked: boolean;
      name: string;
    }[]
  ) => void;
  openTeamModal: [boolean, string];
  setOpenTeamModal: (param: [boolean, string]) => void;
}>({
  wizardInput: {
    productInput: {},
    partInput: [{}],
  } as WizardContextInput,
  setWizardInput: () => '',
  partCount: 1,
  partValidated: false,
  setPartValidated: () => '',
  setPartCount: () => '',
  currentPart: 1,
  setCurrentPart: () => '',
  currentStep: 1,
  setCurrentStep: () => '',
  stepComplete: false,
  setStepComplete: () => '',
  productStructure: undefined,
  setProductStructure: () => '',
  transPass: '',
  setTransPass: () => '',
  identicalTeam: undefined,
  setIdenticalTeam: () => '',
  identicalIntendedUse: undefined,
  setIdenticalIntendedUse: () => '',
  chosenDist: [],
  setChosenDist: () => '',
  includedParts: [],
  setIncludedParts: () => '',
  type: { key: '', value: '', text: '' },
  setType: () => '',
  openTeamModal: [false, ''],
  setOpenTeamModal: () => '',
});

export default WizardContext;
