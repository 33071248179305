import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeType } from '../../../../../graphql/generated/graphql';
import OverflowPopup from '../../../productsList/listComponents/columnComponents/OverflowPopup';

type PropsType = {
  changeType: ChangeType;
  fileName: string;
  fileCategory: string;
};

/**
 * returns an element with the details of StorageChange type
 *
 * @param {PropsType} props takes StorageChange type as parameter
 * @returns {JSX.Element} a span with text
 */
const StorageChangeElement = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('history');
  const [storage] = useTranslation('productStorage');

  const { changeType, fileName, fileCategory } = props;

  return (
    <OverflowPopup
      contentClassName="overflow-popup-history-content"
      content={
        <>
          <span className="changeType">
            {changeType === ChangeType.UPDATE
              ? text(`tableBody.operations.autoFileCategoryChange`)
              : text(`tableBody.operations.${changeType}`)}{' '}
          </span>
          <span className="fileName">{fileName} </span>
          <span className="fileCategory">
            ( {storage(`categoryOptions.${fileCategory}`)} )
          </span>
        </>
      }
    />
  );
};

export default StorageChangeElement;
