import React, { useEffect, useRef } from 'react';

interface Props {
  content: string;
  maxRows?: number;
}
/**
 * AutoExpanding Textarea
 *
 * @param {Props} props - Component props.
 * @returns {JSX.Element} textarea.
 */
const AutoExpandingTextarea = ({ content, maxRows }: Props): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // resize the textarea based on content
  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.value = content;
      if (maxRows) {
        const maxHeight =
          maxRows *
          parseFloat(getComputedStyle(textareaRef.current).lineHeight || '0');
        if (textareaRef.current.scrollHeight > maxHeight) {
          textareaRef.current.style.overflowY = 'auto';
          textareaRef.current.style.height = `${maxHeight}px`;
        } else {
          textareaRef.current.style.overflowY = 'hidden';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      } else {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    // resize the textarea when the content changes
    resizeTextarea();

    // resize the textarea when the window size changes
    window.addEventListener('resize', resizeTextarea);

    // clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', resizeTextarea);
    };
  }, [content]); // eslint-disable-line

  return (
    <textarea
      id="AutoExpandingTextarea"
      ref={textareaRef}
      readOnly
      style={{
        overflow: 'hidden',
        resize: 'none',
        maxHeight: 'none',
      }}
    />
  );
};

export default AutoExpandingTextarea;
