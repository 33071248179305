import { useQuery } from '@apollo/client/react/hooks/useQuery';
import {
  DependencySubComponentsRelationship,
  LicenseTextDiffVisualType,
  Subcomponent,
} from '../../../../../../../../graphql/generated/graphql';
import {
  GET_LICENSE_BY_ID,
  LICENSE_TEXT_DIFF_VISUAL,
} from '../../../../../../../../graphql/queries/LicenseQuery';

enum DiffColor {
  RED = -1,
  BLACK = 0,
  GREEN = 1,
}

/**
 * Return type of the useLicenseDiff hook
 *
 * @interface LicenseDiffReturnType
 * @property {LicenseTextDiffVisualType[]} visualizedDiffResult - array of the visualized diffs.
 * @property {string | undefined} originalText - originalText to show, if there are no diffs.
 */
export interface LicenseDiffReturnType {
  visualizedDiffResult: LicenseTextDiffVisualType[];
  originalText: string | undefined;
}

/**
 * @param {Subcomponent} subComponent - the subcomponent to compare
 * @param {DependencySubComponentsRelationship} subComponentEdge - the edge to compare
 * @param {string} inputText - the input text to compare with the original license text
 * @returns {LicenseDiffReturnType} licenseTextDiffVisualArr
 */
export const useLicenseDiff = (
  subComponent: Subcomponent,
  subComponentEdge: DependencySubComponentsRelationship,
  inputText: string | undefined
): LicenseDiffReturnType => {
  const { data: originalLicenseData } = useQuery(GET_LICENSE_BY_ID, {
    variables: { licenseId: subComponent.licenses[0].id },
  });

  const textIsAPermissionNote = subComponentEdge.permissionNote;
  const originalText = textIsAPermissionNote
    ? originalLicenseData?.License[0]?.permissionNote
    : originalLicenseData?.License[0]?.text;

  const { data: visualizedDiffData } = useQuery(LICENSE_TEXT_DIFF_VISUAL, {
    skip: !originalLicenseData || !inputText,
    variables: {
      spdxId: subComponentEdge.spdxId,
      inputText: inputText || '',
      persistedText: originalText || '',
    },
  });

  return {
    visualizedDiffResult: visualizedDiffData?.LicenseTextDiffVisual || [],
    originalText: originalText || '',
  };
};

/**
 *
 * @param {Array<LicenseTextDiffVisualType>} licenseTextDiffVisualArr - array of objects containing colorInt and text
 * @returns {{addedSnippets: number, removedSnippets: number, snippetsSum: number}} licenseDiffAmountObj
 */
export const licenseDiffAmount = (
  licenseTextDiffVisualArr: LicenseTextDiffVisualType[]
): { addedSnippets: number; removedSnippets: number; snippetsSum: number } => {
  const licenseDiffAmountObj = {
    addedSnippets: 0,
    removedSnippets: 0,
    snippetsSum: 0,
  };
  if (licenseTextDiffVisualArr) {
    licenseTextDiffVisualArr.forEach((licenseTextDiffVisual) => {
      switch (licenseTextDiffVisual.colorInt) {
        case DiffColor.RED:
          licenseDiffAmountObj.removedSnippets += 1;
          break;
        case DiffColor.GREEN:
          licenseDiffAmountObj.addedSnippets += 1;
          break;
        default:
          break;
      }
    });
    licenseDiffAmountObj.snippetsSum =
      licenseDiffAmountObj.addedSnippets + licenseDiffAmountObj.removedSnippets;
  }
  return licenseDiffAmountObj;
};

/**
 * takes in one snippet and returns the corresponding class name for the diff visualization
 *
 * @param {LicenseTextDiffVisualType} licenseTextDiffSnippet - object containing colorInt and text
 * @returns {string} classNames
 */
export const visualizeDiff = (
  licenseTextDiffSnippet: LicenseTextDiffVisualType
): string => {
  let classNames = '';
  switch (licenseTextDiffSnippet.colorInt) {
    case DiffColor.RED:
      classNames = 'changed negativeBackgroundColor';
      break;
    case DiffColor.GREEN:
      classNames = 'changed positiveBackgroundColor';
      break;
    default:
      break;
  }
  return classNames;
};
