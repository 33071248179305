import React from 'react';
import { Form } from 'semantic-ui-react';
import InfoIconTooltip from '../ui/InfoIconTooltip';

interface FormFieldLabelProps {
  nameText: string;
  infoText?: string;
  htmlFor?: string;
  optional?: boolean;
}

/**
 * @param {string}  nameText a string transfering the litteral string info form useTranslation
 *@returns {boolean} A Label form the Form:Field with a Info Icon to be used as Info Popup or Modal
 */
const WizardFormFieldLabel = ({
  nameText,
  infoText = '',
  htmlFor = '',
  optional = false,
}: FormFieldLabelProps): JSX.Element => {
  return (
    <InfoIconTooltip infoText={infoText} on="click">
      <Form.Field required={!optional} inline style={{ margin: '0 0 0.5em' }}>
        <label htmlFor={htmlFor}>{nameText}</label>
      </Form.Field>
    </InfoIconTooltip>
  );
};

export default WizardFormFieldLabel;
