import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import Toast from '../../../ui/Toast';
import useBrandOSOEmail from './helper/customHooks';
/**
 * Button for sending an email to Brand OSO.
 *
 * @returns {JSX.Element} EmailBrandOSOButton.
 */
const EmailBrandOSOButton = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { versionId } = useContext(ProductDetailsContext);
  const [toastVisible, setToastVisible] = useState(false);
  const [errorState, setErrorState] = useState<ApolloError | null>(null);

  const {
    email,
    uniqueIdentifier,
    transferContact,
    productName,
    loading,
    error,
  } = useBrandOSOEmail(versionId);

  const currentYear = new Date().getFullYear();
  const emailSubject = `${currentYear}_Konzernuebernahme_Anfrage_${productName}_${uniqueIdentifier}`;
  const emailText = `Sehr geehrte Damen und Herren,

im Rahmen des zwischen den Marken abgestimmten Leitplankenprozesses von FOSS-Produkten möchte die AUDI AG eine Konzernübernahme für das Produkt ${productName} mit dem unique identifier ${uniqueIdentifier} durchführen.

Hierfür benötigen wir Ihre Unterstützung und bitten um Zusendung der abgestimmten Unterlagen.

Die Kontaktinformation des/der zuständigen Ansprechpartner_in bei Ihnen lautet: ${transferContact}

 

Vielen Dank für Ihre Unterstützung.
  `;

  const handleToastClose = () => {
    setToastVisible(false);
    setErrorState(null);
  };

  const handleClick = () => {
    if (error) {
      setErrorState(error);
      setToastVisible(true);
      return;
    }

    if (email) {
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        emailSubject
      )}&body=${encodeURIComponent(emailText)}`;
      window.location.href = mailtoLink;
    }
  };

  // Update error state whenever a new error occurs
  useEffect(() => {
    if (error) {
      setErrorState(error);
      setToastVisible(true);
    }
  }, [error]);

  return (
    <>
      <Toast
        visible={toastVisible}
        type="error"
        header={errorState?.name}
        content={errorState?.message}
        onClose={handleToastClose}
        position="absolute bottom right"
        offset={{ y: -42 }}
      />
      <Button
        primary
        basic
        id="brandOSOEmailButton"
        icon="mail outline"
        loading={loading}
        disabled={loading || toastVisible}
        content={t('emailBrandOSO')}
        floated="right"
        onClick={handleClick}
      />
    </>
  );
};

export default EmailBrandOSOButton;
