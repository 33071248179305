import React, { Fragment, useState } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  FeatureTypeName,
  useLicenseContext,
} from '../../context/LicenseContext';
import {
  AdditionalLicenseFeature,
  ConflictRule,
  LicenseConflictRule,
  LicenseFeature,
  ToDo,
} from '../../graphql/generated/graphql';
import { generateHighlightedText } from './helpers/logics';
import { useGetFeatures } from './helpers/customHooks';
import useDebounce from '../../hooks/useDebounce';

type DropdownOptions = {
  key: string;
  text: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  type: FeatureTypeName;
};
/**
 * SelectFeature component, fetches all existing features based on provided type (todos sorted by category)
 *
 * @param {Props} props type of feature
 * @returns {JSX.Element} - JSX.Element
 */
const SelectFeature = (props: Props): JSX.Element => {
  const { type } = props;
  const { t, i18n } = useTranslation('licenseList');
  const {
    setTodos,
    setAdditionalLicenseFeatures,
    setLicenseConflictRules,
    setConflictRules,
    setLicenseFeatures,
    todos: contextTodos,
    additionalLicenseFeatures,
    licenseConflictRules,
    conflictRules,
    licenseFeatures,
  } = useLicenseContext();
  const [searchString, setSearchString] = useState('');
  const [debouncedSearchString, setDebouncedSearchString] = useDebounce(
    '',
    750
  );

  const { data, loading } = useGetFeatures(
    type,
    debouncedSearchString,
    i18n.language
  );

  const handleSearchChange = (
    e: React.SyntheticEvent<HTMLElement>,
    { searchQuery }: DropdownProps
  ) => {
    setSearchString(searchQuery as string);
    setDebouncedSearchString(searchQuery as string);
  };

  const handleItemClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemData: DropdownItemProps
  ) => {
    if (typeof itemData.value === 'string') {
      const selectedItem = data?.find((item) => item.id === itemData.value);
      if (selectedItem) {
        switch (selectedItem.__typename) {
          case 'ToDo':
            setTodos((prevTodos) => [...prevTodos, selectedItem as ToDo]);
            break;
          case 'LicenseConflictRule':
            setLicenseConflictRules((prevRules) => [
              ...prevRules,
              selectedItem as LicenseConflictRule,
            ]);
            break;
          case 'ConflictRule':
            setConflictRules((prevRules) => [
              ...prevRules,
              selectedItem as ConflictRule,
            ]);
            break;
          case 'AdditionalLicenseFeature':
            setAdditionalLicenseFeatures((prevFeatures) => [
              ...prevFeatures,
              selectedItem as AdditionalLicenseFeature,
            ]);
            break;
          case 'LicenseFeature':
            setLicenseFeatures((prevFeatures) => [
              ...prevFeatures,
              selectedItem as LicenseFeature,
            ]);
            break;
          default:
            throw new Error('Unhandled type');
        }
      }
    }
  };

  // ToDos are grouped by category, other probably just listed
  const groupedOptions: JSX.Element[] = [];
  const ungroupedOptions: JSX.Element[] = [];

  const isItemDisabled = (featureId: string, featureType: string): boolean => {
    switch (featureType) {
      case 'ToDo':
        return contextTodos.some((contextTodo) => contextTodo.id === featureId);
      case 'LicenseConflictRule':
        return licenseConflictRules.some(
          (contextRule) => contextRule.id === featureId
        );
      case 'ConflictRule':
        return conflictRules.some(
          (contextRule) => contextRule.id === featureId
        );
      case 'AdditionalLicenseFeature':
        return additionalLicenseFeatures.some(
          (contextFeature) => contextFeature.id === featureId
        );
      case 'LicenseFeature':
        return licenseFeatures.some(
          (contextFeature) => contextFeature.id === featureId
        );
      default:
        return false;
    }
  };

  if (type === 'ToDo') {
    const categories: { [key: string]: DropdownOptions[] } = {};

    (data as ToDo[])?.forEach((todo) => {
      if (todo && !categories[todo.category]) {
        categories[todo.category] = [];
      }
      if (todo && todo.id) {
        categories[todo.category].push({
          key: todo.id,
          text: i18n.language === 'en' ? todo.name_en : todo.name_de,
          value: todo.id,
          disabled: isItemDisabled(todo.id, type),
        });
      }
    });

    Object.keys(categories).forEach((category) => {
      groupedOptions.push(
        <Dropdown.Header
          key={`category-${category}`}
          content={generateHighlightedText(category, searchString)}
        />
      );
      categories[category].forEach((todo, index) => {
        const isLastItem = index === categories[category].length - 1;
        groupedOptions.push(
          <Fragment key={`fragment-${todo.key}`}>
            <Dropdown.Item
              key={todo.key}
              value={todo.value}
              disabled={todo.disabled}
              onClick={handleItemClick}
            >
              {generateHighlightedText(todo.text, searchString)}
            </Dropdown.Item>
            {!isLastItem && <Dropdown.Divider />}
          </Fragment>
        );
      });
    });
  } else {
    data?.forEach((item, index) => {
      const isLastItem = index === data.length - 1;
      const itemName = i18n.language === 'en' ? item.name_en : item.name_de;
      if (item.id) {
        ungroupedOptions.push(
          <Fragment key={`fragment-${item.id}`}>
            <Dropdown.Item
              key={item.id}
              value={item.id}
              disabled={isItemDisabled(item.id, type)}
              onClick={handleItemClick}
            >
              {generateHighlightedText(itemName, searchString)}
            </Dropdown.Item>
            {!isLastItem && <Dropdown.Divider />}
          </Fragment>
        );
      }
    });
  }

  return (
    <>
      {type !== 'LicenseFeature' && (
        <span>{t(`license-detail.selectFeature.label.${type}`)}</span>
      )}
      <Dropdown
        id="SelectFeature"
        className="selection"
        placeholder={t(`license-detail.selectFeature.placeholder.${type}`)}
        fluid
        search
        closeOnEscape
        scrolling
        upward={false}
        loading={loading}
        onSearchChange={handleSearchChange}
        searchQuery={searchString}
      >
        <Dropdown.Menu>
          {data?.length === 0 && (
            <Dropdown.Item disabled>
              {t('license-detail.noResults')}
            </Dropdown.Item>
          )}
          {data && data.length > 0 && type === 'ToDo'
            ? groupedOptions
            : ungroupedOptions}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SelectFeature;
