import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Header, Icon, Item } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../context/ProductContext';

import { Maybe } from '../../../../../graphql/generated/graphql';
import LegalResultModal from '../dependencyList/commonListComponents/columnComponents/legalResultModal/LegalResultModal';

type PropsType = {
  overallResultStatus: string;
  overallResultNote?: Maybe<string> | undefined;
  productName: string;
  versionName: string;
  versionId: string;
};

/**
 * Group takeover result status
 * element showing the overall result status e.g. OK, CONFLICT, etc.
 *
 * @param {PropsType} props takes in array of the results
 * @returns {JSX.Element} a span with status and colored border
 */
const GroupTakeOverStatusCard = (props: PropsType): JSX.Element => {
  const {
    overallResultStatus,
    overallResultNote,
    productName,
    versionId,
    versionName,
  } = props;
  const [t] = useTranslation(['productDetails']);
  const [openLegalCheck, setOpenLegalCheck] = useState(false);
  const { authorizations } = useContext(ProductDetailsContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const isOverallResultNote =
    overallResultNote &&
    overallResultNote.length > 0 &&
    overallResultNote !== ('initial result' || 'new resolver start');

  // Toggle the expanded state
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const shouldShowMore = isOverallResultNote && overallResultNote.length > 90; // 90 characters
  return (
    <>
      <LegalResultModal
        open={openLegalCheck}
        setOpen={setOpenLegalCheck}
        id={versionId}
        name={`${productName}@${versionName}`}
        currentStatus={overallResultStatus}
        resultNode="Version"
      />
      <Card
        id="GroupTakeoverCurrentStatus"
        style={isOverallResultNote ? {} : { width: '200px' }}
      >
        <div>
          <Header as="h4">{t('transferCurrentStatus')}</Header>
          <div className={`GroupTakeoverCurrentStatus${overallResultStatus}`}>
            {t(`dependencyList.resolverStatus.${overallResultStatus}`)}
          </div>
        </div>
        {isOverallResultNote && (
          <div className="NoteContainer">
            <Header as="h4">{t('transferCurrentStatusComment')}</Header>
            <div
              className={`GroupTakeoverCurrentStatusNote ${
                isExpanded ? 'expanded' : 'collapsed'
              }`}
            >
              {isExpanded || overallResultNote.length <= 90
                ? overallResultNote
                : `${overallResultNote.substring(0, 90)} `}
              {shouldShowMore && (
                <Item.Extra
                  className="edit-link"
                  onClick={toggleExpanded}
                  content={
                    isExpanded
                      ? `  ${t('show.less', { ns: 'buttons' })}`
                      : `... ${t('show.more', { ns: 'buttons' })}`
                  }
                />
              )}
            </div>
          </div>
        )}
        {authorizations.includes('CreateLegalVersionResult') && (
          <Icon
            id="GroupTakeoverElementEditStatusButton"
            name="pencil"
            color="blue"
            size="large"
            onClick={() => setOpenLegalCheck(true)}
          />
        )}
      </Card>
    </>
  );
};

export default GroupTakeOverStatusCard;
