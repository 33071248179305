import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Icon } from 'semantic-ui-react';

/**
 * Logout Button
 * sets the login token in the local storage to undefined and assigns the location to '/login'
 *
 * @returns {JSX.Element} Logout Button
 */
const Logout = (): JSX.Element => {
  const [t] = useTranslation('buttons');
  const onLogout = () => {
    localStorage.removeItem('searchString');
    localStorage.removeItem('itemsPerPage');
    localStorage.removeItem('columns');
    localStorage.removeItem('activePageState');
    localStorage.removeItem('onlyProjects');
    localStorage.setItem('token', 'undefined');
    window.location.href = '/login';
  };
  return (
    <Menu.Item data-cy="LogoutButton" onClick={onLogout}>
      {t('logout')}
      <Icon id="UserMenu.IconLogout" name="log out" />
    </Menu.Item>
  );
};

export default Logout;
