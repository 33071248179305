import { gql } from '@apollo/client';

/**
 * Mutation to create a product togehter with its parts
 * via the wizard.
 *
 * @param {string} email the users email address
 * @param {string} password the users password
 */
const WIZARD_MUTATION = gql`
  mutation wizard($wizardInput: WizardInput!) {
    CreateWizardProduct(wizardInput: $wizardInput) {
      id
    }
  }
`;

export default WIZARD_MUTATION;

/**
 * Updating copyrights & text of a License. Mutation is fired on SaveChanges click
 */
export const UPDATE_PRODUCT_LICENSE_DETAILS_MUTATION = gql`
  mutation UpdateProductLicenseDetails(
    $dependencyId: ID!
    $subcomponentId: ID!
    $spdxId: String!
    $copyrights: [String!]
    $licenseText: String
    $permissionNote: Boolean!
    $subComponentName: String!
  ) {
    UpdateProductLicenseDetails(
      dependencyId: $dependencyId
      subcomponentId: $subcomponentId
      spdxId: $spdxId
      copyrights: $copyrights
      licenseText: $licenseText
      permissionNote: $permissionNote
      subComponentName: $subComponentName
    )
  }
`;

/**
 * Updating legal checked dependency result
 */
export const CREATE_LEGAL_DEPENDENCY_RESULT = gql`
  mutation UpdateLegalDependencyResult(
    $versionId: ID!
    $dependencyId: ID!
    $status: String!
    $note: String!
  ) {
    CreateLegalDependencyResult(
      versionId: $versionId
      dependencyId: $dependencyId
      status: $status
      note: $note
    )
  }
`;

/**
 * Updating legal checked version result
 */
export const CREATE_LEGAL_VERSION_RESULT = gql`
  mutation UpdateLegalVersionResult(
    $versionId: ID!
    $status: String!
    $note: String!
  ) {
    CreateLegalVersionResult(
      versionId: $versionId
      status: $status
      note: $note
    )
  }
`;

/**
 * Update product contacs
 */
export const UPDATE_PRODUCT_CONTACTS = gql`
  mutation UpdateProductContacts($versionId: ID!, $contacts: ContactsInput!) {
    UpdateProductContacts(versionId: $versionId, 
      contacts: $contacts
    )
  }
`;

/**
 *  Mutating Tags via Update tags modal
 */
export const UPDATE_TAGS = gql`
  mutation UpdateTags($versionId: ID!, $tags: [String!]!) {
    UpdateTags(versionId: $versionId, tags: $tags)
  }
`;

/**
 * Update products parts when he is a project! 
 */
export const UPDATE_PRODUCT_PARTS = gql`
  mutation UpdateProductParts($projectVersionId: ID!, $partVersionIds: [ID!]!) {
    UpdateProductParts(projectVersionId: $projectVersionId, 
      partVersionIds: $partVersionIds
    )
  }
`;
/**
 * Delete a product
 */
export const DELETE_PRODUCT = gql `
mutation DeleteProduct($id:ID!) {
  DeleteProduct(id: $id)
 }
`;
/**
 * Create a product without parts nor parent version
 * 
 * have ask to be returned: ID aka productId & its version ID...there is bunch of other possible parameters if needed -see backend/tests/graphql/ProductGraphQL.ts
 */
export const CREATE_PRODUCT_WITHOUT_PARTS = gql`
  mutation CreateProductWithoutParts  (
    $productInput: CreateProductInput!
  ) {
    CreateProduct(
      productInput: $productInput
    ) {
      id
      versions {
        id
        name
      }
     
    }
  }
`;
