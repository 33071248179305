import React, { Fragment } from 'react';
import { Table, Icon, Label } from 'semantic-ui-react';
import EditUserDetailsButton from '../EditUserDetailsButton';
import { User } from '../../../graphql/generated/graphql';

type StatusTabProps = {
  user: User;
  setChosenUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * @param {StatusTabProps} props Props
 *@returns {JSX.Element} Table with  informations
 */
const AllUsersInfoTabBody = (props: StatusTabProps): JSX.Element => {
  const { user, setChosenUser, setOpenModal } = props;
  const { id, name, roles, groups, email } = user;
  return (
    <Fragment key={id}>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Icon color="green" id="AllUsersInfoActiveUserIcon" name="user" />
            {name}
          </Table.Cell>
          <Table.Cell>
            {roles.map((r) => (
              <Label
                id="AllUsersInfoTableActiveRole"
                key={r}
                content={r.charAt(0).toUpperCase() + r.slice(1)}
                style={{ cursor: 'default' }}
              />
            ))}
          </Table.Cell>
          <Table.Cell>{email}</Table.Cell>
          <Table.Cell>
            {groups?.map((group) => (
              <Label
                as="a"
                basic
                pointing
                id="AllUsersInfoTableUsersGroup"
                key={group?.id}
                content={group?.name}
              />
            ))}
          </Table.Cell>
          <Table.Cell textAlign="center">
            <EditUserDetailsButton
              onClick={() => {
                // will select the user in which it exists
                setChosenUser(user);
                setOpenModal(true);
              }}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Fragment>
  );
};

export default AllUsersInfoTabBody;
