import React, { Fragment, useState } from 'react';
import {
  DependencyListElement as DependencyListElementType,
  Version,
} from '../../../../../../graphql/generated/graphql';
import { Columns } from '../../../../productsList/listComponents/ProductListTypes';
import DependencyListElement from '../fossDependencyList/listComponents/DependencyListElement';
import PVDependencyListElement from '../productVersionDependencyList/PVDependencyListElement';

type PropsType = {
  columns: Columns;
  fossDependencies?: Array<DependencyListElementType>;
  pVDependencies?: Array<Version>;
  onComponentClick?: (name: string) => void;
};

/**
 * content of body element of products list
 *
 * @param {PropsType} props takes in an array of Products
 * sticks together the ProductsListElements
 * @returns {JSX.Element} all rows belonging to the table bosy of the ProductsList
 */
const DependencyListBody = (props: PropsType): JSX.Element => {
  const { columns, fossDependencies, pVDependencies, onComponentClick } = props;
  const [open, setOpen] = useState<Array<string>>([]);

  const toggleList = (clickedId: string) => {
    if (open.length === 0) {
      setOpen([clickedId]);
      return;
    }
    const isOpen = open.includes(clickedId);
    const newOpen = open.filter((openId) => {
      if (openId.length < clickedId.length) {
        // keep if identical to a part of the id of the clicked element
        return openId === clickedId.slice(0, openId.length);
      }
      return false;
    });
    setOpen([...newOpen, ...(isOpen ? [] : [clickedId])]);
  };

  return (
    <>
      {fossDependencies &&
        fossDependencies.map((fossDependency) => {
          return (
            <Fragment
              key={`${fossDependency.dependency.id}+${fossDependency.sourceDependencyIds}`}
            >
              <DependencyListElement
                key={`DependencyListElement${fossDependency.dependency.id}`}
                fossDep={fossDependency}
                onClick={toggleList}
                columns={columns}
                open={open}
                onComponentClick={onComponentClick}
              />
            </Fragment>
          );
        })}
      {pVDependencies &&
        pVDependencies.map((pVDependency) => {
          return (
            <Fragment key={pVDependency.id}>
              <PVDependencyListElement
                key={`DependencyListElement${pVDependency.id}`}
                pVDep={pVDependency}
                onClick={toggleList}
                columns={columns}
                open={open}
              />
            </Fragment>
          );
        })}
    </>
  );
};

DependencyListBody.defaultProps = {
  fossDependencies: [],
  pVDependencies: [],
};

export default DependencyListBody;
