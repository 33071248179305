import React, { Fragment, useEffect, useState } from 'react';
import { Product, Version } from '../../../../graphql/generated/graphql';
import { Columns } from './ProductListTypes';
import ProductsListElement from './ProductsListElement';

type PropsType = {
  columns: Columns;
  products: Array<Product>;
};

/**
 * content of body element of products list
 *
 * The useEffect sets the open state of the product to render its Versions when the processStatus Filter is active && it`s not on Product but one of its Versions
 *
 * @param {PropsType} props takes in an array of Products
 * sticks together the ProductsListElements
 * @returns {JSX.Element} all rows belonging to the table bosy of the ProductsList
 */
const ProductsListBody = (props: PropsType): JSX.Element => {
  const { columns, products } = props;
  const [open, setOpen] = useState('');
  const openElement = (id: string) => {
    return open === id ? setOpen('') : setOpen(id);
  };

  useEffect(() => {
    const projectStatusObject = columns.find(
      (column) => column.name === 'projectStatus'
    );
    const trueFilteringKeys = projectStatusObject
      ? Object.keys(projectStatusObject.filterInfo.filtering).filter(
          (key) => projectStatusObject.filterInfo.filtering[key]
        )
      : [];
    const productToOpen = products.find(
      (product) => !trueFilteringKeys.includes(product.projectStatus)
    );
    if (productToOpen && trueFilteringKeys.length > 0) {
      setOpen(productToOpen.id);
    } else setOpen('');
  }, [columns, products]);

  return (
    <>
      {products.map((product: Product) => {
        return (
          <Fragment key={product.id}>
            <ProductsListElement
              key={`Products.ListElement.${product.id}`}
              product={product}
              onClick={() => openElement(product.id)}
              columns={columns}
              open={open === product.id}
            />
            {open === product.id &&
              product.versions.map((version: Version) => {
                // if more levels needed, recursion could be done here by
                // replacing ProductsListElement with ProductsListBody
                return (
                  <ProductsListElement
                    key={version.id}
                    version={version}
                    versionsParentName={product.name}
                    columns={columns}
                  />
                );
              })}
          </Fragment>
        );
      })}
    </>
  );
};

export default ProductsListBody;
