import React, { useContext, useState } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { Role } from '../../../../graphql/generated/graphql';
import { PROJECT_INFOBOX } from '../../../../graphql/queries/VersionQuerys';
import DescriptionItem from './components/DescriptionItem';
import EditProductButton from './components/EditProductButton';
import InfoBoxHeader from './components/InfoBoxHeader';
import InfoBoxProvider from './components/InfoBoxProvider';
import PriorityItem from './components/PriorityItem';
import ProductTypeItem from './components/ProductTypeItem';
import StandardSoftwareItem from './components/StandardSoftwareItem';
import TargetDateItem from './components/TargetDate';
import UpdateMessage from './components/UpdateMessage';

interface Props {
  maxDescriptionLines?: number;
}

/**
 * @param {string} props {maxDescriptionLines}
 * @returns {JSX.Element} Info Box Component
 */
const ProjectInfoBox = (props: Props): JSX.Element => {
  const { maxDescriptionLines = 6 } = props;
  const [open, setOpen] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const { selectedRole } = useContext(SelectedRoleContext);

  return (
    <InfoBoxProvider
      editing={editing}
      setEditing={setEditing}
      query={PROJECT_INFOBOX}
      refetchQueries={[PROJECT_INFOBOX]}
    >
      <Segment id="InfoBox" raised>
        <InfoBoxHeader open={open} setOpen={setOpen} />
        {open && (
          <>
            <Grid relaxed padded>
              {/* First Row */}
              <Grid.Row>
                <Grid.Column width={10}>
                  <DescriptionItem maxDescriptionLines={maxDescriptionLines} />
                </Grid.Column>
                <Grid.Column width={6} textAlign="center">
                  <EditProductButton />
                </Grid.Column>
              </Grid.Row>
              {/* Second Row */}
              <Grid.Row columns="equal" stretched>
                <Grid.Column>
                  <TargetDateItem />
                </Grid.Column>
                <Grid.Column>
                  <ProductTypeItem />
                </Grid.Column>
                {[Role.oso, Role.legal].includes(selectedRole) && (
                  <Grid.Column>
                    <PriorityItem />
                  </Grid.Column>
                )}
                <Grid.Column>
                  <StandardSoftwareItem />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <UpdateMessage />
          </>
        )}
      </Segment>
    </InfoBoxProvider>
  );
};

export default ProjectInfoBox;
