import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
  SelectedRoleContext,
  UserContext,
} from '../../../../../context/UserContext';
import {
  Version,
  useAuthorizationsQuery,
} from '../../../../../graphql/generated/graphql';
import { UPDATE_OSO_REVIEWER } from '../../../../../graphql/mutations/VersionMutations';
import {
  PRODUCTS_LIST,
  VERSION_REVIEWERS_LIST,
} from '../../../../../graphql/queries/ProductQuerys';
import OverflowPopup from './OverflowPopup';

type PropsType = {
  version: Version;
};

/**
 * Shows the name of the osoReviewer and a button to assign the current user as osoReviewer
 * Only versions have OsoReviewers, products do not.
 *
 * @param {PropsType} props version
 * @returns {JSX.Element} current osoReviewer & link-button that assigns the current user as OsoReviewer
 */
const OsoElement = (props: PropsType): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const { version } = props;
  const { id: userId } = useContext(UserContext);
  const { selectedRole } = useContext(SelectedRoleContext);

  const { data } = useAuthorizationsQuery({
    variables: { versionId: version.id, selectedRole },
  });
  const authorized = data?.Authorizations?.includes('UpdateOsoReviewer');
  const showAssignSelfOso = authorized && version?.osoReviewer?.id !== userId;

  const [assignSelfOso] = useMutation(UPDATE_OSO_REVIEWER, {
    variables: {
      versionId: version.id,
      osoId: userId,
    },
    refetchQueries: [PRODUCTS_LIST, VERSION_REVIEWERS_LIST],
  });

  return (
    <OverflowPopup
      content={
        <Item
          id="ProductsListElement.oso.item"
          className="flex-column overflow"
        >
          {version.osoReviewer?.name || t('tableElements.notAssigned')}
          {showAssignSelfOso && (
            <Item.Extra
              id="AssignSelfOso"
              className="ui clickable"
              onClick={assignSelfOso}
            >
              {t('tableElements.assignMe')}
            </Item.Extra>
          )}
        </Item>
      }
    />
  );
};

export default OsoElement;
