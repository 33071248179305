import { gql } from '@apollo/client';

/**
 * List of all visible Integration Items (with filter/sort)
 *
 * a filtered and sorted list of integration items
 * resolverResult {id} removed as it lead to an error, couldn't be found
 */
export const PRODUCTS_LIST = gql`
  query AllProducts(
    # $searchName: String
    # $searchId: ID
    $where: ProductWhere
    $options: ProductOptions = { sort: [], offset: 0, limit: 5 }
  ) {
    # OR: [{ name_CONTAINS: $searchName }, { id_CONTAINS: $searchId }, { tags_INCLUDES: $searchName }]
    # könnte nochmal wichtig werden, falls es notwendig ist, das where in die gpl-datei zu verlegen
    Product(where: $where, options: $options) {
      id
      name
      priority
      description
      isProject
      projectStatus
      external
      targetDate
      type
      tags
      completeness
      resolverResult
      supplierTeam {
        id
      }
      selectedDistribution {
        id
        name_de
        name_en
      }
      standardSoftware
      createdBy {
        name
        email
      }
      versions {
        id
        name
        priority
        legalReviewer {
          id
          name
        }
        osoReviewer {
          id
          name
        }
        targetDate
        processStatus
        processStatusAsString
        createdBy {
          id
          name
          email
        }
        overallResult {
          id
          status
        }
        resolverResult {
          id
          status
        }
        comments {
          id
        }
        numberOfDependenciesToProof
        numberOfDirectDependencies
        numberOfTransitiveDependencies        
      }      
    }
    ProductAmount(where: $where)
  }
`;

/**
 * Product Names for input validation in Wizard.
 */
export const PRODUCT_NAMES = gql`
  query AllProductNames {
    Product {
      id
      name
    }
  }
`;

/**
 * Checks if there are any projects, for 'show only projects' toggle
 */
export const CHECK_FOR_PROJECTS = gql`
query CheckForProjects {
  Product {
    isProject
  }
}
`;

/**
 * Product Version Names to be checked against duplicates in a Product.
 */
export const PRODUCT_VERSION_NAMES = gql`
  query ProductVersionNames( $where: ProductWhere) {
    Product( where: $where) {
      id
      name
      versions {
        id
        name
      }
    }
  }
`;
/**
 *
 */
export const PRODUCTS_REQUESTOR_LIST = gql`
  query ProductsRequestorList {
    Product {
      createdBy {
        id
        name
      }
    }
  }
`;

/**
 * List of all products as project parts (with filter/sort)
 * For Edit Product Parts Modal
 * a filtered and sorted list pf parts
 */
export const GET_ALL_PRODUCT_PARTS = gql`
  query AllProductParts(
    $where: ProductWhere
    $options: ProductOptions = { sort: [{ name: ASC }], offset: 0 }
  ) {
    Product(where: $where, options: $options) {
      id
      name 
      isProject    
      versions {
        id
        name
      }
    }  
  }
`;

/**
 *
 */
export const CHECK_IF_PRODUCT_DELETABLE = gql`
  query IsProductDeletable($id:ID!) {
    ProductDeletable(id:$id) {
      deletable
      reason
    }
  }
`;
/**
 *
 */
export const VERSION_REVIEWERS_LIST = gql`
  query allReviewers {
  Product {
    versions {
      osoReviewer {
        id
        name
      }
      legalReviewer {
        id
        name
      }
    }
  }
}
`;
