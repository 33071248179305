import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import ApolloProvider from '../graphql/Provider';
import Layout from './Layout';

/**
 * App
 * is the entry point from the index.
 *
 * @returns {JSX.Element} AOSD2 App
 */
const App = (): JSX.Element => {
  return (
    <CookiesProvider>
      <BrowserRouter basename="/">
        <ApolloProvider>
          <Suspense fallback={null}>
            <Layout />
          </Suspense>
        </ApolloProvider>
      </BrowserRouter>
    </CookiesProvider>
  );
};

export default App;
