import React from 'react';
import { Header, Placeholder, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

/**
 * Directories as a tree structured list
 *
 * @returns {JSX.Element} Directories View
 */
const Directories = (): JSX.Element => {
  const { t } = useTranslation('users');

  return (
    <Segment id="userDirectories" style={{ height: '100vh' }}>
      <>
        <Header content={t('user.directories')} />
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </>
    </Segment>
  );
};
export default Directories;
