import React from 'react';
import { Item } from 'semantic-ui-react';
import { Product, Version } from '../../../../../graphql/generated/graphql';
import {
  directProductDependencies,
  transitivePrdoductDependencies as productTransitiveDependencies,
  toDosCount,
} from '../helpers/logics';
import OverflowPopup from './OverflowPopup';

type PropsType = {
  product: Product | undefined;
  version: Version | undefined;
};

/**
 * basically only returns an element with the time till targetDate
 * nothing too fancy
 *
 * @param {PropsType} props takes in targetDate
 * @returns {JSX.Element} a span with text
 */
const ToDosElement = (props: PropsType): JSX.Element => {
  const { product, version } = props;
  let toDosAmount = {
    openToDos: 0,
    totalToDos: 0,
  };
  if (version) {
    toDosAmount.totalToDos +=
      (version.numberOfDirectDependencies || 0) +
      (version.numberOfTransitiveDependencies || 0);
    toDosAmount.openToDos += version.numberOfDependenciesToProof || 0;
  } else if (product) {
    toDosAmount = toDosCount(toDosAmount, product);
  }

  return (
    <Item id="ProductsList.ToDosElement">
      <Item.Content>
        <OverflowPopup
          content={
            <>
              <span style={{ fontWeight: 'bold' }}>
                {toDosAmount.openToDos}{' '}
              </span>
              <span>/ {toDosAmount.totalToDos}</span>
              {version && (
                <Item.Extra style={{ color: 'GrayText' }}>
                  {`D: ${version.numberOfDirectDependencies || 0}  / T: ${
                    version.numberOfTransitiveDependencies || 0
                  }`}
                </Item.Extra>
              )}
              {product && (
                <Item.Extra style={{ color: 'GrayText' }}>
                  {`D: ${directProductDependencies(
                    product
                  )}  / T: ${productTransitiveDependencies(product)}`}
                </Item.Extra>
              )}
            </>
          }
        />
      </Item.Content>
    </Item>
  );
};

export default ToDosElement;
