import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  internal?: boolean;
}

/**
 * LicenseTodoBadges
 *
 * Component that renders badge(s) based on the props provided.
 *
 * @param {boolean} [props] - Indicates if the internal badge should be displayed.
 * @returns {JSX.Element} The rendered `LicenseTodoBadges` component.
 */
const LicenseTodoBadges = (props: Props): JSX.Element => {
  const { internal } = props;
  const [t] = useTranslation('badges');

  return (
    <div className="badge_container">
      {internal && <span className="internal_badge">{t('internal')}</span>}
    </div>
  );
};

export default LicenseTodoBadges;
