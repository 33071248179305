import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon, Label, Menu, Modal } from 'semantic-ui-react';
import {
  DependencySubComponentsRelationship,
  Subcomponent,
  useGetLicenseTextQuery,
  useLicenseFeaturesByIdQuery,
} from '../../../../../../../../graphql/generated/graphql';
import LicenseFeatures from '../../../../../../../licenses/LicenseFeatures';
import { licenseDiffAmount, useLicenseDiff } from './LicenseElementModalHelper';
import LicenseElementModalsGeneralDetails from './LicenseElementModalsGeneralDetails';
import LicenseElementModalsLicenseDiff from './LicenseElementModalsLicenseDiff';
import LicenseElementModalsProductDetails from './LicenseElementModalsProductDetails';
import LicenseElementVerificationUpdate from './LicenseElementVerificationUpdate';
import MenuTab from './LicenseModalConstantsAndHelp';

/**
 * License Element Modal Props
 */
export type LicenseElementModalProps = {
  openLicensesModal: boolean;
  setOpenLicensesModal: (openLicensesModal: boolean) => void;
  dependencyId: string;
  subComponent: Subcomponent;
  subComponentEdge: DependencySubComponentsRelationship;
};

/**
 * License Element Modal
 *
 * @param {LicenseElementModalProps} props Props from LicenseElement => licenses and subComponentsConnections
 * @returns {JSX.Element} Modal for Product Licenses
 */
const LicenseElementModal = (props: LicenseElementModalProps): JSX.Element => {
  const {
    openLicensesModal,
    setOpenLicensesModal,
    dependencyId,
    subComponent,
    subComponentEdge,
  } = props;
  const [text] = useTranslation('productDetails');
  const [activeTab, setActiveTab] = useState(MenuTab.ProductLicense);

  const getLicenseText = useGetLicenseTextQuery({
    variables: {
      licenseIdentificationChecksum:
        subComponentEdge.licenseTextIdentificationChecksum || '',
    },
  });
  const licenseText = getLicenseText.data?.GetLicenseText;

  const licenseDiffResult = useLicenseDiff(
    subComponent,
    subComponentEdge,
    licenseText
  );

  const licenseDiffAmountObj = licenseDiffAmount(
    licenseDiffResult?.visualizedDiffResult
  );

  const {
    data: featuresData,
    loading: loadingFeatures,
    error: featuresErr,
  } = useLicenseFeaturesByIdQuery({
    variables: { licenseId: subComponent.licenses[0].id },
  });

  // concatenating features arrays to show total number of them
  const licenseFeatureData = featuresData?.License && featuresData?.License[0];
  const allLicFeatures = licenseFeatureData?.licenseFeatures?.concat(
    licenseFeatureData?.licenseConflictRules as [],
    licenseFeatureData?.conflictRules as [],
    licenseFeatureData?.toDos as [],
    licenseFeatureData?.additionalLicenseFeatures as []
  );

  return (
    <Modal
      id="LicensesElementModal"
      open={openLicensesModal}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      onClose={() => setOpenLicensesModal(false)}
      onOpen={() => setOpenLicensesModal(true)}
    >
      <Modal.Header className="LicensesElementModalHeader">
        <span className="LicensesElementModalHeaderTitle">
          <Header id="LicensesElementModalHeaderTitle">
            {`${subComponent.licenses[0].name} | ${subComponentEdge.spdxId}`}
          </Header>
          <Icon
            id="LicensesElementModalHeaderCloseIcon"
            name="close"
            link
            onClick={() => setOpenLicensesModal(false)}
          />
        </span>
        {/* VERIFY LICENSE TOGGLE  */}
        <LicenseElementVerificationUpdate
          subComponentEdge={subComponentEdge}
          dependencyId={dependencyId}
          subcomponentId={subComponent.id}
        />
      </Modal.Header>
      <div className="LicensesElementModalMenuContainer">
        <Menu
          id="LicensesElementModalMenuTabs"
          className="LicensesElementModalMenuTabs"
          secondary
          pointing
        >
          <Menu.Item
            name="Product license details"
            active={activeTab === MenuTab.ProductLicense}
            onClick={() => setActiveTab(MenuTab.ProductLicense)}
          >
            {text('dependencyList.licenseModal.productLicenseDetails')}
          </Menu.Item>
          <Menu.Item
            name="General license details"
            active={activeTab === MenuTab.GeneralDetails}
            onClick={() => setActiveTab(MenuTab.GeneralDetails)}
          >
            {text('dependencyList.licenseModal.generalLicenseDetails')}
          </Menu.Item>
          <Menu.Item
            name="License diff"
            active={activeTab === MenuTab.LicenseDiff}
            onClick={() => setActiveTab(MenuTab.LicenseDiff)}
          >
            {text('dependencyList.licenseModal.licenseDiff')}
            <Label
              id="LicenseDiffsCountLabel"
              circular
              color="blue"
              content={licenseDiffAmountObj.snippetsSum}
            />
          </Menu.Item>
          <Menu.Item
            name="License features"
            active={activeTab === MenuTab.LicenseFeatures}
            onClick={() => setActiveTab(MenuTab.LicenseFeatures)}
          >
            {text('dependencyList.licenseModal.licenseFeatures')}
            <Label
              id="LicenseFeaturesCountLabel"
              circular
              color="blue"
              content={
                loadingFeatures ? (
                  <Icon
                    id="LicenseFeaturesCountLabelWhenLoading"
                    fitted
                    loading
                    name="asterisk"
                  />
                ) : (
                  allLicFeatures?.length
                )
              }
            />
          </Menu.Item>
        </Menu>
      </div>
      <Modal.Content scrolling>
        {activeTab === MenuTab.ProductLicense && (
          <LicenseElementModalsProductDetails
            dependencyId={dependencyId}
            licenseText={licenseText}
            licenseTextLoading={getLicenseText.loading}
            subComponent={subComponent}
            subComponentEdge={subComponentEdge}
          />
        )}
        {activeTab === MenuTab.GeneralDetails && (
          <LicenseElementModalsGeneralDetails
            licenseId={subComponent.licenses[0].id}
          />
        )}
        {activeTab === MenuTab.LicenseDiff && (
          <LicenseElementModalsLicenseDiff
            filteredDiffSnippets={licenseDiffResult.visualizedDiffResult}
            originalLicenseText={licenseDiffResult.originalText}
            licenseDiffAmountObj={licenseDiffAmountObj}
            licenseTextLoading={getLicenseText.loading}
          />
        )}
        {activeTab === MenuTab.LicenseFeatures && (
          <LicenseFeatures
            licenceFeaturesObject={featuresData}
            featuresErr={featuresErr}
            className="displayed-in-modal"
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default LicenseElementModal;
