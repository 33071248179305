import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';

type PropsType = {
  verified: boolean | undefined;
};

/**
 * returns a popup component containing OR and AND licenses of subcomponents.
 *
 * @param {PropsType} props takes in licenses
 * @returns {JSX.Element} a span with license names and icon with amount
 */
const UnverifiedIcon = ({ verified }: PropsType): JSX.Element | null => {
  const { t } = useTranslation('productDetails');

  if (verified) {
    return null;
  }

  return (
    <Popup
      id="UnverifiedIconPopup"
      className="unverified-icon-popup"
      hoverable
      position="top center"
      content={<div>{t('dependencyList.tableBody.unverified')}</div>}
      trigger={
        <Icon
          id="UnverifiedIcon"
          className="unverified-license-icon"
          name="exclamation triangle"
          color="yellow"
        />
      }
    />
  );
};

export default UnverifiedIcon;
