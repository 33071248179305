import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import FilterMenu from '../../../../productsList/listComponents/FilterMenu';
import {
  sortByAttribute,
  toggleColumnActive,
} from '../../../../productsList/listComponents/helpers/formatters';
import {
  Columns,
  Column,
} from '../../../../productsList/listComponents/ProductListTypes';
import OverflowPopup from '../../../../productsList/listComponents/columnComponents/OverflowPopup';

type PropsType = {
  columns: Columns;
  setColumns?: React.Dispatch<React.SetStateAction<Columns>>;
};

/**
 * header of the dependency list table
 *
 * @param {PropsType} props takes in columns and setter for columns.
 * @returns {JSX.Element} table header containing column names.
 */
const DependencyListHeader = (props: PropsType): JSX.Element => {
  const { columns, setColumns } = props;
  const [text] = useTranslation('dashboard');
  return (
    <Table.Row key="headerRow">
      {columns.map((column: Column) => {
        if (column.permitted) {
          // hard coded columns that shall not be displayed
          if (
            column.name === 'distribution' ||
            column.name === 'standardSoftware' ||
            column.name === 'completeness'
          ) {
            return null;
          }
          return (
            <Table.HeaderCell
              className={column.active ? 'active' : ''}
              width={column.width}
              key={`tableHeader.${column.name}`}
              id={`tableHeader.${column.name}`}
            >
              <span
                id={`productsList.${column.name}.Paragraph`}
                style={{
                  width: 'calc(100% - 1.5em)',
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <OverflowPopup
                  content={
                    <>
                      {text(`tableHeader.${column.name}`)}
                      {column.name === 'todos' ? (
                        <>
                          <br />
                          <div>{text(`tableHeader.todos2`)}&nbsp;</div>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  }
                />
              </span>
              <span style={{ float: 'right' }}>
                {column.sorterInfo.sortable && (
                  <Icon
                    id="productsList.Sort"
                    link
                    onClick={() => {
                      if (
                        column.name === 'comments' ||
                        column.name === 'status'
                      ) {
                        return null;
                      }
                      if (setColumns) {
                        setColumns((oldColumns) => {
                          return sortByAttribute(column, oldColumns);
                        });
                        setColumns((oldColumns) => {
                          return toggleColumnActive(column, oldColumns);
                        });
                      }
                      return null;
                    }}
                    name="sort"
                  />
                )}
                {setColumns &&
                  column.filterInfo.filterable &&
                  !column.sorterInfo.sortable && (
                    <FilterMenu column={column} setColumns={setColumns} />
                  )}
              </span>
            </Table.HeaderCell>
          );
        }
        return null;
      })}
    </Table.Row>
  );
};

export default DependencyListHeader;

DependencyListHeader.defaultProps = {
  setColumns: () => null,
};
