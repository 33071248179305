import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Item, Label, Popup } from 'semantic-ui-react';
import {
  Grouped,
  GroupedSubcomponent,
} from '../../helper/licenseElementHelpers';
import UnverifiedIcon from './UnverifiedIcon';

type PropsType = {
  groupedSubcomponents: Grouped;
  handleLicenseClick: (groupedSubcomponent: GroupedSubcomponent) => void;
  isOr?: boolean;
};

/**
 * returns a popup component containing OR and AND licenses of subcomponents.
 *
 * @param {PropsType} props takes in licenses
 * @returns {JSX.Element} a span with license names and icon with amount
 */
const LicensesPopup = (props: PropsType): JSX.Element => {
  const { groupedSubcomponents, handleLicenseClick, isOr = false } = props;
  const { t } = useTranslation('productDetails');

  const countAllLicenses = (grouped: Grouped): number => {
    // Counting the number of groups with groupId (OR licenses)
    const groupsCount = Object.keys(grouped).filter(
      (key) => key !== 'noGroupId'
    ).length;
    // Counting subcomponents from the noGroupId group (AND licenses)
    const noGroupIdCount = grouped.noGroupId ? grouped.noGroupId.length : 0;
    return groupsCount + noGroupIdCount;
  };

  const licensesTotalNumber = countAllLicenses(groupedSubcomponents);

  const orLicenses = Object.keys(groupedSubcomponents).filter(
    (key) => key !== 'noGroupId'
  );

  const orLicensesNumber = orLicenses.length;
  const isOrLicensePlural = orLicensesNumber > 1;

  return (
    <Popup
      id="LicensesElementListOfLicensesPopup"
      className="license-popup"
      hoverable
      position="top center"
      content={
        <div>
          {orLicensesNumber > 0 && (
            <>
              <strong>
                {t(`dependencyList.tableBody.orLicenses.${isOrLicensePlural}`, {
                  orLicensesNumber,
                })}
              </strong>
              {orLicenses.map((key, index) => {
                if (key === 'noGroupId') return null;
                return (
                  <div key={`or-license-${key}`}>
                    <ul style={{ paddingLeft: '1em' }}>
                      {groupedSubcomponents[key].map((groupedSubcomponent) => (
                        <li
                          key={`or-license-part-${groupedSubcomponent.subComponent.id}`}
                        >
                          {groupedSubcomponent.subComponentEdge.selected ? (
                            <Item.Extra
                              onClick={() =>
                                handleLicenseClick(groupedSubcomponent)
                              }
                            >
                              <span>
                                {groupedSubcomponent.subComponentEdge.spdxId}
                              </span>
                              <UnverifiedIcon
                                verified={
                                  groupedSubcomponent.subComponentEdge.verified
                                }
                              />
                            </Item.Extra>
                          ) : (
                            <Item.Extra
                              onClick={() =>
                                handleLicenseClick(groupedSubcomponent)
                              }
                            >
                              <s id="unselectedLicense">
                                {groupedSubcomponent.subComponentEdge.spdxId}
                                <UnverifiedIcon
                                  verified={
                                    groupedSubcomponent.subComponentEdge
                                      .verified
                                  }
                                />
                              </s>
                            </Item.Extra>
                          )}
                        </li>
                      ))}
                    </ul>
                    {index !== orLicensesNumber - 1 && (
                      <Divider horizontal>
                        {t('dependencyList.tableBody.and')}
                      </Divider>
                    )}
                  </div>
                );
              })}
            </>
          )}
          {groupedSubcomponents.noGroupId && (
            <div id="AdditionalLicenses" style={{ paddingRight: '1em' }}>
              <strong>{t('dependencyList.tableBody.additional')}</strong>
              <ul style={{ paddingLeft: '1em' }}>
                {groupedSubcomponents.noGroupId
                  .filter((s) => s.subComponentEdge.subComponentName !== 'main')
                  .map((groupedSubcomponent) => (
                    <li
                      key={`additional-license-${groupedSubcomponent.subComponent.id}`}
                    >
                      <Item.Extra
                        onClick={() => handleLicenseClick(groupedSubcomponent)}
                      >
                        <span>
                          {groupedSubcomponent.subComponentEdge.spdxId}
                        </span>
                        <UnverifiedIcon
                          verified={
                            groupedSubcomponent.subComponentEdge.verified
                          }
                        />
                      </Item.Extra>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      }
      trigger={
        <Label
          circular
          id="LicensesCountLabel"
          content={
            isOr ? `/+ ${orLicensesNumber}` : `+${licensesTotalNumber - 1}`
          }
        />
      }
    />
  );
};

export default LicensesPopup;
