import { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';

import { CommentsModalContext } from '../../requirements/helper/Constants';
import {
  useGetCommentsForToDoInVersionQuery,
  useGetCommentsForRequirementInVersionQuery,
  Comment,
  useGetCommentsForHistoryLogInVersionQuery,
} from '../../../../../graphql/generated/graphql';

/**
 * Custom hook to handle comments queries for ToDo or Requirement or History in a Version
 *
 * @returns {{ commentsVar: Comment[] | undefined, isLoading: boolean, error: ApolloError | undefined }}   Returns an object
 * @param {string} versionId The version id
 * @param {string} refersToId id of queried property
 * @param {CommentsModalContext | null} context - The current context of the comments modal.
 */
const useCommentsQuery = (
  versionId: string,
  refersToId: string,
  context: CommentsModalContext | null
) => {
  const [commentsVar, setComments] = useState<Comment[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApolloError>();

  const {
    data: toDoData,
    loading: loadingToDo,
    error: errorToDo,
  } = useGetCommentsForToDoInVersionQuery({
    skip: context !== CommentsModalContext.Todos || !versionId,
    variables: { versionId, toDoId: refersToId },
    fetchPolicy: 'network-only',
  });

  const {
    data: requirementData,
    loading: loadingRequirement,
    error: errorRequirement,
  } = useGetCommentsForRequirementInVersionQuery({
    skip: context !== CommentsModalContext.Requirements || !versionId,
    variables: { versionId, requirementId: refersToId },
    fetchPolicy: 'network-only',
  });
  const {
    data: historyData,
    loading: loadingHistory,
    error: errorHistory,
  } = useGetCommentsForHistoryLogInVersionQuery({
    skip: context !== CommentsModalContext.History || !versionId,
    variables: { versionId, historyLogId: refersToId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const loading = loadingToDo || loadingRequirement || loadingHistory;

    // Delay loading state to avoid modal twitching
    const loadingTimeout = setTimeout(() => setIsLoading(loading), 200);
    setError(errorToDo || errorRequirement || errorHistory);

    if (context === CommentsModalContext.Todos && toDoData) {
      setComments(toDoData.GetCommentsForToDoInVersion as Comment[]);
    } else if (context === 'Requirements' && requirementData) {
      setComments(
        requirementData.GetCommentsForRequirementInVersion as Comment[]
      );
    } else if (context === 'History' && historyData) {
      setComments(historyData.GetCommentsForHistoryLogInVersion as Comment[]);
    }
    return () => clearTimeout(loadingTimeout);
  }, [
    toDoData,
    requirementData,
    loadingToDo,
    loadingRequirement,
    errorToDo,
    errorRequirement,
    context,
    loadingHistory,
    errorHistory,
    historyData,
  ]);

  return { commentsVar, isLoading, error };
};

export default useCommentsQuery;
