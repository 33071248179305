import {
  Columns,
  Sorting,
} from '../../../../productsList/listComponents/ProductListTypes';
import ColumnNames from './constants';

// type categoryOptionsType = {
//   key: string;
//   text: string;
//   value: string;
// };

// const categoryFiltering = categoryOptions.reduce(
//   (acc: Column['filterInfo']['filtering'], curr: categoryOptionsType) => {
//     acc[curr.key] = false;
//     return acc;
//   },
//   {}
// );

/**
 * @returns {Columns} array of columns
 */
const getDefaultColumns = (): Columns => {
  const allColumns: Columns = [
    {
      name: ColumnNames.FileName,
      shown: true,
      active: false,
      width: 3,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: ColumnNames.Category,
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: true,
        filtering: {}, // categoryFiltering,
      },
    },
    {
      name: ColumnNames.UploadedBy,
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: ColumnNames.UploadedAt,
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: ColumnNames.FileSize,
      shown: true,
      active: false,
      width: 1,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: ColumnNames.Downloads,
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: ColumnNames.Sha256,
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: true,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: ColumnNames.Actions,
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
  ];
  return allColumns;
};

export default getDefaultColumns;

/**
 * Change event for adding new coprights to ProductLicenseDetails
 */
export interface ChangeEventProps {
  value: string;
}
