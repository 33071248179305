import { useTranslation } from 'react-i18next';
import { DropdownItemProps } from 'semantic-ui-react';
import { SelectionType } from '../../../../../graphql/generated/graphql';

/**
 * Enumerates the contexts from which the comments modal can be opened.
 * It is crucial for determining the modal's behavior and rendering.
 */
export enum CommentsModalContext {
  Requirements = 'Requirements',
  Comments = 'Comments',
  Todos = 'Todos',
  History = 'History',
}

/**
 * Dropdown options for general version selection of all dependencies
 *
 * @returns {DropdownItemProps[]} dropdown options
 */
export const useSelectionTypeOpts = (): DropdownItemProps[] => {
  const { t } = useTranslation('requirements');
  const options = [
    {
      key: 1,
      text: t('deselected'),
      value: SelectionType.userdeselected,
      icon: { name: 'ban' },
    },
    {
      key: 2,
      text: t('selected'),
      value: SelectionType.userselected,
      icon: { name: 'checkmark' },
    },
    {
      key: 3,
      text: t('unknown'),
      value: SelectionType.unselected,
      icon: { name: 'question' },
    },
    {
      key: 4,
      text: t('individuall'),
      value: 'individuall',
      // className: 'SelectionRed',
      style: { display: 'none' }, // Make this option invisible
    },
  ];
  return options;
};
