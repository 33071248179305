import { ApolloError } from '@apollo/client/errors';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

type PropsType = {
  loading: boolean;
  error: ApolloError | undefined;
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  itemAmount: number;
  activePage: number;
};

/**
 * Input for the Items per Page to be shown to the User.
 *
 * Default MIN can be set via useState of the Component but here is the MAX Input set
 * MAX Input is 3 Digits, only Numbers are allowed and Delete/Backspace, Left/Right
 *
 * Additionally the input Field grows regarding the number of digits starting at 3rem, adding 0,5rem/digit
 *
 * @param {PropsType} props takes in loading and error status as
 * well as event function (setItemsPerPage)
 * @returns {JSX.Element} Items Per Page
 */
const ItemsPerPage = ({
  loading,
  error,
  itemsPerPage,
  setItemsPerPage,
  itemAmount,
  activePage,
}: PropsType): JSX.Element => {
  const timeoutID = useRef<number | undefined>();
  const { t } = useTranslation('dashboard');

  const itemStart = (activePage - 1) * itemsPerPage + 1;
  const itemEnd = Math.min(activePage * itemsPerPage, itemAmount);

  const calculateInputWidth = (value: string) => {
    return `${3 + (value.length - 1) * 0.5}rem`;
  };

  const [inputWidth, setInputWidth] = useState(
    calculateInputWidth(itemsPerPage.toString())
  );

  const itemsPerPageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d{1,3}$/.test(value) && parseInt(value, 10) >= 1) {
      setInputWidth(calculateInputWidth(value));
      if (timeoutID.current) clearTimeout(timeoutID.current);
      timeoutID.current = window.setTimeout(() => {
        setItemsPerPage(Math.min(parseInt(value, 10), itemAmount));
      }, 600);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    if (
      !allowedKeys.includes(e.key) &&
      (e.key < '0' || e.key > '9' || e.currentTarget.value.length >= 3)
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="ItemsPerPageContainer">
      <span>{t('perPage')}</span>
      <Input
        id="ItemsPerPage"
        className="ItemsPerPageInput"
        type="number"
        defaultValue={itemsPerPage.toString()}
        onChange={itemsPerPageChanged}
        onKeyDown={handleKeyDown}
        disabled={loading || !!error}
        style={{ width: inputWidth }}
        min="1"
      />
      <span className="items-counter">
        {t('itemsCounter', {
          itemStart,
          itemEnd,
          itemAmount,
        })}
      </span>
    </div>
  );
};

export default ItemsPerPage;
