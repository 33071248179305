import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import { SetValue } from '../../../../hooks/useLocalStorage';
import { useProductCounters } from './helpers/costumHooks';

type PropsType = {
  onlyProjects: boolean;
  setOnlyProjects: SetValue<boolean>;
};
/**
 * Toggle component to switch between normal and project-only views
 *
 * @param {PropsType} props takes in onlyProjects localStprage state and set function to set it..
 * @returns {JSX.Element} Toggle component
 */
const ProjectViewToggle = (props: PropsType): JSX.Element => {
  const { onlyProjects, setOnlyProjects } = props;
  const { t } = useTranslation('dashboard');

  const { totalProjects } = useProductCounters();

  const isActive = totalProjects > 0;

  return (
    <Popup
      id="ProjectViewToggleMainPopup"
      disabled={isActive}
      content={t('noProjects')}
      trigger={
        <div
          id="ProjectViewToggleComponent"
          className={isActive ? '' : 'disabled'}
        >
          <Checkbox
            id="ProjectViewToggleComponentCheckbox"
            toggle
            disabled={!isActive}
            checked={onlyProjects}
            onChange={() => setOnlyProjects(!onlyProjects)}
          />
          <strong>{t('projectToggle')}</strong>
          <Popup
            id="ProjectViewToggleComponentPopup"
            content={t('projectToggleInfo')}
            disabled={!isActive}
            trigger={
              <Icon
                id="ProjectViewToggleComponentIcon"
                name="info"
                color="blue"
                circular
                size="small"
              />
            }
          />
        </div>
      }
    />
  );
};
export default ProjectViewToggle;
