import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Maybe } from '../../../../../../../graphql/generated/graphql';

type PropsType = {
  copyleft: Maybe<boolean> | undefined;
};

/**
 * element for displaying the name of the parent dependency
 *
 * @param {PropsType} props id to source dependency
 * @returns {JSX.Element} element containing the name of the parent dependency
 */
const CopyleftElement = (props: PropsType): JSX.Element => {
  const { copyleft } = props;

  return (
    <span
      style={{
        textAlign: 'center',
        display: 'inline-block',
        width: '100%',
      }}
    >
      {copyleft ? (
        <Icon
          id="CopyleftStatusTrueIcon"
          name="copyright outline"
          aria-label="copyleft true"
          flipped="horizontally"
          size="large"
        />
      ) : (
        '-'
      )}
    </span>
  );
};

export default CopyleftElement;
