import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';

/**
 * AccessDenied Page for the Product details route.
 *
 * @returns {JSX.Element} NoAccessPage
 */
const NoAccessPage = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const navigate = useNavigate();
  return (
    <Segment
      textAlign="center"
      style={{ height: '100vh' }}
      id="NoAccessPage"
      placeholder
    >
      <Header icon>
        <Icon id="NoAccessIcon" name="dont" />
        {t('AccessDenied')}
      </Header>
      <Button
        id="BackButton"
        content={t('back')}
        onClick={() => navigate('/dashboard')}
      />
    </Segment>
  );
};

export default NoAccessPage;
