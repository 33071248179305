import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { Columns, Column } from './ProductListTypes';

type PropsType = {
  column: Column | undefined;
  setColumns: React.Dispatch<React.SetStateAction<Columns>>;
};

/**
 * FilterPills
 *
 * @param {PropsType} props takes in ...
 * @returns {JSX.Element} FilterPills
 */
const FilterPills = (props: PropsType): JSX.Element => {
  const { column, setColumns } = props;
  const [text] = useTranslation('dashboard');

  const filterByAttribute = (triggeredColumn: Column, filteringKey: string) => {
    // TODO: Auslagern & Merge with filterByAttributes in
    // creating temporary column, finding correct column
    // and replacing it in the state object with the temporary column
    const tempFiltering: { [K: string]: boolean } = {
      ...triggeredColumn.filterInfo.filtering,
      [filteringKey]: !triggeredColumn.filterInfo.filtering[filteringKey],
    };
    const tempFilterInfo = {
      filterable: true,
      filtering: tempFiltering,
    };
    const tempColumn: Column = {
      ...triggeredColumn,
      filterInfo: tempFilterInfo,
    };

    setColumns((oldColumns) => {
      return oldColumns.map((col) =>
        col.name === tempColumn.name ? tempColumn : col
      );
    });
  };
  if (column && column.filterInfo.filtering)
    return (
      <>
        {Object.keys(column?.filterInfo.filtering).map((filteringKey) => {
          return (
            <Button
              size="small"
              className={`filterpill ${filteringKey.toLowerCase()}`}
              active={column?.filterInfo.filtering[filteringKey]}
              id={`FilterMenu.Segment.FilterPill.${filteringKey}`}
              key={filteringKey}
              onClick={() => filterByAttribute(column, filteringKey)}
            >
              {text(`filterPills.${filteringKey}`)}
            </Button>
          );
        })}
      </>
    );

  return <div />;
};

export default FilterPills;
