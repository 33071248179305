import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps, Form, Icon, Input } from 'semantic-ui-react';
import { statusIcons } from '../../../helper/logics';

type Props = {
  setNewStatus: React.Dispatch<React.SetStateAction<string>>;
  newNote: string;
  setNewNote: (newNote: string) => void;
  currentStatus: string;
  newStatus: string;
};
/**
 *@returns {JSX.Element} customized dropdown option
 *@param {Props} props setNewStatus
 */
const LegalResultModalStatusForm = (props: Props): JSX.Element => {
  const [t] = useTranslation('productDetails');
  const {
    setNewStatus,
    currentStatus = 'UNKNOWN',
    newNote,
    setNewNote,
    newStatus,
  } = props;

  const resolverStatusOpts = [
    {
      key: 1,
      value: 'OK',
      text: (
        <span className="OK">
          <Icon
            id="LegalResultModalFormOkStatusIcon"
            name={statusIcons('OK')}
          />
          {t(`dashboard:filterPills.${'OK'}`)}
        </span>
      ),
    },
    {
      key: 2,
      text: (
        <span className="CONFLICT">
          <Icon
            id="LegalResultModalFormConflictStatusIcon"
            name={statusIcons('CONFLICT')}
          />
          {t(`dashboard:filterPills.${'CONFLICT'}`)}
        </span>
      ),
      value: 'CONFLICT',
    },
  ];

  return (
    <Form id="LegalResultModalForm">
      <Form.Group widths="equal">
        <Form.Field
          id="LegalResultModalCurrentStatus"
          control={Input}
          label={t('legalResultModal.currentStatus')}
        >
          <span className={currentStatus}>
            <Icon
              id="LegalResultModalFormStatusIcon"
              name={statusIcons(currentStatus)}
            />
            {t(`dashboard:filterPills.${currentStatus}`)}
          </span>
        </Form.Field>
        <Form.Field
          id="LegalResultModalStatusDropdown"
          control={Dropdown}
          selection
          closeOnChange
          options={resolverStatusOpts}
          label={t('legalResultModal.newStatus')}
          placeholder={t('legalResultModal.newStatusPlaceholder')}
          onChange={(_e: ChangeEvent, data: DropdownProps) =>
            setNewStatus(data.value as string)
          }
        />
      </Form.Group>
      <Form.TextArea
        readOnly={!newStatus}
        placeholder={t('legalResultModal.notePlaceholder')}
        style={{ resize: 'none' }}
        value={newNote || ''}
        rows={5}
        label={t('legalResultModal.noteTitle')}
        onChange={(_evt: ChangeEvent<HTMLTextAreaElement>, data) =>
          setNewNote(data.value as string)
        }
      />
    </Form>
  );
};

export default LegalResultModalStatusForm;
