import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

type PropsType = {
  status: string | undefined;
};

/**
 * basically only returns an element with the time till targetDate
 * nothing too fancy
 *
 * @param {PropsType} props takes in targetDate
 * @returns {JSX.Element} a span with text
 */
const ProjectStatusElement = (props: PropsType): JSX.Element => {
  const { status } = props;
  const [text] = useTranslation('dashboard');

  if (status) {
    return (
      <Button
        id="ProductsList.ProjectStatus"
        size="small"
        onClick={(e) => {
          e.preventDefault();
        }}
        className={`filterpill active ${status.toLowerCase()}`}
        content={text(`filterPills.${status}`)}
      />
    );
  }
  return <span>-</span>;
};

export default ProjectStatusElement;
