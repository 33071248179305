import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Form, Input, FormProps, Popup } from 'semantic-ui-react';
import { CreateUserMutationVariables } from '../../../graphql/generated/graphql';
import PasswordsFormValidations from './PasswordsFormValidations';
import { isValidEmail, isValidName } from './helpers/logics';

type AddNewUserDetailsFormProps = {
  newUserDetails: CreateUserMutationVariables;
  setCreateUserDetails: (newUserDetails: CreateUserMutationVariables) => void;
  repeatPass: {
    pass: string;
    enabled: boolean;
  };
  setRepeatPass: (repeatPass: { pass: string; enabled: boolean }) => void;
};

/**
 *@returns {JSX.Element} Element
 @param {AddNewUserDetailsFormProps} props Properties
 */
const AddNewUserDetailsForm = (
  props: AddNewUserDetailsFormProps
): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);
  const { newUserDetails, setCreateUserDetails, repeatPass, setRepeatPass } =
    props;

  const hasStartedTypingRef = useRef(false);

  const handleInputChange = (data: FormProps) => {
    const startedTyping = data.value.length > 0;
    if (!hasStartedTypingRef.current) {
      hasStartedTypingRef.current = startedTyping;
    }
  };

  return (
    <Segment id="AddNewUserDetails">
      <Form id="AddNewUserDetailsForm">
        <Popup
          id="AddNewUserDetailsFormNameError"
          open={
            hasStartedTypingRef.current &&
            (newUserDetails?.name?.trim() === undefined ||
              newUserDetails?.name.trim().length < 1 ||
              !isValidName(newUserDetails?.name?.trim()))
          }
          content={
            <div>
              <p id="NameErrorP">{t('invalidNameError')}</p>
              <i id="AllowedName" style={{ color: 'red' }}>
                {t('allowedName')}
              </i>
            </div>
          }
          position="top left"
          trigger={
            <Form.Field
              required
              value={newUserDetails.name}
              placeholder={t('name.placeholder')}
              data-cy="UserName"
              control={Input}
              label={t('name')}
              onChange={(
                _event: React.FormEvent<HTMLFormElement>,
                data: FormProps
              ) => {
                handleInputChange(data);
                setCreateUserDetails({
                  ...newUserDetails,
                  name: data.value,
                });
              }}
            />
          }
        />
        <Popup
          id="AddNewUserDetailsFormEmailError"
          open={
            newUserDetails.email !== '' && !isValidEmail(newUserDetails.email)
          }
          content={t('invalidEmailError')}
          position="top left"
          trigger={
            <Form.Field
              required
              value={newUserDetails.email}
              placeholder={t('email.placeholder')}
              data-cy="UserEmail"
              control={Input}
              label={t('email')}
              onChange={(
                _event: React.FormEvent<HTMLFormElement>,
                data: FormProps
              ) =>
                setCreateUserDetails({
                  ...newUserDetails,
                  email: data.value,
                })
              }
            />
          }
        />
      </Form>
      {/* PASSWORDS */}
      <PasswordsFormValidations
        setUsersPass={setCreateUserDetails}
        userDetails={newUserDetails}
        setRepeatPass={setRepeatPass}
        repeatPass={repeatPass}
        required
      />
    </Segment>
  );
};

export default AddNewUserDetailsForm;
