import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { Grid, Header, Icon, Menu, Message, Segment } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { convertISOtoLocalDate } from '../helpers/logics';
import ProductTags from './ProductTags';
import NotificationToggle from './NotificationToggle';
import HeaderPlaceholder from '../skeletons/HeaderSkeleton';
import ProjectStatusElement from '../../productsList/listComponents/columnComponents/ProjectStatusElement';
import {
  DirectoryRole,
  useUserDetailsHeaderQuery,
} from '../../../../graphql/generated/graphql';
import BrandName from './BrandName';

/**
 * Header Bar for the Product Details.
 *
 * @returns {JSX.Element} ProductDetailsHeader
 */
const ProductDetailsHeader = (): JSX.Element => {
  const { t, i18n } = useTranslation('productDetails');
  const [searchParams, setSearchParams] = useSearchParams();
  const { versionId, isProject, accessRoles } = useContext(
    ProductDetailsContext
  );

  const guest = accessRoles.includes(DirectoryRole.guest);
  let onlyGuestRights = false;
  if (
    guest &&
    accessRoles.filter((r: DirectoryRole) => r !== DirectoryRole.guest)
      .length === 0
  ) {
    onlyGuestRights = true;
  }
  const { error, data, loading } = useUserDetailsHeaderQuery({
    variables: { id: versionId },
  });
  if (loading) return <HeaderPlaceholder />;
  if (error)
    return (
      <Message
        id="ProductDetailsHeaderErrorMessage"
        error
        content={error.message}
      />
    );
  if (data) {
    const version = data.Version?.name;
    const productName = data.Version?.product?.name;
    const processStatus = data.Version?.processStatusAsString;
    const creator = data.Version?.createdBy.name;
    const createdDate = convertISOtoLocalDate(
      data.Version?.createdAt,
      i18n.language
    );
    return (
      <>
        <Segment
          id="ProductDetailsHeader"
          className="product-details-header"
          basic
          raised
        >
          <Grid columns={3}>
            <Grid.Column width={3} floated="left">
              <Link
                style={{ whiteSpace: 'nowrap', paddingTop: '0.5rem' }}
                to="/dashboard"
                id="ProductDetailsHeaderBackButton"
              >
                <Icon
                  id="ProductDetailsHeaderBackButtonIcon"
                  name="angle left"
                />
                {t('back')}
              </Link>
            </Grid.Column>
            <Grid.Column width={10}>
              <Header textAlign="center">
                {productName} @{version} |{' '}
                <b
                  id="ProductDetailsHeaderBackButtonUnBold"
                  style={{ fontWeight: 100, marginRight: '1rem' }}
                >
                  {t('id')} {versionId}
                </b>
                <ProjectStatusElement status={processStatus} />
              </Header>
              <ProductTags />
            </Grid.Column>
            <BrandName
              brandName={data?.Version?.product?.brandDirectory?.name || ''}
              width={1}
            />
            <Grid.Column
              width={2}
              floated="right"
              style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Header
                style={{ marginBottom: 0 }}
                as="h5"
                textAlign="right"
                disabled
              >
                {t('requester')}
              </Header>
              <Header style={{ marginTop: 0 }} textAlign="right" as="h5">
                {creator}
                <Header.Subheader style={{ marginTop: 3 }}>
                  <b id="ProductDetailsHeaderBackButtonCreatedDate">
                    {t('created')} {createdDate}
                  </b>
                </Header.Subheader>
              </Header>
              <NotificationToggle />
            </Grid.Column>
          </Grid>
          <div style={{ position: 'absolute', bottom: 2, width: '97%' }}>
            <Grid verticalAlign="bottom" columns={2}>
              <Grid.Column>
                <Menu
                  compact
                  pointing
                  secondary
                  id="ProductDetailsHeaderBackButtonPointingSubMenu"
                >
                  <Menu.Item
                    active={searchParams.get('view') === 'Details'}
                    onClick={() => setSearchParams({ view: 'Details' })}
                  >
                    {t('details')}
                  </Menu.Item>
                  {!isProject && (
                    <Menu.Item
                      active={searchParams.get('view') === 'Questions'}
                      onClick={() => setSearchParams({ view: 'Questions' })}
                    >
                      {t('requirements')}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    active={searchParams.get('view') === 'History'}
                    onClick={() => setSearchParams({ view: 'History' })}
                  >
                    {t('history')}
                  </Menu.Item>
                  <Menu.Item
                    active={searchParams.get('view') === 'Comments'}
                    onClick={() => setSearchParams({ view: 'Comments' })}
                  >
                    {t('comments')}
                  </Menu.Item>
                  <Menu.Item
                    active={searchParams.get('view') === 'Storage'}
                    onClick={() => setSearchParams({ view: 'Storage' })}
                  >
                    {t('storage')}
                  </Menu.Item>
                </Menu>
              </Grid.Column>
            </Grid>
          </div>
        </Segment>
        {onlyGuestRights && (
          <Message
            icon="exclamation circle"
            id="GuestViewMessage"
            warning
            attached="bottom"
            content={t('guestMessage')}
            style={{ marginBottom: 0, height: '7vh' }}
          />
        )}
      </>
    );
  }
  return <div />;
};

export default ProductDetailsHeader;
