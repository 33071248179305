import React, { Fragment } from 'react';
import { Grid, Placeholder, Segment, SemanticWIDTHS } from 'semantic-ui-react';

type GenericClosedSegmentSkeletonProps = {
  image?: boolean;
  height?: number | null;
  lines?: 1 | 2 | 3;
  linesLength?: 'short' | 'long' | 'full';
  labels?: 'none' | 'all' | 'firstOnly';
};

const lineLengthMap: { [key: string]: SemanticWIDTHS } = {
  short: 1,
  long: 2,
  full: 3,
};

// TODO: This component can be customized even more and become sort of 💫 universal placeholder

/**
 *@returns {JSX.Element} returns a skeleton component, which can be used for load state of the closed segments.
 *@param {GenericClosedSegmentSkeletonProps} props image (boolean), height (number), lines, linesLength, labels )
 */
const GenericClosedSegmentSkeleton = (
  props: GenericClosedSegmentSkeletonProps
): JSX.Element => {
  const {
    height,
    image = false,
    lines = 1,
    linesLength = 'full',
    labels = 'firstOnly',
  } = props;

  const mappedLength = lineLengthMap[linesLength];

  const linesMarkup = () => {
    const linePlaceholders = [];
    for (let i = 0; i < lines; i += 1) {
      const shouldRender =
        (i === 0 && labels === 'firstOnly') || labels === 'all';
      linePlaceholders.push(
        <Fragment key={`line${i}`}>
          <Grid.Column width={mappedLength}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length="full" />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          {shouldRender && (
            <Grid.Column width={1}>
              <Placeholder
                style={{ height: '24px', width: '24px', borderRadius: '50%' }}
              >
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
          )}
        </Fragment>
      );
    }
    return linePlaceholders;
  };

  return (
    <Segment
      id="GenericClosedSegment"
      raised
      style={{ height: height ? `${height}px` : null }}
    >
      <Grid
        id="GenericCloseSegmentGrid"
        columns="equal"
        verticalAlign="middle"
        style={{ height: height ? `${height}px` : null }}
      >
        {image && (
          <Grid.Column width={1}>
            <Placeholder>
              <Placeholder.Image style={{ height: '30px' }} />
            </Placeholder>
          </Grid.Column>
        )}
        {linesMarkup()}
        <Grid.Column />
      </Grid>
    </Segment>
  );
};

export default GenericClosedSegmentSkeleton;
