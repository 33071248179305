/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Header,
  Icon,
  Item,
  List,
  Message,
  Modal,
  Popup,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../context/ProductContext';
import {
  ImportStatus,
  ImportStep,
  useCancelJsonImportMutation,
  useImportStatusQuery,
  useJsonImportMutation,
  useProductImportModalQuery,
  useSystemsAllOnlineQuery,
} from '../../../../../../../graphql/generated/graphql';
import DependencyImportStatusItem from './DependencyImportStatusItem';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetchRunningImport: () => void;
};

/**
 * @returns {JSX.Element}  Modal for importing dependencies
 * @param {Props} props - amount of dependencies
 */
const DependencyImportModal = (props: Props): JSX.Element => {
  const { open, setOpen, refetchRunningImport } = props;
  const { versionId, authorizations } = useContext(ProductDetailsContext);
  const { t } = useTranslation(['productDetails']);
  const [rawFile, setRawFile] = useState<File | null>(null);
  const [jsonImportFile, setJsonImportFile] = useState<string | null>(null);
  const [inputError, setInputError] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const { data: productData } = useProductImportModalQuery({
    variables: {
      id: versionId,
    },
  });
  const productId = productData?.Version?.product?.id;
  const productName = productData?.Version?.product?.name;

  const [JsonImport, jsonImport] = useJsonImportMutation();
  const [CancelJsonImport] = useCancelJsonImportMutation();

  const importStatus = useImportStatusQuery({
    variables: { versionId },
    pollInterval: 500,
    notifyOnNetworkStatusChange: true,
    onCompleted: async () => {
      if (
        importStatus.previousData?.ImportStatus.status === undefined ||
        importStatus.data?.ImportStatus.status !==
          importStatus.previousData?.ImportStatus.status
      ) {
        refetchRunningImport();
      }
      if (importStatus.data?.ImportStatus?.status === ImportStatus.finished) {
        importStatus.stopPolling();
      }
    },
  });
  const systemOnlineQuery = useSystemsAllOnlineQuery({
    fetchPolicy: 'no-cache',
    onCompleted: async () => {
      if (systemOnlineQuery.data?.SystemsAllOnline === false) {
        importStatus.stopPolling();
      }
    },
  });
  const onFileChange = (event: any) => {
    setRawFile(event.target.files[0]);
  };

  const onImport = () => {
    if (!jsonImportFile || !productId || !versionId) {
      return;
    }
    JsonImport({
      variables: {
        productId,
        versionId,
        jsonImportFile,
        filename: rawFile?.name || 'importFile.json',
      },
    });
  };

  const status = importStatus.data?.ImportStatus?.status;
  const step = importStatus.data?.ImportStatus?.step;

  let allSystemsOnline = systemOnlineQuery.data
    ? systemOnlineQuery.data.SystemsAllOnline
    : true;

  const onInit = jsonImport.loading;
  const onRunning = status === ImportStatus.running;
  const onSuccess = status === ImportStatus.finished;
  const onCanceled = status === ImportStatus.canceled;
  const onError = !!(
    inputError || // Error in input of json file
    jsonImport.error || // Error initiating import
    importStatus.error || // Error in Import, returned as GraphQL error
    status === ImportStatus.error ||
    status === ImportStatus.networkError ||
    !allSystemsOnline
  );
  const errorMessages = () => {
    if (!allSystemsOnline || status === ImportStatus.networkError) {
      allSystemsOnline = false;
      return [t('dependencyList.importModal.systemOffline')];
    }
    if (importStatus.data?.ImportStatus?.errors) {
      return importStatus.data?.ImportStatus?.errors.map(
        (error) => error.message
      );
    }
    if (importStatus.error) {
      return [importStatus.error.message];
    }
    if (jsonImport.error) {
      return [jsonImport.error.message];
    }
    if (inputError) {
      return [t('dependencyList.importModal.input.error')];
    }
    return [];
  };

  useEffect(() => {
    if (rawFile !== null) {
      const reader = new FileReader();

      reader.readAsText(rawFile);
      reader.onload = (e: any) => {
        try {
          JSON.parse(e?.target?.result); // Throws error if not valid JSON
          setJsonImportFile(e?.target?.result);
          setInputError(false);
        } catch (err) {
          setJsonImportFile(null);
          setInputError(true);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);

  if (!authorizations?.includes('JsonImport')) {
    return <div />;
  }

  return (
    <Modal
      id="ImportModal"
      open={open}
      size="tiny"
      style={{ minHeight: '50%' }}
    >
      <Modal.Header id="ImportModal.Header">
        {`${t('dependencyList.importModal.header')} ${productName}`}
        <Icon
          id="iconCloseModal"
          name="close"
          link
          floated="right"
          onClick={() => setOpen(false)}
        />
      </Modal.Header>
      <Modal.Content id="ImportModal.Content">
        <Form
          id="ImportModal.Content.Form"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Button
            id="ImportModal.Content.Form.InputJson.Button"
            basic
            icon="file"
            labelPosition="left"
            disabled={onRunning || onInit || onSuccess || !allSystemsOnline}
            content={
              rawFile?.name || t('dependencyList.importModal.input.placeholder')
            }
            color={onError ? 'red' : 'blue'}
            style={{
              flexGrow: 1,
              textAlign: 'left',
              margin: 0,
            }}
            onClick={() => {
              document
                .getElementById('ImportModal.Content.Form.InputJson.Input')
                ?.click();
            }}
          />
          <Form.Input
            id="ImportModal.Content.Form.InputJson.Input"
            type="file"
            accept=".json"
            disabled={onRunning || onInit || onSuccess || !allSystemsOnline}
            onChange={onFileChange}
            style={{ position: 'absolute', left: '-9999px' }}
          />
          {onRunning ? (
            <Form.Button
              id="ImportModal.Content.Button.Import.Cancel"
              onClick={() => {
                CancelJsonImport({
                  variables: { versionId },
                });
              }}
              color="red"
              basic
              content={t('dependencyList.importModal.button.cancel')}
              style={{ margin: 0 }}
            />
          ) : (
            <Form.Button
              id="ImportModal.Content.Test.Button.Import"
              onClick={onImport}
              primary
              loading={onInit}
              disabled={!jsonImportFile || onInit || onSuccess}
              content={t('dependencyList.importModal.button.import')}
              style={{ margin: 0 }}
            />
          )}
          {inputError && (
            <Message
              id="DependencyImportModalInputError"
              error
              visible
              icon="times circle"
              content={t('dependencyList.importModal.input.error')}
            />
          )}
        </Form>
        {importStatus.data?.ImportStatus.step && (
          <>
            <Header
              id="ImportModal.Content.Status.Header"
              content={t('dependencyList.importModal.status')}
              as="h4"
            />
            <List
              id="ImportModal.Content.Status.List"
              as="ol"
              className="suffixed"
            >
              <DependencyImportStatusItem
                success={
                  status === ImportStatus.finished ||
                  step === ImportStep.schemaValidated ||
                  step === ImportStep.dataValidated
                }
                error={onError}
                canceled={onCanceled}
                step={step === ImportStep.validateSchema}
                text={t('dependencyList.importModal.validateSchema')}
              />
              <DependencyImportStatusItem
                success={
                  status === ImportStatus.finished ||
                  step === ImportStep.dataValidated
                }
                error={onError}
                canceled={onCanceled}
                step={step === ImportStep.schemaValidated}
                text={t('dependencyList.importModal.validateData')}
              />
              <DependencyImportStatusItem
                success={status === ImportStatus.finished}
                error={onError}
                canceled={onCanceled}
                step={step === ImportStep.dataValidated}
                text={t('dependencyList.importModal.importData')}
              />
            </List>
          </>
        )}

        {onError && (
          <Item
            id="ImportModal.Content.Error"
            style={{ color: '#DB2828', marginBottom: '1rem' }}
          >
            <hr />
            <Item.Content
              id="ImportModal.Content.Error.Default"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              {allSystemsOnline && status !== ImportStatus.networkError && (
                <>
                  <Item.Extra
                    id="ImportModal.Content.Error.ShowMore.Button"
                    content={t(showMore ? 'show.less' : 'show.more', {
                      ns: 'buttons',
                    })}
                    className="inline-link"
                    onClick={() => setShowMore(!showMore)}
                  />
                  <Popup
                    id="copyicontooltip"
                    content={t('dependencyList.importModal.copyImportError')}
                    trigger={
                      <Icon
                        id="ImportModalCopyToClipboardButton"
                        name="copy outline"
                        link
                        color="black"
                        size="large"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            errorMessages().join('\n')
                          );
                        }}
                        style={{ marginLeft: 'auto' }}
                      />
                    }
                  />
                </>
              )}
              <Message
                id="DependencyImportModalError"
                error
                icon="times circle"
                header={t('dependencyList.importModal.importError.header')}
                content={
                  !showMore &&
                  (allSystemsOnline && status !== ImportStatus.networkError
                    ? t('dependencyList.importModal.import.error')
                    : t('dependencyList.importModal.systemOffline'))
                }
                list={showMore ? errorMessages() : []}
              />
            </Item.Content>
          </Item>
        )}
        {onSuccess && (
          <Message
            id="DependencyImportModalSuccess"
            success
            icon="check circle"
            content={importStatus.data?.ImportStatus?.message}
          />
        )}
        {onCanceled && (
          <Message
            id="DependencyImportModalCanceled"
            warning
            icon="exclamation triangle"
            content={t('dependencyList.importModal.canceled')}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {onSuccess && (
          /* TODO: Button Styling */
          <Button
            id="CloseBtn"
            onClick={() => setOpen(false)}
            content={t('dependencyList.importModal.button.close')}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DependencyImportModal;
