import React from 'react';
import { useTranslation } from 'react-i18next';
import * as client from '@apollo/client';
import { Button, Message } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';
import { USER_LOGIN } from '../../graphql/mutations/UserMutations';
import { lastUpdatedOn } from '../imprintAndLegal/TermsOfUse';

type LoginButtonProps = {
  email: string;
  password: string;
  systemOffline: boolean;
  setOpenTermsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Login Button
 * Semantic UI Button with login mutation.
 *
 * @param {LoginButtonProps} props Email and password
 * @returns {JSX.Element} Login Button element
 */
const LoginButton = (props: LoginButtonProps): JSX.Element => {
  const [login, { data, error }] = client.useMutation(USER_LOGIN);
  const { email, password, setOpenTermsModal, systemOffline } = props;
  const [t] = useTranslation('buttons');
  const [cookies] = useCookies(['terms']);

  const terms = cookies?.terms?.user || [];
  const termsRead = terms.includes(`${email} - ${lastUpdatedOn}`);

  if (data) {
    localStorage.setItem('token', data.Login);
    localStorage.removeItem('expired');
    window.location.assign(localStorage.lastUrl || '/');
  }

  const handleLoginButtonClick = () => {
    if (termsRead) {
      login({ variables: { email, password } });
    } else setOpenTermsModal(true);
  };

  return (
    <>
      <Button
        disabled={email.trim() === '' || systemOffline}
        id="LoginButton"
        color="blue"
        data-cy="LoginButton"
        fluid
        size="large"
        onClick={handleLoginButtonClick}
      >
        {t('login')}
      </Button>
      {!!error && (
        <Message
          id="LoginButtonError"
          visible
          error
          icon="times circle"
          content={`Error ${error.message}`}
        />
      )}
    </>
  );
};

export default LoginButton;
