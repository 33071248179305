import { Item } from 'semantic-ui-react';
import React from 'react';
import { User } from '../../../../graphql/generated/graphql';
import { WizardTeamDropdownOption } from './types';

/**
 * @returns {WizardTeamDropdownOption[]} dropdown options for the wizard team modals
 * @param {User[]} users array of users
 */
const dropdownOptions = (users: User[]): WizardTeamDropdownOption[] =>
  users?.map((user) => ({
    key: user.id,
    text: user.name,
    value: user.id,
    email: user.email,
    roles: user.roles,
    content: <Item id="AddUser" header={user?.name} meta={user?.email} />,
  }));

export default dropdownOptions;
