// write an enum containing the names of the getDefault function in types.ts
enum ColumnNames {
  FileName = 'filename',
  Category = 'type',
  UploadedBy = 'userName',
  UploadedAt = 'created',
  FileSize = 'size',
  Downloads = 'downloadCount',
  Sha256 = 'sha256',
  Actions = 'actions',
}

export default ColumnNames;
