import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Item } from 'semantic-ui-react';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';
import HighPriorityRequestModal from './HighPriorityRequestModal';

/**
 * @returns {JSX.Element}  High priority request Component for Info Box
 */
const HighPriorityRequestItem = (): JSX.Element => {
  const { t } = useTranslation(['productDetails', 'buttons']);
  const [openModal, setOpenModal] = useState(false);
  const { version, versionInput, editing } = useContext(ProductInfoBoxContext);
  const highPriorityRequest =
    versionInput.highPriorityRequest ??
    (version?.product && 'highPriorityRequest' in version.product
      ? version.product.highPriorityRequest
      : undefined);

  return (
    <>
      <HighPriorityRequestModal open={openModal} setOpen={setOpenModal} />
      <Item id="HighPriorityRequestContent">
        <Item.Content>
          <Item.Meta>
            {/* ICON INFO IN CIRCLE */}

            {t('highPriority')}
            <InfoIconTooltip infoText={t('highPriorityInfo')} />
            {editing && (
              <Item.Extra
                className="edit-link"
                onClick={() => setOpenModal(true)}
              >
                {t('editHighPriority', { ns: 'buttons' })}
              </Item.Extra>
            )}
          </Item.Meta>
          {highPriorityRequest === undefined ? (
            <Item.Description style={{ color: 'black' }}>
              {t('enterHighPriorityMessage')}
            </Item.Description>
          ) : (
            <Item.Description style={{ color: 'black', fontWeight: 'bold' }}>
              {highPriorityRequest ? (
                <Icon
                  id="HighPriorityRequestIconHigh"
                  name="angle double up"
                  color="red"
                />
              ) : (
                <Icon
                  id="HighPriorityRequestIconNotHigh"
                  name="angle double down"
                  color="blue"
                />
              )}
              <span>{t(`highPriority.${highPriorityRequest}`)}</span>
            </Item.Description>
          )}
        </Item.Content>
      </Item>
    </>
  );
};

export default HighPriorityRequestItem;
