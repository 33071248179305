import {
  DependencySubComponentsRelationship,
  Subcomponent,
} from '../../../../../../graphql/generated/graphql';

/**
 * Represents a subcomponent that is grouped with additional properties.
 *
 * @interface GroupedSubcomponent
 * @augments Subcomponent
 * @property {boolean} selected - Indicates if the subcomponent is selected (as an active part of OR license).
 * @property {string | undefined} spdxId - The SPDX identifier for the license, if available.
 */
export interface GroupedSubcomponent {
  subComponent: Subcomponent;
  subComponentEdge: DependencySubComponentsRelationship;
}

/**
 * Represents an object structure where keys are group IDs and values are arrays of grouped subcomponents.
 * Group = Licenses, which are parts of the OR license type.
 *
 * @interface Grouped
 */
export interface Grouped {
  [key: string]: GroupedSubcomponent[];
}

/**
 * Groups subcomponents based on their group IDs and adds additional properties like 'selected' and 'spdxId'.
 * If a subcomponent does not have a group ID, it will be grouped under 'noGroupId'.
 *
 * @function groupSubComponents
 * @param {Subcomponent[]} subComponents - The list of subcomponents to group.
 * @param {DependencySubComponentsRelationship[] | undefined} subComponentEdges - The relationships of subcomponents.
 * @returns {Grouped} An object containing grouped subcomponents.
 */
export const groupSubComponents = (
  subComponents: Subcomponent[],
  subComponentEdges: DependencySubComponentsRelationship[]
) => {
  const grouped: Grouped = {};

  subComponentEdges?.forEach((edge) => {
    // Find the corresponding subcomponent
    const subComponent = subComponents.find((sub) => sub.id === edge.node.id);
    if (!subComponent) return;

    const { groupId } = subComponent;

    if (!groupId) {
      if (!grouped.noGroupId) grouped.noGroupId = [];
      grouped.noGroupId.push({
        subComponent,
        subComponentEdge: edge,
      });
      return;
    }

    if (!grouped[groupId]) {
      grouped[groupId] = [];
    }

    grouped[groupId].push({
      subComponent,
      subComponentEdge: edge,
    });
  });

  return grouped;
};

/**
 * This function finds the subComponent which should be displayed in LicensesElement component.
 * It first checks, whether there is 'main' subcomponent, if not it fallback to those containing
 *  'main_' and being selected, then just selected ones and finally first one from noGroupId group.
 *
 * @function getDisplayedSubcomponent
 * @param {Grouped} grouped - An object containing grouped subcomponents.
 * @returns {GroupedSubcomponent} subcomponent that is grouped with additional properties.
 */
export const getDisplayedSubcomponent = (
  grouped: Grouped
): GroupedSubcomponent => {
  const subcomponentGroups = Object.keys(grouped)
    .filter((key) => key !== 'noGroupId')
    .map((key) => grouped[key]);

  const mainSubcomponent = grouped.noGroupId
    ?.flat()
    ?.find((sub) => sub.subComponentEdge.subComponentName === 'main');

  if (mainSubcomponent) {
    return mainSubcomponent;
  }

  const mainAndSelectedSubcomponents = subcomponentGroups
    .flat()
    .filter(
      (sub) =>
        sub?.subComponentEdge.subComponentName?.includes('main_') &&
        sub.subComponentEdge.selected
    );

  if (mainAndSelectedSubcomponents.length > 0) {
    return mainAndSelectedSubcomponents[0];
  }

  const allSelectedSubs = subcomponentGroups
    .flat()
    .filter((sub) => sub.subComponentEdge.selected);
  return allSelectedSubs.length > 0 ? allSelectedSubs[0] : grouped.noGroupId[0];
};
