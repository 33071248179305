import React, { useContext, useState } from 'react';
import { Segment, Transition } from 'semantic-ui-react';
import WizardProductTypeSelection from './productTypeSelection/WizardProductTypeSelection';
import WizardProductDetailsInput from './secondStep-productDetails/WizardProductDetailsInput';
import WizardPartsSegment from './partsSelection/WizardPartsSegment';
import WizardContext from '../../context/WizardContext';
import WizardIntendedUse from './fourthStep-IntendedUse/WizardIntendedUse';
import WizardTeamSelection from './fifthStep-Team/WizardTeamSelection';
/**
 * Main Segment for creating new Product wizard.
 *
 * @returns {JSX.Element} WizardMainSegment
 */
const WizardMainSegment = (): JSX.Element => {
  const { currentStep } = useContext(WizardContext);
  const [isTransitionMounted, setIsTransitionMounted] = useState(false);

  const handleTransitionShow = () => {
    setIsTransitionMounted(true);
  };

  const handleTransitionHide = () => {
    setIsTransitionMounted(false);
  };

  return (
    <Segment
      basic
      tertiary
      raised
      id="WizardMainSegment"
      style={{ overflowY: isTransitionMounted ? 'hidden' : 'auto' }}
    >
      {/* STEP ONE */}
      <Transition
        unmountOnHide
        onStart={handleTransitionShow}
        onComplete={handleTransitionHide}
        animation="fade"
        duration={300}
        visible={currentStep === 1}
      >
        <Segment id="WizardStepSegment1" className="WizardStepSegment">
          <WizardProductTypeSelection />
        </Segment>
      </Transition>
      {/* STEP TWO -Product Details */}
      <Transition
        unmountOnHide
        onStart={handleTransitionShow}
        onComplete={handleTransitionHide}
        animation="fade"
        duration={300}
        visible={currentStep === 2}
      >
        <Segment id="WizardStepSegment2" className="WizardStepSegment">
          <WizardProductDetailsInput />
        </Segment>
      </Transition>
      {/* STEP THREE -Parts Segment */}
      <Transition
        unmountOnHide
        onStart={handleTransitionShow}
        onComplete={handleTransitionHide}
        animation="fade"
        duration={300}
        visible={currentStep === 3}
      >
        <Segment id="WizardStepSegment3" className="WizardStepSegment">
          <WizardPartsSegment />
        </Segment>
      </Transition>
      {/* STEP FOUR -Intended Use */}
      <Transition
        unmountOnHide
        onStart={handleTransitionShow}
        onComplete={handleTransitionHide}
        animation="fade"
        duration={300}
        visible={currentStep === 4}
      >
        <Segment id="WizardStepSegment4" className="WizardStepSegment">
          <WizardIntendedUse />
        </Segment>
      </Transition>
      {/* STEP FIVE - Team Selection */}
      <Transition
        unmountOnHide
        onStart={handleTransitionShow}
        onComplete={handleTransitionHide}
        animation="fade"
        duration={300}
        visible={currentStep === 5}
      >
        <Segment id="WizardStepSegment5" className="WizardStepSegment">
          <WizardTeamSelection />
        </Segment>
      </Transition>
    </Segment>
  );
};

export default WizardMainSegment;
