import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import WizardIntendedUseDistribution from './WizardIntendedUseDistribution';
import WizardIntendedUseProtection from './WizardIntendedUseProtection';
import WizardIntendedUseHighPriorityRequest from './WizardIntendedUsePriority';

/**
 *@returns {JSX.Element} Intended Use Komponent/ View as step 4 in the Wizard Product Creation Process
 */
const WizardIntendedUse = (): JSX.Element => {
  const {
    wizardInput,
    setPartValidated,
    currentPart,
    chosenDist,
    setStepComplete,
    identicalIntendedUse,
  } = useContext(WizardContext);

  const wizPart = wizardInput.partInput;
  const checkedPart = wizPart[currentPart - 1];

  // validating input fields
  const checkFields = (inputValue: string | boolean) => {
    if (inputValue !== undefined && inputValue !== null && inputValue !== '') {
      return true;
    }
    return false;
  };
  const partIsValidated =
    checkFields(checkedPart?.distribution) &&
    checkFields(checkedPart?.protection) &&
    checkFields(checkedPart?.highPriorityRequest || false) &&
    checkFields(chosenDist[currentPart - 1]?.dist);

  useEffect(() => {
    if (partIsValidated && identicalIntendedUse === false) {
      setPartValidated(true);
    } else if (partIsValidated && identicalIntendedUse === true) {
      setPartValidated(false);
      setStepComplete(true);
    } else setPartValidated(false);
  }, [
    currentPart,
    partIsValidated,
    identicalIntendedUse,
    setStepComplete,
    setPartValidated,
  ]);

  return (
    <Grid columns={3} divided padded="horizontally" style={{ height: '100%' }}>
      <Grid.Row style={{ height: '80%' }}>
        {/* ------FIRST COLUMN => DISTRIBUTION ------ */}
        <Grid.Column>
          <WizardIntendedUseDistribution />
        </Grid.Column>
        {/* ------SECOND COLUMN => RISK ASSESSMENT ------ */}
        <Grid.Column>
          <WizardIntendedUseHighPriorityRequest />
        </Grid.Column>
        {/* ------THIRD COLUMN => PROTECTION ------ */}
        <Grid.Column>
          <WizardIntendedUseProtection />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WizardIntendedUse;
