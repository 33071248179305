import React from 'react';
import { useTranslation } from 'react-i18next';
import { DirectoryRole } from '../../../../../graphql/generated/graphql';

type PropsType = {
  roleArray: DirectoryRole[];
};

/**
 * returns an element with the details of change
 *
 * @param {PropsType} props takes changeType
 * @returns {JSX.Element} a span with text
 */
const RoleElement = (props: PropsType): JSX.Element => {
  // as of now, only JSON_UPLOAD is known using the type NodeChange
  const [userText] = useTranslation('users');
  const { roleArray } = props;

  if (roleArray.length === 0) {
    return (
      <span key="auto" className="role">
        -
      </span>
    );
  }

  const nonDuplicateRoleArray = roleArray.filter(
    (role, pos, parentRoleArray) => {
      return parentRoleArray.indexOf(role) === pos;
    }
  );
  return (
    <>
      {nonDuplicateRoleArray.map(
        (
          role: DirectoryRole,
          index: number,
          parentRoleArray: DirectoryRole[]
        ) => {
          if (index < parentRoleArray.length - 1) {
            return (
              <span key={role} className="role">
                {userText(`role.${role}`)}
                {', '}
              </span>
            );
          }
          return (
            <span key={role} className="role">
              {userText(`role.${role}`)}
            </span>
          );
        }
      )}
    </>
  );
};

export default RoleElement;
