import { Distribution, User } from '../../../../graphql/generated/graphql';
import { DistributionIds } from '../../../wizard/WizardConstants';

/**
 * @param {Distribution} distribution of an product
 * @returns {string} of locale for dashboard.json
 */
export function distributionLocale(distribution: Distribution): string {
  switch (distribution.id) {
    case DistributionIds.SERVERUSAGE:
      return 'tableElements.distribution.serveruse';
    case DistributionIds.NODISTRIBUTION:
      return 'tableElements.distribution.false';
    case DistributionIds.DISTRIBUTIONBINARYEXTERNAL:
      return 'tableElements.distribution.external';
    case DistributionIds.DISTRIBUTIONBINARYINTERNAL:
      return 'tableElements.distribution.internal';
    case DistributionIds.DISTRIBUTIONSOURCEEXTERNAL:
      return 'tableElements.distribution.external';
    case DistributionIds.DISTRIBUTIONSOURCEINTERNAL:
      return 'tableElements.distribution.internal';
    default:
      return 'tableElements.distribution.null';
  }
}
/**
 * @param {User[]} array of an product
 * @returns {string} of locale for dashboard.json
 */
const checkForSupplier = (array: User[]): string => {
  if (array && array.length > 0) {
    return 'tableElements.supplierParticipation';
  }
  return 'tableElements.noSupplier';
};
export default checkForSupplier;
