import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import { User } from '../../../graphql/generated/graphql';
import AllUsersInfoTabBody from './AllUsersInfoTabBody';

type AllUsersInfoTabProps = {
  activeTab: string;
  activeUsers: User[];
  inactiveUsers: User[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setChosenUser: React.Dispatch<React.SetStateAction<User | undefined>>;
};

/**
 *
 * All users table with Active and inactive users Tabs for admin view
 *
 * @param {AllUsersInfoTabProps} props Props
 *@returns {JSX.Element} Menu.Menu element with AddUSer Button and Search Field
 */
const AllUsersInfoTable = (props: AllUsersInfoTabProps): JSX.Element => {
  const { t } = useTranslation(['users', 'buttons']);
  const { activeTab, activeUsers, inactiveUsers, setChosenUser, setOpenModal } =
    props;

  return (
    <Table id="AllUsersInfoTable" celled compact definition>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell>
            <Icon
              circular
              color="grey"
              size="big"
              id="AllUsersInfoTableUserIcon"
              name="user"
            />
            {t('name')}
          </Table.HeaderCell>
          <Table.HeaderCell>{t('user-roles')}</Table.HeaderCell>
          <Table.HeaderCell>{t('email')}</Table.HeaderCell>
          <Table.HeaderCell>
            <Icon
              circular
              color="grey"
              size="big"
              id="AllUsersInfoTableActiveUsersGroupIcon"
              name="users"
            />
            {t('user-groups')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('editUser', { ns: 'buttons' })}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {/* ACTIVE USERS */}
      {activeTab === 'active' &&
        activeUsers.map((user: User) => (
          <AllUsersInfoTabBody
            key={`AllActiveUsersInfo${user.id}`}
            user={user}
            setOpenModal={setOpenModal}
            setChosenUser={setChosenUser}
          />
        ))}
      {/* INACTIVE USERS */}
      {activeTab === 'inactive' &&
        inactiveUsers.map((user: User) => (
          <AllUsersInfoTabBody
            key={`AllInactiveUsersInfo${user.id}`}
            user={user}
            setOpenModal={setOpenModal}
            setChosenUser={setChosenUser}
          />
        ))}
    </Table>
  );
};

export default AllUsersInfoTable;
