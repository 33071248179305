import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Popup, Segment } from 'semantic-ui-react';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  MissingInfo,
  useCompletionPossibleLazyQuery,
  useFinishSupplierReportPossibleLazyQuery,
  useLegalCheckDonePossibleLazyQuery,
  useLegalCheckPossibleLazyQuery,
  useProjectCompletionPossibleLazyQuery,
  useSupplierInputDonePossibleLazyQuery,
  useSupplierInputPossibleLazyQuery,
  useSupplierToDosDonePossibleLazyQuery,
  useSupplierToDosPossibleLazyQuery,
} from '../../../../graphql/generated/graphql';
import LCDownloadButton from './LCDownloadButton';
import ReportDownloadButton from './ReportDownloadButton';
import StateChangeButton from './StateChangeButton';
import {
  StateMapping,
  getMutationsForRoleAndStatus,
  getPopupInfo,
  mappingStatesToMutation,
} from './helper/logics';
import EmailBrandOSOButton from './EmailBrandOSOButton';
import {
  ProcessStatus,
  ProcessStatusType,
} from '../dependencySegment/dependencyList/helper/types';

/**
 * Footbar with Dynamic Button for Product Workflow.
 *
 * @returns {JSX.Element} ProductDetailsFootbar.
 */
const ProductDetailsFootBar = (): JSX.Element | null => {
  const { t } = useTranslation('productDetails');
  const {
    versionId,
    authorizations,
    accessRoles,
    processStatusAsString,
    isProject,
    isGroupTransfer,
  } = useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);

  const showMessage =
    selectedRole === 'developer' &&
    isGroupTransfer &&
    processStatusAsString === ProcessStatus[ProcessStatusType.OSOREQUESTED];

  const [legalCheckPossible, { data: legalCheckPossibleData }] =
    useLegalCheckPossibleLazyQuery({
      variables: { versionId },
    });
  const [legalCheckDonePossible, { data: LegalCheckDonePossibleData }] =
    useLegalCheckDonePossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierInputPossible, { data: supplierInputPossibleData }] =
    useSupplierInputPossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierInputDonePossible, { data: supplierInputDonePossibleData }] =
    useSupplierInputDonePossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierToDosPossible, { data: supplierToDosPossibleData }] =
    useSupplierToDosPossibleLazyQuery({
      variables: { versionId },
    });
  const [supplierToDosDonePossible, { data: SupplierToDosDonePossibleData }] =
    useSupplierToDosDonePossibleLazyQuery({
      variables: { versionId },
    });
  const [
    finishSupplierReportPossible,
    { data: finishSupplierReportPossibleData },
  ] = useFinishSupplierReportPossibleLazyQuery({
    variables: { versionId },
  });
  const [completionPossible, { data: completionPossibleData }] =
    useCompletionPossibleLazyQuery({
      variables: { versionId },
    });
  const [projectCompletionPossible, { data: projectCompletionPossibleData }] =
    useProjectCompletionPossibleLazyQuery({
      variables: { versionId },
      fetchPolicy: 'cache-and-network',
    });

  const disabled = (state: StateMapping) => {
    if (!state.authorized) {
      return true; // Disable if not authorized
    }
    if (state.mutationName === 'SubmitToLegal') {
      return !legalCheckPossibleData?.LegalCheckPossible?.stateChangePossible;
    }
    if (state.mutationName === 'RequestSupplierInput') {
      return !supplierInputPossibleData?.SupplierInputPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'RequestSupplierToDos') {
      return !supplierToDosPossibleData?.SupplierToDosPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishLegalCheck') {
      return !LegalCheckDonePossibleData?.LegalCheckDonePossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishProductCheck') {
      return !completionPossibleData?.CompletionPossible?.stateChangePossible;
    }
    if (state.mutationName === 'SupplierInput') {
      return !supplierInputPossibleData?.SupplierInputPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'SupplierInputDone') {
      return !supplierInputDonePossibleData?.SupplierInputDonePossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'SupplierToDosDone') {
      return !SupplierToDosDonePossibleData?.SupplierToDosDonePossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishProjectCheck') {
      return !projectCompletionPossibleData?.ProjectCompletionPossible
        ?.stateChangePossible;
    }
    if (state.mutationName === 'FinishSupplierReport') {
      return !finishSupplierReportPossibleData?.FinishSupplierReportPossible
        ?.stateChangePossible;
    }
    return false;
  };

  const reasonCode = (state: StateMapping): MissingInfo | undefined => {
    if (state.mutationName === 'SubmitToLegal') {
      return (
        legalCheckPossibleData?.LegalCheckPossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'RequestSupplierInput') {
      return (
        supplierInputPossibleData?.SupplierInputPossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'SupplierInputDone') {
      return (
        supplierInputDonePossibleData?.SupplierInputDonePossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'RequestSupplierToDos') {
      return (
        supplierToDosPossibleData?.SupplierToDosPossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'FinishLegalCheck') {
      return (
        LegalCheckDonePossibleData?.LegalCheckDonePossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'FinishProductCheck') {
      return (
        completionPossibleData?.CompletionPossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'SupplierToDosDone') {
      return (
        SupplierToDosDonePossibleData?.SupplierToDosDonePossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'FinishProjectCheck') {
      return (
        projectCompletionPossibleData?.ProjectCompletionPossible?.reason ||
        MissingInfo.INVALID_STATE
      );
    }
    if (state.mutationName === 'FinishSupplierReport') {
      return (
        finishSupplierReportPossibleData?.FinishSupplierReportPossible
          ?.reason || MissingInfo.INVALID_STATE
      );
    }
    return undefined;
  };

  useEffect(() => {
    if (authorizations?.includes('SubmitToLegal')) legalCheckPossible();
    if (authorizations?.includes('RequestSupplierInput'))
      supplierInputPossible();
    if (authorizations?.includes('SupplierInputDone'))
      supplierInputDonePossible();
    if (authorizations?.includes('RequestSupplierToDos'))
      supplierToDosPossible();
    if (authorizations?.includes('FinishLegalCheck')) legalCheckDonePossible();
    if (authorizations?.includes('FinishProductCheck')) completionPossible();
    if (authorizations?.includes('SupplierToDosDone'))
      supplierToDosDonePossible();
    if (authorizations?.includes('FinishProjectCheck'))
      projectCompletionPossible();
    if (authorizations?.includes('FinishSupplierReport'))
      finishSupplierReportPossible();
  }, [
    authorizations,
    legalCheckDonePossible,
    completionPossible,
    legalCheckPossible,
    supplierInputPossible,
    supplierInputDonePossible,
    supplierToDosPossible,
    supplierToDosDonePossible,
    projectCompletionPossible,
    finishSupplierReportPossible,
  ]);

  // adding state: "authorized" regarding authorizations for each stateChangeBtn mutations
  const allMutations = mappingStatesToMutation.map((state) => {
    return {
      ...state,
      authorized: authorizations?.includes(state.mutationName),
    };
  });
  // filtering mutations to render buttons
  const applicableMutations = getMutationsForRoleAndStatus(
    allMutations,
    processStatusAsString,
    accessRoles,
    isProject,
    isGroupTransfer,
    selectedRole as string
  );

  return (
    <Segment id="ProductDetailsFootBar" className="footbar" basic>
      {showMessage ? (
        <Message
          id="TakeoverProcessInfo"
          info
          icon="info circle"
          content={t('transferInfo')}
          compact
          size="tiny"
        />
      ) : (
        applicableMutations.map((state: StateMapping) => {
          const id = `StateChangeButton${state.mutationName}`;
          const { popupId, popupContent } = getPopupInfo(
            id,
            state.authorized ? reasonCode(state) : MissingInfo.DEACTIVATED
          );
          const content = (
            <div>
              {state.authorized ? (
                <>
                  {t('stateChangeNotPossibleReason.default')}
                  <br />
                </>
              ) : null}
              {t(popupContent)}
            </div>
          );
          return (
            <Popup
              id={popupId}
              key={popupId}
              disabled={!disabled(state)}
              trigger={
                <span>
                  <StateChangeButton
                    id={id}
                    key={id}
                    state={state}
                    disabled={disabled(state)}
                  />
                </span>
              }
              content={content}
              position="top center"
            />
          );
        })
      )}
      <span>
        {authorizations.includes('DownloadDisclaimer') && <LCDownloadButton />}
        {authorizations.includes('DownloadReport') && <ReportDownloadButton />}
        {authorizations.includes('GetEmailsByBrand') && isGroupTransfer && (
          <EmailBrandOSOButton />
        )}
      </span>
    </Segment>
  );
};

export default ProductDetailsFootBar;
