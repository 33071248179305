import { useQuery } from '@apollo/client';
import React from 'react';
import { Grid, Loader, Message } from 'semantic-ui-react';
import { ALL_USERS_QUERY } from '../../../graphql/queries/UserQuerys';
import WizardTeamDeveloper from './WizardTeamDeveloper';
import WizardTeamGuest from './WizardTeamGuest';
import WizardTeamManager from './WizardTeamManager';
import WizardTeamModal from './WizardTeamModal';
import WizardTeamSupplier from './WizardTeamSupplier';
import dropdownOptions from './helper/options';

/**
 * Wizard Product creation -Step Five - Team selection and management
 *
 *@returns {JSX.Element} Element returns a Team Selection View with four columns as separate components
 */
const WizardTeamSelection = (): JSX.Element => {
  const { loading, data: allUsers, error } = useQuery(ALL_USERS_QUERY);

  if (loading) {
    return <Loader active size="large" />;
  }
  if (error) {
    return (
      <Message
        id="WizardTeamSelectionError"
        error
        icon="times circle"
        content={`Error ${error}`}
      />
    );
  }

  if (allUsers) {
    const usersForTeam = allUsers.User;
    const usersOptions = dropdownOptions(usersForTeam);

    return (
      <Grid
        columns={4}
        divided
        padded="horizontally"
        style={{ height: '100%' }}
      >
        {/* TEAMS MODAL */}
        <WizardTeamModal usersOptions={usersOptions} />
        <Grid.Row style={{ height: '80%' }}>
          {/* ------FIRST COLUMN => MANAGER ------ */}
          <Grid.Column>
            <WizardTeamManager usersOptions={usersOptions} />
          </Grid.Column>
          {/* ------SECOND COLUMN => DEVELOPER ------ */}
          <Grid.Column>
            <WizardTeamDeveloper usersOptions={usersOptions} />
          </Grid.Column>
          {/* ------THIRD COLUMN => SUPPLIER ------ */}
          <Grid.Column>
            <WizardTeamSupplier usersOptions={usersOptions} />
          </Grid.Column>
          {/* ------FOURTH COLUMN => GUEST ------ */}
          <Grid.Column>
            <WizardTeamGuest usersOptions={usersOptions} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  return <div />;
};

export default WizardTeamSelection;
