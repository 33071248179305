/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Item, Message, Segment } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { Role } from '../../../../graphql/generated/graphql';
import {
  LEGAL_CHECK_DONE_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  PRODUCT_INFOBOX,
} from '../../../../graphql/queries/VersionQuerys';
import DescriptionItem from './components/DescriptionItem';
import DistributionItem from './components/DistributionItem';
import EditProductButton from './components/EditProductButton';
import HighPriorityRequestItem from './components/HighPriorityRequestItem';
import InfoBoxHeader from './components/InfoBoxHeader';
import InfoBoxProvider from './components/InfoBoxProvider';
import PriorityItem from './components/PriorityItem';
import ProductTypeItem from './components/ProductTypeItem';
import ProtectionItem from './components/ProtectionItem';
import StandardSoftwareItem from './components/StandardSoftwareItem';
import TargetDateItem from './components/TargetDate';
import UpdateMessage from './components/UpdateMessage';

interface Props {
  maxDescriptionLines?: number;
}

/**
 * @param {string} props {maxDescriptionLines}
 * @returns {JSX.Element} Info Box Component
 */
const ProductInfoBox = (props: Props): JSX.Element => {
  const [t] = useTranslation(['productDetails']);
  const { maxDescriptionLines = 5 } = props;
  const [open, setOpen] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);

  const { selectedRole } = useContext(SelectedRoleContext);
  const { inLegalCheckOrDone } = useContext(ProductDetailsContext);

  return (
    <InfoBoxProvider
      editing={editing}
      setEditing={setEditing}
      query={PRODUCT_INFOBOX}
      refetchQueries={[
        PRODUCT_INFOBOX,
        LEGAL_CHECK_POSSIBLE,
        LEGAL_CHECK_DONE_POSSIBLE,
      ]}
    >
      <Segment id="InfoBox" raised>
        <InfoBoxHeader open={open} setOpen={setOpen} />
        {open && (
          <>
            <Grid relaxed padded>
              {inLegalCheckOrDone && editing && (
                <Grid.Row>
                  <Grid.Column>
                    <Message
                      id="ProductDetailsWarning"
                      warning
                      icon="exclamation triangle"
                      content={t('noEditLegalCheckMessage')}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row columns={2} divided centered>
                <Grid.Column width={10}>
                  {/* First Row */}
                  <Grid columns={2}>
                    <Grid.Column width={10}>
                      <DescriptionItem
                        maxDescriptionLines={maxDescriptionLines}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} textAlign="center">
                      <EditProductButton />
                    </Grid.Column>
                  </Grid>
                  {/* Second Row */}
                  <Grid columns="equal">
                    <Grid.Column>
                      <TargetDateItem />
                    </Grid.Column>
                    <Grid.Column>
                      <DistributionItem />
                    </Grid.Column>
                    <Grid.Column>
                      <ProductTypeItem />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Item.Group>
                    <HighPriorityRequestItem />
                    {[Role.oso, Role.legal].includes(selectedRole) && (
                      <PriorityItem />
                    )}
                    <ProtectionItem />
                    <StandardSoftwareItem />
                  </Item.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <UpdateMessage />
          </>
        )}
      </Segment>
    </InfoBoxProvider>
  );
};

export default ProductInfoBox;
