/* eslint-disable enforce-ids-in-jsx/missing-ids */
import React from 'react';
import { Icon, List } from 'semantic-ui-react';
import LicenseFeatureConditionsInfo from './LicenseFeatureConditionsInfo';
import i18n from '../../i18n/i18n';
import InfoIconTooltip from '../ui/InfoIconTooltip';
import {
  FeatureTypeName,
  useLicenseContext,
} from '../../context/LicenseContext';
import { isConflictRule } from './helpers/logics';
import { LicenseFeatureItem } from './helpers/types';

type Props = {
  rule: LicenseFeatureItem;
};

/**
 *  License Features List Item
 *
 * @param {Props} props - Props
 * @returns {JSX.Element}  - License Features List Item
 */
const LicenseFeatureListItem = (props: Props): JSX.Element => {
  const { rule } = props;
  const { isEditMode, removeFeature } = useLicenseContext();
  const itemName = i18n.language === 'de' ? rule?.name_de : rule?.name_en;
  const isRuleWithConditions =
    rule &&
    isConflictRule(rule) &&
    rule.conditions &&
    rule.conditions.length > 0;

  return (
    <List.Item as="li" value="-" className="feature-item-container">
      <span>
        {itemName}
        {isRuleWithConditions &&
          rule?.conditions &&
          rule?.conditions.length > 0 && (
            <InfoIconTooltip
              infoText={<LicenseFeatureConditionsInfo rule={rule} />}
              on="click"
              wide
            />
          )}
      </span>
      {rule && rule.id && rule.__typename && isEditMode && (
        <Icon
          id={`RemoveFeature${rule.id}`}
          className="delete-feature-icon"
          name="x"
          onClick={() =>
            removeFeature(rule.__typename as FeatureTypeName, rule.id)
          }
        />
      )}
    </List.Item>
  );
};
export default LicenseFeatureListItem;
