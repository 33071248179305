import { TransferProduct } from '../../../../../graphql/generated/graphql';

/**
 *
 *@returns {string} setting default values
 *@param {void} setProps set properties
 * @param {object} obj object
 */
const settingTransferProps = (
  setProps: React.Dispatch<
    React.SetStateAction<{
      transferExternalId: string | undefined;
      transferGroupCompany: string | undefined;
      transferContactPerson: string | undefined;
    }>
  >,
  obj: TransferProduct
): void => {
  return setProps({
    transferExternalId: obj.externalId?.trim(),
    transferGroupCompany: obj.groupCompany?.trim(),
    transferContactPerson: obj.contactPerson?.trim(),
  });
};

export default settingTransferProps;
