import React from 'react';
import {
  Divider,
  Menu,
  Placeholder,
  PlaceholderImage,
} from 'semantic-ui-react';

const SideBarMenuItemSkeleton = (): JSX.Element => {
  return (
    <Placeholder
      style={{
        height: '16px',
        width: '16px',
        borderRadius: '4px',
        margin: '16px',
      }}
      inverted
    >
      <PlaceholderImage />
    </Placeholder>
  );
};

/**
 * Skeleton fallback for sidebar suspense
 *
 * @returns {JSX.Element} SideBarMenu
 */
const SideBarMenuSkeleton = (): JSX.Element => {
  return (
    <Menu
      id="SideBarMenu"
      icon
      vertical
      inverted
      width="thin"
      style={{
        width: 50,
        height: '100vh',
        position: 'fixed',
        border: 0,
        borderRadius: 0,
        margin: 0,
      }}
    >
      <SideBarMenuItemSkeleton />
      <SideBarMenuItemSkeleton />
      <SideBarMenuItemSkeleton />
      <SideBarMenuItemSkeleton />

      <Divider hidden />
      <div style={{ position: 'absolute', bottom: '5px' }}>
        <SideBarMenuItemSkeleton />
        <SideBarMenuItemSkeleton />
        <SideBarMenuItemSkeleton />
        <SideBarMenuItemSkeleton />
      </div>
    </Menu>
  );
};

export default SideBarMenuSkeleton;
