import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Icon, Message, Modal, Popup } from 'semantic-ui-react';
import {
  CHECK_IF_PRODUCT_DELETABLE,
  PRODUCTS_LIST,
  VERSION_REVIEWERS_LIST,
} from '../../../../../graphql/queries/ProductQuerys';
import { CHECK_IF_VERSION_DELETABLE } from '../../../../../graphql/queries/VersionQuerys';
import { Version } from '../../../../../graphql/generated/graphql';
import { DELETE_PRODUCT } from '../../../../../graphql/mutations/ProductMutations';
import { DELETE_VERSION } from '../../../../../graphql/mutations/VersionMutations';
import DeleteProductModalsVersionElement from './DeleteProductModalsVersionElement';

type DeleteProductAndVersionProps = {
  elementId: string;
  isItProduct: boolean;
  elementName: string | undefined;
  versionsParentName?: string | undefined;
  productVersions: Version[] | undefined;
};

/**
 *
 *@returns {JSX.Element} Delete Icon for product and/or version
 *@param {string} elementId Id of the element that si clicked
 */
const DeleteProductAndVersion = ({
  elementId,
  isItProduct,
  elementName,
  productVersions,
  versionsParentName,
}: DeleteProductAndVersionProps): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [open, setOpen] = useState(false);
  const query = isItProduct
    ? CHECK_IF_PRODUCT_DELETABLE
    : CHECK_IF_VERSION_DELETABLE;
  const { data, loading, error } = useQuery(query, {
    variables: { id: elementId },
  });
  const mutation = isItProduct ? DELETE_PRODUCT : DELETE_VERSION;
  const [DeleteProductORVersion] = useMutation(mutation, {
    refetchQueries: [PRODUCTS_LIST, VERSION_REVIEWERS_LIST],
  });

  const resetAndClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <div />;
  }
  if (error) {
    return (
      <Popup
        id="DeleteProductAndVersionErrorPopup"
        style={{ color: 'red' }}
        on={['click', 'hover']}
        header="Error"
        content={error.message}
        trigger={<Icon id="ErrorIcon" name="warning sign" color="red" />}
      />
    );
  }
  if (data) {
    const deletableProduct = isItProduct
      ? data.ProductDeletable.deletable
      : false;
    const deletableVersion = !isItProduct
      ? data.VersionDeletable.deletable
      : false;
    return (
      <Modal
        id="DeleteProductAndVersion"
        size="small"
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        open={open}
        onOpen={() => setOpen(true)}
        trigger={
          deletableProduct || deletableVersion ? (
            <Icon
              id="RemoveProducts"
              style={{ cursor: 'pointer' }}
              name="trash alternate outline"
              color="red"
              onClick={() => setOpen(open)}
            />
          ) : (
            <div />
          )
        }
      >
        <Modal.Header id="DeleteProductAndVersionHeader">
          {isItProduct
            ? t('deleteModal.titleProduct', { productName: elementName })
            : t('deleteModal.titleVersion', {
                versionName: elementName,
                productName: versionsParentName,
              })}
          <Icon
            id="DeleteProductAndVersionCloseBtn"
            name="close"
            link
            floated="right"
            onClick={resetAndClose}
          />
        </Modal.Header>
        {deletableProduct && (
          <Modal.Content>
            <div>{t('deleteModal.descriptionTitle')}</div>
            {productVersions?.length !== 0 ? (
              <div>
                <Icon
                  fitted
                  id="icon-warning-product"
                  name="exclamation triangle"
                />
                <span>
                  <Trans>
                    {t('deleteModal.deleteProduct', {
                      productName: elementName,
                    })}
                  </Trans>
                </span>
              </div>
            ) : (
              <div> {t('deleteModal.noVersions')}</div>
            )}
            {productVersions?.map((vers) => (
              <DeleteProductModalsVersionElement vers={vers} key={vers.id} />
            ))}
            <Message
              id="DeleteProductAndVersionErrorDeleteProduct"
              error
              icon="times circle"
              content={t('deleteModal.deleteMsgProduct', {
                productName: elementName,
                number: productVersions?.length,
              })}
            />
          </Modal.Content>
        )}
        {deletableVersion && (
          <Modal.Content>
            <div>{t('deleteModal.descriptionTitle')}</div>
            <div>
              <Icon
                fitted
                id="icon-warning-version"
                name="exclamation triangle"
              />
              <span>
                <Trans>
                  {t('deleteModal.deleteVersion', {
                    versionName: elementName,
                    productName: versionsParentName,
                  })}
                </Trans>
              </span>
            </div>
            <Message
              id="DeleteProductAndVersionErrorDeleteVersion"
              error
              icon="times circle"
              content={t('deleteModal.deleteMsgVersion', {
                versionName: elementName,
              })}
            />
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button
            id="DeleteProductAndVersionSaveChanges"
            color="red"
            content={t('deleteModal.deleteBtn')}
            onClick={() =>
              DeleteProductORVersion({ variables: { id: elementId } })
            }
          />
        </Modal.Actions>
      </Modal>
    );
  }
  return <div />;
};

export default DeleteProductAndVersion;
