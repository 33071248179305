import { useContext } from 'react';
import WizardContext from '../../../context/WizardContext';
import { CreateProductPart } from '../WizardConstants';

/**
 *@returns {void} Function that populates WizardInput properties for Team selection Part
 it has to options: first if all parts are populated separately and second when parts have "identical teams values"
 */
const useWizardPartInputForTeams = (): ((
  input: string[],
  detail: string
) => void) => {
  const { setWizardInput, wizardInput, currentPart, identicalTeam } =
    useContext(WizardContext);
  const wizProduct = wizardInput.productInput;
  const wizPart = wizardInput.partInput;

  const TeamsUpdate = (input: string[], detail: string): void => {
    if (identicalTeam === false) {
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: [
          ...wizPart.slice(0, currentPart - 1),
          { ...wizPart[currentPart - 1], [detail]: input },
          ...wizPart.slice(currentPart),
        ],
      });
    } else
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: wizPart.map((part: CreateProductPart) => {
          return { ...part, [detail]: input };
        }),
      });
  };
  return TeamsUpdate;
};

export default useWizardPartInputForTeams;
