import React, { useContext } from 'react';
import ProductsList from '../components/products/productsList/ProductsList';
import AllUsersInfo from '../components/user/AllUsersInfo';
import { SelectedRoleContext, UserContext } from '../context/UserContext';
import { Role } from '../graphql/generated/graphql';

/**
 * Dashboard component
 *
 * @returns {JSX.Element} Dashboard
 */
const Dashboard = (): JSX.Element => {
  const { selectedRole } = useContext(SelectedRoleContext);
  const { roles } = useContext(UserContext); // currentUser.roles
  if (['admin'].includes(selectedRole) && roles.includes(Role.admin)) {
    return <AllUsersInfo />;
  }
  return <ProductsList />;
};

export default Dashboard;
