import React, { useEffect, ReactElement, useRef, useState } from 'react';
import {
  TransitionablePortal,
  Message,
  IconProps,
  MessageProps,
} from 'semantic-ui-react';
import getToastStyle from './helpers/logics';

interface BaseToastProps extends MessageProps {
  visible: boolean;
  type: 'success' | 'warning' | 'error' | 'info';
  onClose: () => void;
  offset?: {
    x?: number;
    y?: number;
  };
}

interface AbsolutePositionProps extends BaseToastProps {
  position:
    | 'absolute top left'
    | 'absolute top right'
    | 'absolute bottom right'
    | 'absolute bottom left';
  trigger?: never;
}

interface RelativePositionProps extends BaseToastProps {
  position:
    | 'relative top'
    | 'relative right'
    | 'relative bottom'
    | 'relative left';
  trigger: ReactElement;
}

type ToastProps = AbsolutePositionProps | RelativePositionProps;

/**
 * Toast component
 *
 * @param {ToastProps} props accepts same props as regular semantic message, plus position which can be either absolute or relative to the trigger element.
 *  More granular position control can be achieved with offset. If trigger is not provided component allows only absolute positioning.
 * @returns {JSX.Element} Toast
 */
const Toast = (props: ToastProps): JSX.Element => {
  const {
    visible,
    onClose,
    type,
    position,
    trigger,
    offset: { x = 0, y = 0 } = {},
    ...rest
  } = props;
  const triggerRef = useRef<HTMLDivElement>(null);
  const toastRef = useRef<HTMLDivElement>(null);
  const [toastHeight, setToastHeight] = useState(0);

  const typeToIcon: IconProps = {
    success: 'check circle',
    warning: 'exclamation triangle',
    error: 'times circle',
    info: 'info circle',
  };

  useEffect(() => {
    if (toastRef.current) {
      setToastHeight(toastRef.current.offsetHeight);
    }
  }, [visible]);

  return (
    <>
      {trigger && (
        <div ref={triggerRef} style={{ display: 'inline-block' }}>
          {trigger}
        </div>
      )}
      <TransitionablePortal
        open={visible}
        transition={{ animation: 'fade up' }}
      >
        <div
          style={getToastStyle(triggerRef, position, toastHeight, x, y)}
          ref={toastRef}
        >
          <Message
            id="message-id"
            className="toast"
            success={type === 'success'}
            warning={type === 'warning'}
            error={type === 'error'}
            info={type === 'info'}
            icon={typeToIcon[type]}
            onDismiss={() => onClose()}
            {...rest} // eslint-disable-line  react/jsx-props-no-spreading
          />
        </div>
      </TransitionablePortal>
    </>
  );
};

export default Toast;
