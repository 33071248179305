import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Header, Item, Select } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import { SelectionTypes } from '../WizardConstants';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
import useWizardPartInputForIntendedUse from './useWizardPartInputForIntendedUse';

/**
 *@returns {JSX.Element} Third column with protection selection field
 */
const WizardIntendedUseProtection = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const IntendedUseUpdate = useWizardPartInputForIntendedUse(); // Function that populates the WizardInput -partInput properties
  const { wizardInput, currentPart, identicalIntendedUse } =
    useContext(WizardContext);
  const wizPart = wizardInput.partInput;

  /**
   * selecting IntendedUse Protection YES/NO
   */
  const protectOptions = [
    {
      key: 'yes',
      text: t('intendedUsePart.protection-yes'),
      value: true,
      icon: 'check',
    },
    {
      key: 'no',
      text: t('intendedUsePart.protection-no'),
      value: false,
      icon: 'x',
    },
  ];

  const booleanValue = () => {
    if (
      wizPart[currentPart - 1]?.protection === undefined ||
      wizPart[currentPart - 1]?.protection === null
    ) {
      return '';
    }
    return wizPart[currentPart - 1].protection;
  };
  return (
    <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
      <Item id="WizardIntendedUse.Protection">
        <Item.Content>
          <Item.Header>{t('intendedUsePart.intendedUseTitle')}</Item.Header>
          <Item.Extra
            style={{
              fontSize: '18px',
              display: 'inline',
            }}
          >
            {t('intendedUsePart.protection-header')}
          </Item.Extra>
          {!identicalIntendedUse && (
            <Header size="small">
              {`${
                wizardInput.partInput[currentPart - 1]?.name || ''
              } ${currentPart} / ${wizardInput.partInput.length}`}
            </Header>
          )}
          <Item.Description>
            <Form
              id="WizardIntendedUse.ProtectionSelection"
              style={{ marginTop: '40px' }}
            >
              <WizardFormFieldLabel
                nameText={t('intendedUsePart.protection')}
                infoText={t('intendedUsePart.protection-info')}
                htmlFor="protection"
              />
              <Form.Field
                id="protection"
                control={Select}
                options={protectOptions}
                name="protection"
                value={booleanValue()}
                placeholder={t('pleaseSelect')}
                onChange={(
                  _e: ChangeEvent,
                  { value, name }: SelectionTypes
                ) => {
                  IntendedUseUpdate(value, name);
                }}
                style={{ width: '65%' }}
              />
            </Form>
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default WizardIntendedUseProtection;
