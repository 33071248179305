import React from 'react';
import { Icon } from 'semantic-ui-react';

/**
 * @returns {JSX.Element} DeveloperTeamIcon.
 */
const DevelopmentTeamIcon = (): JSX.Element => {
  return (
    <div className="iconGroup">
      <Icon
        className="inline"
        name="chevron left"
        size="small"
        id="ChevronLeftIcon"
        style={{ display: 'inline' }}
        color="grey"
      />
      <Icon
        className="inline"
        name="chevron right"
        size="small"
        id="ChevronRightIcon"
        style={{ display: 'inline' }}
        color="grey"
      />
    </div>
  );
};

export default DevelopmentTeamIcon;
