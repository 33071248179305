import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Version } from '../../../../../graphql/generated/graphql';

type Props = {
  vers: Version;
};

/**
 *@returns {JSX.Element} Version of products element in DleteProductModal
 *@param {Version} vers Version object
 */
const DeleteProductModalsVersionElement = ({ vers }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');

  return (
    <div className="DeleteProductAndVersionsListItem">
      <Button
        id="DeleteProductProjectStatus"
        size="small"
        className={`filterpill active ${vers.processStatusAsString.toLowerCase()}`}
        content={t(`filterPills.${vers.processStatusAsString}`)}
      />
      <span>{vers.name}</span>
      <Icon
        id="DeleteProductVersionsLinkIcon"
        name="external alternate"
        onClick={() => navigate(`/product-details/${vers.id}?view=Details`)}
      />
    </div>
  );
};

export default DeleteProductModalsVersionElement;
