import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  // .use(LanguageDetector) deactivated because of "AOSD-1861 i18n-Defaultsprache passt nicht zur benötigten Angabe für Wizardguidetrigger"
  .use(initReactI18next)
  .init({
    saveMissingTo: 'fallback',
    fallbackLng: 'de',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
