import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  Form,
  FormProps,
  Grid,
  Header,
  Icon,
  Label,
  Popup,
} from 'semantic-ui-react';
import {
  BrandDirectory,
  CreateUserMutationVariables,
  Role,
  UpdateUserMutationVariables,
} from '../../../graphql/generated/graphql';
import { BrandOptions, RoleOptions } from './helpers/DropdownOptions';
import { filteringRolesDropdownOptions } from './helpers/logics';
import useTranslateOptions from './helpers/useTranslateOptions';

type UserDetailsRolesAndBrandsProps<TDetailProps> = {
  userDetails: TDetailProps; // either Update or CreateUser Mutation types
  setUpdateDetails: (userDetails: TDetailProps) => void;
};

/**
 *@returns {JSX.Element} Adding/updating roles and brand for either creating or updating user- it recognizes Type and respectively adds or removes roles /brand
 * @param {UserDetailsRolesAndBrandsProps} props Properties
 */
const UserDetailsRolesAndBrands = <
  TDetailProps extends CreateUserMutationVariables | UpdateUserMutationVariables
>(
  props: UserDetailsRolesAndBrandsProps<TDetailProps>
): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);
  const { userDetails, setUpdateDetails } = props;

  const translate = useTranslateOptions('users'); // translating text from users.json

  // user in users list that has only admin role should not have "brand"
  const userRoleAdmin =
    userDetails.roles?.length === 1 && userDetails.roles[0] === Role.admin;
  const removeBrand = () => {
    setUpdateDetails({ ...userDetails, brand: BrandDirectory.NoBrand });
  };
  const removeRole = (roleToRemove: Role) => {
    if (Array.isArray(userDetails.roles)) {
      const filteredArr = userDetails.roles?.filter(
        (ro: Role) => ro !== roleToRemove
      );
      setUpdateDetails({ ...userDetails, roles: filteredArr });
    }
  };

  return (
    <>
      {/* TODO  anschauen warum userDetails.roles potentiell ein String ist ! */}
      <Header content={t('assignRolesAndBrands')} />
      <Grid>
        <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
          <Grid.Column>
            {Array.isArray(userDetails.roles) &&
              userDetails.roles.map((r: Role) => (
                <Label id="DeleteRoleLabel" key={`Role${r}`}>
                  {t(`role.${r}`)}
                  <Icon
                    id="RoleIcon"
                    name="delete"
                    onClick={() => removeRole(r)}
                  />
                </Label>
              ))}
          </Grid.Column>
          <Grid.Column>
            {userDetails.brand && (
              <Label id="UserDetailsEditBrandsBrandLabel">
                {userDetails.brand}
                <Icon
                  id="DeleteBrandIcon"
                  name="delete"
                  onClick={removeBrand}
                />
              </Label>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          columns={2}
          className="UserDetailsEditRolesAndBrandsDropdownsRow"
        >
          <Grid.Column>
            <Popup
              id="UserDirectoriesDropdownErrorMessage"
              open={userDetails.roles?.length === 0}
              content={t('noRolesError')}
              position="top left"
              trigger={
                <Form.Field
                  className="UserDetailsCustomDropdowns"
                  control={Dropdown}
                  required={userDetails.roles?.length === 0}
                  error={userDetails.roles?.length === 0}
                  trigger={t('roles.placeholder', { ns: 'users' })}
                  value={null} // disables automatic selection of items-we are not using the input filed
                  fluid
                  selectOnBlur
                  icon="angle down"
                  data-cy="RolesDropdown"
                  options={translate(
                    filteringRolesDropdownOptions(
                      RoleOptions,
                      userDetails?.roles
                    )
                  )}
                  placeholder={t('roles.placeholder', { ns: 'users' })}
                  onChange={(
                    _event: React.FormEvent<HTMLFormElement>,
                    data: FormProps
                  ) =>
                    setUpdateDetails({
                      ...userDetails,
                      roles: [
                        ...(userDetails.roles as Array<Role>),
                        data.value,
                      ],
                    })
                  }
                />
              }
            />
          </Grid.Column>
          {!userRoleAdmin && (
            <Grid.Column>
              <Form.Field
                className="UserDetailsCustomDropdowns"
                control={Dropdown}
                trigger={t('brand.placeholder', { ns: 'users' })}
                value={null} // disables automatic selection of items-we are not using the input filed
                fluid
                icon="angle down"
                data-cy="UserBrand"
                options={translate(BrandOptions)}
                placeholder={t('brand.placeholder', { ns: 'users' })}
                onChange={(
                  _event: React.FormEvent<HTMLFormElement>,
                  data: FormProps
                ) =>
                  setUpdateDetails({
                    ...userDetails,
                    brand: data.value,
                  })
                }
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default UserDetailsRolesAndBrands;
