import { User } from '../../../../../graphql/generated/graphql';

/**
 * User with only id, name and email
 */
export type Contact = Pick<User, 'id' | 'name' | 'email'>;

/**
 * User with only id, name and email + roles
 */
export type ContactOption = Pick<User, 'id' | 'name' | 'email' | 'roles'>;

/**
 * Contacts for a product
 */
export type Contacts = {
  legalReviewer: Contact | null | undefined;
  osoReviewer: Contact | null | undefined;
  managerTeam: Contact[];
  developmentTeam: Contact[];
  supplierTeam: Contact[];
  guestTeam: Contact[];
};

/**
 * Department enum
 */
export enum Department {
  LEGAL = 'legal',
  OSO = 'oso',
  MANAGER = 'manager',
  SUPPLIER = 'supplier',
  GUEST = 'guest',
  DEVELOPMENT = 'development',
}
