import { useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Loader, Message, Modal } from 'semantic-ui-react';

import {
  DirectoryAccess,
  UpdateUserMutationVariables,
  User,
  useGetBrandDirectoryAccessQuery,
} from '../../graphql/generated/graphql';
import { UPDATE_USER_BRAND_ACCESS } from '../../graphql/mutations/DirectoryMutations';
import { UPDATE_USER } from '../../graphql/mutations/UserMutations';
import { GET_ALL_BRAND_DIRECTORIES_FOR_USER } from '../../graphql/queries/DirectoryQuerys';
import {
  ALL_USERS_QUERY,
  CURRENT_USER_QUERY,
} from '../../graphql/queries/UserQuerys';
import UpdateSuccessMsg from './userComponents/UpdateSuccessMsg';
import UserDetailsContentContainer from './userComponents/UserDetailsContentContainer';
import {
  compareObjects,
  isValidEmail,
  isValidName,
} from './userComponents/helpers/logics';

type UserDetailProps = {
  onlyAdmin?: boolean | undefined;
  user: User;
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * UserDetails
 * useMutation UPDATE-USER refetches depending on the user Role/credits-Admin refetches AllUsers Query and a NON admin User refetches CurrentUser!!**
 *
 * @returns {JSX.Element} UserDetails for the given user as a modal element
 * @param {boolean} props Props
 */
const UserDetails = (props: UserDetailProps): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);
  const { onlyAdmin, open, setOpen, user } = props;
  const [openMsg, setOpenMsg] = useState(false); // Message Modal
  const [directoryAccessArr, setDirectoryAccessArr] = useState<
    DirectoryAccess[]
  >([]);
  const [directoryAccessArrChanges, setChangesAreMade] =
    useState<boolean>(false); // directoryAccessArr compared to userDirectoryAccess
  const [hasChanges, setHasChanges] = useState<boolean>(false); // checking if sth is edited

  const [UpdateUser, { loading: loadingUpdate, error: updatingError }] =
    useMutation(UPDATE_USER, {
      refetchQueries: [onlyAdmin ? ALL_USERS_QUERY : CURRENT_USER_QUERY],
    });
  // UPDATE mutation for directories and its access permissions
  const [UpdateBrandDirectoryAccess] = useMutation(UPDATE_USER_BRAND_ACCESS, {
    refetchQueries: [GET_ALL_BRAND_DIRECTORIES_FOR_USER],
  });

  const userDetailsObj = useMemo(
    () => ({
      id: user.id,
      name: user.name.trim(),
      email: user.email.trim(),
      password: '',
      roles: user.roles,
      status: user.status,
      brand: user.brand,
    }),
    [user.id, user.name, user.email, user.roles, user.status, user.brand]
  );
  const [userDetails, setUpdateDetails] =
    useState<UpdateUserMutationVariables>(userDetailsObj);
  const {
    data: userDirectories,
    loading: loadDirectories,
    error: directoriesErr,
  } = useGetBrandDirectoryAccessQuery({
    variables: { userId: userDetails.id },
    skip: !onlyAdmin, // Only runs the query if the user is an admin
  });

  // repeat password state for password validation
  const [repeatPassword, setRepeatPassword] = useState({
    pass: '',
    enabled: false,
  });
  // reseting all fields after either submit or close
  const ResetEditable = () => {
    setRepeatPassword({
      pass: '',
      enabled: false,
    });
    setUpdateDetails(userDetailsObj);
    setDirectoryAccessArr([]);
  };
  // validate Details and update. if User hasn`t got ADMIN Role then it updates only PASSWORD
  const ConfirmEdit = () => {
    if (onlyAdmin) {
      UpdateUser({ variables: userDetails });
      UpdateBrandDirectoryAccess({
        variables: {
          userId: userDetails.id,
          directoryAccess: directoryAccessArr,
        },
      });
    } else
      UpdateUser({
        variables: { id: userDetails.id, password: userDetails.password },
      });
    // close the modal,reset states and show the success message.
    setOpenMsg(true);
    setOpen(false);
    ResetEditable();
  };
  // populating user details State on QueryResult
  useEffect(() => {
    if (user) {
      setUpdateDetails({
        id: user.id,
        name: user.name.trim(),
        email: user.email.trim(),
        password: '',
        roles: user.roles,
        status: user.status,
        brand: user.brand,
      });
    }
  }, [user]);
  // comparing objects for changes-if no change then false!
  useEffect(() => {
    const hasUserDetailsChanged =
      !compareObjects(userDetails, userDetailsObj) || directoryAccessArrChanges;
    setHasChanges(hasUserDetailsChanged);
  }, [
    directoryAccessArrChanges,
    directoryAccessArr,
    userDetails,
    userDetailsObj,
    userDirectories,
  ]);

  if (loadingUpdate) return <Loader active />;
  if (updatingError)
    return (
      <Message
        id="UserDetailsError"
        error
        icon="times circle"
        content={updatingError.message}
      />
    );

  return (
    <>
      {/* SUCCESS MSG IF UPDATE VALIDATED */}
      <UpdateSuccessMsg
        openMsg={openMsg}
        setOpenMsg={setOpenMsg}
        resetData={ResetEditable}
        userName={user.name}
        onlyAdmin={onlyAdmin}
      />
      {/* EDIT USER MODAL */}
      <Modal
        open={open}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        size="tiny"
        id="UserInfoView"
      >
        <Modal.Header>
          {t('editProfile', { ns: 'users' })}
          <Icon
            id="UserDetailsCloseModal"
            data-cy="CloseUserDetails"
            name="close"
            link
            onClick={() => {
              setOpen(false);
              ResetEditable();
            }}
          />
        </Modal.Header>
        <UserDetailsContentContainer
          onlyAdmin={onlyAdmin}
          user={user}
          userDetails={userDetails}
          setUpdateDetails={setUpdateDetails}
          setRepeatPass={setRepeatPassword}
          repeatPass={repeatPassword}
          brandDirectories={userDirectories?.GetBrandDirectoryAccess}
          usersGlobalRoles={userDetails.roles}
          directoryAccessArr={directoryAccessArr}
          setDirectoryAccessArr={setDirectoryAccessArr}
          loading={loadDirectories}
          error={directoriesErr}
          setChangesAreMade={setChangesAreMade}
        />
        <Modal.Actions>
          <Button
            primary
            disabled={
              !isValidName(userDetails.name) ||
              userDetails.roles?.length === 0 ||
              userDetails.password !== repeatPassword.pass ||
              !isValidEmail(userDetails.email) ||
              !hasChanges
            }
            id="UserDetailsUserDirectoriesSaveChanges"
            content={t('saveChanges', { ns: 'buttons' })}
            onClick={ConfirmEdit}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default UserDetails;
