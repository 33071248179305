import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Icon } from 'semantic-ui-react';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}
/**
 * @param {object} props {open, setOpen}
 * @returns {JSX.Element}  Header for Info Box
 */
const InfoBoxHeader = (props: Props): JSX.Element => {
  const [t, i18n] = useTranslation(['productDetails']);
  const i18nLang = i18n.language;

  const { open, setOpen } = props;
  return (
    <Menu id="InfoBoxHeader" secondary compact>
      <Menu.Item>
        <Icon
          id="InfoBoxHeader.Accordion"
          onClick={() => setOpen(!open)}
          name={open ? 'chevron up' : 'chevron down'}
          color={open ? 'blue' : 'black'}
        />
      </Menu.Item>
      <Menu.Item style={{ fontWeight: 'bold', fontSize: '16px' }}>
        {t('details')}
        <span>
          <Icon
            size="large"
            name="question circle"
            id={`ProductDetailsGuideIcon-${i18nLang}`}
            style={{ marginLeft: '0.5rem', display: 'none' }} // TODO: delete display none when guide is ready
          />
        </span>
      </Menu.Item>
    </Menu>
  );
};

export default InfoBoxHeader;
