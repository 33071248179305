import React from 'react';
import { Icon } from 'semantic-ui-react';

/**
 *@returns {JSX.Element} Info Icon in a Circle Blue Icon for popup Information
 */
const InfoCircleBlueIcon = (): JSX.Element => {
  return (
    <Icon
      link
      size="tiny"
      color="blue"
      circular
      id="InfoCircleBlueIcon"
      name="info"
    />
  );
};

export default InfoCircleBlueIcon;
