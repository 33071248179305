import React, { useEffect, useState } from 'react';
import { Button, Icon, Label, Table } from 'semantic-ui-react';
import {
  DependencySelection,
  RequirementSourceDetails,
  SelectionType,
} from '../../../../graphql/generated/graphql';

type Props = {
  authorized: boolean;
  sourceDetails: RequirementSourceDetails;
  dependencySelections: DependencySelection[];
  setDependencySelections: (
    dependencySelections: DependencySelection[]
  ) => void;
  versionSelectionInfo: SelectionType | null;
  individually: boolean;
  dependenciesToCheck: DependencySelection[];
  setDepsToCheck: (dependencySelections: DependencySelection[]) => void;
};
/**
 *@returns {JSX.Element} Dependant product of a requirement in the list
 @param {Props} props { authorized, product }
 */
const RequirementComponent = (props: Props): JSX.Element => {
  const {
    sourceDetails,
    authorized,
    dependencySelections,
    setDependencySelections,
    versionSelectionInfo,
    individually,
    dependenciesToCheck,
    setDepsToCheck,
  } = props;
  // it populates dependencies from query info but when deps` selectiontype is "null", it uses VersionSelectionType
  const [individualSelection, setIndividualSelection] = useState(
    dependencySelections.find(
      (d) => d.dependencyId === sourceDetails.dependencyId
    )?.selectionType ||
      (sourceDetails.selectionInfo !== null
        ? sourceDetails.selectionInfo
        : versionSelectionInfo)
  );

  const handleSelection = (selection: SelectionType) => {
    if (selection === individualSelection) {
      return;
    }
    setIndividualSelection(selection);
    // this state is only using changed items and will be used for mutation
    setDependencySelections([
      ...dependencySelections.filter(
        (d) => d.dependencyId !== sourceDetails.dependencyId
      ),
      {
        dependencyId: sourceDetails.dependencyId,
        selectionType: selection,
      },
    ]);
    // it enables checking all dependencies if and when are equal-all dependencies of the requirement
    setDepsToCheck([
      ...dependenciesToCheck.filter(
        (d) => d.dependencyId !== sourceDetails.dependencyId
      ),
      {
        dependencyId: sourceDetails.dependencyId,
        selectionType: selection,
      },
    ]);
  };
  // automatically assign to all dependencies the VersionSelectionTyp on its choice
  useEffect(() => {
    if (versionSelectionInfo !== null && !individually) {
      setIndividualSelection(versionSelectionInfo);
    }
  }, [versionSelectionInfo, individually]);

  return (
    <Table.Body>
      <Table.Row>
        <Table.Cell>{sourceDetails.componentName}</Table.Cell>
        <Table.Cell>
          {sourceDetails.spdxIds.map((r) => (
            <Label
              id="AllUsersInfoTableActiveRole"
              key={r}
              content={r.charAt(0).toUpperCase() + r.slice(1)}
              style={{ cursor: 'default' }}
            />
          ))}
        </Table.Cell>
        <Table.Cell>{sourceDetails.componentVersion}</Table.Cell>

        <Table.Cell textAlign="center">
          <Button.Group
            id="ProductCheckButtonsGroup"
            size="mini"
            style={{ padding: '0 20px' }}
          >
            <Button
              id="SelectedButton"
              icon
              disabled={!authorized}
              color={
                individualSelection === SelectionType.autoselected ||
                individualSelection === SelectionType.userselected
                  ? 'blue'
                  : undefined
              }
              onClick={() => {
                handleSelection(SelectionType.userselected);
              }}
            >
              <Icon id="Required" name="check" />
            </Button>
            <Button
              id="DeselectedButton"
              icon
              disabled={!authorized}
              color={
                individualSelection === SelectionType.userdeselected ||
                individualSelection === SelectionType.autodeselected
                  ? 'blue'
                  : undefined
              }
              onClick={() => {
                handleSelection(SelectionType.userdeselected);
              }}
            >
              <Icon id="NotRequired" name="ban" />
            </Button>
            <Button
              id="QuestionButton"
              icon
              disabled={!authorized}
              color={
                individualSelection === SelectionType.unselected
                  ? 'blue'
                  : undefined
              }
              onClick={() => {
                handleSelection(SelectionType.unselected);
              }}
            >
              <Icon id="Unknown" name="question" />
            </Button>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  );
};

export default RequirementComponent;
