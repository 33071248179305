import React from 'react';
import { Feed, Icon } from 'semantic-ui-react';

type Props = {
  name: string;
  email: string;
};

/**
 *
 * @param {object} props Name, email
 * @returns {JSX.Element} ContactFeedElement
 */
const ContactFeedElement = (props: Props): JSX.Element => {
  const { name, email } = props;

  return (
    <Feed.Event>
      <Feed.Content>
        <Feed.Summary content={name} />
        <span
          className="email"
          style={email ? { visibility: 'visible' } : { visibility: 'hidden' }}
        >
          <Icon name="mail outline" id="EmailIcon" />
          {email}
        </span>
      </Feed.Content>
    </Feed.Event>
  );
};

export default ContactFeedElement;
