import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface UseTextAreaChangeReturn {
  maxCharLimit: number;
  charCount: number;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

/**
 * Hook handles textarea changes and keeps the state of the character amount.
 *
 * @template T - The type of the state.
 * @param {Dispatch<SetStateAction<T>>} setState sets state, currently works with string and object with property 'info'.
 * @param {string} initialText pogjt
 * @param {number} maxCharLimit maximum amount of characters (defaults to 280).
 * @param {number} maxEmptyLines maximum amount of empty lines (defaults to 2).
 * @returns {UseTextAreaChangeReturn} -  maxCharLimit, charCount, handleTextAreaChange, resetCharacterCount
 */
const useTextAreaChange = <T>(
  setState: Dispatch<SetStateAction<T>>,
  initialText = '',
  maxCharLimit = 280,
  maxEmptyLines = 2
): UseTextAreaChangeReturn => {
  const [charCount, setCharCount] = useState(0);

  const sanitizeInput = useCallback(
    (input: string): string => {
      const regex = new RegExp(`(\\n\\s*){${maxEmptyLines + 1},}`, 'g');
      return input.replace(regex, '\n'.repeat(maxEmptyLines));
    },
    [maxEmptyLines]
  );

  const handleTextAreaChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      let newText = event.target.value;
      if (newText.length > maxCharLimit) {
        newText = newText.substring(0, maxCharLimit);
      }

      const sanitizedText = sanitizeInput(newText);
      setState((prevState) => {
        if (typeof prevState === 'string') {
          return sanitizedText as unknown as T;
        }
        return { ...prevState, info: sanitizedText } as unknown as T;
      });
      setCharCount(sanitizedText.length);
    },
    [maxCharLimit, sanitizeInput, setState]
  );

  useEffect(() => {
    setCharCount(initialText.length);
  }, [initialText]);

  return {
    maxCharLimit,
    charCount,
    handleTextAreaChange,
  };
};

export default useTextAreaChange;
