import React from 'react';
import { Header, Placeholder, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

/**
 * FeatureGroups list
 *
 * @returns {JSX.Element} FeatureGroups View
 */
const FeatureGroups = (): JSX.Element => {
  const { t } = useTranslation('views');

  return (
    <Segment id="featureGroups" style={{ height: '100vh' }}>
      <>
        <Header content={t('featureGroups')} />
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </>
    </Segment>
  );
};
export default FeatureGroups;
