import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'semantic-ui-react';

type PropsType = {
  filtering: {
    [K: string]: boolean;
  };
  filterOptionName: string;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<{
      [K: string]: boolean;
    }>
  >;
  labeled: boolean | undefined;
};

/**
 * Checkbox for FilterMenu
 *
 * @param {PropsType} props takes in ...
 * @returns {JSX.Element} checkbox of filter menu.
 */
const FilterMenuCheckbox = (props: PropsType): JSX.Element => {
  const { filtering, filterOptionName, setSelectedFilters, labeled } = props;
  const [text] = useTranslation('dashboard');
  const [checked, setChecked] = useState(filtering[filterOptionName]);

  const { t } = useTranslation('dashboard');

  const setSelectionAndCheck = () => {
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filterOptionName]: !prevSelectedFilters[filterOptionName],
    }));
    setChecked((prevCheck) => !prevCheck);
  };

  const label =
    filterOptionName.split('__')[0] === 'unassigned'
      ? t('filterMenu.unassigned')
      : filterOptionName.split('__')[0];

  return (
    <Checkbox
      checked={checked}
      id={`FilterMenu.Segment.Checkbox.${filterOptionName}`}
      label={labeled ? text(`filterMenu.${filterOptionName}`) : label}
      onClick={setSelectionAndCheck}
    />
  );
};

export default FilterMenuCheckbox;
