import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Item, Label, Modal, TextArea, Message } from 'semantic-ui-react';
import { visualizeDiff } from './LicenseElementModalHelper';
import { LicenseTextDiffVisualType } from '../../../../../../../../graphql/generated/graphql';

type Props = {
  originalLicenseText: string | undefined;
  filteredDiffSnippets: LicenseTextDiffVisualType[];
  licenseDiffAmountObj: {
    addedSnippets: number;
    removedSnippets: number;
    snippetsSum: number;
  };
  licenseTextLoading: boolean;
};

// TODO: Mir ist bewusst, dass viele der Fehler und Unschönheiten, die ich in anderen Teilen des LicenseModals ansprach
// nun auch hier zu finden sind, da Zeit und Einheitlichkeit über Sauberkeit und Lesbarkeit prioritisiert wurden.
// Nach Rücksprache mit anderen beteiligten hat man sich darauf geeinigt, dass
// das LicenseModal nun erstmal etwa so fortgesetzt wird und bei Fertigstellung komplett refactored wird.

/**
 *  License Modals Third Tab
 *
 *@param {string} licenseId - not the spdxId, but the id of the license in the database
 *@returns {JSX.Element} a tab of the license modal - in this case the license diff
 */
const LicenseElementModalsLicenseDiff = ({
  originalLicenseText,
  filteredDiffSnippets,
  licenseDiffAmountObj,
  licenseTextLoading,
}: Props): JSX.Element => {
  const [text] = useTranslation('productDetails');

  return (
    <Modal.Content>
      <Item.Group>
        <Item id="LicenseElementModalsLicenseDiffLicenseText">
          <Item.Content>
            <Item.Meta
              style={{ paddingBottom: '0.5rem' }}
              content={text('dependencyList.licenseModal.licenseText')}
            />
            {!filteredDiffSnippets && !licenseTextLoading && (
              <Message
                id="LicenseDiffNoLicenseTextMessage"
                error
                icon="times circle"
                header={text('dependencyList.licenseModal.noLicenseTextHeader')}
                content={text('dependencyList.licenseModal.noLicenseText')}
              />
            )}
            <Form id="LicenseElementModalsLicenseDiffChangesTextArea">
              {filteredDiffSnippets && filteredDiffSnippets.length > 0 ? (
                <div className="LicenseElementModalsLicenseDiffTextScrollable">
                  {filteredDiffSnippets.map((licenseTextDiffSnippet, i) => {
                    return (
                      <span
                        style={{ whiteSpace: 'pre-line' }}
                        key={`${i + 1}`}
                        className={visualizeDiff(licenseTextDiffSnippet)}
                      >
                        {licenseTextDiffSnippet.text}
                      </span>
                    );
                  })}
                </div>
              ) : (
                <TextArea
                  rows={40}
                  value={originalLicenseText || ''}
                  readOnly
                  className="LicensesElementModalLicenseTextEdit"
                />
              )}
            </Form>
          </Item.Content>
        </Item>
      </Item.Group>
      <Modal.Actions style={{ marginTop: '15px' }}>
        <Label
          id="LicenseElementModalsLicenseDiffAdded"
          className="positiveBackgroundColor positiveTextColor"
          content={`+${licenseDiffAmountObj.addedSnippets}`}
        />
        <span
          className="changed positiveTextColor"
          style={{ paddingLeft: '0.5em', paddingRight: '2em' }}
        >
          {text('dependencyList.licenseModal.added')}
        </span>
        <Label
          id="LicenseElementModalsLicenseDiffRemoved"
          className="negativeBackgroundColor negativeTextColor"
          content={`-${licenseDiffAmountObj.removedSnippets}`}
        />
        <span
          className="changed negativeTextColor"
          style={{ paddingLeft: '0.5em', paddingRight: '2em' }}
        >
          {text('dependencyList.licenseModal.removed')}
        </span>
      </Modal.Actions>
    </Modal.Content>
  );
};

export default LicenseElementModalsLicenseDiff;
