import React, { useContext, useEffect, useMemo } from 'react';
import { List } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import {
  Role,
  ToDo as ToDoType,
  ToDoSelectionInfo,
} from '../../../../graphql/generated/graphql';
import ToDo from './ToDo';
import { SelectedRoleContext } from '../../../../context/UserContext';
import {
  COMPLETION_POSSIBLE,
  SUPPLIER_TODOS_DONE_POSSIBLE,
} from '../../../../graphql/queries/VersionQuerys';

interface ToDoProps {
  todos: ToDoSelectionInfo[];
  parentTodos: ToDoSelectionInfo[];
  versionId: string | undefined;
  allSelected: boolean;
}

/**
 * List of all Todos
 *
 *@returns {JSX.Element} The ToDos List in the chosen product
 @param {ToDoSelectionInfo[]} parentTodos array of parent todos
 */
const ToDosList = ({
  todos,
  parentTodos,
  versionId,
  allSelected,
}: ToDoProps): JSX.Element => {
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;
  const { refetch } = useQuery(COMPLETION_POSSIBLE, {
    variables: {
      versionId,
    },
  });
  const { refetch: refetchSupplier } = useQuery(SUPPLIER_TODOS_DONE_POSSIBLE, {
    variables: {
      versionId,
    },
  });
  // we need to refetch on every state change and for 2 states-LEGALCHECKDONE and SUPPLIERTODOS
  useEffect(() => {
    refetch();
    refetchSupplier();
  }, [allSelected, refetch, refetchSupplier]);

  // list all active toDos WITH their tasks for the 'breakingpoint'
  const activeTodos: string[] = parentTodos.flatMap((todo) => [
    todo.toDo.id,
    ...(todo.toDo.tasks?.map((task: ToDoType) => task.id) || []),
  ]);

  const breakpoint = Math.ceil(activeTodos.length / 2);
  const beforeBreak = activeTodos.slice(0, breakpoint);
  const afterBreak = activeTodos.slice(breakpoint);
  const firstColumn = useMemo(
    () => parentTodos.filter((td) => beforeBreak.includes(td.toDo.id)),
    [parentTodos, beforeBreak]
  );
  const secondColumn = useMemo(
    () => parentTodos.filter((td) => afterBreak.includes(td.toDo.id)),
    [parentTodos, afterBreak]
  );

  const renderColumn = (
    column: ToDoSelectionInfo[],
    vId: string | undefined,
    topLevelTodos: ToDoSelectionInfo[],
    allTodos: ToDoSelectionInfo[],
    identifier: number
  ) => (
    <List.Item>
      <List.Content>
        <List id={`EditableToDosListOfToDos_${identifier}`}>
          {column.map((todo: ToDoSelectionInfo) => {
            return role.includes('supplier') && todo.toDo.internal ? null : (
              <ToDo
                key={todo.toDo.id}
                versionId={vId}
                todo={todo}
                toDosIndex={topLevelTodos.indexOf(todo)}
                todos={allTodos}
              />
            );
          })}
        </List>
      </List.Content>
    </List.Item>
  );

  return (
    <div>
      {parentTodos.length > 0 ? (
        <List id="EditableToDosList" horizontal relaxed verticalAlign="top">
          {renderColumn(firstColumn, versionId, parentTodos, todos, 1)}
          {renderColumn(secondColumn, versionId, parentTodos, todos, 2)}
        </List>
      ) : null}
    </div>
  );
};

export default ToDosList;
