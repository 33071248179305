import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';

/**
 * @returns {JSX.Element}  Edit Button Component for Info Box
 */
const EditProductButton = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { authorizations } = useContext(ProductDetailsContext);
  const {
    version,
    versionInput,
    editing,
    setEditing,
    setVersionInput,
    updateVersionMutation,
  } = useContext(ProductInfoBoxContext);
  const types = versionInput.types || version?.product?.type;

  if (editing)
    return (
      <Button
        id="EditProductButton.Save"
        primary
        disabled={types && types[1].length < 1} // disabled until both inputs are selected!
        icon
        labelPosition="left"
        onClick={() => {
          setEditing(false);
          updateVersionMutation();
          setVersionInput({});
        }}
      >
        <Icon id="EditProductButton.Save.Icon" name="check" />
        {t('saveDetails', { ns: 'buttons' })}
      </Button>
    );
  return (
    <Popup
      id="EditProductButton.Popup"
      disabled={authorizations.includes('UpdateVersion')}
      content={t('noChangesAuthorized')}
      trigger={
        <span>
          <Button
            id="EditProductButton.Edit"
            icon
            labelPosition="left"
            basic
            disabled={!authorizations.includes('UpdateVersion')}
            color="blue"
            onClick={() => setEditing(true)}
          >
            <Icon id="EditProductButton.Edit.PencilIcon" name="pencil" />
            {t('editDetails', { ns: 'buttons' })}
          </Button>
        </span>
      }
    />
  );
};

export default EditProductButton;
