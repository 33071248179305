import React from 'react';

type PropsType = {
  numberOfDirectDependencies: number | undefined | null;
  numberOfTransitiveDependencies: number | undefined | null;
  numberOfDependenciesToProof: number | undefined | null;
};

/**
 * basically only returns an element with the time till targetDate
 * nothing too fancy
 *
 * @param {PropsType} props takes in targetDate
 * @returns {JSX.Element} a span with text
 */
const ToDosElement = (props: PropsType): JSX.Element => {
  const {
    numberOfDirectDependencies,
    numberOfDependenciesToProof,
    numberOfTransitiveDependencies,
  } = props;

  // are the total todos indeed the sum of the direct and transitive dependencies'?
  const toDosAmount = {
    openToDos: 0,
    totalToDos: 0,
  };

  toDosAmount.totalToDos +=
    (numberOfDirectDependencies || 0) + (numberOfTransitiveDependencies || 0);
  toDosAmount.openToDos += numberOfDependenciesToProof || 0;

  return (
    <div>
      <span style={{ fontWeight: 'bold' }}>{toDosAmount.openToDos} </span>
      <span>/ {toDosAmount.totalToDos}</span>
    </div>
  );
};

export default ToDosElement;
