import {
  Columns,
  Sorting,
} from '../../productsList/listComponents/ProductListTypes';

/**
 * @returns {Columns} array of columns
 */
const getDefaultColumns = (): Columns => {
  const allColumns: Columns = [
    {
      name: 'date',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'author',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'role',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'category',
      shown: true,
      active: false,
      width: 2,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    {
      name: 'operation',
      shown: true,
      active: false,
      width: 8,
      permitted: true,
      sorterInfo: {
        sortable: false,
        sorting: Sorting.UNSORTED,
      },
      filterInfo: {
        filterable: false,
        filtering: {},
      },
    },
    // TODO: Uncomment when comments are implemented
    // {
    //   name: 'comments',
    //   shown: true,
    //   active: false,
    //   width: 2,
    //   permitted: true,
    //   sorterInfo: {
    //     sortable: false,
    //     sorting: Sorting.UNSORTED,
    //   },
    //   filterInfo: {
    //     filterable: false,
    //     filtering: {},
    //   },
    // },
  ];
  return allColumns;
};

export default getDefaultColumns;

/**
 * Change event for adding new coprights to ProductLicenseDetails
 */
export interface ChangeEventProps {
  value: string;
}
