import React from 'react';
import { Divider, Header, Loader, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  InputMaybe,
  Role,
  DirectoryAccess,
  useGetBrandDirectoryAccessQuery,
} from '../../../graphql/generated/graphql';

import DirectoryAccessComponent from './DirectoryAccessComponent';
import { checkingRolesForAccess } from './helpers/logics';
import AddDirectoriesDropdownButton from './AddDirectoriesDropdownButton';

type Props = {
  usersGlobalRoles?: InputMaybe<Role | Role[]> | undefined;
  directoryAccessArr: DirectoryAccess[];
  setDirectoryAccessArr: React.Dispatch<
    React.SetStateAction<DirectoryAccess[]>
  >;
};

/**
 * Users Directories when creating a new user as a tree structured list
 *
 * @returns {JSX.Element} a list of User assigned directories with roles
 * @param {string} userId props
 */
const AddNewUserDirectoriesAccess = ({
  usersGlobalRoles,
  directoryAccessArr,
  setDirectoryAccessArr,
}: Props): JSX.Element => {
  const { t } = useTranslation('users');

  const {
    data: directoriesAccessData,
    loading: load,
    error: err,
  } = useGetBrandDirectoryAccessQuery({ variables: { userId: '' } });

  const brandDirectories = directoriesAccessData?.GetBrandDirectoryAccess;
  // in orer to add directory access user needs Global roles "oso" or "legal"
  const usersWithAccess = checkingRolesForAccess(usersGlobalRoles, 'access');

  // LOADING & ERROR
  if (load) {
    return <Loader active size="large" />;
  }
  if (err) {
    return (
      <Message
        id="AddNewUserDirectoriesAccessError"
        error
        icon="times circle"
        content={`Add new user BrandDirectories Error :${err}`}
      />
    );
  }

  return (
    <>
      <Divider />
      <Header
        content={t('directories.edit')}
        disabled={!usersWithAccess}
        className="UserDirectoriesHeader"
      />
      <div className="UserDirectoriesListOfDirectoriesSegment">
        {directoryAccessArr.map((dir: DirectoryAccess, index: number) => (
          <DirectoryAccessComponent
            key={`DirectoryAccess${dir.directoryId}${directoryAccessArr.indexOf(
              dir
            )}`}
            directory={dir}
            usersGlobalRoles={usersGlobalRoles}
            indexOfDirectory={index}
            directoryAccessArr={directoryAccessArr}
            setDirectoryAccessArr={setDirectoryAccessArr}
          />
        ))}
      </div>

      {/* Add brand directories to user to give him access to for it */}
      <AddDirectoriesDropdownButton
        directoryAccessArr={directoryAccessArr}
        setDirectoryAccessArr={setDirectoryAccessArr}
        brandDirectories={brandDirectories}
        usersWithAccess={usersWithAccess}
      />
    </>
  );
};

export default AddNewUserDirectoriesAccess;
