import { FileData } from '../../../../../../graphql/generated/graphql';

/**
 * Format bytes into a human-readable string.
 *
 * @param {number} bytes - The number of bytes to format.
 * @param {number} [decimals=2] - The number of decimal places to include.
 * @returns {string} - The formatted bytes string.
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Search for files that match search tokens in filename or sha256.
 *
 * @param {FileData[]} files - An array of FileData objects.
 * @param {string} searchword - The search word(s) to match against filenames and sha256.
 * @returns {FileData[]} - An array of FileData objects that match the search criteria.
 */
export const searchFiles = (files: FileData[], searchword: string) => {
  if (searchword) {
    const tokens = searchword.split(' ').filter(Boolean);
    return files.filter((file) => {
      let score = 0;
      tokens.forEach((token) => {
        if (file?.filename?.toLowerCase().includes(token.toLowerCase()))
          score += 1;
        if (
          file?.checksums?.sha256?.toLowerCase().includes(token.toLowerCase())
        )
          score += 1;
      });
      return score > 0;
    });
  }
  return files;
};
