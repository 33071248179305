import { DocumentNode } from 'graphql';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  DropdownItemProps,
  Feed,
  Header,
  Icon,
  Message,
  Popup,
  SemanticICONS,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import ContactFeedElement from './ContactFeedElement';
import { Contact, Department } from './helper/types';
import TeamModal from './TeamModal';
import InfoIconTooltip from '../../../ui/InfoIconTooltip';

type Props = {
  team: Contact[];
  department: Department;
  mutation: DocumentNode;
  options: DropdownItemProps[] | undefined;
  icon: JSX.Element | SemanticICONS;
  maxFeedLength?: number;
  inLegalCheckOrDone?: boolean;
};

/**
 * @param {object} props team, department, mutation, icon, options, readOnly
 * @returns {JSX.Element} Card that shows team members
 */
const TeamCard = (props: Props): JSX.Element => {
  const {
    team,
    department,
    mutation,
    icon,
    options,
    maxFeedLength = 4,
    inLegalCheckOrDone,
  } = props;
  const [t] = useTranslation(['users', 'newProductWizard']);
  const { authorizations } = useContext(ProductDetailsContext);
  const readOnly = !authorizations?.includes('UpdateProductContacts');
  const [supplierMessage, setSupplierMessage] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (team.length > 0 && department === 'supplier') {
      setSupplierMessage(true);
    }
  }, [team.length, department]);

  return (
    <Card id="TeamCard" className="team">
      <Feed>
        <Feed.Event>
          <Feed.Label as="div">
            {typeof icon === 'string' ? (
              <Icon size="large" name={icon} id={`${icon}Icon`} />
            ) : (
              icon
            )}
          </Feed.Label>
          <Feed.Content>
            <Header as="h4">
              {readOnly ? (
                <span>{t(`${department}Team`, { ns: 'users' })}</span>
              ) : (
                <InfoIconTooltip
                  infoText={t(`wizardsTeam.${department}-info`, {
                    ns: 'newProductWizard',
                  })}
                >
                  <span>{t(`${department}Team`, { ns: 'users' })}</span>
                </InfoIconTooltip>
              )}
              {!readOnly && (
                <TeamModal
                  team={team}
                  department={department}
                  mutation={mutation}
                  options={options}
                  inLegalCheckOrDone={inLegalCheckOrDone}
                />
              )}
            </Header>
            {team.length === 0 ? (
              <Feed.Summary className="inline">
                {t('department.none-assigned', { ns: 'users' })}
              </Feed.Summary>
            ) : (
              <>
                {team.slice(0, maxFeedLength).map((developer) => (
                  <ContactFeedElement
                    key={developer?.id || ''}
                    name={developer?.name || ''}
                    email={developer?.email || ''}
                  />
                ))}
                {team.length > maxFeedLength && (
                  <Feed.Event>
                    <Feed.Content>
                      <Feed.Summary>
                        <TeamModal
                          team={team}
                          department={department}
                          mutation={mutation}
                          options={options}
                          trigger={
                            <Button
                              id="ShowTeamButton"
                              className="borderless paddingless underline"
                              color="grey"
                              basic
                              compact
                              content={t('plusShowMore', {
                                ns: 'buttons',
                                number: team.length - maxFeedLength,
                              })}
                            />
                          }
                          readOnly
                        />
                      </Feed.Summary>
                    </Feed.Content>
                  </Feed.Event>
                )}
              </>
            )}
          </Feed.Content>
        </Feed.Event>
      </Feed>
      {supplierMessage && (
        <div style={{ padding: '1em' }}>
          <Popup
            id="TeamSupplierMessagePopup"
            on="hover"
            size="small"
            content={t('wizardsTeam.supplierNote', { ns: 'newProductWizard' })}
            hideOnScroll
            trigger={
              <Message
                id="TeamSupplierMessage"
                warning
                icon="exclamation triangle"
                content={t('wizardsTeam.supplierNote-header', {
                  ns: 'newProductWizard',
                })}
              />
            }
          />
        </div>
      )}
    </Card>
  );
};

export default TeamCard;
