import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeDiffFormatter } from '../../../../../productsList/listComponents/helpers/formatters';
import { timeDiffCalc } from '../../../../../productsList/listComponents/helpers/logics';

type PropsType = {
  targetDate: string;
};

/**
 * basically only returns an element with the time till targetDate
 * nothing too fancy
 *
 * @param {PropsType} props takes in targetDate
 * @returns {JSX.Element} a span with text
 */
const TargetDateElement = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('dashboard');
  const { targetDate } = props;

  return <span>{timeDiffFormatter(timeDiffCalc(targetDate), text)}</span>;
};

export default TargetDateElement;
