import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup, Segment } from 'semantic-ui-react';
import FilterMenuCheckbox from './FilterMenuCheckbox';
import { filterByAttributes, toggleColumnActive } from './helpers/formatters';
import { Column, Columns } from './ProductListTypes';

type PropsType = {
  column: Column;
  setColumns: React.Dispatch<React.SetStateAction<Columns>>;
};

/**
 * menu that belongs to a table columns and allows filtering
 *
 * @param {PropsType} props filtering attributes and toggle function
 * @returns {JSX.Element} filter menu in form of a popup attached to an icon
 */
const FilterMenu = (props: PropsType): JSX.Element => {
  const { setColumns, column } = props;
  const [text] = useTranslation('dashboard');
  const columnName = column.name;
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const openingFilterMenu = () => {
    setOpenFilterMenu((prevOpenFilterMenu) => !prevOpenFilterMenu);
  };
  const [selectedFilters, setSelectedFilters] = useState(
    column.filterInfo.filtering
  );

  const initialFilterOptions = useRef(new Map());
  const hasSetInitialFilters = useRef(new Set());

  // Handle initial filter setup when the component mounts
  if (
    !hasSetInitialFilters.current.has(columnName) &&
    Object.keys(column.filterInfo.filtering).length > 0
  ) {
    initialFilterOptions.current.set(columnName, column.filterInfo.filtering);
    hasSetInitialFilters.current.add(columnName);
  }

  return (
    <Popup
      id={`ProductsList.FilterMenu.${columnName}`}
      basic
      on="click"
      onClose={() => setOpenFilterMenu(false)}
      onOpen={() => setOpenFilterMenu(true)}
      position="bottom right"
      open={openFilterMenu}
      trigger={
        <Icon
          id="Icon"
          style={{
            float: 'right',
            lineHeight: '1.75em',
          }}
          link
          onClick={() => openingFilterMenu()}
          name="filter"
          size="small"
        />
      }
    >
      <Segment
        id="FilterMenuSegment"
        basic
        style={{ overflow: 'auto', maxHeight: '25vh' }}
      >
        {Object.keys(initialFilterOptions.current.get(columnName) || {}).map(
          (filterOptionName) => {
            // filteringSubObjectKey entspricht einem Key des Kindobjects, bspw. ein username
            return (
              <Segment
                id={`${filterOptionName}`}
                key={filterOptionName}
                vertical
              >
                <FilterMenuCheckbox
                  filtering={column.filterInfo.filtering}
                  filterOptionName={filterOptionName}
                  labeled={column.filterInfo.labeled}
                  setSelectedFilters={setSelectedFilters}
                />
              </Segment>
            );
          }
        )}
      </Segment>
      <Button
        id="FilterMenu.Button"
        onClick={() => {
          setColumns((oldColumns) => {
            return filterByAttributes(column, oldColumns, selectedFilters);
          });
          setColumns((oldColumns) => {
            return toggleColumnActive(column, oldColumns);
          });
          setOpenFilterMenu(false);
        }}
      >
        {text('filterMenu.showButton')}
      </Button>
    </Popup>
  );
};

export default FilterMenu;
