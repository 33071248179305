/* eslint-disable jsdoc/require-jsdoc */
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import {
  AdditionalLicenseFeature,
  ConflictRule,
  LicenseConflictRule,
  LicenseFeature,
  ToDo,
} from '../graphql/generated/graphql';

export type FeatureType =
  | ToDo
  | LicenseFeature
  | LicenseConflictRule
  | ConflictRule
  | AdditionalLicenseFeature;

export type FeatureTypeName =
  | 'ToDo'
  | 'LicenseFeature'
  | 'LicenseConflictRule'
  | 'ConflictRule'
  | 'AdditionalLicenseFeature';

interface LicenseDetailsContextProps {
  isEditMode: boolean;
  verified: boolean;
  description: string;
  licenseFeatures: LicenseFeature[];
  todos: ToDo[];
  licenseConflictRules: LicenseConflictRule[];
  conflictRules: ConflictRule[];
  additionalLicenseFeatures: AdditionalLicenseFeature[];
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setVerified: React.Dispatch<React.SetStateAction<boolean>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setLicenseFeatures: React.Dispatch<React.SetStateAction<LicenseFeature[]>>;
  setTodos: React.Dispatch<React.SetStateAction<ToDo[]>>;
  setLicenseConflictRules: React.Dispatch<
    React.SetStateAction<LicenseConflictRule[]>
  >;
  setConflictRules: React.Dispatch<React.SetStateAction<ConflictRule[]>>;
  setAdditionalLicenseFeatures: React.Dispatch<
    React.SetStateAction<AdditionalLicenseFeature[]>
  >;
  setAllFeatures: (features: FeatureType[]) => void;
  removeFeature: (type: FeatureTypeName, id: string) => void;
  getFeaturesByType: (type: FeatureTypeName) => FeatureType[];
  countFeatures: (type: string) => number;
  wasSaved: boolean;
  setWasSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

const LicenseDetailsContext = createContext<
  LicenseDetailsContextProps | undefined
>(undefined);

export const LicenseDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [verified, setVerified] = useState(false);
  const [description, setDescription] = useState('');
  const [todos, setTodos] = useState<ToDo[]>([]);
  const [licenseConflictRules, setLicenseConflictRules] = useState<
    LicenseConflictRule[]
  >([]);
  const [licenseFeatures, setLicenseFeatures] = useState<LicenseFeature[]>([]);
  const [conflictRules, setConflictRules] = useState<ConflictRule[]>([]);
  const [additionalLicenseFeatures, setAdditionalLicenseFeatures] = useState<
    AdditionalLicenseFeature[]
  >([]);
  const [wasSaved, setWasSaved] = useState(false);

  // function to initialize states of features (triggered in LicenseFeatures.tsx)
  const setAllFeatures = (features: FeatureType[]) => {
    const filteredTodos: ToDo[] = features
      .filter((f) => f?.__typename === 'ToDo')
      .map((feature) => {
        const todo = feature as ToDo;
        const selectedTasks = todo.tasks?.filter(
          (task) => features.findIndex((f) => f.id === task.id) > -1
        );
        return { ...todo, tasks: selectedTasks };
      }) as ToDo[];

    setTodos(filteredTodos);
    setLicenseFeatures(
      features.filter(
        (f) => f?.__typename === 'LicenseFeature'
      ) as LicenseFeature[]
    );
    setLicenseConflictRules(
      features.filter(
        (f) => f?.__typename === 'LicenseConflictRule'
      ) as LicenseConflictRule[]
    );
    setConflictRules(
      features.filter((f) => f?.__typename === 'ConflictRule') as ConflictRule[]
    );
    setAdditionalLicenseFeatures(
      features.filter(
        (f) => f?.__typename === 'AdditionalLicenseFeature'
      ) as AdditionalLicenseFeature[]
    );
  };

  const removeFeature = (name: FeatureTypeName, id: string) => {
    switch (name) {
      case 'ToDo':
        setTodos((prevTodos) => {
          // Check if the item to be removed is a todo or a task
          const itemToRemove = prevTodos.find((item) => item.id === id);
          if (itemToRemove?.parent === null) {
            const taskIdsToRemove =
              itemToRemove.tasks?.map((task) => task.id) || [];
            // Filter out the parent todo and its tasks from the main array
            return prevTodos
              .filter(
                (item) => item.id !== id && !taskIdsToRemove.includes(item.id)
              )
              .map((todo) => ({
                ...todo,
                tasks:
                  todo.tasks?.filter(
                    (task) => !taskIdsToRemove.includes(task.id)
                  ) || [],
              }));
          }
          // If the item is a task, remove it from both the todos array and the tasks array within each todo
          return prevTodos
            .filter((item) => item.id !== id)
            .map((todo) => ({
              ...todo,
              tasks: todo.tasks?.filter((task) => task.id !== id) || [],
            }));
        });
        break;
      case 'LicenseFeature':
        setLicenseFeatures((prevFeatures) =>
          prevFeatures.filter((feature) => feature.id !== id)
        );
        break;
      case 'LicenseConflictRule':
        setLicenseConflictRules((prevRules) =>
          prevRules.filter((rule) => rule.id !== id)
        );
        break;
      case 'ConflictRule':
        setConflictRules((prevRules) =>
          prevRules.filter((rule) => rule.id !== id)
        );
        break;
      case 'AdditionalLicenseFeature':
        setAdditionalLicenseFeatures((prevFeatures) =>
          prevFeatures.filter((feature) => feature.id !== id)
        );
        break;
      default:
        break;
    }
  };

  const getFeaturesByType = useCallback(
    (type: FeatureTypeName): FeatureType[] => {
      switch (type) {
        case 'ToDo':
          return todos;
        case 'LicenseFeature':
          return licenseFeatures;
        case 'LicenseConflictRule':
          return licenseConflictRules;
        case 'ConflictRule':
          return conflictRules;
        case 'AdditionalLicenseFeature':
          return additionalLicenseFeatures;
        default:
          return [];
      }
    },
    [
      todos,
      licenseFeatures,
      licenseConflictRules,
      conflictRules,
      additionalLicenseFeatures,
    ]
  );

  const countFeatures = useCallback(
    (type: string): number => {
      switch (type) {
        case 'ToDo': {
          const uniqueIds = new Set<string>();
          todos.forEach((todo) => {
            uniqueIds.add(todo.id);
            if (todo.tasks) {
              todo.tasks.forEach((task) => {
                uniqueIds.add(task.id);
              });
            }
          });
          return uniqueIds.size;
        }
        case 'LicenseConflictRule':
          return licenseConflictRules.length;
        case 'ConflictRule':
          return conflictRules.length;
        case 'AdditionalLicenseFeature':
          return additionalLicenseFeatures.length;
        default:
          return 0;
      }
    },
    [todos, licenseConflictRules, conflictRules, additionalLicenseFeatures]
  );

  const value = useMemo(
    () => ({
      isEditMode,
      verified,
      description,
      todos,
      licenseFeatures,
      licenseConflictRules,
      conflictRules,
      additionalLicenseFeatures,
      setIsEditMode,
      setVerified,
      setDescription,
      setTodos,
      setLicenseFeatures,
      setConflictRules,
      setAdditionalLicenseFeatures,
      setLicenseConflictRules,
      setAllFeatures,
      removeFeature,
      getFeaturesByType,
      countFeatures,
      wasSaved,
      setWasSaved,
    }),
    [
      isEditMode,
      verified,
      description,
      todos,
      licenseFeatures,
      licenseConflictRules,
      conflictRules,
      additionalLicenseFeatures,
      getFeaturesByType,
      countFeatures,
      wasSaved,
    ]
  );

  return (
    <LicenseDetailsContext.Provider value={value}>
      {children}
    </LicenseDetailsContext.Provider>
  );
};

export const useLicenseContext = (): LicenseDetailsContextProps => {
  const context = useContext(LicenseDetailsContext);
  const noop = () => {
    // No operation performed.
  };
  const defaultGetFeaturesByType = (): FeatureType[] => [];
  const defaultCountFeatures = (): number => 0;
  if (context === undefined) {
    return {
      isEditMode: false,
      verified: false,
      description: '',
      todos: [],
      licenseFeatures: [],
      licenseConflictRules: [],
      conflictRules: [],
      additionalLicenseFeatures: [],
      setIsEditMode: noop,
      setVerified: noop,
      setTodos: noop,
      setDescription: noop,
      setLicenseFeatures: noop,
      setLicenseConflictRules: noop,
      setConflictRules: noop,
      setAdditionalLicenseFeatures: noop,
      setAllFeatures: noop,
      removeFeature: noop,
      getFeaturesByType: defaultGetFeaturesByType,
      countFeatures: defaultCountFeatures,
      wasSaved: false,
      setWasSaved: noop,
    };
  }
  return context;
};
