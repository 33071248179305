import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  SegmentGroup,
  Checkbox,
} from 'semantic-ui-react';
import { Columns, Column } from './ProductListTypes';

type PropsType = {
  columns: Columns;
  setColumns: React.Dispatch<React.SetStateAction<Columns>>;
};

/**
 * modal to edit the table view
 *
 * @param {PropsType} props takes in ...
 * @returns {JSX.Element} ProductsListEditModal
 */
const ProductsListEditModal = (props: PropsType): JSX.Element => {
  const { columns, setColumns } = props;
  const [text] = useTranslation('dashboard');
  const [open, setOpen] = useState(false);

  const showAllColumns = () => {
    setColumns((oldColumns) =>
      oldColumns.map((column: Column) => ({ ...column, shown: true }))
    );
  };

  const triggerColumn = (triggeredColumn: Column) => {
    setColumns((oldColumns) => {
      const currentColumn = oldColumns.find(
        (oldCol) => oldCol.name === triggeredColumn.name
      );
      if (currentColumn) {
        const tempColumn = { ...currentColumn, shown: !currentColumn.shown };
        return oldColumns.map((column: Column) =>
          column.name === tempColumn.name ? tempColumn : column
        );
      }
      return oldColumns;
    });
  };

  const isEveryColumnShown = columns.every((column) => column.shown);

  return (
    <Modal
      id="ProductsListEditModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={
        <Button
          primary
          inverted
          style={{
            float: 'right',
          }}
          id="ProductsListEditModal.TriggerButton"
          icon="pencil"
          content={text('buttons.editTable')}
        />
      }
    >
      <Header>
        {text('buttons.editTable')}
        <Icon
          id="iconClose"
          name="close"
          link
          floated="right"
          onClick={() => setOpen(false)}
        />
      </Header>
      <Modal.Content>
        {text('editTableModal.description')}
        <SegmentGroup>
          <Segment attached id="ProductsListEditModal.Segment.Header">
            <Checkbox
              id="ProductsListEditModal.Toggle"
              type="checkbox"
              toggle
              checked={isEveryColumnShown}
              disabled={isEveryColumnShown}
              onClick={showAllColumns}
            />
            <span style={{ opacity: isEveryColumnShown ? 0.45 : 1 }}>
              {text('editTableModal.showAllColumns')}
            </span>
          </Segment>
          {columns.map((column: Column) => {
            // id does not understand that column.name is a string
            const columnName: string = column.name;
            return (
              column.permitted && (
                <Segment
                  attached
                  id={`ProductsListEditModal.Segment.${columnName}`}
                  key={`ProductsListEditModal.Segment.${column.name}`}
                >
                  <span className={column.shown ? '' : 'grey'}>
                    {text(`tableHeader.${column.name}`)}
                  </span>
                  <Icon
                    id="ProductsListEditModal.Segment.EyeIcon"
                    className={column.shown ? 'colored' : 'grey'}
                    style={{
                      float: 'right',
                    }}
                    name={column.shown ? 'eye' : 'eye slash'}
                    size="large"
                    onClick={() => triggerColumn(column)}
                  />
                </Segment>
              )
            );
          })}
        </SegmentGroup>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="ProductsListEditModal.OK"
          primary
          // TODO
          onClick={() => setOpen(false)}
        >
          {text('editTableModal.saveButton')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProductsListEditModal;
