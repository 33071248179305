import React, { useEffect, useState } from 'react';
import { Icon, MenuItem, Popup } from 'semantic-ui-react';
import UserMenu from './UserMenu';

type UserDetailProps = {
  setOpen: (open: boolean) => void;
};

/**
 * UserPopup
 * at the top of the {@link SideBarMenu} with the user icon as trigger
 * it provides the {@link UserMenu} on mouseover disappearence is delayed after mouseleave.
 *
 * @param {UserDetailProps} setOpen prop for opening the modal view
 * @returns {JSX.Element} UserPopup
 */
const UserPopup = ({ setOpen }: UserDetailProps): JSX.Element => {
  let delay: ReturnType<typeof setTimeout>;

  const [openPopup, setOpenPopup] = useState(false);

  const handleMouseEnter = () => {
    clearTimeout(delay);
    setOpenPopup(true);
  };

  const handleMouseLeave = () => {
    delay = setTimeout(() => {
      setOpenPopup(false);
    }, 150);
  };

  useEffect(() => {
    return () => {
      clearTimeout(delay);
    };
  });

  return (
    <Popup
      id="UserPopup"
      open={openPopup}
      offset={[5, -5]}
      inverted
      hoverable
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      position="right center"
      trigger={
        <MenuItem
          id="UserPopup.MenuItem"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => setOpen(true)}
        >
          <Icon id="UserPopup.MenuItem.Icon" name="user circle" />
        </MenuItem>
      }
      content={<UserMenu setOpen={setOpen} />}
    />
  );
};
export default UserPopup;
