import { useTranslation } from 'react-i18next';

/**
 * @param {string} jsonFile param
 *@returns {void} Function that translates text using custom useTranslation hook
 * Roles selection and render as labels in Dropdown
 * Used for UserDetails.tsx and AddUser.tsx
 */
const useTranslateOptions = (
  jsonFile: string
): ((input: { key: number; text: string; value: string }[]) => void) => {
  const [t] = useTranslation([jsonFile]);

  const translate = (input: { key: number; text: string; value: string }[]) => {
    const translated = input.map(
      (opt: { key: number; text: string; value: string }) => ({
        ...opt,
        text: t(opt.text),
      })
    );
    return translated;
  };
  return translate;
};

export default useTranslateOptions;
