import { useQuery } from '@apollo/client';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Icon,
  Label,
  Menu,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../context/ProductContext';

import ErrorAndLoadingMessage from './ErrorAndLoadingMessage';
import Discussions, { DiscussionsReset } from '../Discussions';
import GenericClosedSegmentSkeleton from '../../skeletons/GenericClosedSegmentSkeleton';
import { Comment } from '../../../../../graphql/generated/graphql';
import {
  extractFeaturesComments,
  extractHistoryElement,
  filterReferences,
} from '../helper/logics';
import { ALL_HISTORY_COMMENTS } from '../../../../../graphql/queries/CommentQuerys';
import ProductHistoryListElement from '../../../productHistory/listComponents/ProductHistoryListElement';
import getDefaultColumns from '../../../productHistory/helpers/types';

/**
 *@returns {JSX.Element} History Comments Segment returns an overview of all History comments of a version/product
 */
const HistoryCommentsSegment = (): JSX.Element => {
  const [t, i18n] = useTranslation(['comments']);
  const i18nLang = i18n.language;
  const { versionId } = useContext(ProductDetailsContext);
  const [open, setOpen] = useState(false);

  const ref = useRef<DiscussionsReset>(null);

  const resetAndClose = () => {
    ref?.current?.reset();
    setOpen(!open);
  };

  // Query for todo comments
  const {
    data: historyData,
    loading: historyLoading,
    error: historyError,
  } = useQuery(ALL_HISTORY_COMMENTS, {
    variables: { versionId },
    fetchPolicy: 'cache-and-network',
  });

  if (historyLoading && !open) {
    <GenericClosedSegmentSkeleton
      image
      lines={1}
      labels="all"
      linesLength="long"
    />;
  }
  const columns = getDefaultColumns();

  if (historyData) {
    const allHistoryComments = historyData.Comments;
    const hasComments = allHistoryComments.length > 0;
    const majorComments = allHistoryComments.filter(
      (comment: Comment) => comment.replied === null
    );

    return (
      <Segment id="HistoryCommentsSegment">
        <Menu id="HistoryCommentsSegmentMenu" secondary compact>
          <Menu.Item>
            <Icon
              id="HistoryCommentsSegmentMenuAccordion"
              onClick={resetAndClose}
              name={open ? 'chevron up' : 'chevron down'}
              color={open ? 'blue' : 'black'}
            />
          </Menu.Item>
          <Menu.Item
            content={t('onHistory')}
            style={{ fontWeight: 'bold', fontSize: '16px' }}
          />
          <Menu.Item>
            <Label id="CommentsNumberLabel" circular style={{ margin: 0 }}>
              {allHistoryComments.length}
            </Label>
            <Icon
              size="large"
              name="question circle"
              id={`onHistoryCommentGuideIcon-${i18nLang}`}
              style={{
                marginLeft: '0.5rem',
                display: 'none',
                // TODO: delete display none when guide is ready
              }}
            />
          </Menu.Item>
        </Menu>
        {/* Comments View - name of the History Log is displayed for which comments are listed */}
        {open && (
          <div className="AllCommentsSegmentCommentsOverview">
            {hasComments ? (
              filterReferences(majorComments).map((efs) => {
                return (
                  <Fragment
                    key={`AllHistorySegmentCommentsOverviewRequirementName${efs}`}
                  >
                    <Table id="ProductHistoryListTableCommentsSegment">
                      <Table.Body>
                        <ProductHistoryListElement
                          key={`ProductHistoryListElement${efs}`}
                          element={extractHistoryElement(
                            efs as string,
                            allHistoryComments
                          )}
                          columns={columns}
                          useCommentIcon={false}
                        />
                      </Table.Body>
                    </Table>
                    <Divider fitted style={{ margin: '0 0 0 1em' }} />
                    {/* here we list the comments which together coming from above listed referred component  */}
                    <Discussions
                      ref={ref}
                      comments={extractFeaturesComments(
                        efs as string,
                        allHistoryComments
                      )}
                      refersToId={efs}
                      refetch={[ALL_HISTORY_COMMENTS]}
                    />
                  </Fragment>
                );
              })
            ) : (
              <Message
                id="HistoryCommentsSegmentNoCommentsYet"
                info
                floating
                compact
              >
                <Message.Header content={t('noHistoryCommentsYet')} />{' '}
                {t('noHistoryCommentsCreate')}
              </Message>
            )}
          </div>
        )}
      </Segment>
    );
  }
  if (historyError || historyLoading) {
    return (
      <ErrorAndLoadingMessage
        error={historyError}
        loading={historyLoading}
        source="onHistory"
      />
    );
  }
  return <div />;
};

export default HistoryCommentsSegment;
