import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Divider, Menu, MenuItem, Popup } from 'semantic-ui-react';
import { SelectedRoleContext, UserContext } from '../../context/UserContext';
import SelectLanguage from '../user/SelectLanguage';
import UserDetails from '../user/UserDetails';
import UserPopup from '../user/UserPopup';
import '../../usetiful/Usetiful';
import ImprintAndLegal from '../imprintAndLegal/ImprintAndLegal';
import DownloadsLinksModal from './DownloadsLinksModal';

/**
 * Sidebar Menu
 * Provides the left navigation bar.
 *
 * provides the user editing interface as a poping modal view {@link UserPopup} & {@link UserDetails}
 *
 * @returns {JSX.Element} SideBarMenu
 */
const SideBarMenu = (): JSX.Element => {
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openDownloadsModal, setOpenDownloadsModal] = useState(false);
  const { menuitem } = useParams();
  const [t] = useTranslation('menu');
  const { selectedRole } = useContext(SelectedRoleContext);
  const role = selectedRole.toString();
  const currentUser = useContext(UserContext);

  return (
    <>
      <UserDetails
        open={openUserModal}
        setOpen={setOpenUserModal}
        user={currentUser}
        data-cy="cylogout"
      />
      <DownloadsLinksModal
        open={openDownloadsModal}
        setOpen={setOpenDownloadsModal}
      />
      <Menu
        id="SideBarMenu"
        icon
        vertical
        inverted
        width="thin"
        style={{
          width: 50,
          height: '100vh',
          position: 'fixed',
          border: 0,
          borderRadius: 0,
        }}
      >
        <UserPopup setOpen={setOpenUserModal} />
        <Popup
          id="SideBarMenuDashboardPopup"
          trigger={
            <MenuItem
              id="SideBarMenuDashboardPopupMenuItem"
              active={menuitem?.includes('dashboard')}
              icon="tachometer alternate"
              as={Link}
              to="/dashboard"
            />
          }
          content={t('dashboard')}
          inverted
          position="right center"
        />
        {['admin'].includes(role) && (
          <Popup
            id="SideBarMenuDirectoriesPopup"
            trigger={
              <MenuItem
                id="SideBarMenuDirectoriesPopupMenuItem"
                active={menuitem?.includes('directories')}
                icon="folder"
                as={Link}
                to="/directories"
              />
            }
            content={t('directories')}
            inverted
            position="right center"
          />
        )}
        {[
          'legal',
          'rulesetlegal',
          'brandlegal',
          'externlegal',
          'ososervices',
          'oso',
        ].includes(role) && (
          <Popup
            id="SideBarMenu.licensesPopup"
            trigger={
              <MenuItem
                id="SideBarMenulicensesPopupMenuItem"
                active={menuitem?.includes('licenses')}
                icon="file alternate"
                as={Link}
                to="/licenses"
              />
            }
            content={t('licenses')}
            inverted
            position="right center"
          />
        )}
        {[
          'legal',
          'rulesetlegal',
          'brandlegal',
          'externlegal',
          'ososervices',
          'oso',
        ].includes(role) && (
          <Popup
            id="SideBarMenuFeatureGroupsPopup"
            trigger={
              <MenuItem
                id="SideBarMenuFeatureGroupsPopupMenuItem"
                active={menuitem?.includes('feature-groups')}
                icon="sitemap"
                as={Link}
                to="/feature-groups"
              />
            }
            content={t('feature-groups')}
            inverted
            position="right center"
          />
        )}
        <Divider hidden />
        <div style={{ position: 'absolute', bottom: '5px' }}>
          <MenuItem id="SideBarMenuSelectLanguageMenuItem" position="right">
            <SelectLanguage />
          </MenuItem>
          <Popup
            id="SideBarMenuDownloadsPopup"
            trigger={
              <MenuItem
                id="SideBarMenuDownloadsMenuItem"
                icon="question"
                onClick={() => setOpenDownloadsModal(true)}
              />
            }
            content={t('downloadsModal.title')}
            inverted
            position="right center"
          />
          <MenuItem
            id="SideBarMenuGitInfoMenuItem"
            position="right"
            icon="info"
          />
          {/*  Menu Item with Links in a Popup with more functions */}
          <ImprintAndLegal />
        </div>
      </Menu>
    </>
  );
};

export default SideBarMenu;
