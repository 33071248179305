import React, { useState } from 'react';
import { Modal, Dimmer, Segment, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import UserDirectoriesAccess from './UserDirectoriesAccess';
import UserDetailsDataDisplay from './UserDetailsDataDisplay';
import UserDetailsEditForm from './UserDetailsEditForm';
import UserDetailsRolesAndBrands from './UserDetailsRolesAndBrands';
import {
  DirectoryAccess,
  InputMaybe,
  Maybe,
  Role,
  UpdateUserMutationVariables,
  User,
  UserStatus,
} from '../../../graphql/generated/graphql';
import UserDataMenu from './helpers/types';

type UserDetailsContentProps = {
  onlyAdmin?: boolean | undefined;
  user: User;
  userDetails: UpdateUserMutationVariables;
  setUpdateDetails: (userDetails: UpdateUserMutationVariables) => void;
  repeatPass: {
    pass: string;
    enabled: boolean;
  };
  setRepeatPass: (repeatPass: { pass: string; enabled: boolean }) => void;
  usersGlobalRoles?: InputMaybe<Role | Role[]> | undefined;
  directoryAccessArr: DirectoryAccess[];
  setDirectoryAccessArr: React.Dispatch<
    React.SetStateAction<DirectoryAccess[]>
  >;
  brandDirectories: Maybe<Array<Maybe<DirectoryAccess>>> | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  setChangesAreMade: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 *@returns {JSX.Element} User Details Content Element where there is 2 Parts divided by Menu Tabs. Deactivation toggle disables all inputs with Dimmer element
 *@param {UserDetailsContentProps} props props
 */
const UserDetailsContentContainer = (
  props: UserDetailsContentProps
): JSX.Element => {
  const {
    user,
    onlyAdmin,
    userDetails,
    setUpdateDetails,
    repeatPass,
    setRepeatPass,
    brandDirectories,
    loading,
    error,
    usersGlobalRoles,
    directoryAccessArr,
    setDirectoryAccessArr,
    setChangesAreMade,
  } = props;
  const [t] = useTranslation('users');

  const [activeProfileTab, setActiveTab] = useState(UserDataMenu.profile);
  // active user goes to inactive status/state-& vice versa by Toggle button
  const deactivated = userDetails.status === UserStatus.inactive;

  return (
    <>
      {onlyAdmin && (
        <Dimmer.Dimmable dimmed={deactivated}>
          <Dimmer active={deactivated} inverted />
          <Menu id="UserDetailsMenu" pointing secondary size="large" widths={2}>
            <Menu.Item
              name={t('modal.userProfile')}
              active={activeProfileTab === UserDataMenu.profile}
              onClick={() => setActiveTab(UserDataMenu.profile)}
            />
            <Menu.Item
              name={t('modal.userDirectories')}
              active={activeProfileTab === UserDataMenu.access}
              onClick={() => setActiveTab(UserDataMenu.access)}
            />
          </Menu>
        </Dimmer.Dimmable>
      )}
      <Modal.Content className="UserDetailsContentContainer">
        {/* USER DATA DISPLAY-AVATAR & PROFILE PART */}
        {activeProfileTab === UserDataMenu.profile && (
          <>
            <UserDetailsDataDisplay
              user={user}
              onlyAdmin={onlyAdmin}
              userDetails={userDetails}
              setUpdateDetails={setUpdateDetails}
            />
            <Dimmer.Dimmable dimmed={deactivated}>
              <Dimmer active={deactivated} inverted />
              {/* EDIT USER DETAILS PART */}
              <UserDetailsEditForm
                onlyAdmin={onlyAdmin}
                userDetails={userDetails}
                setUpdateDetails={setUpdateDetails}
                repeatPass={repeatPass}
                setRepeatPass={setRepeatPass}
              />
            </Dimmer.Dimmable>
          </>
        )}
        {/* USER ROLES , BRANDS & BRAND-DIRECTORIES ACCESS COMPONENT */}
        {onlyAdmin && activeProfileTab === UserDataMenu.access && (
          <Dimmer.Dimmable dimmed={deactivated}>
            <Dimmer active={deactivated} inverted />
            <Segment
              id="UserRolesBrandsAndBrandAccessDirectoriesSegment"
              raised
            >
              <UserDetailsRolesAndBrands
                userDetails={userDetails}
                setUpdateDetails={setUpdateDetails}
              />
              <UserDirectoriesAccess
                brandDirectories={brandDirectories}
                usersGlobalRoles={usersGlobalRoles}
                directoryAccessArr={directoryAccessArr}
                setDirectoryAccessArr={setDirectoryAccessArr}
                loading={loading}
                error={error}
                setChangesAreMade={setChangesAreMade}
              />
            </Segment>
          </Dimmer.Dimmable>
        )}
      </Modal.Content>
    </>
  );
};

export default UserDetailsContentContainer;
