import { useTranslation } from 'react-i18next';
import {
  BrandDirectory,
  CreateProductInput,
  CreateProjectInput,
  ProductType,
} from '../../graphql/generated/graphql';

/**
 * Inputtype for proudct parts, we added an id to identify the parts
 * if the user goes back to edit a partname later in the process.
 */
export type CreateProductPart = CreateProductInput & { id: number };

/**
 * Arrary of the product part input type
 */
export type ProductPartInput = Array<CreateProductPart>;

/**
 * Input type for the Product information
 */
export type WizardContextInput = {
  productInput: CreateProjectInput;
  partInput: ProductPartInput;
};

/**
 *
 */
export interface OptionTypes {
  value: string;
  detail: string;
  name: string;
}
/**
 *
 */
export type SelectionTypes = {
  value: boolean;
  name: string;
};
/**
 *
 */
export type ProductStructureType = {
  value: number;
};
/**
 *
 */
export type selectedDist = {
  id: number;
  dist: string;
};
/**
 *
 */
export type Part = {
  id: number;
  name: string;
};

/**
 * setNoParts onChange - WizardContext noParts property
 *
 *@returns {Array<unknown>} - multiple parts
 */
export const SelectProductStructure = (): Array<unknown> => {
  const { t } = useTranslation('newProductWizard');

  return [
    {
      key: 'productWithParts',
      text: t('productWithParts'),
      value: 0,
    },
    { key: 'emptyProduct', text: t('emptyProduct'), value: 1 },
    { key: 'productAsPart', text: t('productAsPart'), value: 2 },
  ];
};

/**
 *
 */
export const transferCompanyOptions = [
  { key: BrandDirectory.VW, text: BrandDirectory.VW, value: BrandDirectory.VW },
  {
    key: BrandDirectory.Cariad,
    text: BrandDirectory.Cariad,
    value: BrandDirectory.Cariad,
  },
  {
    key: BrandDirectory.Porsche,
    text: BrandDirectory.Porsche,
    value: BrandDirectory.Porsche,
  },
];
/**
 * passing yes or no for the transfer/ pass- meaning on Yes additional 3 Inputs are open
 *
 *@returns {Array<unknown>} yes or no for the group-takeover
 */
export const TransferOrPass = (): Array<unknown> => {
  const { t } = useTranslation('newProductWizard');

  return [
    { key: 'noPass', text: t('productDetails.noPass'), value: 'noPass' },
    { key: 'pass', text: t('productDetails.pass'), value: 'pass' },
  ];
};

/**
 * on standard software it will update "true" / Part of the wizardInput.productInput
 *
 *@returns {Array<unknown>}}
 */
export const SoftwareSelect = (): Array<unknown> => {
  const { t } = useTranslation('newProductWizard');

  return [
    { key: 'third-party', text: t('productDetails.third-party'), value: false },
    { key: 'standard', text: t('productDetails.standard'), value: true },
  ];
};

/**
 * selecting IntendedUse high priority options
 * on highPriorityRequest it will update "true" / Part of the wizardInput.productInput
 *
 *@returns {Array<unknown>}}
 */
export const SelectHighPriority = (): Array<unknown> => {
  const { t } = useTranslation('newProductWizard');

  return [
    {
      key: 'false',
      text: t('intendedUsePart.highPrio-false'),
      value: false,
      icon: { name: 'angle double down', color: 'blue' },
    },
    {
      key: 'true',
      text: t('intendedUsePart.highPrio-true'),
      value: true,
      icon: { name: 'angle double up', color: 'red' },
    },
  ];
};

/**
 * Protection Dropdown options
 *
 * @returns {Array<unknown>} - yes or no for the protection
 */
export const SelectProtection = (): Array<unknown> => {
  const { t } = useTranslation('newProductWizard');

  return [
    {
      key: 'yes',
      text: t('intendedUsePart.protection-yes'),
      value: true,
      icon: 'check',
    },
    {
      key: 'no',
      text: t('intendedUsePart.protection-no'),
      value: false,
      icon: 'x',
    },
  ];
};

/**
 * Part type of WizardPartsSegment
 */
export type PartForSelection = {
  id: number;
  name: string;
  checked: boolean;
};
/**
 * Dropdown options Type
 */
export type DropdownOptions = {
  key: string;
  text: string;
  value: string;
};
/**
 * @returns {Array<DropdownOptions>} - Distribution Dropdown Options localized
 */
export const DistributionOptions = (): Array<DropdownOptions> => {
  const { t } = useTranslation('newProductWizard');
  return [
    {
      key: 'binaryCode',
      text: t('intendedUsePart.distribution-binaryCode'),
      value: 'binaryCode',
    },

    {
      key: 'noDistribution',
      text: t('intendedUsePart.distribution-noDistribution'),
      value: 'noDist',
    },
    {
      key: 'distSource',
      text: t('intendedUsePart.distribution-distSource'),
      value: 'distSource',
    },
  ];
};
/**
 * selecting IntendedUse Recipients
 *
 */
export const DistributionIds = {
  NODISTRIBUTION: 'b54ab285-fd75-4f20-9dba-bee6fd05e615',
  DISTRIBUTION: '0dc42af9-2c5b-4758-b2ad-43ece580d9a0',
  DISTRIBUTIONBINARY: '3639daa6-9bd2-4a11-8eff-c93e586851da',
  DISTRIBUTIONSOURCE: '3364403c-1b79-4108-b30a-09406b7abc30',
  DISTRIBUTIONBINARYEXTERNAL: '5cc5db93-9224-4c2b-a827-33c4f73ffc57',
  DISTRIBUTIONBINARYINTERNAL: '4dff7de7-e87c-4be4-894f-aa2bb9c105ab',
  DISTRIBUTIONSOURCEEXTERNAL: 'fc1dc44f-dfa5-426f-9ff1-ebf70cf3e24f',
  DISTRIBUTIONSOURCEINTERNAL: 'f88eb28a-7218-4a1d-9930-a8dfc2d6a101',
  SERVERUSAGE: '7867b3eb-af5f-4288-b286-b77909beb777',
  TIVO: '6fd29837-a4b0-48b9-9ef7-946b40d2b1bd',
};

/**
 *Names to be added regarding the above given ID for useContext before update
 *
 *@returns {object} Object of Distribution names
 */
export const DistributionNamesLocalized = (): object => {
  const { t } = useTranslation('productDetails');
  return {
    'b54ab285-fd75-4f20-9dba-bee6fd05e615': t(
      'distributionNamesLocalized.noDist'
    ),
    '7867b3eb-af5f-4288-b286-b77909beb777': t(
      'distributionNamesLocalized.noDistServerUse'
    ),
    '2b2e8d47-4daa-42e4-9280-a643802d909d': t(
      'distributionNamesLocalized.dist'
    ),
    '3639daa6-9bd2-4a11-8eff-c93e586851da': t(
      'distributionNamesLocalized.binaryCode'
    ),
    '3364403c-1b79-4108-b30a-09406b7abc30': t(
      'distributionNamesLocalized.sourceCode'
    ),
    '5cc5db93-9224-4c2b-a827-33c4f73ffc57': t(
      'distributionNamesLocalized.binaryExt'
    ),
    '4dff7de7-e87c-4be4-894f-aa2bb9c105ab': t(
      'distributionNamesLocalized.binaryInt'
    ),
    'fc1dc44f-dfa5-426f-9ff1-ebf70cf3e24f': t(
      'distributionNamesLocalized.sourceExt'
    ),
    'f88eb28a-7218-4a1d-9930-a8dfc2d6a101': t(
      'distributionNamesLocalized.sourceInt'
    ),
  };
};

/**
 * Distributions server usage selection
 *
 *@returns {Array<DropdownOptions>} Array of server usage recipients
 */
export const ServerUsageOptions = (): Array<DropdownOptions> => {
  const { t } = useTranslation('newProductWizard');
  return [
    {
      key: 'serverYes',
      text: t('intendedUsePart.server-yes'),
      value: DistributionIds.SERVERUSAGE,
    },
    {
      key: 'serverNo',
      text: t('intendedUsePart.server-no'),
      value: DistributionIds.NODISTRIBUTION,
    },
  ];
};
/**
 * Distributions source code selection
 *
 *@returns {Array<DropdownOptions>} Array of source code recipients
 */
export const SourceRecipientsOptions = (): Array<DropdownOptions> => {
  const { t } = useTranslation('newProductWizard');
  return [
    {
      key: 'internalSourceRecipient',
      text: t('intendedUsePart.recipient-internal'),
      value: DistributionIds.DISTRIBUTIONSOURCEINTERNAL,
    },
    {
      key: 'externalSourceRecipient',
      text: t('intendedUsePart.recipient-external'),
      value: DistributionIds.DISTRIBUTIONSOURCEEXTERNAL,
    },
  ];
};
/**
 * Distributions binary code selection
 *
 *@returns {Array<DropdownOptions>} Array of BinaryCode recipients
 */
export const BinaryCodeOptions = (): Array<DropdownOptions> => {
  const { t } = useTranslation('newProductWizard');
  return [
    {
      key: 'internalBinary',
      text: t('intendedUsePart.recipient-internal'),
      value: DistributionIds.DISTRIBUTIONBINARYINTERNAL,
    },
    {
      key: 'externalBinary',
      text: t('intendedUsePart.recipient-external'),
      value: DistributionIds.DISTRIBUTIONBINARYEXTERNAL,
    },
  ];
};
/**
 *
 */
export const subTypeCar = [
  {
    key: 'car-application',
    text: 'Application',
    value: ProductType.Application,
  },
  { key: 'car-ecu', text: 'ECU', value: ProductType.ECU },
  { key: 'car-hardware', text: 'Hardware', value: ProductType.Hardware },
  { key: 'car-software', text: 'Software', value: ProductType.Software },
  { key: 'car-other', text: 'Other', value: ProductType.Other },
];
/**
 *
 */
export const subTypeEnterprise = [
  {
    key: 'enterprise-application',
    text: 'Application',
    value: ProductType.Application,
  },
  {
    key: 'enterprise-hardware',
    text: 'Hardware',
    value: ProductType.Hardware,
  },
  { key: 'enterprise-other', text: 'Other', value: ProductType.Other },
];

/**
 * @param {string} input - current name input value
 * @param {string[]} productNames - array of existing product names in lowercase
 * @returns {[string | null, boolean]} returns possible error message and boolean if name is valid
 */
export const CheckIfNameExists = (
  input: string | undefined,
  productNames: string[]
): [string | null, boolean] => {
  const { t } = useTranslation('newProductWizard');
  if (!input) {
    return [null, false];
  }
  if (input?.length < 3) {
    return [t('productDetails.errorProductName'), false];
  }
  if (productNames.includes(input.toLowerCase())) {
    return [t('productDetails.errorProductNameExists'), false];
  }
  return [null, true];
};
