import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Popup } from 'semantic-ui-react';

/**
 * SelectLanguage
 * Popup menu to change the language.
 *
 * @returns {JSX.Element} Select Language Menu
 */
const SelectLanguage = (): JSX.Element => {
  const [, i18n] = useTranslation('translation');
  return (
    <Popup
      id="SelectLanguagePopup"
      basic
      style={{
        border: 0,
        borderRadius: 0,
        padding: 0,
      }}
      inverted
      hoverable
      position="top center"
      trigger={
        <MenuItem id="SelectLanguagePopup.MenuItem" fitted>
          <CircleFlag
            height="18"
            countryCode={i18n.language.includes('en') ? 'gb' : 'de'}
          />
        </MenuItem>
      }
      content={
        <Menu
          id="SelectLanguagePopup.LanguageSelectionMenu"
          icon
          vertical
          inverted
          width="thin"
          style={{
            border: 0,
            borderRadius: 0,
          }}
        >
          <MenuItem
            id="SelectLanguagePopup.LanguageSelectionMenu.en"
            active={i18n.language.includes('en')}
            onClick={() => i18n.changeLanguage('en')}
          >
            <CircleFlag height="18" countryCode="gb" />
          </MenuItem>
          <MenuItem
            id="SelectLanguagePopup.LanguageSelectionMenu.de"
            active={i18n.language.includes('de')}
            onClick={() => i18n.changeLanguage('de')}
          >
            <CircleFlag height="18" countryCode="de" />
          </MenuItem>
        </Menu>
      }
    />
  );
};

export default SelectLanguage;
