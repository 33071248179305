import { Reducer, useReducer } from 'react';
import { AdditionalFileData } from '../../../../../../graphql/generated/graphql';

/**
 * @type {Direction} - determines sorting direction
 */
export type Direction = 'ascending' | 'descending' | undefined;

interface SortingState<T> {
  column: string | null;
  data: T[];
  direction: Direction;
  additionalData: Record<string, AdditionalFileData>;
}

interface UpdateDataAction<T> {
  type: 'UPDATE_DATA';
  data: T[];
}

interface ChangeSortAction {
  type: 'CHANGE_SORT';
  column: string;
}

/**
 * @type {SetAdditionalData} - setting additional data to the files
 */
export interface SetAdditionalData {
  type: 'SET_ADDITIONAL_DATA';
  payload: Record<string, AdditionalFileData>;
}

type SortingActions<T> =
  | UpdateDataAction<T>
  | ChangeSortAction
  | SetAdditionalData;

/**
 * A reducer function to handle the sorting state.
 *
 * @template T
 * @param {SortingState<T>} state The current sorting state.
 * @param {SortingActions<T>} action The action to apply.
 * @returns {SortingState<T>} The new sorting state after the action has been applied.
 */
// eslint-disable-next-line
const sortingReducer = <T extends Record<string, any>>(
  state: SortingState<T>,
  action: SortingActions<T>
): SortingState<T> => {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: [...state.data].reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        additionalData: state.additionalData,
        data: [...state.data].sort((a, b) => {
          const key = action.column as keyof T;

          /* eslint-disable */
          let aValue: any;
          let bValue: any;
          /* eslint-enable */

          // If the key is 'sha256', goes to checksums otherwise use first level of data.
          if (key === 'sha256') {
            /* eslint-disable dot-notation */
            aValue = a['checksums']?.[key];
            bValue = b['checksums']?.[key];
          } else {
            aValue = a[key];
            bValue = b[key];
          }

          // If values in data are undefined, tries to get them from 'additionalData'
          if (typeof aValue === 'undefined' || typeof bValue === 'undefined') {
            /* eslint-disable */
            const aAdditional = state.additionalData[(a as any).filename];
            const bAdditional = state.additionalData[(b as any).filename];
            /* eslint-enable */

            if (aAdditional && bAdditional) {
              const additionalKey = key as keyof AdditionalFileData;
              aValue = aValue ?? aAdditional[additionalKey];
              bValue = bValue ?? bAdditional[additionalKey];
            }
          }

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue);
          }

          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return aValue - bValue;
          }

          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;

          return 0;
        }),
        direction: 'ascending',
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: action.data,
      };
    case 'SET_ADDITIONAL_DATA':
      return {
        ...state,
        additionalData: { ...state.additionalData, ...action.payload },
      };

    default:
      throw new Error('Unknown action type');
  }
};

/**
 * A hook to manage sortable table state.
 *
 * @template T
 * @param {T[]} initialData The initial data to populate the table.
 * @returns {Array} A triplet containing the sort state, a dispatch function and a updateData function.
 */
// eslint-disable-next-line
const useTableSorting = <T extends Record<string, any>>(
  initialData: T[] = []
): [
  SortingState<T>,
  React.Dispatch<SortingActions<T>>,
  (newData: T[]) => void
] => {
  const initialState: SortingState<T> = {
    column: null,
    data: initialData,
    direction: undefined,
    additionalData: {},
  };

  const [sortState, dispatch] = useReducer<
    Reducer<SortingState<T>, SortingActions<T>>
  >(sortingReducer, initialState);

  const updateData = (newData: T[]) => {
    dispatch({ type: 'UPDATE_DATA', data: newData });
  };

  return [sortState, dispatch, updateData];
};

export default useTableSorting;
