import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, List, Placeholder, PlaceholderLine } from 'semantic-ui-react';
import { Maybe } from '../../graphql/generated/graphql';

type PropsType = {
  id: Maybe<string> | undefined;
  verified: boolean | null | undefined;
  name: Maybe<string> | undefined;
  url: Maybe<string> | undefined;
  loading: boolean;
};

/**
 * LicenseListItem component
 * shows the list item
 *
 * @param {PropsType} props license name, verification status and url to spdx site.
 * @returns {JSX.Element} list item component
 */
const LicenseListItem = (props: PropsType): JSX.Element => {
  const { id, verified, name, url, loading } = props;
  const { licenseId } = useParams();
  const navigate = useNavigate();
  const isActive = licenseId === id;

  if (loading) {
    return (
      <Placeholder>
        <PlaceholderLine />
      </Placeholder>
    );
  }

  return (
    <List.Item
      className={[
        verified ? 'verified' : 'not-verified',
        isActive ? 'active' : '',
      ].join(' ')}
      onClick={() => navigate(`${id}`)}
    >
      <span>
        {name || ''} {verified}
      </span>
      {typeof url === 'string' && (
        <List.Content floated="right">
          <a
            id="SpdxUrlExternalLink"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="spdx url external link"
            onClick={(event) => event.stopPropagation()}
          >
            <Icon id="SpdxUrlExternalIcon" name="external alternate" link />
          </a>
        </List.Content>
      )}
    </List.Item>
  );
};

export default LicenseListItem;
