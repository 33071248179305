import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResultStatus, mapResultNrToString } from '../../helper/logics';

type PropsType = {
  overallResultNr: number | undefined | null;
};

/**
 * element showing the resolver status e.g. OK, CONFLICT, etc.
 *
 * @param {PropsType} props takes in array of the results
 * @returns {JSX.Element} a span with status and colored border
 */
const PVResolverStatusElement = (props: PropsType): JSX.Element => {
  const { overallResultNr } = props;
  const [text] = useTranslation('dashboard');
  const overallResultString = mapResultNrToString(
    overallResultNr || ResultStatus.UNKNOWN
  );
  return (
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
        lineHeight: '2em',
      }}
    >
      {overallResultNr
        ? text(`tableElements.resolverStatusInformation.${overallResultString}`)
        : text(`tableElements.noInfo`)}
    </div>
  );
};

export default PVResolverStatusElement;
