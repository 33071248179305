import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Icon, Modal, TextArea } from 'semantic-ui-react';

import { PRODUCT_DESCRIPTION_CHAR_LIMIT } from '../../../../../helper/constants';
import onTextAreaChange from '../../../../../helper/logics';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
}

/**
 *The Modal is used for UserInfoBox AND for OSO InfoBox "Description" Editing
 *
 *@param {object} props {openDescriptionModal, setOpenDescriptionModal, description, setDescription, editing}
 *@returns {JSX.Element} The Modal is used for UserInfoBox Description Editing
 */
const DescriptionModal = (props: Props): JSX.Element => {
  const [t] = useTranslation(['buttons', 'productDetails']);
  const { open, setOpen } = props;
  const { editing, version, versionInput, setPartialVersionInput } = useContext(
    ProductInfoBoxContext
  );
  const description = versionInput.description || version?.product?.description;

  const [textState, setTextState] = useState(description);
  const [charCount, setCharCount] = useState<number>(description?.length || 0);

  useEffect(() => {
    if (open && editing) {
      setTextState(description);
      setCharCount(description?.length || 0);
    }
  }, [open, editing, description]);

  return (
    <>
      <Modal
        size="tiny"
        id="DescriptionModal"
        open={open && editing}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          {t('descriptionEdit', { ns: 'productDetails' })}
          <Icon
            id="iconClose1"
            name="close"
            link
            floated="right"
            onClick={() => setOpen(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form id="DescriptionModal.TextArea">
              <TextArea
                value={textState || ''}
                rows="7"
                placeholder={t('textAreaPlaceholder', { ns: 'productDetails' })}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  onTextAreaChange(
                    e,
                    setTextState,
                    setCharCount,
                    PRODUCT_DESCRIPTION_CHAR_LIMIT
                  );
                }}
              />
              <span>
                {charCount} / {PRODUCT_DESCRIPTION_CHAR_LIMIT}
              </span>
            </Form>
          </Modal.Description>{' '}
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            id="DescriptionModal.EditButton"
            onClick={() => {
              setPartialVersionInput({ description: textState?.trim() }); // trims empty space around given text
              setOpen(false);
            }}
          >
            {t('update', { ns: 'buttons' })}
          </Button>
        </Modal.Actions>
      </Modal>
      {/* READ-ONLY Show more from the View */}
      <Modal
        size="tiny"
        id="DescriptionModal.ReadOnly"
        className="description-modal-readonly"
        open={open && !editing}
        onClose={() => setOpen(false)}
        closeOnDimmerClick
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          {t('description', { ns: 'productDetails' })}
          <Icon
            id="iconClose2"
            name="close"
            link
            floated="right"
            onClick={() => setOpen(false)}
          />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>{description}</Modal.Description>{' '}
        </Modal.Content>
        <Modal.Actions />
      </Modal>
    </>
  );
};

export default DescriptionModal;
