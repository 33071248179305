import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Message, Button } from 'semantic-ui-react';

type Props = {
  onlyAdmin?: boolean | undefined;
  openMsg: boolean;
  setOpenMsg: (openMsg: boolean) => void;
  resetData: () => void;
  userName: string;
  validNew?: boolean;
};

/**
 *  Success message when User updated or created
 *
 *@param {string} userName Users name being created or updated by Admin
 * @returns {JSX.Element} Success msg for either Admin who is updating or creating a user od for the user updateing his password
 */
const UpdateSuccessMsg = ({
  onlyAdmin,
  userName,
  openMsg,
  setOpenMsg,
  resetData,
  validNew,
}: Props): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);

  return (
    <Modal id="UserDetailsMessageModal" open={openMsg}>
      <Modal.Content>
        {!onlyAdmin && !validNew && (
          <Message
            id="UserDetailsMessageModalSuccessUser"
            success
            icon="check circle"
            header={t('user.update.success.header')}
            content={t('user.login.success')}
          />
        )}
        {onlyAdmin && !validNew && (
          <Message
            id="UserDetailsMessageModalSuccessAdmin"
            success
            icon="check circle"
            header={t('user.update.success', {
              userName,
            })}
            content={t('admin.update.success')}
          />
        )}
        {validNew && (
          <Message
            id="CreateNewUserSuccessMessage"
            success
            icon="check circle"
            header={t('user.create.success', {
              userName,
            })}
            content={t('admin.update.success')}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="UserDetailsMessageModalCloseButton"
          icon="check"
          content={t('close', { ns: 'buttons' })}
          onClick={() => {
            setOpenMsg(false);
            resetData();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UpdateSuccessMsg;
