/* eslint-disable enforce-ids-in-jsx/missing-ids */
import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { ToDo } from '../../graphql/generated/graphql';
import { LicenseFeatureArray } from './helpers/types';
import LicenseTodoListItem from './LicenseTodoListItem';
import { useLicenseContext } from '../../context/LicenseContext';

interface Props {
  items: ToDo[];
  allFeatures: LicenseFeatureArray;
}

/**
 *  Generates nested list of todos and tasks
 *
 *@param {Props} props - takes in Todo items first, Tasks recursively
 *@returns {JSX.Element}  - License Todo List
 */
const LicenseTodoList = (props: Props): JSX.Element => {
  const { items, allFeatures } = props;
  const { isEditMode } = useLicenseContext();

  return (
    <>
      {items.map((item) => {
        const itemIsTask = item.parent !== null;
        const itemIsToDo = !item.parent;
        const itemIsStandaloneToDO =
          itemIsToDo && (!item.tasks || item.tasks.length === 0);

        if (itemIsToDo && item.tasks && item.tasks.length > 0) {
          // render ToDo and only the tasks which are included in the feature list of this license
          const existingTasks = item.tasks;
          const selectedIds = allFeatures.map((i) => i?.id || '');
          const selectedTasks = existingTasks.filter((t) => {
            return selectedIds.findIndex((selected) => selected === t.id) > -1;
          });

          return (
            <Segment key={`list-item${item.id}`} as="li">
              <LicenseTodoListItem todo={item} />
              <List.List as="ul">
                <LicenseTodoList
                  items={isEditMode ? existingTasks : selectedTasks}
                  allFeatures={allFeatures}
                />
              </List.List>
            </Segment>
          );
        }
        if (itemIsStandaloneToDO) {
          // render standalone toDo
          return (
            <Segment key={`list-item${item.id}`} as="li">
              <LicenseTodoListItem
                todo={item}
                isTask={itemIsTask}
                default={itemIsTask ? item.default : undefined}
              />
            </Segment>
          );
        }
        return null;
      })}
    </>
  );
};

export default LicenseTodoList;
