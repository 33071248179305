import { gql } from '@apollo/client';

/**
 * Create comment mutation for a component regarding versionId & referal
 * For : dependency, requirement, todo, version itself...
 */
const CREATE_A_COMMENT = gql`
  mutation CreateCommentForAnyComponent(
    $versionId: ID!
    $replyCommentId: ID
    $refersToId: ID
    $text: String!
  ) {
    CreateComment(
      versionId: $versionId
      replyCommentId: $replyCommentId
      refersToId: $refersToId
      text: $text
    ) {
      id
      text
    }
  }
`;
export default CREATE_A_COMMENT;
