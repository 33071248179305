import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Search,
  SearchProps,
  SearchResultData,
  SearchResultProps,
} from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { Product, Version } from '../../../../../graphql/generated/graphql';
import { EditParts, ProductPartsOption } from '../dependencyList/helper/types';
import { GET_ALL_PRODUCT_PARTS } from '../../../../../graphql/queries/ProductQuerys';

type SearchForProductPartsProps = {
  openSession: EditParts[];
  setOpenSession: React.Dispatch<React.SetStateAction<EditParts[]>>;
  ownerId: string;
};

/**
 *@returns {JSX.Element} Element
 *@param {SearchForProductPartsProps} props all products-for analysis
 */
const SearchForProductParts = ({
  openSession,
  setOpenSession,
  ownerId,
}: SearchForProductPartsProps): JSX.Element => {
  const { t } = useTranslation(['productDetails']);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ProductPartsOption[]>([]);

  const {
    data: productsData,
    loading: allProductsLoading,
    error: allProductsError,
  } = useQuery(GET_ALL_PRODUCT_PARTS);

  const allProductsArr: Array<Product> =
    productsData?.Product.map((product: Product) => product) || [];

  // Setting search options from all products minus the projects & minus versions that are already selected
  const setPartsOptions = () => {
    const options = allProductsArr.flatMap((prod) =>
      prod.versions
        .filter(
          (version: Version) =>
            !openSession.some(
              (sessionPart) => sessionPart.partVersionId === version.id
            )
        )
        .filter((version: Version) => version.id !== ownerId)
        .map((version: Version) => ({
          partId: prod.id,
          partName: prod.name,
          partVersionId: version.id,
          partVersionName: version.name,
        }))
    );
    return options;
  };

  const handleSearchChange = (
    _event: React.MouseEvent<HTMLElement>,
    { value }: SearchProps
  ) => {
    setSearchTerm(value || '');
    // Filter options based on the search term
    const filteredResults = setPartsOptions().filter((option) =>
      option.partName.toLowerCase().includes((value || '').toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleResultSelect = (
    _event: React.MouseEvent<HTMLDivElement>,
    { result }: SearchResultData
  ) => {
    // Save the selected result -productsVersionId to state variable
    setOpenSession([
      ...openSession,
      {
        partId: result.id,
        partName: result.title,
        partVersionId: result.partversionid,
        partVersionName: result.partversionname,
      },
    ]);
    // Clear the search input and results
    setSearchTerm('');
    setSearchResults([]);
  };
  const resultRenderer = ({
    title: partName,
    id: partId,
    partversionname: partVersionName,
  }: SearchResultProps) => (
    <div key={partId}>
      <span>{`${partName} @ ${partVersionName}`}</span>
      <span className="EditProductPartsSearchResults">
        {t('dependencyList.editPartsModal.add')}
      </span>
    </div>
  );

  return (
    <Form id="EditProductPartsModalForm">
      {(allProductsLoading || allProductsError) && (
        <Search
          id="EditProductPartsSearchOnError"
          fluid
          input={{ icon: 'search', iconPosition: 'left' }}
          placeholder={t('dependencyList.editPartsModal.showNoResults')}
        />
      )}
      {productsData && (
        <Search
          id="EditProductPartsSearch"
          fluid
          showNoResults
          input={{ icon: 'search', iconPosition: 'left' }}
          placeholder={t('dependencyList.editPartsModal.searchPlaceholder')}
          value={searchTerm}
          resultRenderer={resultRenderer}
          onSearchChange={handleSearchChange}
          results={searchResults.map((result) => ({
            key: result.partVersionId, // secures unique identifier
            id: result.partId,
            title: result.partName,
            partversionname: result.partVersionName,
            partversionid: result.partVersionId,
          }))}
          onResultSelect={handleResultSelect}
        />
      )}
    </Form>
  );
};

export default SearchForProductParts;
