import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';
import { Maybe } from '../../../../../graphql/generated/graphql';

type PropsType = {
  externalId: Maybe<string> | undefined;
};

/**
 * Copy Identifier To Clipboard
 * small popup that informs the user that they just copied something
 * and selfdestructs after a seconds
 *
 * @param {PropsType} props takes in ...
 * @returns {JSX.Element} Popup
 */
const CopyIdentifierToClipboard = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('productDetails');
  const { externalId } = props;
  const [openCopyPopup, setOpenCopyPopup] = useState(false);

  useEffect(() => {
    if (openCopyPopup) {
      setTimeout(() => {
        setOpenCopyPopup(false);
      }, 1000);
    }
  }, [openCopyPopup]);

  return (
    <Popup
      id={`GroupTakeoverElementIdentifierCopyClipButtonPopup.${externalId}`}
      trigger={
        <Icon
          id="GroupTakeoverElementIdentifierCopyClipButton"
          name="copy outline"
          size="large"
          style={{ margin: '1rem 1rem 1rem 1.5rem', cursor: 'pointer' }}
          onClick={() => navigator.clipboard.writeText(externalId as string)}
        />
      }
      content={text('copyToClipboard')}
      on="click"
      open={openCopyPopup}
      onClose={() => setOpenCopyPopup(false)}
      onOpen={() => setOpenCopyPopup(true)}
      position="top center"
    />
  );
};

export default CopyIdentifierToClipboard;
