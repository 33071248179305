import React from 'react';
import { Placeholder, PlaceholderLine, Table } from 'semantic-ui-react';

type PropsType = {
  rowAmount: number;
  columnAmount: number;
};

/**
 * Table body filled with placeholders
 * takes the amount of rows to be shown and fills those with smokey placeholders
 *
 * @param {PropsType} props so far only contains number of rows and cells to be shown
 * @returns {JSX.Element} LoadingTable
 */
const LoadingTable = (props: PropsType): JSX.Element => {
  const { rowAmount, columnAmount } = props;

  return (
    <>
      {Array.from({ length: rowAmount }, (_v, i: React.Key) => (
        <Table.Row key={i}>
          {Array.from({ length: columnAmount }, (_w, j: React.Key) => (
            <Table.Cell key={j}>
              <Placeholder style={{ height: '2em' }}>
                <PlaceholderLine />
              </Placeholder>
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </>
  );
};

export default LoadingTable;
