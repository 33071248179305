import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import CalendarForm from '../../../../ui/CalendarForm';
import TargetDateMessage from './TargetDateMessage';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';

/**
 * @returns {JSX.Element}  Target Date Component for Info Box
 */
const TargetDateItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');

  const { version, versionInput, editing, setPartialVersionInput } = useContext(
    ProductInfoBoxContext
  );
  const targetDate = versionInput.targetDate || version?.targetDate;

  return (
    <Item id="TargetDate" className="deadline">
      <Item.Content>
        <Item.Meta content={t('targetDate')} />
        {!editing ? (
          <Item.Header>
            {targetDate
              ? new Date(targetDate).toLocaleDateString(undefined, {
                  timeZone: 'UTC',
                })
              : ''}
            <TargetDateMessage targetDate={targetDate} />
          </Item.Header>
        ) : (
          <CalendarForm
            targetDate={targetDate}
            setTargetDate={(date: string) =>
              setPartialVersionInput({ targetDate: date })
            }
            format="short"
          />
        )}
      </Item.Content>
    </Item>
  );
};

export default TargetDateItem;
