import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';
import TermsOfUseModal from '../components/imprintAndLegal/TermsOfUseModal';
import LoginButton from '../components/user/LoginButton';
import PublicDownloadElement from '../components/downloadLinkElements/PublicDownloadElement';

type LoginProps = {
  systemOffline: boolean;
};

/**
 * Login
 * Page with Form Input for User email and password
 *
 * @param {LoginProps} props LoginProperties
 * @returns {JSX.Element} Login Page
 */
const Login = ({ systemOffline }: LoginProps): JSX.Element => {
  const [t] = useTranslation('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const client = useApolloClient();
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (systemOffline) {
      intervalIdRef.current = setInterval(() => {
        client.refetchQueries({
          include: ['CurrentUser'],
        });
      }, 5000);
    } else if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    // Cleanup interval on component unmount
    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    };
  }, [systemOffline, client]);

  return (
    <Grid id="LoginPage" textAlign="center" verticalAlign="middle">
      <Grid.Column>
        <Header as="h2" color="blue" textAlign="center">
          {t('header')}
        </Header>
        <Form id="LoginForm" size="large" disabled={systemOffline}>
          <Segment id="LoginForm.Segment">
            <Form.Input
              id="LoginForm.Segment.EmailInput"
              fluid
              icon="user"
              iconPosition="left"
              placeholder={t('email')}
              value={email}
              disabled={systemOffline}
              data-cy="LoginForm.Segment.EmailInput"
              onChange={(evt, { value }) => {
                setEmail(value);
              }}
            />
            <Form.Input
              id="LoginForm.Segment.PasswordInput"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={t('password')}
              type="password"
              value={password}
              disabled={systemOffline}
              data-cy="LoginForm.Segment.PasswordInput"
              onChange={(evt, { value }) => setPassword(value)}
            />
            <TermsOfUseModal
              email={email}
              password={password}
              openTermsModal={openTermsModal}
              setOpenTermsModal={setOpenTermsModal}
            />
            <LoginButton
              email={email}
              password={password}
              systemOffline={systemOffline}
              setOpenTermsModal={setOpenTermsModal}
            />
          </Segment>
        </Form>
        {systemOffline ? (
          <Message
            visible
            error
            icon="times circle"
            id="LoginButtonErrorMessageSystemOffline"
            content={
              <>
                {t('systemOffline')}
                <a
                  id="SystemOfflineError.ServiceDeskLink"
                  href="https://devstack.vwgroup.com/jira/servicedesk/customer/portal/174"
                >
                  {t('serviceDesk')}
                </a>
              </>
            }
          />
        ) : (
          <>
            <Message
              id="LoginPageNoAccountInfo"
              info
              icon="info circle"
              content={
                <>
                  {t('noAccount')}
                  <Button id="SignUpButton" as={Link} to="/signup">
                    {t('signUp')}
                  </Button>
                </>
              }
            />
            <Message
              id="CommunityInfo"
              info
              icon="info circle"
              content={
                <>
                  {t('communityInfo1')}
                  <PublicDownloadElement
                    filename="AOSD-Nutzerforum.ics"
                    elementName="Mittwoch, der 02.04.25, von 15.00 – 16.00 Uhr."
                  />
                  {t('communityInfo2')}
                  <a
                    id="VivaEngageLink"
                    href="https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fengage.cloud.microsoft%2Fmain%2Fgroups%2FeyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI1NjU0NTA0MjQzMyJ9%2Fall&data=05%7C02%7Cchristoph.eibl%40digitalwerk.net%7C42ba75512e1a47d466e008dd67fa2516%7C1daa929d026446b8b2e52e8116be53da%7C0%7C0%7C638781046257694329%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&sdata=%2FWqkBgtx%2BZE2ZbkFwLl49a3MEPZ2i2PseTFPyYYjz%2BE%3D&reserved=0"
                  >
                    {t('vivaEngage')}
                  </a>
                  {t('endInfo')}
                </>
              }
            />
          </>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Login;
