import {
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  RefObject,
} from 'react';
import useDebounce from './useDebounce';

interface Size {
  width: number;
  height: number;
}

/**
 * Custom hook to measure the inner size of a HTML element
 * (inner size = exluding borders, paddings, scrollbars..)
 *
 * @template T The type of HTML element to be measured, defaults to HTMLDivElement
 * @returns {[RefObject<T>, Size]} A function to bind the ref and the size state.
 */
const useElementInnerSize = <T extends HTMLElement = HTMLDivElement>(): [
  RefObject<T>,
  Size
] => {
  const ref = useRef<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const updateSize = useCallback(() => {
    if (
      ref.current &&
      (size.width !== ref.current.clientWidth ||
        size.height !== ref.current.clientHeight)
    ) {
      setSize({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedUpdateSize = useDebounce(updateSize, 500);

  useLayoutEffect(() => {
    const currentElement = ref.current;
    debouncedUpdateSize();
    const resizeObserver = new ResizeObserver(() => debouncedUpdateSize());
    if (currentElement) {
      resizeObserver.observe(currentElement);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [debouncedUpdateSize]);

  return [ref, size];
};

export default useElementInnerSize;
