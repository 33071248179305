import { useState, Dispatch, SetStateAction } from 'react';

/**
 * Custom hook to handle state with partial updates
 *
 * @template T - The type of the state
 * @param {T} initialState - The initial state
 * @returns {[T, Dispatch<SetStateAction<Partial<T>>>]} - The state and update function
 */
const usePartialState = <T>(initialState: T) => {
  const [state, setState] = useState<T>(initialState);

  const setPartialState: Dispatch<SetStateAction<Partial<T>>> = (
    partialState
  ) => {
    setState((prevState) => ({
      ...prevState,
      ...partialState,
    }));
  };

  return [state, setState, setPartialState] as const;
};

export default usePartialState;
