import i18n from '../../../../../../i18n/i18n';

type DateFormat = 'full' | 'short';

/**
 * Format an ISO date for display in a localized date format.
 *
 * @param {string} isoDate - The ISO date string to be formatted.
 * @param {string} format - either full or short, default = full
 * @returns {string} The formatted date string in the localized format.
 */
const formatDateForView = (
  isoDate: string,
  format: DateFormat = 'full'
): string => {
  if (!isoDate) {
    return '';
  }

  const newDate = new Date(isoDate);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };

  if (format === 'full') {
    return newDate.toLocaleDateString(
      i18n.language === 'en' ? 'en-US' : 'de-DE',
      options
    );
  }
  return newDate.toLocaleDateString(
    i18n.language === 'en' ? 'en-US' : 'de-DE',
    {
      timeZone: 'UTC',
    }
  );
};

export default formatDateForView;
