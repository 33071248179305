enum MenuTab {
  ProductLicense = 'ProductLicense',
  LicenseDiff = 'LicenseDiff',
  LicenseFeatures = 'LicenseFeatures',
  GeneralDetails = 'GeneralDetails',
}

export default MenuTab;

/**
 *
 */
export type FeatureArray = ({
  id: string;
  name_de: string;
  name_en: string;
} | null)[];
