import React, { useContext } from 'react';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import UserRolesMenu from './UserRolesMenu';

import { SelectedRoleContext } from '../../context/UserContext';

/**
 * UserRolesPopup
 * at the top of the {@link UserMenu} with the current user role as trigger
 * it provides the {@link UserRolesMenu} on mouseover.
 *
 * @returns {JSX.Element} UserRolesPopup
 */
const UserRolesPopup = (): JSX.Element => {
  const { selectedRole } = useContext(SelectedRoleContext);
  const [t] = useTranslation('users');

  return (
    <Popup
      id="UserRolesPopup"
      offset={({ popper }) => [(38 * popper.height) / 100, 10]}
      inverted
      on="hover"
      hoverable
      position="right center"
      trigger={
        <Menu.Item id="UserPopup.MenuItem">
          {t(`role.${selectedRole}`)}
          <Icon id="UserRolesPopup.Icon" name="caret right" />
        </Menu.Item>
      }
      content={<UserRolesMenu />}
    />
  );
};
export default UserRolesPopup;
