/**
 * @param {string} dateInput data from DB comes as a ISO date object
 * @param {string} language for now only "english" and "deutsch"
 *@returns {string} Reusable ISO Date to localString converter
 */
export const convertISOtoLocalDate = (
  dateInput: string,
  language: string
): string => {
  const converted = new Date(dateInput);

  if (language === 'en') {
    return converted.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }
  if (language === 'de') {
    return converted.toLocaleDateString('de-DE', {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }
  return converted.toLocaleDateString();
};

/**
 * @param {string} timeInput data from DB comes as a ISO date object
 * @param {string} language for now only "english" and "deutsch"
 *@returns {string} Reusable ISO Date to localString converter
 */
export const toLocalDateAndTime = (
  timeInput: string,
  language: string
): string => {
  // New Date object
  const inputedDate = new Date(timeInput);

  // Extracting hours and minutes, ensuring only two digits
  const hours = `${inputedDate.getHours()}`.padStart(2, '0');
  const minutes = `${inputedDate.getMinutes()}`.padStart(2, '0');

  // Determine the locale based on the provided language for date conversion
  const locale = language === 'en' ? 'en-US' : 'de-DE';

  const date = inputedDate.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return `${date}, ${hours}:${minutes}`;
};
