/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useQuery } from '@apollo/client';
import { Placeholder } from 'semantic-ui-react';
import {
  Dependency,
  Maybe,
} from '../../../../../../../../../graphql/generated/graphql';
import DependencyTreeNode from './DependencyTreeNode';
import { DEPENDENCY_CHILDREN } from '../../../../../../../../../graphql/queries/DependencyQuerys';

interface DependencyTreeBranchProps {
  deps: Dependency[];
  sourceDepId?: Maybe<string>;
  targetDepId?: Maybe<string>;
  currentPathLength: number | 0;
  setDependencyCount: (value: number | ((prevValue: number) => number)) => void;
  onComponentClick?: (name: string) => void;
  isRoot?: boolean;
  currentDep: Dependency;
  nextDep: Dependency | null;
  nthElement: number;
}

/**
 * `DependencyTreeBranch` renders a single branch of a dependency tree.
 *  It displays information about a dependency and recursively renders its children.
 *
 * @param {DependencyTreeBranchProps} props - Props for the component.
 * @param {Dependency} props.dep - Dependency object containing information like id, componentName, componentVersion, etc.
 * @param {string} props.sourceDepId - The ID of the source dependency to be highlighted.
 * @param {string} props.targetDepId - The ID of the target dependency to be highlighted.
 * @param {Function} props.onComponentClick - Callback function that will be invoked when a component is clicked.
 * @param {boolean} props.isRoot - Flag to indicate if this dependency is the root dependency.
 * @returns {JSX.Element} Returns a JSX element that represents a single branch of a dependency tree, including its children.
 */
const DependencyTreeBranch = (
  props: DependencyTreeBranchProps
): JSX.Element => {
  const {
    deps,
    sourceDepId,
    targetDepId,
    setDependencyCount,
    onComponentClick,
    isRoot,
    currentDep,
    nextDep,
    nthElement,
    currentPathLength,
  } = props;

  const { data, error, loading } = useQuery(DEPENDENCY_CHILDREN, {
    variables: { dependencyId: currentDep.id },
    fetchPolicy: 'cache-first',
    skip: !!nextDep, // skip if nextDep is not null
    onCompleted: (res) => {
      setDependencyCount(
        currentPathLength + (res.DependencyChildren?.length ?? 0)
      );
    },
  });

  const hasChildren = nextDep || (data && data.DependencyChildren?.length > 0);

  const getClassNames = (
    id: string | undefined,
    root: boolean | undefined
  ): string => {
    const classes = [];
    if (root) classes.push('root-dep');
    if (sourceDepId === id) classes.push('source-dep');
    if (targetDepId === id) classes.push('target-dep');
    if (currentDep && currentDep.overallResult.status) {
      classes.push(currentDep.overallResult.status);
    }
    return classes.join(' ');
  };

  const cssClass = getClassNames(currentDep.id, isRoot);

  if (loading)
    return (
      <Placeholder className="tree-placeholder">
        <Placeholder.Line />
      </Placeholder>
    );
  if (error) return <div>{error.message}</div>;

  return (
    <>
      <DependencyTreeNode
        id={currentDep.id}
        componentName={currentDep.componentName}
        componentVersion={currentDep.componentVersion}
        license={currentDep.licenses?.[0]?.spdxId}
        cssClass={cssClass}
        onComponentClick={onComponentClick}
      >
        {hasChildren && (
          <ul>
            {nextDep ? (
              <DependencyTreeBranch
                deps={deps}
                currentDep={nextDep}
                nextDep={
                  deps.length > nthElement + 2 ? deps[nthElement + 2] : null
                }
                currentPathLength={currentPathLength}
                setDependencyCount={setDependencyCount}
                sourceDepId={sourceDepId}
                targetDepId={targetDepId}
                onComponentClick={onComponentClick}
                nthElement={nthElement + 1}
              />
            ) : (
              data &&
              data.DependencyChildren?.length > 0 && (
                <>
                  {data.DependencyChildren.map((child: Dependency) => {
                    return (
                      <DependencyTreeNode
                        key={child.id}
                        id={child.id}
                        componentName={child.componentName}
                        componentVersion={child.componentVersion}
                        license={child.licenses?.[0]?.spdxId}
                        cssClass={getClassNames(child.id, false)}
                        onComponentClick={onComponentClick}
                      />
                    );
                  })}
                </>
              )
            )}
          </ul>
        )}
      </DependencyTreeNode>
    </>
  );
};

export default DependencyTreeBranch;
