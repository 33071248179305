import { Product, Version } from '../../../../../graphql/generated/graphql';
import { Columns } from '../ProductListTypes';

/**
 * @param {string} targetDateString unix time stamp of release date
 * @returns {number} time difference
 */
export const timeDiffCalc = (targetDateString: string): number => {
  const currentDate = new Date();
  const targetDate = new Date(targetDateString);
  const differenceSeconds = targetDate.getTime() - currentDate.getTime();
  return Math.ceil(differenceSeconds / (1000 * 3600 * 24));
};

/**
 * @param {Product} currentProduct
 * this products comments will be counted
 * @returns {number}
 * number of counted comments
 */
export const commentCountForProduct = (currentProduct: Product): number => {
  let commentCount = 0;
  for (let i = 0; i < currentProduct.versions.length; i += 1) {
    commentCount += currentProduct.versions[i].comments.length;
  }
  return commentCount;
};

/**
 * @param {{ openToDos: number; totalToDos: number }} currentToDos takes in a start value
 * @param {number} currentToDos.openToDos part of given starting value
 * @param {number} currentToDos.totalToDos part of given starting value
 * @param {Product} product takes in the current product to take its versions' dependencies
 * @returns {{ openToDos: number; totalToDos: number }} object containing sum of open and total todos
 */
export const toDosCount = (
  currentToDos: { openToDos: number; totalToDos: number },
  product: Product
): {
  openToDos: number;
  totalToDos: number;
} => {
  const toDoArray = product.versions.map((ver: Version) => {
    const newTotalTodos: number =
      (ver.numberOfDirectDependencies || 0) +
      (ver.numberOfTransitiveDependencies || 0);
    return {
      openToDos: ver.numberOfDependenciesToProof,
      totalToDos: newTotalTodos,
    };
  });
  const newToDos = currentToDos;
  toDoArray.forEach((toDoObj) => {
    newToDos.openToDos += toDoObj.openToDos || 0;
    newToDos.totalToDos += toDoObj.totalToDos || 0;
  });
  return newToDos;
};

/**
 * @param {Product} product takes in the current product to take its versions' dependencies
 * @returns {number} number of direct dependencies
 * @description calculates the number of direct dependencies for a given product
 */
export const directProductDependencies = (product: Product): number => {
  let directDependencies = 0;
  product.versions.forEach((version) => {
    directDependencies += version.numberOfDirectDependencies || 0;
  });
  return directDependencies;
};

/**
 * @param {Product} product takes in the current product to take its versions' dependencies
 * @returns {number} number of transitive dependencies
 * @description calculates the number of transitive dependencies for a given product
 */
export const transitivePrdoductDependencies = (product: Product): number => {
  let transitiveDependencies = 0;
  product.versions.forEach((version) => {
    transitiveDependencies += version.numberOfTransitiveDependencies || 0;
  });
  return transitiveDependencies;
};

/**
 * Merges two arrays of columns, preserving 'active','shown' and 'filterInfo' from localStorage.
 *
 * @param {Columns} localStorageColumns - The array of columns retrieved from localStorage.
 * @param {Columns} roleSpecificColumns - The array of role-specific columns.
 * @returns {Columns} A new array of columns that is a merge of the two input arrays, preserving specific properties from localStorage.
 */
export const mergeColumns = (
  localStorageColumns: Columns,
  roleSpecificColumns: Columns
): Columns => {
  const localStorageMap = new Map(
    localStorageColumns.map((col) => [col.name, col])
  );

  return roleSpecificColumns.map((roleCol) => {
    const localCol = localStorageMap.get(roleCol.name);
    if (localCol) {
      return {
        ...roleCol,
        active: localCol.active,
        shown: localCol.shown,
        filterInfo: localCol.filterInfo,
      };
    }
    return roleCol;
  });
};
