import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { SelectedRoleContext } from '../../../../../context/UserContext';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import { DistributionIds } from '../../../../wizard/WizardConstants';
import { SELECTED_DISTRIBUTION } from '../../../../../graphql/queries/VersionQuerys';
import {
  DirectoryRole,
  SelectedDistributionQuery,
  SelectedDistributionQueryVariables,
} from '../../../../../graphql/generated/graphql';

/**
 * Custom hook checks whether the currently selected distribution is binary and user is supplier
 * with proper authorization.
 *
 * @returns {boolean} is or isnt authorized to upload LC
 */
const useAuthorizeLC = (): boolean => {
  const { versionId, authorizations, processStatusAsString, accessRoles } =
    useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);

  const { data, loading, error } = useQuery<
    SelectedDistributionQuery,
    SelectedDistributionQueryVariables
  >(SELECTED_DISTRIBUTION, {
    variables: { id: versionId },
    fetchPolicy: 'network-only',
  });

  const distId = data?.Version?.product?.selectedDistribution?.id;

  const binaryDistributionIds = [
    DistributionIds.DISTRIBUTIONBINARY,
    DistributionIds.DISTRIBUTIONBINARYEXTERNAL,
    DistributionIds.DISTRIBUTIONBINARYINTERNAL,
  ];

  const isBinaryDistribution = binaryDistributionIds.includes(distId ?? '');
  const hasBasicPermission = authorizations.includes(
    'PersistFileToArtifactory'
  );
  const isSupplier = selectedRole?.includes('supplier') && hasBasicPermission;

  const isManager =
    accessRoles.includes(DirectoryRole.manager) &&
    (processStatusAsString === 'INPROGRESS' ||
      processStatusAsString === 'REWORK') &&
    hasBasicPermission;

  const isOso = accessRoles.includes(DirectoryRole.oso) && hasBasicPermission;

  const isAuthorized =
    isBinaryDistribution &&
    (isSupplier || isManager || isOso) &&
    !loading &&
    !error;

  return isAuthorized;
};

export default useAuthorizeLC;
