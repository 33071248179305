import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import { CreateProductPart } from '../WizardConstants';

/**
 *@returns {JSX.Element} Takes over from previos Part
 */
const WizardTeamTakeOverButton = (): JSX.Element => {
  const { t } = useTranslation('buttons');
  const { setWizardInput, wizardInput, currentPart, identicalTeam } =
    useContext(WizardContext);

  const wizProduct = wizardInput.productInput;
  const wizPart = wizardInput.partInput;

  const takeOverMembers = () => {
    if (identicalTeam === false) {
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: [
          ...wizPart.slice(0, currentPart - 1),
          {
            ...wizPart[currentPart - 1],
            manager: wizPart[currentPart - 2].manager,
            developer: wizPart[currentPart - 2].developer,
            supplier: wizPart[currentPart - 2].supplier,
            guest: wizPart[currentPart - 2].guest,
          },
          ...wizPart.slice(currentPart),
        ],
      });
    } else
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: wizPart.map((part: CreateProductPart) => {
          return {
            ...part,
            manager: wizPart[currentPart - 2].manager,
            developer: wizPart[currentPart - 2].developer,
            supplier: wizPart[currentPart - 2].supplier,
            guest: wizPart[currentPart - 2].guest,
          };
        }),
      });
  };

  return (
    <>
      {/*  Button is disabled if on the first part(no previous) and if all parts identical  */}
      <Button
        id="WizardTeamTakeOverButton"
        primary
        disabled={identicalTeam || currentPart === 1}
        basic
        style={{
          marginTop: 5,
        }}
        onClick={() => takeOverMembers()}
      >
        {t('takeOverMembers')}
      </Button>
    </>
  );
};

export default WizardTeamTakeOverButton;
