import React, {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownProps,
  Form,
  Grid,
  Input,
  Item,
  Select,
  TextArea,
} from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import {
  AllProductNamesQuery,
  CreateProductInput,
  Maybe,
  useAllProductNamesQuery,
} from '../../../graphql/generated/graphql';
import {
  OptionTypes,
  SelectionTypes,
  SoftwareSelect,
  CheckIfNameExists,
  SelectProductStructure,
  ProductStructureType,
} from '../WizardConstants';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
// import WizardDateInputPicker from './WizardDateInputPicker';
import WizardProductTransferInput from './WizardProductTransferInput';
import CalendarForm from '../../ui/CalendarForm';
import { PRODUCT_DESCRIPTION_CHAR_LIMIT } from '../../../helper/constants';
import onTextAreaChange from '../../../helper/logics';

/**
 *  Wizard Product creation -Step Two
 *
 *@returns {boolean} Wizard step 2 of Product creation where the Product Details are filled in the PartsInput is either enabled or not
 */
const WizardProductDetailsInput = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const {
    setWizardInput,
    wizardInput,
    setProductStructure,
    productStructure,
    transPass,
    setTransPass,
    setStepComplete,
  } = useContext(WizardContext);
  const wizProduct = wizardInput.productInput;
  const [productNames, setProductNames] = useState<string[]>([]);
  const [descriptionText, setTextState] = useState<Maybe<string> | undefined>(
    wizardInput.productInput.description || ''
  );
  const [charCount, setCharCount] = useState<number>(
    descriptionText?.length || 0
  );

  useAllProductNamesQuery({
    onCompleted(data: AllProductNamesQuery) {
      if (data.Product) {
        const names = data.Product.map(
          (product) => product?.name.trim().toLowerCase() || ''
        );
        setProductNames(names);
      }
    },
  });

  const UpdateWizardInput = (
    input: string | string[] | boolean | Date,
    detail: string
  ) => {
    setWizardInput({
      ...wizardInput,
      productInput: {
        ...wizProduct,
        [detail]: input,
      } as CreateProductInput,
    });
  };

  const handleTagChange = (
    _e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    UpdateWizardInput(value as string[], 'tags');
  };

  const handleDescriptionText = (
    _e: ChangeEvent<HTMLTextAreaElement>,
    { name }: OptionTypes
  ) => {
    const description = onTextAreaChange(
      _e,
      setTextState,
      setCharCount,
      PRODUCT_DESCRIPTION_CHAR_LIMIT
    );
    UpdateWizardInput(description, name);
  };

  const tagOptions =
    wizardInput.productInput.tags?.map((tag, i) => ({
      key: tag + i,
      text: tag,
      value: tag,
    })) || [];

  const transPassValues =
    transPass === 'pass'
      ? wizProduct.transferExternalId !== null &&
        wizProduct.transferExternalId !== undefined &&
        wizProduct.transferExternalId.trim().length >= 2 &&
        wizProduct.transferGroupCompany !== null &&
        wizProduct.transferGroupCompany !== undefined &&
        wizProduct.transferGroupCompany.trim().length >= 2 &&
        wizProduct.transferContactPerson !== null &&
        wizProduct.transferContactPerson !== undefined &&
        wizProduct.transferContactPerson.trim().length >= 2
      : true;
  const wizardInputValues =
    CheckIfNameExists(wizProduct.name?.trim(), productNames)[1] &&
    wizProduct.name !== undefined &&
    wizProduct.name.trim().length > 2 &&
    wizProduct.versionName !== undefined &&
    wizProduct.targetDate !== undefined &&
    wizProduct.versionName.trim().length > 2 &&
    wizProduct.standardSoftware !== undefined &&
    productStructure !== undefined &&
    transPass.length > 1 &&
    transPassValues;

  useEffect(() => {
    if (wizardInputValues) {
      setStepComplete(true);
    } else setStepComplete(false);
  }, [setStepComplete, wizardInput, wizardInputValues, wizProduct.name]);

  useEffect(() => {
    // reset group-transfer data when empty project is chosen (1 = empty project)
    if (productStructure === 1) {
      setTransPass('noPass');
      delete wizProduct.transferExternalId;
      delete wizProduct.transferGroupCompany;
      delete wizProduct.transferContactPerson;
    }
  }, [productStructure, setTransPass, wizProduct]);

  return (
    <Grid columns={2} divided padded="horizontally">
      <Grid.Row>
        {/* --------------------LEFT COLUMN--------------------- */}
        <Grid.Column>
          {' '}
          <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
            <Item id="WizardProductDetailsInput.General">
              <Item.Content>
                <Item.Header>{t('productDetails.details')}</Item.Header>
                <Item.Extra
                  style={{
                    fontSize: '18px',
                    display: 'inline',
                  }}
                >
                  {t('productDetails.general')}
                </Item.Extra>
                <Item.Description>
                  {/* FORM Input fields for GENERAL-Left side */}
                  <Form
                    id="WizardProductDetailsInput.GeneralForm"
                    style={{ width: '65%', marginTop: '40px' }}
                  >
                    <WizardFormFieldLabel
                      nameText={t('productDetails.name')}
                      infoText={t('productDetails.name-info')}
                      htmlFor="name"
                    />
                    <Form.Field
                      id="name"
                      name="name"
                      control={Input}
                      value={wizProduct?.name || ''}
                      placeholder={t('productDetails.name')}
                      onChange={(
                        _e: ChangeEvent,
                        { value, name }: OptionTypes
                      ) => {
                        UpdateWizardInput(value, name);
                      }}
                      error={
                        wizProduct !== undefined &&
                        CheckIfNameExists(
                          wizProduct.name?.trim(),
                          productNames
                        )[0]
                      }
                    />
                    <WizardFormFieldLabel
                      nameText={t('productDetails.version')}
                      infoText={t('productDetails.version-info')}
                      htmlFor="versionName"
                    />
                    <Form.Field
                      id="versionName"
                      name="versionName"
                      control={Input}
                      value={wizProduct?.versionName || ''}
                      placeholder={t('productDetails.version')}
                      onChange={(
                        _e: ChangeEvent,
                        { value, name }: OptionTypes
                      ) => {
                        UpdateWizardInput(value, name);
                      }}
                      error={
                        wizProduct?.versionName?.trim().length < 3
                          ? t('productDetails.errorProductVersion')
                          : null
                      }
                    />
                    {/* TAGS ARE ALSO OPTIONAL */}
                    <WizardFormFieldLabel
                      nameText={t('productDetails.tags')}
                      infoText={t('productDetails.tags-info')}
                      htmlFor="tags"
                      optional
                    />
                    <Form.Dropdown
                      options={tagOptions}
                      placeholder={t('productDetails.tags-placeholder')}
                      additionLabel={t('productDetails.tags-add')}
                      search
                      selection
                      fluid
                      multiple
                      closeOnChange
                      allowAdditions
                      value={wizardInput.productInput.tags || []}
                      onChange={handleTagChange}
                    />
                    {/* DEADLINE- Calendar element */}
                    <WizardFormFieldLabel
                      nameText={t('productDetails.deadline')}
                      infoText={t('productDetails.deadline-info')}
                      htmlFor="targetDate"
                    />
                    {/* REPLACED OLD VERSION CALLED WizardDateInputPicker */}
                    <CalendarForm
                      targetDate={wizProduct.targetDate}
                      setTargetDate={(newDate) => {
                        if (typeof newDate === 'string') {
                          UpdateWizardInput(newDate, 'targetDate');
                        }
                      }}
                      placeholder={t('productDetails.deadlinePlaceholder')}
                      format="full"
                    />
                    {/* DESCRIPTION is Optional */}
                    <Form.Field
                      className="WizardProductDetailsInputDescription"
                      name="description"
                      control={TextArea}
                      label={t('description')}
                      value={descriptionText}
                      placeholder={t(
                        'productDetails.descriptionTextPlaceholder'
                      )}
                      onChange={handleDescriptionText}
                    />
                    <span>
                      {charCount} / {PRODUCT_DESCRIPTION_CHAR_LIMIT}
                    </span>
                  </Form>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
        {/* --------------------RIGHT COLUMN--------------------- */}
        <Grid.Column>
          <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
            <Item id="WizardProductDetailsInput.Special">
              <Item.Content>
                <Item.Header>{t('productDetails.details')}</Item.Header>
                <Item.Extra
                  style={{
                    fontSize: '18px',
                    display: 'inline',
                  }}
                >
                  {t('productDetails.special')}
                </Item.Extra>
                <Item.Description>
                  {/* FORM Input fields for SPECIAL-Right side with optional expansion */}
                  <Form
                    id="WizardProductDetailsInput.SpecialForm"
                    style={{ width: '65%', marginTop: '40px' }}
                  >
                    <WizardFormFieldLabel
                      nameText={t('productDetails.parts')}
                      infoText={t('productDetails.parts-info')}
                      htmlFor="partsSelect"
                    />
                    <Form.Field
                      id="partsSelect"
                      control={Select}
                      options={SelectProductStructure()}
                      name="partsSelect"
                      value={productStructure}
                      placeholder={t('pleaseSelect')}
                      onChange={(
                        _e: ChangeEvent,
                        { value }: ProductStructureType
                      ) => {
                        setProductStructure(value);
                      }}
                    />
                    <WizardFormFieldLabel
                      nameText={t('productDetails.softwareType')}
                      infoText={t('productDetails.softwareType-info')}
                      htmlFor="standardSoftware"
                    />
                    <Form.Field
                      id="standardSoftware"
                      control={Select}
                      options={SoftwareSelect()}
                      name="standardSoftware"
                      value={wizProduct.standardSoftware}
                      placeholder={t('pleaseSelect')}
                      onChange={(
                        _e: ChangeEvent,
                        { value, name }: SelectionTypes
                      ) => {
                        UpdateWizardInput(value, name);
                      }}
                    />
                    {/* WIZARD TRANSFER / PASS OPTIONAL INPUT FIELDS */}
                    {productStructure !== 1 && <WizardProductTransferInput />}
                  </Form>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WizardProductDetailsInput;
