import { gql } from '@apollo/client';

/**
 * get single license by exact id
 */
export const GET_LICENSE_BY_ID = gql`
  query LicenseById($licenseId: ID!) {
    License(where: { id: $licenseId }) {
      id
      name
      spdxId
      dejacode
      exceptionLicense
      category
      sourceUrl
      description
      note
      permissionNote
      text
      verified
      deprecated
      wordCount
      createdAt
      createdById
      lastModifiedAt
      lastModifiedById
    }
  }
`;

/**
 * get all licenses that match the search string in spdxId or name - paginated!
 */
export const LICENSE_LIST = gql`
  query LicenseList(
    $where: LicenseWhere
    $options: LicenseOptions
    $shouldFetchAggregation: Boolean = true
  ) {
    LicenseList(where: $where, options: $options, shouldFetchAggregation: $shouldFetchAggregation) {
      licenses {
        id
        name
        spdxId
        sourceUrl
        verified
      }
      totalAmount @include(if: $shouldFetchAggregation)
      verifiedCount @include(if: $shouldFetchAggregation)
      unverifiedCount @include(if: $shouldFetchAggregation)
    }
  }
`;

/**
 * returns if the difference between 2 given license texts is within allowed bounds
 * see AEVAOSD-966 or C:\AOSD\aosd\backend\src\backend\diff\LicenseTextDiff.ts for details on the rules
 */
export const LICENSE_TEXT_DIFF = gql`
  query LicenseTextDiff(
    $spdxId: String!
    $inputText: String!
    $persistedText: String!
  ) {
    LicenseTextDiff(
      spdxId: $spdxId
      inputText: $inputText
      persistedText: $persistedText
    ) {
      diffStatements {
        matches {
          persisted {
            match
          }
          input {
            match
          }
        }
        withinAllowedLength
      }
      equal
    }
  }
`;

/**
 * returns if the difference between 2 given license texts is within allowed bounds - readable for frontend
 */
export const LICENSE_TEXT_DIFF_VISUAL = gql`
  query LicenseTextDiffVisual(
    $spdxId: String!
    $inputText: String!
    $persistedText: String!
  ) {
    LicenseTextDiffVisual(
      spdxId: $spdxId
      inputText: $inputText
      persistedText: $persistedText
    ) {
      colorInt
      text
    }
  }
`;

/**
 *
 */
export const LICENSE_FEATURES_BY_ID = gql`
  query LicenseFeaturesByID($licenseId: ID!) {
    License(where: { id: $licenseId }) {
      id
      name
      spdxId
      toDos {
          id
          name_de
          name_en
          internal
          category
          tasks {
            id
            internal
            default
            name_de
            name_en
          }
          parent {
            id
            internal
            default
            name_de
            name_en
          }
          conditions {
            __typename
            ... on Distribution {
              id
              name_de
              name_en
            }
            ... on Linking {
              id
              name_de
              name_en
            }
            ... on Modification {
              id
              name_de
              name_en
            }
            ... on Tivo {
              id
              name_de
              name_en
            }
          }
          conditions_OR {
            __typename
            ... on Distribution {
              id
              name_de
              name_en
            }
            ... on Linking {
              id
              name_de
              name_en
            }
            ... on Modification {
              id
              name_de
              name_en
            }
            ... on Tivo {
              id
              name_de
              name_en
            }
          }
        }
      licenseFeatures {
          id
          name_de
          name_en
        }
      conflictRules {
        id
        active
        name_de
        name_en
          conditions {
            __typename
            ... on Distribution {
              id
              name_de
              name_en
            }
            ... on Linking {
              id
              name_de
              name_en
            }
            ... on Modification {
              id
              name_de
              name_en
            }
            ... on Tivo {
              id
              name_de
              name_en
            }
          }
          conditions_OR {
            __typename
            ... on Distribution {
              id
              name_de
              name_en
            }
            ... on Linking {
              id
              name_de
              name_en
            }
            ... on Modification {
              id
              name_de
              name_en
            }
            ... on Tivo {
              id
              name_de
              name_en
            }
          }
          requirements {
            __typename
            ... on Requirement {
              id
              name_de
              name_en
            }
            ... on ToDo {
              id
              name_de
              name_en
            }
          }
          requirements_OR {
            __typename
            ... on Requirement {
              id
              name_de
              name_en
            }
            ... on ToDo {
              id
              name_de
              name_en
            }
          }
      }
      licenseConflictRules {
          id
          name_de
          name_en
        }
      additionalLicenseFeatures {
          id
          name_de
          name_en
        } 
    }
  }
`;


/**
 * Get Todos of specific License
 */
export const LICENSE_TODOS_BY_ID = gql`
  query LicenseTodosByID($licenseId: ID!) {
    License(where: { id: $licenseId }) {
      id
      toDos {
          id
          name_de
          name_en
          internal
          category
          tasks {
            id
            internal
            default
            name_de
            name_en
          }
          parent {
            id
            internal
            default
            name_de
            name_en
          }
          conditions {
            __typename
            ... on Distribution {
              id
              name_de
              name_en
            }
            ... on Linking {
              id
              name_de
              name_en
            }
            ... on Modification {
              id
              name_de
              name_en
            }
            ... on Tivo {
              id
              name_de
              name_en
            }
          }
          conditions_OR {
            __typename
            ... on Distribution {
              id
              name_de
              name_en
            }
            ... on Linking {
              id
              name_de
              name_en
            }
            ... on Modification {
              id
              name_de
              name_en
            }
            ... on Tivo {
              id
              name_de
              name_en
            }
          }
        }
    }
  }
`;

/**
 *
 */
export const LICENSE_CONFLICT_RULES_BY_ID = gql`
  query ConflictRulesByID($licenseId: ID!) {
    License(where: { id: $licenseId }) {
      id
      conflictRules {
        id
        active
        name_de
        name_en
        conditions {
          __typename
          ... on Distribution {
            id
            name_de
            name_en
          }
          ... on Linking {
            id
            name_de
            name_en
          }
          ... on Modification {
            id
            name_de
            name_en
          }
          ... on Tivo {
            id
            name_de
            name_en
          }
        }
        conditions_OR {
          __typename
          ... on Distribution {
            id
            name_de
            name_en
          }
          ... on Linking {
            id
            name_de
            name_en
          }
          ... on Modification {
            id
            name_de
            name_en
          }
          ... on Tivo {
            id
            name_de
            name_en
          }
        }
        requirements {
          __typename
          ... on Requirement {
            id
            name_de
            name_en
          }
          ... on ToDo {
            id
            name_de
            name_en
          }
        }
        requirements_OR {
          __typename
          ... on Requirement {
            id
            name_de
            name_en
          }
          ... on ToDo {
            id
            name_de
            name_en
          }
        }
      }
    }
  }
`;

/**
 *
 */
export const LICENSE_LICENSE_FEATURES_BY_ID = gql`
  query LicenseLicenseFeaturesByID($licenseId: ID!) {
    License(where: { id: $licenseId }) {
      id
      licenseFeatures {
        id
        name_de
        name_en
      }
    }
  }
`;

/**
 *
 */
export const LICENSE_LICENSE_CONFLICT_RULES_BY_ID = gql`
  query LicenseConflictRulesByID($licenseId: ID!) {
    License(where: { id: $licenseId }) {
      id
      licenseConflictRules {
        id
        name_de
        name_en
      }
    }
  }
`;

/**
 *
 */
export const LICENSE_ADDITIONAL_LICENSE_FEATURES_BY_ID = gql`
  query AdditionalLicenseFeaturesByID($licenseId: ID!) {
    License(where: { id: $licenseId }) {
      id
      additionalLicenseFeatures {
        id
        name_de
        name_en
      }
    }
  }
`;