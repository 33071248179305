import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';

/**
 * @returns {JSX.Element}  Priority Component for Info Box
 */
const PriorityItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { version } = useContext(ProductInfoBoxContext);

  return (
    <Item id="Priority">
      <Item.Content>
        <Item.Meta>{t('priority')}</Item.Meta>
        <Item.Description
          style={{ fontWeight: 'bold' }}
          content={version?.priority}
        />
      </Item.Content>
    </Item>
  );
};

export default PriorityItem;
