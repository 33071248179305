import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';

import DescriptionModal from './DescriptionModal';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';

interface Props {
  maxDescriptionLines: number;
}

/**
 * @param {object} props {description and setOpenDescriptionModal}
 * @returns {JSX.Element}  Description Component for Info Box
 */
const DescriptionItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const [openModal, setOpenModal] = React.useState(false);
  const { maxDescriptionLines } = props;
  const [showMoreButton, setShowMoreButton] = useState(false);

  const { version, versionInput, editing } = useContext(ProductInfoBoxContext);
  const description = versionInput.description || version?.product?.description;

  useEffect(() => {
    const checkIfShouldShowMore = () => {
      const shouldShowMoreCondition =
        (description?.split('\n').length ?? 0) > maxDescriptionLines ||
        (description?.length ?? 0) > 260;
      setShowMoreButton(shouldShowMoreCondition);
    };
    checkIfShouldShowMore();
  }, [description, maxDescriptionLines]);
  return (
    <>
      <DescriptionModal open={openModal} setOpen={setOpenModal} />
      <Item id="DescriptionItem">
        <Item.Content>
          <Item.Meta>
            {t('description')}{' '}
            {editing && (
              <Item.Extra
                className="edit-link"
                content={t('editDescription', {
                  ns: 'buttons',
                })}
                onClick={() => {
                  setOpenModal(true);
                }}
              />
            )}
          </Item.Meta>
          <Item.Description>
            <span style={{ WebkitLineClamp: maxDescriptionLines }}>
              {description}
            </span>
            {!editing && showMoreButton && (
              <Item.Extra
                className="ui inline clickable"
                onClick={() => {
                  setOpenModal(true);
                }}
                content={t('show-more', { ns: 'buttons' })}
              />
            )}
          </Item.Description>
          {(description?.length === 0 ||
            description === null ||
            description === undefined) && (
            <Item.Description style={{ color: 'lightgrey' }}>
              {t('noDescription', { ns: 'productDetails' })}
            </Item.Description>
          )}
        </Item.Content>
      </Item>
    </>
  );
};

export default DescriptionItem;
