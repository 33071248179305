import { Role } from '../../../../graphql/generated/graphql';

/**
 *
 */
enum UserDataMenu {
  profile = 'personal data',
  access = 'brand,roles and directory access',
}

export default UserDataMenu;

/**
 *
 */
export type OptionsArrayProps = {
  key: number;
  text: string;
  value: Role;
};

/**
 *
 */
export type BuildDirectoryOptionsProps =
  | {
      key: string;
      text: string;
      value: string;
      icon: { name: string; color: string };
    }[]
  | null;
