import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Form, Input, FormProps, Popup } from 'semantic-ui-react';
import { UpdateUserMutationVariables } from '../../../graphql/generated/graphql';
import PasswordsFormValidations from './PasswordsFormValidations';
import { isValidEmail, isValidName } from './helpers/logics';

type UserDetailsEditFormProps = {
  onlyAdmin?: boolean | undefined;
  userDetails: UpdateUserMutationVariables;
  setUpdateDetails: (userDetails: UpdateUserMutationVariables) => void;
  repeatPass: {
    pass: string;
    enabled: boolean;
  };
  setRepeatPass: (repeatPass: { pass: string; enabled: boolean }) => void;
};

/**
 *@returns {JSX.Element} Element
 @param {UserDetailsEditFormProps} props Properties
 */
const UserDetailsEditForm = (props: UserDetailsEditFormProps): JSX.Element => {
  const [t] = useTranslation(['users', 'buttons']);
  const {
    onlyAdmin,
    userDetails,
    setUpdateDetails,
    repeatPass,
    setRepeatPass,
  } = props;

  return (
    <Segment.Group horizontal className="UserDetailsProfileFormGroup">
      <Segment id="UserDetailsEditProfile">
        <Form id="UserDetailsEditProfileForm">
          {onlyAdmin ? (
            <>
              <Popup
                id="UserDetailsEditProfileFormNameError"
                open={
                  userDetails?.name?.trim() === undefined ||
                  userDetails?.name?.trim().length < 1 ||
                  !isValidName(userDetails?.name?.trim())
                }
                content={
                  <div>
                    <p id="NameErrorP">{t('invalidNameError')}</p>
                    <i id="AllowedName" style={{ color: 'red' }}>
                      {t('allowedName')}
                    </i>
                  </div>
                }
                position="top left"
                trigger={
                  <Form.Field
                    value={userDetails.name}
                    placeholder={t('name.placeholder')}
                    control={Input}
                    label={t('name')}
                    onChange={(
                      _event: React.FormEvent<HTMLFormElement>,
                      data: FormProps
                    ) =>
                      setUpdateDetails({
                        ...userDetails,
                        name: data.value,
                      })
                    }
                  />
                }
              />
              <Popup
                id="UserDetailsEditProfileFormEmailError"
                open={!isValidEmail(userDetails?.email?.trim())}
                content={t('invalidEmailError')}
                position="top left"
                trigger={
                  <Form.Field
                    value={userDetails.email}
                    placeholder={t('email.placeholder')}
                    control={Input}
                    label={t('email')}
                    onChange={(
                      _event: React.FormEvent<HTMLFormElement>,
                      data: FormProps
                    ) =>
                      setUpdateDetails({
                        ...userDetails,
                        email: data.value,
                      })
                    }
                  />
                }
              />
            </>
          ) : (
            <>
              <Form.Field
                label={`${t('name')}\u0020\u0020  ${t('readOnly')}`}
                value={userDetails.name}
                control={Input}
                readOnly
              />
              <Form.Field
                label={`${t('email')} \u0020\u0020${t('readOnly')}`}
                value={userDetails.email}
                control={Input}
                readOnly
              />
            </>
          )}
        </Form>
      </Segment>
      {/* PASSWORDS */}
      <PasswordsFormValidations
        setUsersPass={setUpdateDetails}
        userDetails={userDetails}
        setRepeatPass={setRepeatPass}
        repeatPass={repeatPass}
        required={false}
      />
    </Segment.Group>
  );
};

export default UserDetailsEditForm;
