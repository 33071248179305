import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Menu,
  Message,
  Segment,
} from 'semantic-ui-react';
import { User } from '../../graphql/generated/graphql';
import { ALL_USERS_QUERY } from '../../graphql/queries/UserQuerys';
import AddNewUser from './AddNewUser';
import UserDetails from './UserDetails';
import AllUsersInfoTable from './userComponents/AllUsersInfoTable';

/**
 * AllUsersInfo Component
 *  provides all Users information for an "ADMIN" role.
 *
 * @returns {JSX.Element}
 * On EDIT triggers an UserInfoView
 */
const AllUsersInfo = (): JSX.Element => {
  const { t } = useTranslation(['users', 'buttons']);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState('active');
  const [search, setSearch] = useState('');
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [chosenUser, setChosenUser] = useState<User>(); // chosen user on click opens editor

  const {
    loading: allUsersLoading,
    data: allUsersData,
    error: allUsersError,
  } = useQuery(ALL_USERS_QUERY);

  if (allUsersLoading) {
    return <Loader active size="large" />;
  }
  if (allUsersError) {
    return (
      <Message
        id="AllUsersInfoError"
        error
        icon="times circle"
        content={`Error ${allUsersError}`}
      />
    );
  }

  if (allUsersData) {
    const { User: allUsers } = allUsersData; // Array of allUsers with their data
    const activeUsers = allUsers
      .filter((user: User) => user.status === 'active')
      .filter((user: User) =>
        user.name.toLowerCase().includes(search.toLowerCase())
      );
    const inactiveUsers = allUsers
      .filter((user: User) => user.status === 'inactive')
      .filter((user: User) =>
        user.name.toLowerCase().includes(search.toLowerCase())
      );

    return (
      <>
        {/* EDIT USER DETAILS MODAL */}
        {chosenUser && (
          <UserDetails
            onlyAdmin // variable to disable input fields when NON Admin User is Loged-in
            open={openModal}
            setOpen={setOpenModal}
            user={chosenUser || allUsers} // from this part it props the user that will be selected onClick from ADMIN
          />
        )}
        {/* CREATE NEW USER MODAL */}
        <AddNewUser openAdd={openCreateUser} setOpenAdd={setOpenCreateUser} />
        {/* ALL USERS INFO TABLE WITH MENU, SEARCH BOX AND ADD USER BUTTON */}
        <Segment id="AllUsersInfo" style={{ height: '100%', overflow: 'auto' }}>
          <Header as="h1">{t('allUsersList')}</Header>
          <Menu id="AllUsersInfoMenu" pointing secondary size="large">
            <Menu.Item
              name="Active Users"
              active={activeTab === 'active'}
              onClick={() => setActiveTab('active')}
            >
              {t('users-active', { ns: 'users' })}
              <Label
                style={{ marginLeft: '1em' }}
                id="AllUsersInfoLabelActive"
                color="green"
                circular
              >
                {activeUsers.length}
              </Label>
            </Menu.Item>
            <Menu.Item
              name="Inactive Users"
              active={activeTab === 'inactive'}
              onClick={() => setActiveTab('inactive')}
            >
              {t('users-inactive', { ns: 'users' })}
              <Label
                style={{ marginLeft: '1em' }}
                id="AllUsersInfoLabelInactive"
                color="grey"
                circular
              >
                {inactiveUsers.length}
              </Label>
            </Menu.Item>
            <Menu.Menu position="right">
              {/* SEARCH FOR A USER- FIELD */}
              <Menu.Item>
                <Input
                  id="AllUsersInfoSearchTab"
                  data-cy="SearchUser"
                  icon={{ name: 'search', link: true, position: 'left' }}
                  placeholder={t('search.placeholder', { ns: 'users' })}
                  onChange={(e, { value }) => setSearch(value)}
                />
              </Menu.Item>
              {/* ADD USER BTN - OPENS CREATE USER MODAL */}
              <Menu.Item>
                <Button
                  id="AllUsersInfoAddUserBtn"
                  data-cy="AddUserButton"
                  floated="right"
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                  onClick={() => setOpenCreateUser(true)}
                >
                  <Icon id="AllUsersInfoAddUserUserIcon" name="user" />
                  {t('create-user', { ns: 'buttons' })}
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          {/* ALL USERS INFO TABLE */}
          <AllUsersInfoTable
            activeTab={activeTab}
            activeUsers={activeUsers}
            inactiveUsers={inactiveUsers}
            setOpenModal={setOpenModal}
            setChosenUser={setChosenUser}
          />
        </Segment>
      </>
    );
  }
  return <div />;
};

export default AllUsersInfo;
