import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Item } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
import { WizardTeamModalProps } from './helper/types';

/**
 *@param {object} usersOptions allUsers as options for a Dropdown selection
 * @param {[]} usersOptions.usersOptions type
 * @returns {JSX.Element} Team manager column
 */
const WizardTeamGuest = ({
  usersOptions,
}: WizardTeamModalProps): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const { wizardInput, setOpenTeamModal, currentPart } =
    useContext(WizardContext);
  const guestTeam = wizardInput.partInput[currentPart - 1]?.guest;
  return (
    <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
      <Item id="WizardTeamGuest.Title">
        <Item.Content>
          <Item.Header>{t('wizardsTeam.teamTitle')}</Item.Header>
          <Item.Extra
            style={{
              fontSize: '18px',
              display: 'inline',
            }}
          >
            {t('wizardsTeam.guest')}
          </Item.Extra>
          <Item.Description
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              marginTop: '40px',
            }}
          >
            <Form id="WizardTeamGuest.Form">
              <WizardFormFieldLabel
                optional
                nameText={t('wizardsTeam.guest')}
                infoText={t('wizardsTeam.guest-info')}
                htmlFor="WizardTeamGuest.Addbtn"
              />
            </Form>
            <Button
              id="WizardTeamGuest.Addbtn"
              circular
              icon="plus"
              size="mini"
              onClick={() => {
                setOpenTeamModal([true, 'Guest']);
              }}
            />
          </Item.Description>
          {guestTeam &&
            guestTeam
              .filter((uID) => uID !== '')
              .map((uID) => usersOptions.find((user) => user.value === uID))
              .map((user) => user?.text)
              .map((guest) => <div key={`${guest}+guest`}>{guest}</div>)}
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default WizardTeamGuest;
