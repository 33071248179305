import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { useEffect } from 'react';
import {
  DirectPvDependenciesByVersionIdRequestorListQueryResult,
  Exact,
  VersionOptions,
  VersionWhere,
} from '../../../../../../graphql/generated/graphql';
import { DIRECT_PV_DEPEPENDENCIES_BY_VERSION_ID_REQUESTOR_LIST } from '../../../../../../graphql/queries/VersionQuerys';
import {
  Columns,
  ListType,
} from '../../../../productsList/listComponents/ProductListTypes';
import {
  activeFilters,
  activeSorters,
} from '../../../../productsList/listComponents/helpers/formatters';
import { collatingElementType } from '../helper/types';

/**
 * @description RefetchType is the type for the apollo refetch function
 */
type RefetchType = (
  variables?:
    | Partial<
        Exact<{
          options?: VersionOptions | null;
          where?: VersionWhere | null;
          id: string;
          processStatusAsString?: collatingElementType[] | null;
        }>
      >
    | undefined
) => void;

/* ---------- Helper functions ---------- */

const combineWhereObjWithSearchString = (
  searchString: string
): { OR?: collatingElementType[] } => {
  if (searchString === '') {
    return {};
  }
  return {
    OR: [{ name_CONTAINS: searchString }, { id_CONTAINS: searchString }],
  };
};

/**
 *
 * @param {Array} activeFiltersArray - array containing the active filters
 * @param {Array} filterPillAttributeName - the name of the column that was used for the generation of f
 * @returns {object} - object containing the reduced active filters array and the active filter pill array
 */
export const splitOffFilterPillsArray = (
  activeFiltersArray: collatingElementType[],
  filterPillAttributeName: string
): {
  activeFiltersArrayReduced: collatingElementType[];
  activeFilterPillsArray: collatingElementType[];
} => {
  let activeFiltersArrayReduced: collatingElementType[] = [];
  const activeFilterPillsArray: collatingElementType[] = [];

  if (activeFiltersArray && activeFiltersArray.length > 0) {
    activeFiltersArrayReduced = activeFiltersArray.filter((obj) => {
      if (Object.keys(obj)[0] === filterPillAttributeName) {
        activeFilterPillsArray.push(obj);
        return false;
      }
      return true;
    });
    return {
      activeFiltersArrayReduced,
      activeFilterPillsArray,
    };
  }
  return {
    activeFiltersArrayReduced: [],
    activeFilterPillsArray: [],
  };
};

/**
 * @param {Array} activeFilterPillsArray - array containing the active filter pills
 * @param {Array} activeFiltersArrayReduced - array containing the other active filters
 * @param {Array} activeFiltersArray - array containing all the active filters
 * @param {object} whereObj - object of type DependencyWhere or VersionWhere or whatever TWhere is
 * @returns {object} - object containing the combined where object
 */
export const combineVersionWhereObjWithFilters = (
  activeFilterPillsArray: collatingElementType[],
  activeFiltersArrayReduced: collatingElementType[],
  activeFiltersArray: collatingElementType[],
  whereObj: VersionWhere
): VersionWhere => {
  const filters =
    activeFilterPillsArray.length > 0
      ? activeFiltersArrayReduced
      : activeFiltersArray;

  if (whereObj && whereObj.OR) {
    return {
      OR: [...whereObj.OR, ...filters],
    };
  }
  return {
    OR: [...filters],
  };
};

/* ---------- Hooks ---------- */

/**
 * @param {Function} setColumns - function to set the columns
 * @param {string} productVersionId - id of the product version
 */
export const useFetchRequestorList = (
  setColumns: React.Dispatch<React.SetStateAction<Columns>>,
  productVersionId: string
): void => {
  const { data: requestorListData } = useQuery(
    DIRECT_PV_DEPEPENDENCIES_BY_VERSION_ID_REQUESTOR_LIST,
    { variables: { id: productVersionId } }
  );
  useEffect(() => {
    // overwriting requestor with a new requestor object
    // containing the names pulled form the db
    if (requestorListData) {
      setColumns((oldColumns) => {
        const requestor = oldColumns.find(
          (column) => column.name === 'requestor'
        );
        if (requestor === undefined) {
          return oldColumns;
        }
        requestor.filterInfo.filtering =
          requestorListData.DirectProductVersionDependenciesByVersionId.reduce(
            (
              obj: DirectPvDependenciesByVersionIdRequestorListQueryResult,
              requestorObj: { createdBy: { name: string; id: string } }
            ) => ({
              ...obj,
              [`${requestorObj.createdBy.name}__${requestorObj.createdBy.id}`]:
                false,
            }),
            {}
          );
        return oldColumns
          .filter((column) => column.name !== 'requestor')
          .concat(requestor);
      });
    }
  }, [setColumns, requestorListData]);
};

/**
 *
 * @param {string} searchString - string the user inserted in the search bar
 * @param {Columns} columns - the columns of the list
 * @param {number} activePageState - the current active page starting from 1
 * @param {number} itemsPerPage - the amount of items per page
 * @param {string} productVersionId - the id of the product version
 * @param {Function} refetch - the refetch function
 */
export const useRefetchSortedAndFilteredPVDepList = (
  searchString: string,
  columns: Columns,
  activePageState: number,
  itemsPerPage: number,
  productVersionId: string,
  refetch: RefetchType
): void => {
  useEffect(() => {
    const activeFiltersArray = activeFilters(columns, true);
    const activeSortersArray = activeSorters(
      columns,
      ListType.PRODUCTVERSIONDEPENDENCYLIST
    );
    let whereObj: VersionWhere;

    const { activeFilterPillsArray, activeFiltersArrayReduced } =
      splitOffFilterPillsArray(activeFiltersArray, 'processStatusAsString');

    whereObj = combineWhereObjWithSearchString(searchString);
    whereObj = combineVersionWhereObjWithFilters(
      activeFilterPillsArray,
      activeFiltersArrayReduced,
      activeFiltersArray,
      whereObj
    );
    refetch({
      id: productVersionId,
      options: {
        limit: itemsPerPage,
        offset: (activePageState - 1) * itemsPerPage,
        sort: [...activeSortersArray],
      },
      where: whereObj,
      processStatusAsString: activeFilterPillsArray as collatingElementType[],
    });
  }, [
    columns,
    searchString,
    activePageState,
    itemsPerPage,
    productVersionId,
    refetch,
  ]);
};

/**
 *
 * @param {number} activePageState - the current active page starting from 1
 * @param {number} itemAmount - the amount of dependencies
 * @param {number} pages - the amount of pages
 * @param {Function} setPages - function to set the amount of pages
 * @param {Function} setActivePageState - function to set the active page
 * @param {number} itemsPerPage - the amount of items per page
 */
export const usePagination = (
  activePageState: number,
  itemAmount: number,
  pages: number,
  setPages: React.Dispatch<React.SetStateAction<number>>,
  setActivePageState: React.Dispatch<React.SetStateAction<number>>,
  itemsPerPage: number
): void => {
  useEffect(() => {
    if (itemAmount && itemAmount > 0) {
      setPages(Math.ceil(itemAmount / itemsPerPage));
    }
  }, [setPages, itemsPerPage, itemAmount]);

  useEffect(() => {
    // keeps activePageState in range
    if (pages && pages < activePageState && pages !== 0) {
      setActivePageState(pages);
    }
  }, [setActivePageState, activePageState, pages]);
};
