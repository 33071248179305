import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import OverflowPopup from '../../../../../productsList/listComponents/columnComponents/OverflowPopup';

type PropsType = {
  onClick: (id: string) => void;
  hasDependency?: boolean;
  name: string;
  productName?: string | undefined;
  open: string[];
  elementId: string;
  explicitVersionId?: string | undefined;
};

/**
 * returns the name of the dependency and an
 * icon to open the accordion to more dependencies if there are any
 *
 * @param {PropsType} props takes in name, elementId, click function for the accordion,
 * open state and hasDependency as boolean
 * @returns {JSX.Element} a span with name and an icon to open the accordion
 */
const NameElement = (props: PropsType): JSX.Element => {
  const {
    onClick,
    name,
    productName,
    elementId,
    hasDependency,
    open,
    explicitVersionId,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      {hasDependency ? (
        <Icon
          id="ShowTransitiveDependenciesIcon"
          style={{ position: 'relative' }}
          onClick={onClick ? () => onClick(elementId) : () => null}
          name={open.includes(elementId) ? 'angle up' : 'angle down'}
        />
      ) : (
        explicitVersionId && (
          <Icon
            id="ProductsList.VersionLinkIcon"
            name="external alternate"
            onClick={() =>
              navigate(`/product-details/${explicitVersionId}?view=Details`)
            }
          />
        )
      )}
      <OverflowPopup
        content={productName ? `${productName} @ ${name}` : name}
      />
    </>
  );
};

export default NameElement;

NameElement.defaultProps = {
  productName: undefined,
};
