import { gql } from '@apollo/client';
/**
 *  Get all the todos/tasks for relevant version.
 *
 * @returns {string}  Returns required ToDos/tasks
  and the info if the user has already marked it as done (selection info)
 */
export const GET_TODOS = gql`
query GetToDos($versionId: ID!) {
  GetToDos(versionId: $versionId) {
    parentToDoId
    selectionType
    toDo {
      id
      name_en
      name_de
      internal
      description_en
      description_de
      tasks {
        id
        name_en
        name_de
        internal
      }
    }
  }
}
`

/**
 * Popup on MouseOver event of each To-Do Query Document
 *
 * @returns {string} Data for each to do.
 */
export const TO_DO_PARTS_MOUSEOVER = gql`
  query TodoMouseover($versionId: ID!, $toDoId: ID!) {
    ToDoSourceDetails(versionId: $versionId, toDoId: $toDoId) {
      componentName
      componentVersion
      licenseId
      licenseName
      licenseSpdxId
    }
  }
`;