import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, InputOnChangeData, DropdownProps } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import {
  CreateProductPart,
  PartForSelection,
  selectedDist,
} from '../WizardConstants';
import WizardPartsForAllColumn from './WizardPartsForAllColumn';
import WizardPartsGeneralColumn from './WizardPartsGeneralColumn';

/**
 * Wizard Product creation - Step Three
 *
 *@returns {JSX.Element} Third step in creating a Product is its Parts slection.
 */
const WizardPartsSegment = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const {
    setIdenticalTeam,
    setIdenticalIntendedUse,
    setStepComplete,
    setWizardInput,
    setChosenDist,
    chosenDist,
    wizardInput,
    identicalIntendedUse,
    identicalTeam,
    includedParts,
    setIncludedParts,
  } = useContext(WizardContext);

  /* Variables */
  const minParts = 2;
  const minPartNameLength = 3;

  /* Checks */
  const isValidPartName = (name: string): boolean => {
    return name.length >= minPartNameLength;
  };
  const isValidPart = (part: PartForSelection): boolean => {
    return isValidPartName(part.name) && part.checked;
  };

  /* Handlers */
  const onClickCheckbox = (part: PartForSelection): void => {
    const newParts = includedParts.map((prevPart) =>
      prevPart.id === part.id
        ? {
            ...prevPart,
            checked: !prevPart.checked,
          }
        : prevPart
    );
    setIncludedParts(newParts);
  };

  const onChangePartName = (
    part: PartForSelection,
    data: InputOnChangeData
  ): void => {
    const newParts = includedParts.map((mapPart) =>
      mapPart.id === part.id ? { ...mapPart, name: data.value } : mapPart
    );
    setIncludedParts(newParts);
  };

  const onClickAddPart = () => {
    setIncludedParts([
      ...includedParts,
      {
        id: includedParts.length,
        checked: false,
        name: `${t('parts.custom.label')} #${includedParts.length - 3}`,
      },
    ]);
  };

  const onChangeTeamOption = (data: DropdownProps) => {
    if (typeof data.value === 'boolean') {
      setIdenticalTeam(data.value);
    }
  };

  const onChangeUseOption = (data: DropdownProps) => {
    if (typeof data.value === 'boolean') {
      setIdenticalIntendedUse(data.value);
    }
  };

  /* useEffects */
  useEffect(() => {
    const currentParts = wizardInput.partInput;
    const currentPartIds = currentParts.map((p) => p.id);
    const newChosenDist = includedParts
      .filter((p) => p.checked)
      .map((part) => {
        if (currentPartIds.includes(part.id)) {
          const currentDist = chosenDist.find((p) => p.id === part.id);
          return { ...currentDist } as selectedDist;
        }
        return { id: part.id } as selectedDist;
      });
    setWizardInput({
      ...wizardInput,
      partInput: includedParts
        .filter((part) => part.checked)
        .map((part) => {
          if (currentPartIds.includes(part.id)) {
            const currentPart = currentParts.find((p) => p.id === part.id);
            return { ...currentPart, name: part.name } as CreateProductPart;
          }
          return { id: part.id, name: part.name } as CreateProductPart;
        }),
    });
    setChosenDist(newChosenDist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedParts, setWizardInput]);

  useEffect(() => {
    if (
      identicalIntendedUse !== undefined &&
      identicalTeam !== undefined &&
      wizardInput.partInput.length >= minParts
    ) {
      setStepComplete(true);
    } else {
      setStepComplete(false);
    }
  }, [setStepComplete, wizardInput, identicalIntendedUse, identicalTeam]);

  return (
    <Grid
      columns={2}
      divided
      padded="horizontally"
      style={{ height: 'inherit', padding: '2rem 0 2rem 0' }}
    >
      {/* Parts | General */}
      <WizardPartsGeneralColumn
        parts={includedParts}
        isValidPart={isValidPart}
        isValidPartName={isValidPartName}
        onClickCheckbox={(part) => onClickCheckbox(part)}
        onChangePartName={(part, data) => onChangePartName(part, data)}
        onClickAddPart={() => onClickAddPart()}
      />
      {/* Parts | For all parts */}
      <WizardPartsForAllColumn
        onChangeTeamOption={(data) => onChangeTeamOption(data)}
        onChangeUseOption={(data) => onChangeUseOption(data)}
      />
    </Grid>
  );
};

export default WizardPartsSegment;
