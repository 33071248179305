import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Message, Form, Header, Segment } from 'semantic-ui-react';
import { useLicenseByIdQuery } from '../../../../../../../../graphql/generated/graphql';
import AutoExpandingTextarea from '../../../../../../../licenses/AutoExpandingTextarea';

type PropsType = {
  licenseId: string;
};

/**
 *  License Modals Second Tab
 *
 *@param {PropsType} props - License Id
 *@returns {JSX.Element}  Returning 2. Tab of the modal => General License Details
 */
const LicenseElementModalsGeneralDetails = (props: PropsType): JSX.Element => {
  const { licenseId } = props;
  const [t] = useTranslation('licenseList');

  const { data, loading, error } = useLicenseByIdQuery({
    variables: { licenseId },
  });

  if (loading) {
    return <Loader active size="large" />;
  }
  if (error) {
    return (
      <Message
        id="LicenseElementModalsGeneralDetailsError"
        error
        icon="times circle"
        content={`Error ${error.message}`}
      />
    );
  }

  if (data) {
    const licenseData = data?.License?.[0];
    if (licenseData) {
      return (
        <Segment id="LicenseGeneralDetails" basic>
          <Header as="h5">{t('license-detail.permissionNote')}</Header>
          <Segment id="LicenseDetailPermissionNote">
            <span>
              {licenseData?.permissionNote ||
                t('license-detail.noPermissionNote')}
            </span>
          </Segment>
          <Header as="h5">{t('license-detail.licenseText')}</Header>
          <Form id="LicenseDetailText">
            <AutoExpandingTextarea content={licenseData?.text as string} />
          </Form>
        </Segment>
      );
    }
  }
  return <div />;
};

export default LicenseElementModalsGeneralDetails;
