import { useContext } from 'react';
import WizardContext from '../../../context/WizardContext';
import { CreateProductPart } from '../WizardConstants';

/**
 *@returns {void} Function that populates WizardInput properties for intened Use Part
 it has two options: first if all parts are populated separately and second when parts have "identical intendedUse values"
 */
const useWizardPartInputForIntendedUse = (): ((
  input: string | boolean,
  detail: string
) => void) => {
  const { setWizardInput, wizardInput, currentPart, identicalIntendedUse } =
    useContext(WizardContext);
  const wizProduct = wizardInput.productInput;
  const wizPart = wizardInput.partInput;

  const IntendedUseUpdate = (input: string | boolean, detail: string): void => {
    if (identicalIntendedUse === false) {
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: [
          ...wizPart.slice(0, currentPart - 1),
          { ...wizPart[currentPart - 1], [detail]: input },
          ...wizPart.slice(currentPart),
        ],
      });
    } else
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: wizPart.map((part: CreateProductPart) => {
          return { ...part, [detail]: input };
        }),
      });
  };
  return IntendedUseUpdate;
};

export default useWizardPartInputForIntendedUse;
