import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { RequirementInfo } from '../../../../graphql/generated/graphql';
import RequirementsListItem from './RequirementsListItem';

interface Props {
  requirementInfoArray: RequirementInfo[];
}

/**
 * @returns {JSX.Element} The Requirements List in the chosen product
 * @param {Props} props { requirementInfoArray }
 */
const RequirementsList = ({ requirementInfoArray }: Props): JSX.Element => {
  // breakpoint to always list first column as the biggest
  const breakpoint =
    requirementInfoArray.length % 2 === 0
      ? requirementInfoArray.length / 2
      : (requirementInfoArray.length + 1) / 2;
  // each column has an equal number of requirements(first column is bigger if odd number of to-dos)
  const firstColumn = requirementInfoArray.slice(0, breakpoint);
  const secondColumn = requirementInfoArray.slice(breakpoint);

  return (
    <Grid id="RequirementsGrid" columns={2} divided padded>
      <Grid.Column>
        <List id="RequirementListLeft">
          {firstColumn.map((requirementInfo) => (
            <RequirementsListItem
              key={requirementInfo.requirement.name_en} // unique key for each child*
              requirementInfo={requirementInfo}
              reqIndex={requirementInfoArray.indexOf(requirementInfo)}
            />
          ))}
        </List>
      </Grid.Column>
      <Grid.Column>
        <List id="RequirementListRight">
          {secondColumn.map((requirementInfo) => (
            <RequirementsListItem
              key={requirementInfo.requirement.name_en} // unique key for each child*
              requirementInfo={requirementInfo}
              reqIndex={requirementInfoArray.indexOf(requirementInfo)}
            />
          ))}
        </List>
      </Grid.Column>
    </Grid>
  );
};

export default RequirementsList;
