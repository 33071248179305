import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../context/ProductContext';
import { DependencyListElement as DependencyListElementType } from '../../../../../../../graphql/generated/graphql';
import {
  Column,
  Columns,
} from '../../../../../productsList/listComponents/ProductListTypes';
import Comments from '../../../../comments/Comments';
import LicensesElement from '../../commonListComponents/columnComponents/LicensesElement';
import LinkedToElement from '../../commonListComponents/columnComponents/LinkedToElement';
import ModifiedElement from '../../commonListComponents/columnComponents/ModifiedElement';
import NameElement from '../../commonListComponents/columnComponents/NameElement';
import ResolverStatusElement from '../../commonListComponents/columnComponents/ResolverStatusElement';
import CopyleftElement from '../../commonListComponents/columnComponents/CopyleftElement';

type PropsType = {
  columns: Columns;
  fossDep: DependencyListElementType;
  onClick: (id: string) => void;
  open: string[];
  onComponentClick?: (name: string) => void;
};

/**
 * list element of dependency list
 *
 * @param {PropsType} props takes in direct or transitive dependency
 * Checks which one came in and delivers the according table row.
 * @returns {JSX.Element} table row containing table cells with the according values.
 */
const DependencyListElement = (props: PropsType): JSX.Element => {
  const { columns, fossDep, onClick, open, onComponentClick } = props;
  const [text] = useTranslation('dashboard');
  const { versionId } = useContext(ProductDetailsContext);

  const elementId = fossDep.dependency.id;

  // filtering Comments refering to ProductVersion
  const commentsByVersionId = fossDep?.dependency.comments.filter(
    (c) => c.version?.id === versionId
  );
  return (
    <Table.Row
      id={`DependencyListRow${elementId}`}
      key={`DependencyListRow${elementId}`}
      className={fossDep.directDependency ? '' : 'child'}
    >
      {columns.map((column: Column) => {
        return (
          <Table.Cell
            key={`DependencyListCell${elementId}${column.name}`}
            width={column.width}
            className={
              column.name === 'resolverStatus'
                ? fossDep?.dependency.overallResult.status
                : ''
            }
          >
            {fossDep &&
              (() => {
                switch (column.name) {
                  case 'resolverStatus':
                    return (
                      <ResolverStatusElement
                        overallResult={fossDep?.dependency.overallResult}
                        name={fossDep?.dependency.componentName}
                        id={fossDep?.dependency.id}
                        legalResult={fossDep?.dependency.legalResult}
                        licenseTextEdges={
                          fossDep?.dependency.subComponentsConnection?.edges
                        }
                      />
                    );
                  case 'name':
                    return (
                      <NameElement
                        name={fossDep?.dependency.componentName}
                        onClick={onClick}
                        elementId={elementId}
                        open={open}
                      />
                    );
                  case 'componentVersion':
                    return <span>{fossDep?.dependency?.componentVersion}</span>;
                  case 'licenses':
                    return (
                      <LicensesElement
                        subComponents={fossDep?.dependency.subComponents}
                        dependencyId={fossDep.dependency.id}
                        subComponentEdges={
                          fossDep?.dependency.subComponentsConnection?.edges
                        }
                      />
                    );
                  case 'linking':
                    return (
                      <span>
                        {fossDep.dependency.linkingFeature
                          ? fossDep.dependency.linkingFeature
                          : text('tableElements.noInfo')}
                      </span>
                    );
                  case 'modified':
                    return (
                      <ModifiedElement
                        modified={fossDep?.dependency.modified as boolean}
                      />
                    );
                  case 'linkedTo':
                    return fossDep.sourceDependencyIds &&
                      fossDep.sourceDependencyIds.length > 0 ? (
                      <LinkedToElement
                        sourceDependencyIds={fossDep.sourceDependencyIds}
                        dependencyId={fossDep.dependency.id}
                        onComponentClick={onComponentClick}
                      />
                    ) : (
                      <span>-</span>
                    );
                  case 'comments':
                    return (
                      <Comments
                        comments={commentsByVersionId}
                        dependName={fossDep?.dependency.componentName}
                        refersToId={fossDep?.dependency.id}
                      />
                    );
                  case 'copyleft':
                    return (
                      <CopyleftElement
                        copyleft={fossDep?.dependency.copyleft}
                      />
                    );
                  default:
                    return null;
                }
              })()}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
};

export default DependencyListElement;
