import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Popup } from 'semantic-ui-react';
import {
  Dependency,
  Maybe,
} from '../../../../../../../../graphql/generated/graphql';

type PropsType = {
  dependencyId: Maybe<string> | undefined;
  sourceDeps: Dependency[];
  handleDependencyClick: (id: string) => void;
};

/**
 * element for displaying the name of the parent dependency
 *
 * @param {PropsType} props id to source dependency
 * @returns {JSX.Element} element containing the name of the parent dependency
 */
const LinkedToPopup = (props: PropsType): JSX.Element => {
  const { dependencyId, sourceDeps, handleDependencyClick } = props;
  const { t } = useTranslation('productDetails');

  return (
    <Popup
      id="LinkedToPopup"
      hoverable
      position="top center"
      content={
        <div id="AdditionalLicenses">
          <strong>{t('dependencyList.tableBody.additionalSourceDeps')}</strong>
          <ul style={{ paddingLeft: '1em' }}>
            {sourceDeps.map((dep: Dependency) => {
              return (
                <li key={`${dependencyId}_${dep.id}`}>
                  <button
                    id="DependencyTreeModalButton"
                    key={dep.id}
                    type="button"
                    className="clickableSourceDep"
                    onClick={() => {
                      handleDependencyClick(dep.id);
                    }}
                  >
                    {dep.componentName}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      }
      trigger={
        <Label
          style={{
            marginLeft: '0.5em',
            position: 'relative',
            top: '-0.25em',
          }}
          circular
          id="LinkedToCOuntLabel"
          content={`+ ${sourceDeps.length}`}
        />
      }
    />
  );
};

export default LinkedToPopup;
