/**
 * Product mutations regarding User DirectoryRole and Status/State to be rendered regardless of state
 */
export const applicableProductMutations = {
  manager: [
    {
      status: 'INCREATION',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        // persist but deactivated
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // setting
        'RequestSupplierToDos',
        'FinishProductCheck',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // setting
        'FinishProductCheck',
        'RequestSupplierToDos',
      ],
    },
    {
      status: 'REPORT',
      mutations: [
        // setting
        'RequestSupplierReport',
        'FinishReport',
      ],
    },
  ],
  supplier: [
    {
      status: 'INCREATION',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // setting
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // persist but deactivated
        'SubmitToLegal',
        'SubmitToOSO',
        'SupplierInputDone',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // persist but deactivated
        'SubmitToOSO',
        'SupplierToDosDone',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // persist but deactivated
        'SubmitToOSO',
        // setting
        'SupplierToDosDone',
      ],
    },
    {
      status: 'SUPPLIERREPORT',
      mutations: ['FinishSupplierReport'],
    },
  ],
  developer: [
    {
      status: 'INCREATION',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'RequestSupplierInput',
        'SubmitToLegal',
        'SubmitToOSO',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // setting
        'RequestSupplierInput',
        'SubmitToLegal',
        // persist but deactivated
        'SubmitToOSO',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // setting
        'RequestSupplierToDos',
        'FinishProductCheck',
        // persist but deactivated
        'SubmitToOSO',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // persist but deactivated
        'SubmitToOSO',
        'RequestSupplierToDos',
        'FinishProductCheck',
      ],
    },
    {
      status: 'REPORT',
      mutations: [
        // setting
        'RequestSupplierReport',
        'FinishReport',
      ],
    },
  ],
  legal: [
    {
      status: 'INCREATION',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // setting
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
        'FinishLegalCheck',
      ],
    },
  ],
  oso: [
    {
      status: 'INCREATION',
      mutations: [
        // persist but deactivated
        'SupplierInputDone',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'INPROGRESS',
      mutations: [
        // persist but deactivated
        'SupplierInputDone',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'SUPPLIERINPUT',
      mutations: [
        // setting
        'SupplierInputDone',
        // persist but deactivated
        'RequestSupplierInput',
      ],
    },
    {
      status: 'OSOREQUESTED',
      mutations: [
        // setting
        'SubmitToUser',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'LEGALCHECK',
      mutations: [
        // persist but deactivated
        'SupplierInputDone',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'REWORK',
      mutations: [
        // persist but deactivated
        'SubmitToUser',
        'RequestSupplierInput',
      ],
    },
    {
      status: 'LEGALCHECKDONE',
      mutations: [
        // setting
        // persist but deactivated
        'RequestSupplierInput',
        'SupplierToDosDone',
      ],
    },
    {
      status: 'SUPPLIERTODOS',
      mutations: [
        // setting
        'SupplierToDosDone',
        // persist but deactivated
        'RequestSupplierInput',
      ],
    },
    {
      status: 'SUPPLIERREPORT',
      mutations: ['FinishSupplierReport'],
    },
    {
      status: 'REPORT',
      mutations: [
        // setting
        'RequestSupplierReport',
        'FinishReport',
      ],
    },
  ],
  guest: [],
};

/**
 * Project mutations regarding DirectoryRole and Status/State to be rendered regardless of state
 */
export const applicableProjectMutations = {
  manager: [
    {
      status: 'INCREATION',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
  ],
  supplier: [],
  developer: [
    {
      status: 'INCREATION',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToOSO', 'FinishProjectCheck'],
    },
  ],
  legal: [],
  oso: [
    {
      status: 'INCREATION',
      mutations: ['SubmitToUser', 'FinishProjectCheck'],
    },
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToUser', 'FinishProjectCheck'],
    },
  ],
  guest: [],
};

/**
 * Group transfer mutations regarding DirectoryRole and Status/State to be rendered
 */
export const applicableGroupTransferMutations = {
  oso: [
    {
      status: 'OSOREQUESTED',
      mutations: ['SubmitToBrandOSO', 'SubmitToLegal'],
    },
    {
      status: 'BRANDOSO',
      mutations: ['SubmitToLegal'],
    },
  ],
  legal: [
    {
      status: 'OSOREQUESTED',
      mutations: ['FinishProductCheck'],
    },
    {
      status: 'BRANDOSO',
      mutations: ['FinishProductCheck'],
    },
    {
      status: 'LEGALCHECK',
      mutations: ['SubmitToBrandOSO', 'FinishProductCheck'],
    },
  ],
  manager: [],
  supplier: [],
  developer: [],
  guest: [],
};
