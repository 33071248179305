import React from 'react';
import { Priority } from '../../graphql/generated/graphql';

type PropsType = {
  priority: Priority | null;
};

/**
 * PriorityElement component
 *
 * @param {PropsType} props takes in priority as string or undefined
 * @returns {JSX.Element} a span with text
 */
const PriorityElement = (props: PropsType): JSX.Element => {
  const { priority } = props;
  switch (priority) {
    case Priority.PRIO5:
      return <span style={{ fontWeight: 'bold' }}>5</span>;
    case Priority.PRIO4:
      return <span style={{ fontWeight: 'bold' }}>4</span>;
    case Priority.PRIO3:
      return <span style={{ fontWeight: 'bold' }}>3</span>;
    case Priority.PRIO2:
      return <span style={{ fontWeight: 'bold' }}>2</span>;
    case Priority.PRIO1:
      return <span style={{ fontWeight: 'bold' }}>1</span>;

    default:
      return <span>-</span>;
  }
};

export default PriorityElement;
