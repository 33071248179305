import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { UserContext } from '../../../context/UserContext';
import WizardContext from '../../../context/WizardContext';
import { CreateProductPart } from '../WizardConstants';
import { Role } from '../../../graphql/generated/graphql';

/**
 *@returns {JSX.Element} Reset btn removes all users except the current suer in manager
 */
const WizardTeamResetButton = (): JSX.Element => {
  const { t } = useTranslation('buttons');
  const { setWizardInput, wizardInput, currentPart, identicalTeam } =
    useContext(WizardContext);
  const { id, roles } = useContext(UserContext); // current User ID!

  const wizProduct = wizardInput.productInput;
  const wizPart = wizardInput.partInput;

  const resetAllTeams = () => {
    const isNotOso = !roles.includes(Role.oso);
    if (identicalTeam === false) {
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: [
          ...wizPart.slice(0, currentPart - 1),
          {
            ...wizPart[currentPart - 1],
            manager: isNotOso ? [id] : [], // currentUser.id if not OSO,
            developer: [],
            supplier: [],
            guest: [],
          },
          ...wizPart.slice(currentPart),
        ],
      });
    } else
      setWizardInput({
        productInput: { ...wizProduct },
        partInput: wizPart.map((part: CreateProductPart) => {
          return {
            ...part,
            manager: isNotOso ? [id] : [], // currentUser.id if not OSO,
            developer: [],
            supplier: [],
            guest: [],
          };
        }),
      });
  };

  return (
    <Button
      id="WizardTeamResetButton"
      color="red"
      basic
      style={{
        marginTop: 5,
      }}
      onClick={() => {
        resetAllTeams();
      }}
    >
      {t('resetTeamMembers')}
    </Button>
  );
};

export default WizardTeamResetButton;
