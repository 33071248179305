import { DependencyList, useEffect, useRef } from 'react';

/**
 * useEffect to skip first render.
 *
 * @param {(oldDeps: DependencyList) => void} effect effect to be called
 * @param {DependencyList} deps dependencies
 * @returns {void}
 */
const useEffectWithPrevDeps = <TDeps extends DependencyList>(
  effect: (oldDeps: TDeps) => void,
  deps: TDeps
): void => {
  const oldDepsRef = useRef(deps);
  useEffect(() => {
    effect(oldDepsRef.current);
    oldDepsRef.current = deps;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, effect, oldDepsRef]);
};

export default useEffectWithPrevDeps;
