/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Get all existing parent todos
 */
export const GET_ALL_TODOS = gql`
  query GetAllTodos($where: FeatureWhere, $options: FeatureOptions) {
    GetAllFeatures(where: $where, options: $options) {
      ToDo {
        id
        name_de
        name_en
        internal
        category
        tasks {
          id
          internal
          default
          name_de
          name_en
        }
        parent {
          id
          internal
          default
          name_de
          name_en
        }
        conditions {
          __typename
          ... on Distribution {
            id
            name_de
            name_en
          }
          ... on Linking {
            id
            name_de
            name_en
          }
          ... on Modification {
            id
            name_de
            name_en
          }
          ... on Tivo {
            id
            name_de
            name_en
          }
        }
        conditions_OR {
          __typename
          ... on Distribution {
            id
            name_de
            name_en
          }
          ... on Linking {
            id
            name_de
            name_en
          }
          ... on Modification {
            id
            name_de
            name_en
          }
          ... on Tivo {
            id
            name_de
            name_en
          }
        }
      }
    }
  }
`;

/**
 * Get all existing conflict rules
 */
export const GET_ALL_CONFLICT_RULES = gql`
  query GetAllConflictRules($where: FeatureWhere, $options: FeatureOptions) {
    GetAllFeatures(where: $where, options: $options) {
      ConflictRule {
        id
        name_de
        name_en
        conditions {
          __typename
          ... on Distribution {
            id
            name_de
            name_en
          }
          ... on Linking {
            id
            name_de
            name_en
          }
          ... on Modification {
            id
            name_de
            name_en
          }
          ... on Tivo {
            id
            name_de
            name_en
          }
        }
        conditions_OR {
          __typename
          ... on Distribution {
            id
            name_de
            name_en
          }
          ... on Linking {
            id
            name_de
            name_en
          }
          ... on Modification {
            id
            name_de
            name_en
          }
          ... on Tivo {
            id
            name_de
            name_en
          }
        }
      }
    }
  }
`;

/**
 * Get all existing license conflict rules
 */
export const GET_ALL_LICENSE_CONFLICT_RULES = gql`
  query GetAllLicenseConflictRules($where: FeatureWhere, $options: FeatureOptions) {
    GetAllFeatures(where: $where, options: $options) {
      LicenseConflictRule {
        id
        name_de
        name_en
      }
    }
  }
`;

/**
 * Get all existing license features
 */
export const GET_ALL_LICENSE_FEATURES = gql`
  query GetAllLicenseFeatures($where: FeatureWhere, $options: FeatureOptions) {
    GetAllFeatures(where: $where, options: $options) {
      LicenseFeature {
        id
        name_de
        name_en
      }
    }
  }
`;

/**
 * Get all existing additional license features
 */
export const GET_ALL_ADDITIONAL_LICENSE_FEATURES = gql`
  query GetAllAdditionalLicenseFeatures($where: FeatureWhere, $options: FeatureOptions) {
    GetAllFeatures(where: $where, options: $options) {
      AdditionalLicenseFeature {
        id
        name_de
        name_en
      }
    }
  }
`;