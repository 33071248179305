import { gql } from '@apollo/client';

/**
 * @description fetching basic information about the files in the artifactory
 */
export const BROWSE_ARTIFACTORY = gql`
  query BrowseVersionArtifactory($versionId: ID!) {
    BrowseVersionArtifactory(versionId: $versionId) {
      status
      files {
        filename
        path
        created
        size
        downloadUri
        checksums {
          sha256
        }
        properties {
          type
        }
      }
    }
  }
`;

/**
 * @description fetching basic information about the latest disclaimer in the artifactory
 */
export const GET_LATEST_DISCLAIMER = gql`
  query GetLatestDisclaimerFile($versionId: ID!) {
    GetLatestDisclaimerFile(versionId: $versionId) {
      status
      file {
        filename
        path
        created
        size
        downloadUri
        checksums {
          sha256
        }
      }
    }
  }
`;

/**
 * @description fetching download count from artifactory
 */
export const GET_ADDITIONAL_DATA_FOR_FILE = gql`
  query GetAdditionalDataForFile($versionId: ID!, $filename: String!) {
    GetAdditionalDataForFile(versionId: $versionId, filename: $filename) {
      downloadCount
      info
      type
      userName
    }
  }
`;
