import { gql } from '@apollo/client';

/**
 * Get all Directories query
 */
const GET_ALL_DIRECTORIES = gql`
  query AllDirectories {
    Directory {
      id
      name
    }
  }
`;
export default GET_ALL_DIRECTORIES;
/**
 * Get all BrandDirectories for a certain User query
 */
export const GET_ALL_BRAND_DIRECTORIES_FOR_USER = gql`
  query GetBrandDirectoryAccess($userId: ID!) {
    GetBrandDirectoryAccess(userId: $userId) {
      directoryId
      directoryName
      directoryRoles
    }
  }
`;


/**
 * Get chosen brand directory with all subdirectories which contains brand OSO email
 */
export const GET_EMAILS_BY_BRAND = gql`
  query GetEmailsByBrand($versionId: ID!, $brand: BrandDirectory!, $productType: [ProductType!]) {
    GetEmailsByBrand(versionId: $versionId, brand: $brand, productType: $productType) {
      id
      name
      emailBrandOSO
    }
  }
`;
