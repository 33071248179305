import { DropdownItemProps } from 'semantic-ui-react';
import {
  DirectoryAccess,
  InputMaybe,
  Maybe,
  Role,
  UpdateUserMutationVariables,
} from '../../../../graphql/generated/graphql';
import { BuildDirectoryOptionsProps, OptionsArrayProps } from './types';

/**
 * getting directoryId from Dropdown options
 *
 * @param {DropdownItemProps[]} optionsArr Array of options
 * @param {string} optionValue Value of option
 *@returns {string} id of dropdown option
 */
const getIdFromKey = (
  optionsArr: DropdownItemProps[],
  optionValue: string
): string => {
  const option = optionsArr?.find(
    (dir: DropdownItemProps) => dir.value === optionValue
  );
  return option ? option.key : optionValue;
};
export default getIdFromKey;

/**
 * filtering dropdown options for Global Roles to extract chosen ones
 *
 * @param {OptionsArrayProps[]} optionsArr Array of options
 * @param {InputMaybe<Role | Role[]> | undefined} rolesArr array of roles for user
 *@returns {string} id of dropdown option
 */
export const filteringRolesDropdownOptions = (
  optionsArr: OptionsArrayProps[],
  rolesArr: InputMaybe<Role | Role[]> | undefined
): OptionsArrayProps[] => {
  const filtered = optionsArr.filter(
    (elem: OptionsArrayProps) => !rolesArr?.includes(elem.value as Role)
  );
  return filtered;
};
/**
 *
 *@returns {boolean} true if
 * @param {InputMaybe<Role | Role[]> | undefined} rolesArr array of roles for user
 * @param {string} where for which function/component is check needed
 */
export const checkingRolesForAccess = (
  rolesArr: InputMaybe<Role | Role[]> | undefined,
  where: string
): boolean => {
  if (
    where === 'access' &&
    (rolesArr?.includes(Role.legal) || rolesArr?.includes(Role.oso))
  ) {
    return true;
  }
  if (
    where === 'legal&oso' &&
    rolesArr?.includes(Role.legal) &&
    rolesArr?.includes(Role.oso)
  ) {
    return true;
  }
  if (
    where === 'onlyOso' &&
    rolesArr?.includes(Role.oso) &&
    !rolesArr?.includes(Role.legal)
  ) {
    return true;
  }
  if (
    where === 'onlyLegal' &&
    rolesArr?.includes(Role.legal) &&
    !rolesArr?.includes(Role.oso)
  ) {
    return true;
  }
  return false;
};
/**
 * @returns {Maybe<DirectoryAccess>[]} array of DirectoryAccess
 * @param {Maybe<DirectoryAccess>[]} brandArr array of brand directories
 * @param {DirectoryAccess[]} directoryArr array of actual state
 */
export const filteringOptions = (
  brandArr: Maybe<Maybe<DirectoryAccess>[]> | undefined,
  directoryArr: DirectoryAccess[]
): Maybe<DirectoryAccess>[] | null => {
  const filtered = brandArr?.filter(
    (elem: Maybe<DirectoryAccess>) =>
      !directoryArr.find(({ directoryId }) => elem?.directoryId === directoryId)
  );
  return filtered || null;
};

/**
 * @returns {boolean} whether is comparison true or false
 * @param {Maybe<DirectoryAccess>[]} arr array of brand directories
 * @param {DirectoryAccess[]} dirArr array of actual state
 */
export const noticeWhenEmptyOptions = (
  arr: Maybe<Maybe<DirectoryAccess>[]> | undefined,
  dirArr: DirectoryAccess[]
): boolean => {
  if (filteringOptions(arr, dirArr) === null) {
    return true;
  }
  if (filteringOptions(arr, dirArr)?.length === 0) {
    return true;
  }
  return false;
};

/**
 *setting dropdown-options array from brand directories
 *
 *@returns {BuildDirectoryOptionsProps} options array
 *@param {Maybe<Array<Maybe<DirectoryAccess>>>} array array of directories
 */
export const buildOptions = (
  array: Maybe<Array<Maybe<DirectoryAccess>>>
): BuildDirectoryOptionsProps => {
  if (array !== null && array.length > 0) {
    const accessOptions = array?.map((dir: Maybe<DirectoryAccess>) => {
      if (dir !== null) {
        return {
          key: dir.directoryId,
          text: dir.directoryName,
          value: dir.directoryName,
          icon: { name: 'checkmark', color: 'green' },
        };
      }
      // TODO: What is needed in case of null?
      return {
        key: '',
        text: '',
        value: '',
        icon: { name: 'checkmark', color: 'green' },
      };
    });

    return accessOptions;
  }
  return null;
};
/**
 * @returns {boolean} check if email is valid
 * @param {InputMaybe<string> | undefined} email email
 */
export const isValidEmail = (
  email: InputMaybe<string> | undefined
): boolean => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (email && emailPattern.test(email.trim())) {
    return true;
  }
  return false;
};
/**
 * @returns {boolean} check if name is valid
 * @param {InputMaybe<string> | undefined} name name
 */
export const isValidName = (name: InputMaybe<string> | undefined): boolean => {
  const namePattern = /^(?!.*\s{2,})[\p{L}\p{M}0-9'\s-]+$/u;
  if (name) {
    const trimmedName = name.trim();
    if (namePattern.test(trimmedName) && trimmedName.length > 0) {
      return true;
    }
  }
  return false;
};

/**
 *@param {string[]} arr1 first array
 *@param {string[]} arr2 first array
 * @returns {boolean} boolean value
 */
export const areArraysEqualIgnoringOrder = (
  arr1: DirectoryAccess[] | string[],
  arr2: DirectoryAccess[] | string[]
): boolean => {
  if (arr1.length !== arr2?.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return (
    sortedArr1.length === sortedArr2.length &&
    sortedArr1.every((element, index) => element === sortedArr2[index])
  );
};

/**
 *@param {string[]} arr1 first array- Original from DB
 *@param {string[]} arr2 first array - current changeable
 * @returns {boolean} boolean value
 */
export const areArraysOfObjectsEqual = (
  arr1: DirectoryAccess[],
  arr2: DirectoryAccess[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((obj1, index) => {
    const obj2 = arr2[index];

    return (
      obj1.directoryId === obj2.directoryId &&
      obj1.directoryName === obj2.directoryName &&
      areArraysEqualIgnoringOrder(obj1.directoryRoles, obj2.directoryRoles)
    );
  });
};

/**
 * @param {UpdateUserMutationVariables} userDetails current object under edit
 * @param {UpdateUserMutationVariables} userDetailsObj default object
 * @returns {boolean} boolean
 */
export const compareObjects = (
  userDetails: UpdateUserMutationVariables,
  userDetailsObj: UpdateUserMutationVariables
): boolean => {
  const keys1 = Object.keys(userDetails) as Array<keyof typeof userDetails>;
  const keys2 = Object.keys(userDetailsObj) as Array<
    keyof typeof userDetailsObj
  >;

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => {
    const value1 = userDetails[key];
    const value2 = userDetailsObj[key];

    if (value1 === undefined || value2 === undefined) {
      return false;
    }
    // regardless of order of strings in arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return areArraysEqualIgnoringOrder(value1, value2);
    }

    return value1 === value2;
  });
};
