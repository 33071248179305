import { gql } from '@apollo/client';

/**
 * get all linking objects
 */
const LINKING_LIST = gql`
  query LinkingList($where: LinkingWhere, $options: LinkingOptions) {
    Linking(where: $where, options: $options) {
      id
      name_en
    }
  }
`;

export default LINKING_LIST;
