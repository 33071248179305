import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
  DependencySubComponentsRelationship,
  Subcomponent,
} from '../../../../../../../graphql/generated/graphql';
import {
  GroupedSubcomponent,
  getDisplayedSubcomponent,
  groupSubComponents,
} from '../../helper/licenseElementHelpers';
import OrLicensesPopup from './LicensesPopup';
import UnverifiedIcon from './UnverifiedIcon';
import LicenseElementModal from './LicenseModalComponents/LicenseElementModal';

type PropsType = {
  subComponents: Array<Subcomponent>;
  dependencyId: string;
  subComponentEdges: Array<DependencySubComponentsRelationship>;
};

/**
 * returns a span with the name of the first license followed by the amount of the rest
 * nothing too fancy
 *
 * @param {PropsType} props takes in licenses
 * @returns {JSX.Element} a span with license names and icon with amount
 */
const LicensesElement = (props: PropsType): JSX.Element => {
  const { subComponents, subComponentEdges, dependencyId } = props;
  const [text] = useTranslation('productDetails');
  const [openLicensesModal, setOpenLicensesModal] = useState(false);
  const [selectedSubComponentName, setSelectedSubComponentName] = useState('');
  const [selectedSubComponent, setSelectedSubComponent] =
    useState<GroupedSubcomponent>();

  const groupedSubcomponents = groupSubComponents(
    subComponents,
    subComponentEdges
  );

  const displayedSubcomponent = getDisplayedSubcomponent(groupedSubcomponents);

  const hasGroupsWithGroupId = Object.keys(groupedSubcomponents).some(
    (key) => key !== 'noGroupId'
  );

  const handleLicenseClick = (groupedSubcomponent: GroupedSubcomponent) => {
    setSelectedSubComponentName(
      groupedSubcomponent.subComponentEdge.subComponentName || ''
    );
    setOpenLicensesModal(true);
  };

  useEffect(() => {
    const allSubcomponents = Object.values(groupedSubcomponents).flat();
    const subComponent = allSubcomponents.find(
      (groupedSubcomponent) =>
        groupedSubcomponent.subComponentEdge.subComponentName ===
        selectedSubComponentName
    );
    setSelectedSubComponent(subComponent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(groupedSubcomponents), selectedSubComponentName]);

  return (
    <>
      {openLicensesModal && selectedSubComponent && (
        <LicenseElementModal
          openLicensesModal={openLicensesModal}
          setOpenLicensesModal={setOpenLicensesModal}
          dependencyId={dependencyId}
          subComponent={selectedSubComponent.subComponent}
          subComponentEdge={selectedSubComponent.subComponentEdge}
        />
      )}
      <div className="licenses-table-cell">
        {subComponents && subComponents.length > 0 && (
          <Item.Extra onClick={() => handleLicenseClick(displayedSubcomponent)}>
            <div className="clickable-license">
              {displayedSubcomponent?.subComponentEdge.spdxId}
            </div>
            <UnverifiedIcon
              verified={displayedSubcomponent?.subComponentEdge.verified}
            />
          </Item.Extra>
        )}
        {hasGroupsWithGroupId && (
          <OrLicensesPopup
            groupedSubcomponents={groupedSubcomponents}
            handleLicenseClick={handleLicenseClick}
          />
        )}
        {subComponents && subComponents.length > 1 && (
          <OrLicensesPopup
            groupedSubcomponents={groupedSubcomponents}
            handleLicenseClick={handleLicenseClick}
          />
        )}
        {(!subComponents || subComponents.length === 0) && (
          <div className="license-names">
            {text('dependencyList.tableBody.noInfo')}
          </div>
        )}
      </div>
    </>
  );
};

export default LicensesElement;
