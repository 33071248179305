import { DocumentNode, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Icon,
  Item,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { PRODUCT_DETAILS_CONTACTS } from '../../../../graphql/queries/VersionQuerys';
import { Contact, Department } from './helper/types';

interface Props {
  department: Department;
  mutation: DocumentNode;
  options: DropdownItemProps[] | undefined;
  reviewer?: Contact | null | undefined;
  trigger?: JSX.Element;
}

/**
 * Updates OSO or Legal Reviewer
 * Options filter out the selected User.
 * If you have not selected anyone and no one is already saved, mutation will not be fired.
 *
 * @param {object} props Team and Department
 * @returns {JSX.Element} Modal with the full list of developers
 */
const UpdateReviewerModal = (props: Props): JSX.Element => {
  const { department, mutation, options, reviewer, trigger } = props;
  const [t] = useTranslation(['users']);
  const [open, setOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const [selectedReviewer, setSelectedReviewer] = useState<
    string | undefined | null
  >(reviewer?.id);
  const { versionId } = useContext(ProductDetailsContext);

  useEffect(() => {
    if (reviewer?.id === undefined && selectedReviewer === null) {
      setChanged(false);
    } else if (reviewer?.id !== selectedReviewer) {
      setChanged(true);
    }
  }, [reviewer?.id, selectedReviewer]);

  const [updateMutation] = useMutation(mutation, {
    onCompleted: () => {
      setOpen(false);
    },
    refetchQueries: [PRODUCT_DETAILS_CONTACTS],
  });

  const selectReviewer = (userId: string) => {
    setSelectedReviewer(userId);
  };

  const updateReviewer = () => {
    if (department === Department.OSO) {
      updateMutation({
        variables: {
          versionId,
          osoId: selectedReviewer,
        },
      });
    }
    if (department === Department.LEGAL) {
      updateMutation({
        variables: {
          versionId,
          legalId: selectedReviewer,
        },
      });
    }
    setChanged(false);
  };
  const closeModal = () => {
    setOpen(false);
    setChanged(false);
    setSelectedReviewer(reviewer?.id);
  };

  return (
    <Modal
      size="tiny"
      id="UpdateReviewerModal"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeOnDimmerClick={false}
      trigger={trigger}
    >
      <Modal.Header>
        {t(`department.${department}`)}
        <Icon
          id="CloseButton"
          name="close"
          link
          fitted
          floated="right"
          onClick={() => closeModal()}
        />
      </Modal.Header>
      {selectedReviewer && (
        <Modal.Content>
          <Segment id="UserSegment">
            <Item
              id="SelectedReviewerItem"
              header={
                options?.find((option) => option.value === selectedReviewer)
                  ?.text
              }
              meta={
                options?.find((option) => option.value === selectedReviewer)
                  ?.email
              }
            />
            <Icon
              id="RemoveButton"
              name="trash alternate outline"
              color="red"
              link
              size="large"
              onClick={() => setSelectedReviewer(null)}
            />
          </Segment>
        </Modal.Content>
      )}
      <Modal.Content>
        <Dropdown
          id="SelectReviewerDropdown"
          placeholder={t('modal.dropdownPlaceholder')}
          fluid
          search
          selection
          options={options?.filter(
            (option) => selectedReviewer !== (option.value as string)
          )}
          value=""
          onChange={(_, { value }) => {
            selectReviewer(value as string);
          }}
          selectOnBlur={false}
        />
      </Modal.Content>
      <Modal.Actions style={{ textAlign: 'center' }}>
        <Button
          primary
          disabled={!changed}
          id="SaveButton"
          content={t('apply', {
            ns: 'buttons',
          })}
          onClick={() => {
            updateReviewer();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UpdateReviewerModal;
