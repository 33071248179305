import { useMutation } from '@apollo/client';
import {
  CREATE_LEGAL_DEPENDENCY_RESULT,
  CREATE_LEGAL_VERSION_RESULT,
} from '../../../../../../../../../graphql/mutations/ProductMutations';
import {
  COMPLETION_POSSIBLE,
  PRODUCT_TRANSFER_DATA,
} from '../../../../../../../../../graphql/queries/VersionQuerys';
import { ResultNode } from './types';

interface Variables {
  versionId: string;
  featureId: string;
  status: string;
  note: string;
}

/**
 * Custom hook to handle creating/updating legal results for different features
 *
 * @returns {Promise} Returns object
 */
const useLegalResultQueries = () => {
  const checkIfNoteEmpty = (newNote: string): string => {
    return newNote.trim() && newNote.length > 0 ? newNote : '';
  };
  const [updateLegalDependencyResult] = useMutation(
    CREATE_LEGAL_DEPENDENCY_RESULT,
    {
      refetchQueries: ['DependenciesByVersionId'],
    }
  );
  const [updateLegalVersionResult] = useMutation(CREATE_LEGAL_VERSION_RESULT, {
    refetchQueries: [PRODUCT_TRANSFER_DATA, COMPLETION_POSSIBLE],
  });

  const updateResult = (variables: Variables, resultNode: ResultNode) => {
    const { versionId, featureId, status, note } = variables;
    if (resultNode === 'Dependency') {
      return updateLegalDependencyResult({
        variables: {
          versionId,
          dependencyId: featureId,
          status,
          note: checkIfNoteEmpty(note),
        },
      });
    }
    if (resultNode === 'Version') {
      return updateLegalVersionResult({
        variables: { versionId, status, note: checkIfNoteEmpty(note) },
      });
    }
    return null;
  };

  return { updateResult };
};

export default useLegalResultQueries;
