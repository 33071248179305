import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Item } from 'semantic-ui-react';
import { Comment } from '../../../../graphql/generated/graphql';
import CommentsModalWindow from './CommentsModalWindow';
import { CommentsModalContext } from '../requirements/helper/Constants';
import { OpenCommentsModalState } from './helper/types';

type PropsType = {
  comments?: Comment[];
  dependName: string;
  refersToId: string;
};

/**
 * returns element with comments refering to clicked element(dependency or requirement...)
 *
 * @param {PropsType} props takes in product/version
 * @returns {JSX.Element} a span with text
 */
const Comments = (props: PropsType): JSX.Element => {
  const [t] = useTranslation('comments');
  const { comments, dependName, refersToId } = props;
  const [openCommentsModal, setOpenCommentsModal] =
    useState<OpenCommentsModalState>([false, null]);
  // const [commentType, setCommentType] = useState('' as CommentType);
  // const commentContextValue = useMemo(
  //   () => ({ commentType, setCommentType }),
  //   [commentType]
  // );

  const ifPlural = (number: number) => {
    if (number > 1) {
      return t('manyComments');
    }
    return t('aComment');
  };

  return (
    <>
      <CommentsModalWindow
        openCommentsModal={openCommentsModal}
        setOpenCommentsModal={setOpenCommentsModal}
        elementName={dependName}
        comments={comments}
        refersToId={refersToId}
      />
      {comments && comments.length > 0 && (
        <span className="ShowCommentsLink">
          <Item.Extra
            id="CommentsOpenModal"
            content={`${comments.length} ${ifPlural(comments.length)}`}
            onClick={() =>
              setOpenCommentsModal([true, CommentsModalContext.Comments])
            }
          />
        </span>
      )}
      {(!comments || comments.length === 0) && (
        <span className="ShowAddCommentsBtn">
          <Icon
            id="LicensesElementModalCopyrightsInputIcon"
            name="add circle"
            color="blue"
            size="large"
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenCommentsModal([true, null])}
          />
          <span>{t('addComment')}</span>
        </span>
      )}
    </>
  );
};

export default Comments;
