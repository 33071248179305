/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Segment, SegmentProps } from 'semantic-ui-react';

interface ConditionalSegmentProps extends SegmentProps {
  visible?: boolean;
  mustHaveGrandchildren?: boolean;
  children: React.ReactNode;
}

/**
 * Conditional Segment it either renders or not
 *
 * @param {ConditionalSegmentProps} props visible: boolean.
 * @returns {JSX.Element} segment or nothin
 */
const ConditionalSegment = (
  props: ConditionalSegmentProps
): JSX.Element | null => {
  const { visible = true, children, mustHaveGrandchildren, ...rest } = props;
  const countDescendants = (childs: React.ReactNode): number => {
    let count = 0;
    React.Children.forEach(childs, (child) => {
      count += 1;
      if (React.isValidElement(child) && child.props.children) {
        count += countDescendants(child.props.children);
      }
    });
    return count;
  };

  const hasChildren = React.Children.count(children) > 0;
  const hasGrandchildren =
    countDescendants(children) > React.Children.count(children);

  if (!hasChildren || (mustHaveGrandchildren && !hasGrandchildren)) {
    return null;
  }

  if (!visible) {
    return <>{children}</>;
  }

  return (
    <Segment id="ConditionalSegment" {...rest}>
      {children}
    </Segment>
  );
};

export default ConditionalSegment;
