/* eslint-disable @typescript-eslint/no-empty-function */
import { FetchResult } from '@apollo/client';
import React, { createContext, Dispatch, SetStateAction } from 'react';
import {
  DirectoryRole,
  ProductInfoBoxQuery,
  ProjectInfoBoxQuery,
  UpdateVersionInput,
  UpdateVersionMutation,
  UpdateVersionMutationResult,
} from '../graphql/generated/graphql';

/**
 * ProductDetailsContext
 * provides the ProductDetails of the currently selected Product Version
 */
export const ProductDetailsContext = React.createContext<{
  versionId: string;
  productId: string;
  isProject: boolean;
  finishedImport: boolean;
  accessRoles: DirectoryRole[];
  authorizations: string[];
  processStatusAsString: string;
  tags: string[];
  inLegalCheckOrDone: boolean;
  isGroupTransfer: boolean;
}>({
  versionId: '',
  productId: '',
  isProject: false,
  finishedImport: false,
  accessRoles: [],
  authorizations: [],
  processStatusAsString: 'INCREATION',
  tags: [],
  inLegalCheckOrDone: false,
  isGroupTransfer: false,
});

/**
 * ProductInfoBoxContext
 * provides the ProductInfoBox data
 */
export const ProductInfoBoxContext = createContext<{
  editing: boolean;
  version: ProductInfoBoxQuery['Version'] | ProjectInfoBoxQuery['Version'];
  versionInput: UpdateVersionInput;
  setEditing: Dispatch<SetStateAction<boolean>>;
  setVersionInput: Dispatch<SetStateAction<UpdateVersionInput>>;
  setPartialVersionInput: Dispatch<SetStateAction<UpdateVersionInput>>;
  updateVersionMutation: () => Promise<FetchResult<UpdateVersionMutation>>;
  updateVersionMutationResult: UpdateVersionMutationResult;
}>({
  editing: false,
  version: {} as ProductInfoBoxQuery['Version'],
  versionInput: {} as UpdateVersionInput,
  setEditing: () => {},
  setVersionInput: () => {},
  setPartialVersionInput: () => {},
  updateVersionMutation: () =>
    Promise.resolve({} as UpdateVersionMutationResult),
  updateVersionMutationResult: {} as UpdateVersionMutationResult,
});
