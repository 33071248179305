import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import { SelectedRoleContext } from '../../../../context/UserContext';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import { Role, SelectionType } from '../../../../graphql/generated/graphql';

interface CheckboxProps {
  selectionType: SelectionType;
  onToggle: () => void;
  isDisabled?: boolean;
}

/**
 * Checkbox with 2 options , either checked/solved or empty/unchecked. Is disabled when sibling is selected.
 *
 * @param {SelectionType} selectType Selection type or if toDo is solved or not.
 *@returns {JSX.Element} Checkbox that toggles : selected, autoselected or unselected for TODOs/TASKs
 */
const ToDoCheckboxToggle = ({
  selectionType,
  onToggle,
  isDisabled,
}: CheckboxProps): JSX.Element => {
  // get authorizations from context
  const { authorizations } = useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;

  const isChecked =
    selectionType === SelectionType.autoselected ||
    selectionType === SelectionType.userselected;

  const hasPermission =
    authorizations?.includes('SelectToDo') &&
    (role?.includes('developer') || role?.includes('supplier'));

  return (
    <>
      {/* div prevents layout shift */}
      <div
        style={{
          width: '24px',
          height: '24px',
          display: 'inline-block',
          verticalAlign: 'middle',
          paddingLeft: '5px',
        }}
      >
        <Icon
          style={{ verticalAlign: 'middle' }}
          link
          id="TodoCheckbox"
          className="todo-checkbox"
          name={isChecked ? 'check square' : 'square outline'}
          color="blue"
          disabled={!hasPermission || isDisabled}
          onClick={!isDisabled ? onToggle : undefined}
        />
      </div>
    </>
  );
};

export default ToDoCheckboxToggle;
