import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Icon } from 'semantic-ui-react';

interface EditUserDetailsProps {
  onClick: MouseEventHandler;
}

/**
 * Edit Menu Item for Editing User Profile.
 *
 * @returns {JSX.Element} Edit Menu Item element
 * @param {EditUserDetailsProps} setOpen Modal props
 */
const EditUserDetailsMenuItem = ({
  onClick,
}: EditUserDetailsProps): JSX.Element => {
  const { t } = useTranslation('buttons');
  return (
    <Menu.Item id="EditUserDetailsMenuItem" onClick={onClick}>
      {t('editUser')}
      <Icon id="UserMenu.IconPencil" name="pencil alternate" />
    </Menu.Item>
  );
};

export default EditUserDetailsMenuItem;
