import { FeedLabelProps } from 'semantic-ui-react';
import {
  BrandDirectory,
  DirectoryRole,
  Role,
  UserStatus,
} from '../../../../graphql/generated/graphql';

/**
 * Roles selection and render as labels in Dropdown
 * Used for UserDetails.tsx and AddUser.tsx
 * 'role.' is added cause of user.json property "role":{...}
 */
export const RoleOptions = [
  { key: 1, text: `role.${Role.admin}`, value: Role.admin },
  { key: 2, text: `role.${Role.developer}`, value: Role.developer },
  { key: 3, text: `role.${Role.oso}`, value: Role.oso },
  { key: 4, text: `role.${Role.supplier}`, value: Role.supplier },
  { key: 5, text: `role.${Role.legal}`, value: Role.legal },
  { key: 6, text: `role.${Role.externlegal}`, value: Role.externlegal },
  { key: 7, text: `role.${Role.rulesetlegal}`, value: Role.rulesetlegal },
  { key: 8, text: `role.${Role.ososervices}`, value: Role.ososervices },
  { key: 9, text: `role.${Role.brandlegal}`, value: Role.brandlegal },
];

/**
 * Brand Directory selection in Dropdown
 * Used for UserDetails.tsx and AddUser.tsx
 */
export const BrandOptions = [
  { key: 1, text: `brand.${BrandDirectory.Audi}`, value: BrandDirectory.Audi },
  { key: 2, text: `brand.${BrandDirectory.VW}`, value: BrandDirectory.VW },
  {
    key: 3,
    text: `brand.${BrandDirectory.ABI}`,
    value: BrandDirectory.ABI,
  },
  {
    key: 4,
    text: `brand.${BrandDirectory.NoBrand}`,
    value: BrandDirectory.NoBrand,
  },
];
/**
 * User Status selection as Dropdown
 */
export const StatusOptions = [
  { key: 1, text: UserStatus.active, value: UserStatus.active },
  { key: 2, text: UserStatus.inactive, value: UserStatus.inactive },
];
/**
 * Label renderer for the options in Dropdown
 *
 * @param {string} label TS Typeof String
 * @param {string} label.text text beeing rendered from Options
 * @returns {FeedLabelProps} labels the text in the dropdown
 */
export const renderLabels = (label: { text: string }): FeedLabelProps => ({
  color: 'blue',
  content: `${label.text}`,
  icon: 'check',
});

/**
 *
 */
export const AccessRoles = [
  {
    key: DirectoryRole.oso,
    text: DirectoryRole.oso,
    value: DirectoryRole.oso,
  },
  {
    key: DirectoryRole.legal,
    text: DirectoryRole.legal,
    value: DirectoryRole.legal,
  },
];
