import { useMutation } from '@apollo/client/react/hooks';
import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../context/ProductContext';
import { useDownloadFromArtifactoryMutation } from '../../../../../../../graphql/generated/graphql';
import { REMOVE_FROM_ARTIFACTORY } from '../../../../../../../graphql/mutations/ArtifactoryMutations';
import { BROWSE_ARTIFACTORY } from '../../../../../../../graphql/queries/ArtifactoryQuerys';
import {
  LEGAL_CHECK_DONE_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  SUPPLIER_INPUT_DONE_POSSIBLE,
} from '../../../../../../../graphql/queries/VersionQuerys';

type PropsType = {
  url: string;
  filename: string;
  fileType: string;
  versionId: string;
  refetchAdditionalData: (
    variables?:
      | Partial<{
          versionId: string;
          filename: string;
        }>
      | undefined
  ) => void;
};

/**
 *
 * @param {object} props contains url and versionId.
 * @returns {JSX.Element} ActionsElement.
 */
const ActionsElement = (props: PropsType) => {
  const { versionId, url, filename, fileType, refetchAdditionalData } = props;
  const { authorizations } = useContext(ProductDetailsContext);
  const mayDelete = authorizations.includes('RemoveFromArtifactory');
  const isNonRemovable = fileType === 'import';

  const [downloadFromArtifactory, { data: downloadData }] =
    useDownloadFromArtifactoryMutation({
      variables: { versionId, url },
      onCompleted: () => {
        const extension = filename.slice(filename.lastIndexOf('.') + 1);
        const mimetype = extension === 'pdf' ? 'application/pdf' : 'text/plain';
        const link = document.createElement('a');
        link.href = `data:${mimetype};base64,${downloadData?.DownloadFromArtifactory}`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      },
    });

  const [removeFromArtifactory] = useMutation(REMOVE_FROM_ARTIFACTORY, {
    refetchQueries: [
      BROWSE_ARTIFACTORY,
      LEGAL_CHECK_POSSIBLE,
      LEGAL_CHECK_DONE_POSSIBLE,
      SUPPLIER_INPUT_DONE_POSSIBLE,
    ],
    variables: { versionId, url, fileType },
  });

  return (
    <span>
      {isNonRemovable ? (
        <Icon id="PlacholderForNotRemovable" style={{ visibility: 'hidden' }} />
      ) : (
        <Icon
          id="removeFromArtifactory"
          className="warning"
          name="trash alternate outline"
          link
          disabled={!url || !mayDelete}
          onClick={() => {
            removeFromArtifactory();
          }}
        />
      )}
      <Icon
        id="downloadFromArtifactory"
        className="primary"
        link
        name="download"
        onClick={() => {
          downloadFromArtifactory();
          refetchAdditionalData({
            versionId,
            filename,
          });
        }}
      />
    </span>
  );
};

export default ActionsElement;
