import { DropdownItemProps, Item } from 'semantic-ui-react';
import React from 'react';
import { DirectoryUser, Role } from '../../../../../graphql/generated/graphql';
import { ContactOption } from './types';

/**
 *@returns {DropdownItemProps[] | undefined} dropdown items
 *@param {Array<DirectoryUser>} array arr of potential reviewer
 */
export const reviewerOptions = (
  array: Array<DirectoryUser | null>
): DropdownItemProps[] | undefined => {
  const nonNullUsers = array.filter((user) => user !== null) as DirectoryUser[];
  return nonNullUsers.map((user: DirectoryUser) => ({
    key: user.id,
    text: user.name,
    email: user.email,
    value: user.id,
    content: (
      <Item id="SelectReviewer" header={user?.name} meta={user?.email} />
    ),
  }));
};

/**
 * @param {ContactOption[]} users array of users
 * @returns {DropdownItemProps[] | undefined} dropdown items
 */
export const guestOptions = (
  users: ContactOption[]
): DropdownItemProps[] | undefined =>
  users
    .filter((user) => !user?.roles.includes(Role.admin))
    ?.map((user) => ({
      key: user?.id,
      text: user?.name,
      value: user?.id,
      email: user?.email,
      content: (
        <Item id="AddGuestContact" header={user?.name} meta={user?.email} />
      ),
    }));

/**
 * @returns {DropdownItemProps[] | undefined} dropdown items
 * @param {Role} role user role to filter
 * @param {ContactOption[]} users array of users
 */
export const optionsByRole = (
  role: Role,
  users: ContactOption[]
): DropdownItemProps[] | undefined =>
  users
    .filter((user) => user?.roles.includes(role))
    ?.map((user) => ({
      key: user?.id,
      text: user?.name,
      value: user?.id,
      email: user?.email,
      content: <Item id="AddContact" header={user?.name} meta={user?.email} />,
    }));
