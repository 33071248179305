import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';
import * as client from '@apollo/client';
import TermsOfUse, { lastUpdatedOn } from './TermsOfUse';
import { USER_LOGIN } from '../../graphql/mutations/UserMutations';

type TermsProps = {
  email: string;
  password: string;
  openTermsModal: boolean;
  setOpenTermsModal: (openTermsModal: boolean) => void;
};

/**
 * @param {TermsProps} props Props
 *@returns {JSX.Element} Terms of use modal with a document which is to be read and confiremed clicking the Accepted Terms Button
 */
const TermsOfUseModal = (props: TermsProps): JSX.Element => {
  const { email, openTermsModal, setOpenTermsModal, password } = props;

  const [t] = useTranslation('imprintAndLegal');
  const [cookies, setCookie] = useCookies(['terms']);
  const [login, { data }] = client.useMutation(USER_LOGIN);

  if (data) {
    localStorage.setItem('token', data.Login);
    localStorage.removeItem('expired');
    window.location.assign(localStorage.lastUrl || '/');
  }

  const handleCheckReading = () => {
    setCookie(
      'terms',
      {
        user: [...(cookies?.terms?.user || []), `${email} - ${lastUpdatedOn}`],
      },
      {
        path: '/',
        // 3 years in milliseconds but KONVENTION says 400days MAX-meaning it converts to max 400days in Chrome & Edge browsers
        expires: new Date(Date.now() + 94608000000),
      }
    );
    setOpenTermsModal(false);
    login({ variables: { email, password } });
  };

  return (
    <Modal
      id="TermsOfUseModal"
      onClose={() => setOpenTermsModal(false)}
      onOpen={() => setOpenTermsModal(true)}
      open={openTermsModal}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Content>
        <TermsOfUse />
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="TermsOfUseModalConfirmBtn"
          basic
          primary
          onClick={() => handleCheckReading()}
        >
          <Icon id="TermsOfUseModalBtnIcon" name="check" color="green" />
          {t('checkboxText')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TermsOfUseModal;
