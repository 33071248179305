/*eslint-disable*/
import { ApolloError } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

type PropsType = {
  loading: boolean;
  error: ApolloError | undefined;
  id: string;
  searchString?: string | undefined;
  onSearchChange: (searchInput: string) => void;
};

/**
 * Search Field
 * enables the user to search the associated list
 *
 * @param {PropsType} props takes in loading and error status as
 * well as event function (onSearchChanged) and component name
 * @returns {JSX.Element} Search Field
 */
const Search = (props: PropsType): JSX.Element => {
  const { loading, error, id, onSearchChange, searchString } = props;
  const { t } = useTranslation('dashboard');

  const isFromOnChangeRef = useRef(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const timeoutIDRef = useRef<number | null>(null);

  useEffect(() => {
    // Only update the input value if the searchString is NOT coming from an onChange event (most likely coming from DependencyTreeModal)
    // TODO: weird temporary mechanism, should be improved
    if (
      !isFromOnChangeRef.current &&
      wrapperRef.current &&
      searchString !== undefined
    ) {
      const inputElement = wrapperRef.current.querySelector('input');
      if (inputElement) {
        inputElement.value = searchString;
      }
    }
  }, [searchString]);

  const searchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    isFromOnChangeRef.current = true;
    const inputValue = event.target.value.trim();

    if (timeoutIDRef.current !== null) {
      window.clearTimeout(timeoutIDRef.current);
    }
    timeoutIDRef.current = window.setTimeout(() => {
      onSearchChange(inputValue);
      isFromOnChangeRef.current = false;
    }, 500);
  };

  return (
    <div ref={wrapperRef}>
      <Input
        id={id}
        icon="search"
        fluid
        defaultValue={searchString}
        placeholder={t('searchPlaceholder')}
        onChange={(e) => searchChanged(e)}
        disabled={loading || !!error}
      />
    </div>
  );
};

export default Search;
