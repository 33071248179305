import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Card, Header, Popup } from 'semantic-ui-react';
import CopyIdentifierToClipboard from './CopyIdentifierToClipboard';
import GroupTakeOverEditingModal from './GroupTakeOverEditingModal';
import { Product, Version } from '../../../../../graphql/generated/graphql';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import GroupTakeOverStatusCard from './GroupTakeOverStatusCard';

type Props = {
  versionProduct: Product;
  readOnly?: boolean;
  version: Version;
};

/**
 *
 *@param {string} versionProduct Object of props to be passed on to the component
 *@returns {JSX.Element} Group Takeover element that will display when the group takover exists and will replace dependency list element!
 */
const GroupTakeoverElement = ({
  versionProduct,
  version,
  readOnly,
}: Props): JSX.Element => {
  const { authorizations } = useContext(ProductDetailsContext);
  const [t] = useTranslation(['productDetails']);
  const [openEdit, setOpenEdit] = useState<[boolean, string]>([false, '']);

  return (
    <>
      <GroupTakeOverEditingModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        versionProduct={versionProduct}
        versionId={version.id}
      />
      <GroupTakeOverStatusCard
        overallResultStatus={version.overallResult.status}
        overallResultNote={version.overallResult?.note}
        productName={versionProduct.name}
        versionId={version.id}
        versionName={version.name}
      />
      <Card.Group stackable className="GroupTakeoverElementContainer">
        <Card id="GroupTakeoverElementCompany">
          <Icon
            size="large"
            name="building outline"
            id="GroupTakeoverElementCompanyIcon"
          />
          <div style={{ margin: '1rem' }}>
            <Header as="h4" style={{ margin: '0' }}>
              {versionProduct?.transfer.groupCompany}
            </Header>
            <Card.Meta>{t('transferGroupCompany')}</Card.Meta>
          </div>
          {!readOnly && (
            <Popup
              id="GroupTakeoverElementEditPopup1"
              disabled={authorizations.includes('UpdateVersion')}
              content={t('noChangesAuthorized')}
              trigger={
                <Icon
                  id="GroupTakeoverElementCompanyEditButton"
                  name="pencil"
                  disabled={!authorizations.includes('UpdateVersion')}
                  color="blue"
                  size="large"
                  style={{ margin: '1rem 1rem 1rem auto', cursor: 'pointer' }}
                  onClick={() => setOpenEdit([true, 'transferGroupCompany'])}
                />
              }
            />
          )}
        </Card>
        <Card id="GroupTakeoverElementTransferContact">
          <Icon
            size="large"
            name="user"
            id="GroupTakeoverElementTransferContactIcon"
          />
          <div style={{ margin: '1rem' }}>
            <Header as="h4" style={{ margin: '0' }}>
              {versionProduct?.transfer.contactPerson}
            </Header>
            <Card.Meta>{t('transferContactPerson')}</Card.Meta>
          </div>
          {!readOnly && (
            <Popup
              id="GroupTakeoverElementEditPopup2"
              disabled={authorizations.includes('UpdateVersion')}
              content={t('noChangesAuthorized')}
              trigger={
                <Icon
                  id="GroupTakeoverElementTransferContactPersonEditButton"
                  name="pencil"
                  disabled={!authorizations.includes('UpdateVersion')}
                  color="blue"
                  size="large"
                  style={{ margin: '1rem 1rem 1rem auto', cursor: 'pointer' }}
                  onClick={() => setOpenEdit([true, 'transferContactPerson'])}
                />
              }
            />
          )}
        </Card>
        <Card id="GroupTakeoverElementIdentifier">
          <Icon
            size="large"
            name="500px"
            id="GroupTakeoverElementIdentifierIcon"
          />
          <div style={{ margin: '1rem' }}>
            <Header as="h4" style={{ margin: '0' }}>
              {versionProduct?.transfer.externalId}
            </Header>
            <Card.Meta>{t('transferExternalId')}</Card.Meta>
          </div>
          <CopyIdentifierToClipboard
            externalId={versionProduct?.transfer.externalId}
          />
          {!readOnly && (
            <Popup
              id="GroupTakeoverElementEditPopup3"
              disabled={authorizations.includes('UpdateVersion')}
              content={t('noChangesAuthorized')}
              trigger={
                <Icon
                  id="GroupTakeoverElementIdentifierEditButton"
                  name="pencil"
                  disabled={!authorizations.includes('UpdateVersion')}
                  color="blue"
                  size="large"
                  style={{ margin: '1rem 1rem 1rem auto', cursor: 'pointer' }}
                  onClick={() => setOpenEdit([true, 'transferExternalId'])}
                />
              }
            />
          )}
        </Card>
      </Card.Group>
    </>
  );
};

export default GroupTakeoverElement;
