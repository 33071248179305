import React, { useContext, useEffect } from 'react';
import { Grid, Item, Card } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import WizardContext from '../../../context/WizardContext';

import {
  ProductType,
  CreateProductInput,
} from '../../../graphql/generated/graphql';
import { subTypeCar, subTypeEnterprise } from '../WizardConstants';

/**
 * Wizard Product creation -Stef One
 *
 *@returns {JSX.Element} First step in creating a Product is its Type slection.
 */
const WizardProductTypeSelection = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const {
    setStepComplete,
    setWizardInput,
    wizardInput,
    type,
    setType,
    stepComplete,
  } = useContext(WizardContext);

  const selected = {
    backgroundColor: '#0f97c7',
    color: 'white',
    maxWidth: '400px',
  };
  const initStyle = {
    backgroundColor: 'lightgrey',
    maxWidth: '400px',
  };
  // setting stepComplete here so that it checks value on pressing back
  // state in useContext- [type, setType] to save the infos for scrolling through Wizard
  useEffect(() => {
    if (type.key !== '') {
      setStepComplete(true);
    } else setStepComplete(false);
  }, [setStepComplete, wizardInput, type, stepComplete]);

  return (
    <>
      {' '}
      <Grid columns={2} divided padded="horizontally">
        <Grid.Row>
          {/* TYPE CAR */}
          <Grid.Column>
            <Item.Group relaxed="very">
              <Item id="WizardProductTypeSelection.Car">
                <Item.Content>
                  <Item.Header>{t('productType.type')}</Item.Header>
                  <span
                    style={{
                      fontSize: '18px',
                      display: 'inline',
                    }}
                  >
                    {t('productType.car')}
                  </span>
                  <Item.Meta>{t('productType.car-undertitle')}</Item.Meta>
                  <Item.Description>
                    <Card.Group itemsPerRow={2} style={{ paddingTop: '20px' }}>
                      {subTypeCar.map((subC) => (
                        <Card
                          id="WizardProductTypeSelection.Car.Card"
                          as="a"
                          key={subC.key}
                          style={type.key === subC.key ? selected : initStyle}
                          onClick={() => {
                            setType(subC);
                            setWizardInput({
                              ...wizardInput,
                              productInput: {
                                ...wizardInput.productInput,
                                type: [ProductType.Car, subC.value],
                              } as CreateProductInput,
                            });
                          }}
                        >
                          <Card.Content style={{ padding: 50 }}>
                            <Card.Header
                              textAlign="center"
                              content={subC.text}
                              style={
                                type.key === subC.key ? selected : initStyle
                              }
                            />
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </Grid.Column>
          {/* TYPE ENTERPRISE */}
          <Grid.Column>
            <Item.Group relaxed="very">
              <Item
                id="WizardProductTypeSelection.Enterprise"
                style={{ paddingLeft: '14px' }}
              >
                {' '}
                <Item.Content>
                  <Item.Header>{t('productType.type')}</Item.Header>
                  <span
                    style={{
                      fontSize: '18px',
                      display: 'inline',
                    }}
                  >
                    {t('productType.enterprise')}
                  </span>
                  <Item.Meta>
                    {t('productType.enterprise-undertitle')}
                  </Item.Meta>
                  <Item.Description>
                    <Card.Group itemsPerRow={2} style={{ paddingTop: '20px' }}>
                      {subTypeEnterprise.map((subEn) => (
                        <Card
                          id="WizardProductTypeSelection.Enterprise.Card"
                          as="a"
                          key={subEn.key}
                          value={subEn.value}
                          style={type.key === subEn.key ? selected : initStyle}
                          onClick={() => {
                            setType(subEn);
                            setWizardInput({
                              ...wizardInput,
                              productInput: {
                                ...wizardInput.productInput,
                                type: [ProductType.Enterprise, subEn.value],
                              } as CreateProductInput,
                            });
                          }}
                        >
                          <Card.Content style={{ padding: 50 }}>
                            <Card.Header
                              textAlign="center"
                              content={subEn.text}
                              style={
                                type.key === subEn.key ? selected : initStyle
                              }
                            />
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default WizardProductTypeSelection;
