/* eslint-disable enforce-ids-in-jsx/unique-ids */
import React, { CSSProperties, ReactNode } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Maybe } from '../../../../../../../../../graphql/generated/graphql';

/**
 * Props for the DependencyTree component.
 *
 * @interface DependencyTreeProps
 * @property {string} id - dependency id
 * @property {string} componentName - dependency name
 * @property {string} componentVersion - dependency version
 * @property {string} cssClass - special class for root dependency and source dependency
 * @property {string} children - space for the other levels of tree hierarchy
 * @property {(name: string) => void} onComponentClick - set dependency name as a searchString in DependencyList
 */

interface DependencyTreeNodeProps {
  id: string;
  componentName: string;
  componentVersion: string;
  license?: Maybe<string> | undefined;
  cssClass: string;
  children?: ReactNode;
  onComponentClick?: (name: string) => void;
}

/**
 * A component that renders a list (tree) of dependencies.
 *
 * @param {DependencyTreeNodeProps} props - The props for the component.
 * @returns {JSX.Element} A JSX element representing the list of dependencies.
 */
const DependencyTreeNode = (props: DependencyTreeNodeProps): JSX.Element => {
  const {
    id,
    componentName,
    componentVersion,
    license,
    cssClass,
    children,
    onComponentClick,
  } = props;

  const { t } = useTranslation(['productDetails']);

  const handleClick = () => {
    if (onComponentClick) {
      onComponentClick(componentName);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  const licenseTextLength = (license?.length ?? 0) + 2;

  return (
    <li className={cssClass}>
      <Popup
        id={`popup_${id}`}
        content={`${t('dependencyList.treeModal.dependencyPopup')}`}
        trigger={
          <span
            role="button"
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={handleKeyPress}
          >
            {componentName} | {componentVersion}
          </span>
        }
      />
      {license && (
        <span
          className="license"
          style={
            {
              '--text-length': licenseTextLength,
            } as CSSProperties
          }
        >
          <span>
            <Icon id={`icon_${id}`} name="file alternate outline" />
            {license}
          </span>
        </span>
      )}
      {children}
    </li>
  );
};

export default DependencyTreeNode;
