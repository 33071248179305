import { TFunction } from 'i18next';
import { SemanticICONS } from 'semantic-ui-react';
import { Column, Columns, ListType, Sorting } from '../ProductListTypes';
import { collatingElementType } from '../../../productDetails/dependencySegment/dependencyList/helper/types';

/**
 * @param {Columns} columns - array containing the column objects
 * @param {ListType} listType - enum value indicating which if the lists called the function
 * @returns {Array} onlySorterColumn - array containing objects with column name as key and sort direction of clicked column as value.
 * eg. [{id: 'ASC'}]
 */
// TODO: type!
export const activeSorters = (
  columns: Columns,
  listType: ListType
): Array<{ [x: string]: string }> => {
  // get the column that is to be sorted (TODO: this can be done better)
  const sorterArray: Columns = columns.filter(
    (column) =>
      column.sorterInfo.sortable === true &&
      column.sorterInfo.sorting !== Sorting.UNSORTED
  );
  const onlySorterArray: Array<{ [x: string]: string }> = sorterArray.map(
    (column) => {
      // here we map the column names to the names used in the backend
      let sortName: string = column.name;

      if (column.name === 'projectID') sortName = 'id';

      // names depend on the list type
      if (listType === ListType.PRODUCTVERSIONDEPENDENCYLIST) {
        if (column.name === 'projectStatus') {
          sortName = 'processStatusAsString';
        }
        if (column.name === 'resolverStatus') sortName = 'overallResultNr';
      }
      if (listType === ListType.FOSSDEPENDENCYLIST) {
        if (column.name === 'name') sortName = 'componentName';
      }
      if (listType === ListType.PRODUCTSLIST) {
        if (column.name === 'resolverStatus') sortName = 'resolverResult';
      }

      const sortDirection =
        column.sorterInfo.sorting === Sorting.ASC ? 'ASC' : 'DESC';
      return { [sortName]: sortDirection };
    }
  );
  return onlySorterArray;
};

/**
 * @param {Column} triggeredColumn - clicked column
 * @param {Columns} oldColumns - array containing the current column objects
 * @param {Function} setSortIconName - optional function to set the sort icon name
 * @returns {Columns} array containing the new column objects
 * @description sets the sorting of the clicked column and resets the sorting of all other columns
 */
export const sortByAttribute = (
  triggeredColumn: Column,
  oldColumns: Columns,
  setSortIconName: React.Dispatch<
    React.SetStateAction<SemanticICONS | undefined>
  > = () => null
): Columns => {
  // sortedColumns enthält alle columns, die sortierbar sind
  // und es werden alle bis auf die angeklickte auf unsorted gesetzt
  const sortedColumns: Columns = oldColumns
    .filter(
      (column: Column) =>
        column.name !== triggeredColumn.name && column.sorterInfo.sortable
    )
    .map((column: Column) => {
      const tempColumn = { ...column };
      tempColumn.sorterInfo.sorting = Sorting.UNSORTED;
      return tempColumn;
    });
  const currentSortColumn: Column | undefined = oldColumns.find(
    (col: Column) => col.name === triggeredColumn.name
  );
  if (currentSortColumn) {
    // wenn aktuelles Element Sorted.UNSORTED entspricht , sortiere Sorted.ASC (bspw. von A-Z)
    // wenn aktuelles Element Sorted.ASC entspricht , wechsel zu Sorted.DESC (bspw. von Z-A)
    // wenn aktuelles Element Sorted.DESC entspricht , wechsel zu Sorted.UNSORTED
    switch (currentSortColumn.sorterInfo.sorting) {
      case Sorting.ASC:
        currentSortColumn.sorterInfo.sorting = Sorting.DESC;
        if (setSortIconName) setSortIconName('sort descending');
        break;
      case Sorting.DESC:
        currentSortColumn.sorterInfo.sorting = Sorting.UNSORTED;
        if (setSortIconName) setSortIconName('sort');
        break;
      case Sorting.UNSORTED:
        currentSortColumn.sorterInfo.sorting = Sorting.ASC;
        if (setSortIconName) setSortIconName('sort ascending');
        break;
      default:
        currentSortColumn.sorterInfo.sorting = Sorting.ASC;
    }

    sortedColumns.push(currentSortColumn);
    const allColumns = oldColumns.map(
      (col: Column) => sortedColumns.find((cl) => cl.name === col.name) || col
    );
    return allColumns;
  }
  return oldColumns;
};

/**
 * @param {Columns} columns - array containing the column objects
 * @param {boolean} isVersion - optional boolean indicating if the given columns are from the pvlist
 * @param {boolean} isFossDep - optional boolean indicating if the given columns are from the fossDepList
 * @returns {Array} onlyFilterArray - array containing objects with columnn name as key and filter options as value.
 * eg. [{requestor: "Hans"}]
 */
export const activeFilters = (
  columns: Columns,
  isVersion = false,
  isFossDep = false
): collatingElementType[] => {
  // filtering for all columns that are filterable and contain at least one filter set to true
  const filterArray: Columns = columns.filter(
    (column) =>
      column.filterInfo.filterable === true &&
      Object.values(column.filterInfo.filtering).some(
        (filterBool) => filterBool === true
      )
  );
  let onlyFilterArray: collatingElementType[] = [];
  filterArray.forEach((column) => {
    const filterKeys = Object.keys(column.filterInfo.filtering);
    const activeFilterKeys = filterKeys.filter(
      (key) => column.filterInfo.filtering[key] === true
    );
    const activeFiltersArray: collatingElementType[] = activeFilterKeys.map(
      (key): collatingElementType => {
        // taking the keys of the active filters and creating an array of objects
        // with the column name as key and the filter key as value
        if (isVersion && column.name === 'projectStatus') {
          return {
            processStatusAsString: key,
          };
        }
        if (isFossDep && column.name === 'licenses') {
          return {
            licenseSpdxIds: key,
          };
        }
        if (column.name === 'requestor') {
          return {
            createdById: key.split('__')[1], // giving back ID for exact search results
          };
        }
        if (column.name === 'oso') {
          return {
            osoReviewer: key.split('__')[1],
          };
        }
        if (column.name === 'legal') {
          return {
            legalReviewer: key.split('__')[1],
          };
        }
        if (column.name === 'completeness') {
          return {
            completeness: Number(key),
          };
        }
        if (column.name === 'resolverStatus') {
          return {
            resolverResult: key,
          };
        }
        return { [column.name]: key };
      }
    );
    onlyFilterArray = onlyFilterArray.concat(activeFiltersArray);
  });
  return onlyFilterArray;
};

/**
 * @param {Column} triggeredColumn - clicked column
 * @param {Columns} oldColumns - array containing the current column objects
 * @param {{}} selectedFilters { [K: string]: boolean } - object containing the clicked filter options
 * @returns {Columns} array containing the new column objects
 * @description sets the filter options of the clicked column to the selected ones
 */
export const filterByAttributes = (
  triggeredColumn: Column,
  oldColumns: Columns,
  selectedFilters: { [K: string]: boolean }
): Columns => {
  const tempFilterInfo =
    triggeredColumn.name === 'copyleft'
      ? {
          filterable: true,
          filtering: selectedFilters,
          labeled: true,
        }
      : {
          filterable: true,
          filtering: selectedFilters,
        };
  const tempColumn: Column = {
    ...triggeredColumn,
    filterInfo: tempFilterInfo,
  };

  return oldColumns.map((col) =>
    col.name === tempColumn.name ? tempColumn : col
  );
};

/**
 * @param {Column} triggeredColumn - clicked column
 * @param {Columns} oldColumns - array containing the current column objects
 * @returns {Columns} array containing the new column objects
 * @description sets all filter options to false except the clicked one
 */
export const toggleColumnActive = (
  triggeredColumn: Column,
  oldColumns: Columns
): Columns => {
  const activeColumns: Columns = oldColumns
    .filter((column: Column) => column.name !== triggeredColumn.name)
    .map((column: Column) => {
      const tempColumn = { ...column };
      tempColumn.active = false;
      return tempColumn;
    });
  const currentActiveColumn: Column | undefined = oldColumns.find(
    (col: Column) => col.name === triggeredColumn.name
  );
  if (currentActiveColumn) {
    if (currentActiveColumn.sorterInfo.sortable) {
      // sortColumn clicked
      if (currentActiveColumn.sorterInfo.sorting !== Sorting.UNSORTED) {
        currentActiveColumn.active = true;
      } else {
        currentActiveColumn.active = false;
      }
    } else if (currentActiveColumn.filterInfo.filterable) {
      // filterColumn clicked
      if (
        Object.values(currentActiveColumn.filterInfo.filtering).some(
          (value) => value === true
        )
      ) {
        currentActiveColumn.active = true;
      } else {
        currentActiveColumn.active = false;
      }
    }
    activeColumns.push(currentActiveColumn);
    const allColumns = oldColumns.map(
      (col) => activeColumns.find((cl) => cl.name === col.name) || col
    );
    return allColumns;
  }
  return oldColumns;
};

/**
 * @param {number} differenceDays
 * difference of the time stamps
 * @param {TFunction} text
 * i18next variable containing the needed text snippets
 * @returns {string} timeDifferenceString
 * st9ing explaining the time difference for humans
 */
export const timeDiffFormatter = (
  differenceDays: number,
  text: TFunction
): string => {
  let timeDifferenceString = '';
  if (differenceDays >= 0)
    timeDifferenceString = text('tableElements.dateCounterBefore', {
      differenceDays,
    });
  else if (differenceDays === 1)
    timeDifferenceString = text('tableElements.dateCounterBeforeSingle', {
      differenceDays,
    });
  else if (differenceDays === -1)
    timeDifferenceString = text('tableElements.dateCounterAfterSingle', {
      differenceDays,
    });
  else if (differenceDays < 0)
    timeDifferenceString = text('tableElements.dateCounterAfter', {
      differenceDays: Math.abs(differenceDays),
    });
  return timeDifferenceString;
};
