import { ProductType } from '../../../../../graphql/generated/graphql';

/**
 * Dropdown options for main product types
 */
export const mainTypes = [
  { key: 'Enterprise', text: 'Enterprise', value: ProductType.Enterprise },
  { key: 'Car', text: 'Car', value: ProductType.Car },
];
/**
 * Dropdown options for sub product types
 */
export const subTypeCar = [
  {
    key: 'Application',
    text: 'Application',
    value: ProductType.Application,
  },
  { key: 'Ecu', text: 'ECU', value: ProductType.ECU },
  {
    key: 'Hardware',
    text: 'Hardware',
    value: ProductType.Hardware,
  },
  {
    key: 'Software',
    text: 'Software',
    value: ProductType.Software,
  },
  { key: 'Other', text: 'Other', value: ProductType.Other },
];
/**
 * Dropdown options sub types of enterprise
 */
export const subTypeEnterprise = [
  {
    key: 'Application',
    text: 'Application',
    value: ProductType.Application,
  },
  {
    key: 'Hardware',
    text: 'Hardware',
    value: ProductType.Hardware,
  },
  { key: 'Other', text: 'Other', value: ProductType.Other },
];
