import React from 'react';
import { Icon } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

type Props = {
  id: string;
  checked: boolean;
  valid: boolean;
  size: IconSizeProp;
  onClick: () => void;
};

/**
 * Icon Checkbox component
 *
 * @param {object} props id, checked, valid, size, onClick
 *@returns {JSX.Element} Icon based Checkbox
 */
const IconCheckbox = (props: Props): JSX.Element => {
  const { id = 'Checkbox', checked, valid, size, onClick } = props;
  if (checked)
    return (
      <Icon.Group
        id={id}
        key={id}
        style={{
          textAlign: 'left',
        }}
        onClick={() => onClick()}
      >
        <Icon
          id="IconSquare"
          name="square"
          size="large"
          fitted
          color={valid ? 'blue' : 'red'}
        />
        <Icon id="IconClose" name="close" fitted style={{ color: 'white' }} />
      </Icon.Group>
    );

  return (
    <Icon
      key="icon_key"
      id="icon_key"
      name="square outline"
      fitted
      size={size}
      style={{
        textAlign: 'left',
      }}
      color="grey"
      onClick={() => onClick()}
    />
  );
};

export default IconCheckbox;
