import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Product,
  Version,
} from '../../../../../../../graphql/generated/graphql';
import { commentCountForProduct } from '../../../../../productsList/listComponents/helpers/logics';
import OverflowPopup from '../../../../../productsList/listComponents/columnComponents/OverflowPopup';

type PropsType = {
  product?: Product | undefined;
  version: Version | undefined;
};

/**
 * returns element with amount of comments
 * nothing too fancy
 *
 * @param {PropsType} props takes in product/version
 * @returns {JSX.Element} a span with text
 */
const CommentCountElement = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('dashboard');
  const { product, version } = props;

  const commentCount = product
    ? commentCountForProduct(product)
    : version?.comments.length || 0;

  return (
    <OverflowPopup
      content={
        commentCount > 1
          ? `${commentCount} ${text('tableElements.comments')}`
          : `${commentCount} ${text('tableElements.comments')}`
      }
    />
  );
};

CommentCountElement.defaultProps = {
  product: null,
};

export default CommentCountElement;
