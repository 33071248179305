import { ApolloError } from '@apollo/client';
import React, { SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Header, Loader, Message } from 'semantic-ui-react';
import {
  DirectoryAccess,
  InputMaybe,
  Maybe,
  Role,
} from '../../../graphql/generated/graphql';
import AddDirectoriesDropdownButton from './AddDirectoriesDropdownButton';
import DirectoryAccessComponent from './DirectoryAccessComponent';
import {
  areArraysOfObjectsEqual,
  checkingRolesForAccess,
} from './helpers/logics';

type Props = {
  usersGlobalRoles?: InputMaybe<Role | Role[]> | undefined;
  directoryAccessArr: DirectoryAccess[];
  setDirectoryAccessArr: React.Dispatch<
    React.SetStateAction<DirectoryAccess[]>
  >;
  brandDirectories: Maybe<Array<Maybe<DirectoryAccess>>> | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  setChangesAreMade: React.Dispatch<SetStateAction<boolean>>;
};

/**
 * Users Directories as a tree structured list
 *
 * @returns {JSX.Element} a list of User assigned directories with roles
 * @param {string} userId props
 */
const UserDirectoriesAccess = ({
  usersGlobalRoles,
  directoryAccessArr,
  setDirectoryAccessArr,
  brandDirectories,
  error,
  loading,
  setChangesAreMade,
}: Props): JSX.Element => {
  const { t } = useTranslation('users');
  // in order to add directory access user needs global roles "oso" or "legal"
  const usersWithAccess = checkingRolesForAccess(usersGlobalRoles, 'access');
  // filtering brand directories which have roles -meaning assigned access for this user
  const userDirectoriesArr = useMemo(() => {
    return (
      brandDirectories
        ?.filter((f) => f?.directoryRoles && f?.directoryRoles.length > 0)
        .map((dir) => ({
          directoryId: dir?.directoryId || '',
          directoryName: dir?.directoryName || '',
          directoryRoles: dir?.directoryRoles || [],
        })) || []
    );
  }, [brandDirectories]);

  // setting user brand directories with access to State
  useEffect(() => {
    if (brandDirectories && userDirectoriesArr.length > 0) {
      setDirectoryAccessArr(userDirectoriesArr);
    }
  }, [brandDirectories, userDirectoriesArr, setDirectoryAccessArr]);
  // Listen to changes in directoryAccessArr
  useEffect(() => {
    const hasChanges = !areArraysOfObjectsEqual(
      userDirectoriesArr,
      directoryAccessArr
    );
    setChangesAreMade(hasChanges);
  }, [directoryAccessArr, setChangesAreMade, userDirectoriesArr]);

  // LOADING & ERROR
  if (loading) {
    return <Loader active size="large" />;
  }
  if (error) {
    return (
      <Message
        id="UserDirectoriesAccessQueryError"
        error
        icon="times circle"
        content={`User BrandDirectories Error :${error}`}
      />
    );
  }
  return (
    <>
      <Divider />
      <Header
        content={t('directories.edit')}
        className="UserDirectoriesHeader"
        disabled={!usersWithAccess}
        subheader={t('accessRights')}
      />
      <div className="UserDirectoriesListOfDirectoriesSegment">
        {directoryAccessArr.map((dir: DirectoryAccess, index: number) => (
          <DirectoryAccessComponent
            key={`DirectoryAccess${dir.directoryId}${directoryAccessArr.indexOf(
              dir
            )}`}
            directory={dir}
            usersGlobalRoles={usersGlobalRoles}
            indexOfDirectory={index}
            directoryAccessArr={directoryAccessArr}
            setDirectoryAccessArr={setDirectoryAccessArr}
          />
        ))}
      </div>
      {/* Add brand directories to user to give him access to for it */}
      <AddDirectoriesDropdownButton
        directoryAccessArr={directoryAccessArr}
        setDirectoryAccessArr={setDirectoryAccessArr}
        brandDirectories={brandDirectories}
        usersWithAccess={usersWithAccess}
      />
    </>
  );
};

export default UserDirectoriesAccess;
