import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';
import { ProductInfoBoxContext } from '../../../../../context/ProductContext';

/**
 * @returns {JSX.Element} Update Message Component
 */
const UpdateMessage = (): JSX.Element => {
  const { updateVersionMutationResult } = useContext(ProductInfoBoxContext);

  if (updateVersionMutationResult.error)
    return (
      <Message
        id="UpdateVersionError"
        error
        icon="times circle"
        content={updateVersionMutationResult.error.message}
      />
    );
  return <div />;
};

export default UpdateMessage;
