/* eslint-disable enforce-ids-in-jsx/missing-ids */
import React from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import LicenseTodoBadges from './LicenseTodoBadges';
import LicenseFeatureConditionsInfo from './LicenseFeatureConditionsInfo';
import { ToDo } from '../../graphql/generated/graphql';
import i18n from '../../i18n/i18n';
import InfoIconTooltip from '../ui/InfoIconTooltip';
import {
  FeatureTypeName,
  useLicenseContext,
} from '../../context/LicenseContext';

type Props = {
  todo: ToDo;
  isTask?: boolean;
  default?: boolean;
};

/**
 *  License Features
 *
 * @param {Props} props - Props
 * @returns {JSX.Element}  - License Features
 */
const LicenseTodoListItem = (props: Props): JSX.Element => {
  const { todo, isTask = false, default: defaultProp } = props;
  const { isEditMode, removeFeature } = useLicenseContext();

  const itemName = i18n.language === 'de' ? todo.name_de : todo.name_en;
  return (
    <div className={`todo-item-container ${defaultProp ? 'is-default' : ''}`}>
      <Checkbox radio={isTask} readOnly disabled defaultChecked={defaultProp} />
      <span>
        {itemName}
        {todo?.conditions?.length > 0 && (
          <InfoIconTooltip
            infoText={<LicenseFeatureConditionsInfo rule={todo as ToDo} />}
            on="click"
            wide
          />
        )}
        <LicenseTodoBadges internal={todo.internal} />
      </span>
      {!defaultProp && isEditMode && (
        <Icon
          id={`RemoveTodo${todo.id}`}
          className="delete-feature-icon"
          name="x"
          onClick={() =>
            removeFeature(todo.__typename as FeatureTypeName, todo.id)
          }
        />
      )}
    </div>
  );
};
export default LicenseTodoListItem;
