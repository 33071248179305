import { ApolloError, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import WizardContext from '../../context/WizardContext';
import {
  CreateProductInput,
  WizardInput,
} from '../../graphql/generated/graphql';

import { CHECK_FOR_PROJECTS } from '../../graphql/queries/ProductQuerys';
import WIZARD_MUTATION, {
  CREATE_PRODUCT_WITHOUT_PARTS,
} from '../../graphql/mutations/ProductMutations';

/**
 * Custom hook für die Wizard Mutation
 *
 * @returns {() => void} wizardSave
 * @param {number | undefined} productStructure productStructure = Empty project / ProjectWithParts / Product
 */
const useWizardCreate = (
  productStructure: number | undefined
): [() => void, ApolloError | undefined] => {
  const { wizardInput } = useContext(WizardContext);
  const navigate = useNavigate();

  const mutation =
    productStructure === 2 ? CREATE_PRODUCT_WITHOUT_PARTS : WIZARD_MUTATION;
  const [wizardSaveToCreate, { error }] = useMutation(mutation, {
    onCompleted: (data) => {
      if (productStructure === 2) {
        navigate(
          `/product-details/${data.CreateProduct.versions[0].id}?view=Details`
        );
      } else navigate('/dashboard');
    },
    refetchQueries: [{ query: CHECK_FOR_PROJECTS }],
  });

  const productInput: CreateProductInput[] = wizardInput.partInput?.map((p) => {
    return {
      name:
        productStructure === 0
          ? wizardInput.productInput.name?.concat(` - ${p.name}`)
          : wizardInput.productInput.name,
      versionName: wizardInput.productInput.versionName,
      description: wizardInput.productInput?.description,
      targetDate: wizardInput.productInput.targetDate,
      type: wizardInput.productInput.type,
      tags: wizardInput.productInput.tags,
      standardSoftware: wizardInput.productInput.standardSoftware,
      protection: p.protection,
      transferContactPerson: wizardInput.productInput.transferContactPerson,
      transferExternalId: wizardInput.productInput.transferExternalId,
      transferGroupCompany: wizardInput.productInput.transferGroupCompany,
      distribution: p.distribution,
      highPriorityRequest: p.highPriorityRequest,
      developer: p.developer,
      manager: p.manager,
      supplier: p.supplier,
      guest: p.guest,
    };
  });

  let saveInput: WizardInput | CreateProductInput;
  if (productStructure === 0) {
    saveInput = {
      projectInput: { ...wizardInput.productInput },
      productInput,
    };
  } else if (productStructure === 1) {
    saveInput = {
      projectInput: { ...wizardInput.productInput },
      productInput: [],
    };
  } else {
    saveInput = {
      ...productInput[0],
    };
  }

  const wizardSave = () => {
    wizardSaveToCreate({
      variables:
        productStructure === 2
          ? { productInput: saveInput as CreateProductInput }
          : { wizardInput: saveInput as WizardInput },
    });
  };
  return [wizardSave, error];
};

export default useWizardCreate;
