import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { Columns } from '../../../../productsList/listComponents/ProductListTypes';
import { Direction } from '../customHooks/useTableSorting';

type PropsType = {
  columns: Columns;
  direction: Direction;
  sortedColumn: string | null;
  onColumnClick: (columnName: string) => void;
};

/**
 *
 * @param {object} props contains columns only.
 * @returns {JSX.Element} StorageListHeader.
 */
const StorageListHeader = (props: PropsType): JSX.Element => {
  const { columns, direction, sortedColumn, onColumnClick } = props;
  const [text] = useTranslation('productStorage');

  return (
    <Table.Row key="headerRow">
      {columns.map((col) => {
        const columnName = col.name;
        const isSortable = columnName !== 'actions';

        return (
          <Table.HeaderCell
            key={columnName}
            width={col.width}
            style={{ cursor: isSortable ? 'pointer' : 'default' }}
            id={columnName}
            sorted={
              isSortable && sortedColumn === columnName ? direction : undefined
            }
            onClick={isSortable ? () => onColumnClick(columnName) : undefined}
          >
            <span>
              {columnName !== 'actions'
                ? text(`table.tableHeader.${col.name}`)
                : ''}
              &nbsp;
            </span>
          </Table.HeaderCell>
        );
      })}
    </Table.Row>
  );
};

export default StorageListHeader;
