import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import {
  Column,
  Columns,
} from '../../productsList/listComponents/ProductListTypes';

type PropsType = {
  columns: Columns;
};

/**
 * Header element of the product history list
 *
 * @param {PropsType} props takes in active columns and builds the header accordingly.
 * @returns {JSX.Element} table row containing table cells with the according values.
 */
const ProductHistoryListHeader = (props: PropsType): JSX.Element => {
  const { columns } = props;
  const [text] = useTranslation('history');

  return (
    <Table.Row key="headerRow">
      {columns.map((column: Column) => {
        // id does not work with column.name - columnName works though
        const columnName = column.name;

        return (
          column.permitted &&
          column.shown && (
            <Table.HeaderCell
              className={column.active ? 'active' : ''}
              width={column.width}
              key={`tableHeader.${column.name}`}
              id={`tableHeader.${column.name}`}
            >
              <span id={`productsList.${columnName}.Paragraph`}>
                {text(`tableHeader.${column.name}`)}&nbsp;
              </span>
            </Table.HeaderCell>
          )
        );
      })}
    </Table.Row>
  );
};

export default ProductHistoryListHeader;
