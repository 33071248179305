import { SemanticICONS } from 'semantic-ui-react';
import { Version } from '../../../../../../graphql/generated/graphql';
import { EditParts, ProcessStatus, ProcessStatusType } from './types';

/**
 * enum to represent the status of a result
 */
export enum ResultStatus {
  UNKNOWN = 0,
  OK = 1,
  OK_NOT_VERIFIED = 2,
  OPEN_REQ = 3,
  CONFLICT = 4,
}

/**
 * takes in a resultNr and returns the corresponding string
 *
 * @param {number} resultNr - the result as integer
 * @returns {string} - the result as string
 */
export const mapResultNrToString = (resultNr: number): string => {
  switch (resultNr as ResultStatus) {
    case ResultStatus.UNKNOWN:
      return 'UNKNOWN';
    case ResultStatus.OK:
      return 'OK';
    case ResultStatus.OK_NOT_VERIFIED:
      return 'OK_NOT_VERIFIED';
    case ResultStatus.OPEN_REQ:
      return 'OPEN_REQ';
    case ResultStatus.CONFLICT:
      return 'CONFLICT';
    default:
      return 'UNKNOWN';
  }
};
/**
 *@param {string} status - the result as
 @returns {string} - the result as
 */
export const statusIcons = (status: string): SemanticICONS | undefined => {
  switch (status) {
    case 'OK':
      return 'check circle outline';
    case 'CONFLICT':
      return 'times circle outline';
    case 'OK_NOT_VERIFIED':
      return 'warning circle';
    case 'OPEN_REQ':
      return 'question circle outline';
    case 'UNKNOWN':
      return 'circle notched';
    default:
      return undefined;
  }
};

/**
 * Checking if current version array is differnt from initial one
 * 
 *@param {string[]} array1 - openSessionVersionIds
 *@param {string[]} array2 - currentIds or initial ids
 @returns {boolean} - the result
 */
export const checkIfArrayIsDifferent = (
  array1: string[],
  array2: string[]
): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.reduce((isEqual, element, index) => {
    return isEqual && element === array2[index];
  }, true);
};

/**
 *@returns {void} setOpenSession
 *@param {Array<Version>} input incoming data
 *@param {EditParts[]} setOpenSession opensession
 */
export const makePartObject = (
  input: Array<Version>,
  setOpenSession: (value: React.SetStateAction<EditParts[]>) => void
): void => {
  const currentParts = input?.map((dep: Version) => {
    return {
      partId: dep.product?.id || '',
      partName: dep.product?.name || '',
      partVersionId: dep.id,
      partVersionName: dep.name,
    };
  });
  setOpenSession(currentParts);
};

/**
 * @param {string} status projectStatus from selected pills
 * @returns {ProcessStatusType} ProcessStatusType number  for processStatus as integer for Product version
 */
export function getProcessStatusType(status: string): ProcessStatusType | null {
  // index of the status string in the ProcessStatus array
  const typeNumber = ProcessStatus.indexOf(status);
  // If status string is not found, returns null
  if (typeNumber === -1) {
    return null;
  }
  return typeNumber as ProcessStatusType;
}
