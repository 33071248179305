/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloCache } from '@apollo/client/core';
import { DefaultContext, OperationVariables } from '@apollo/client/core/types';
import { MutationFunctionOptions } from '@apollo/client/react';
import { TFunction } from 'i18next';
import React, { SyntheticEvent } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { acceptedFileTypes } from './constants';
import { sanitizeTextAreaChange } from '../../../../../helper/logics';

type dataStateType = {
  category: string;
  filename: string;
  file: File | null;
  info: string;
  accept: string;
};
type errorStateType = {
  category: boolean;
  file: boolean;
  info: boolean;
  size: boolean;
};
type touchedStateType = {
  category: boolean;
  file: boolean;
  info: boolean;
};
type persistToArtifactoryMutationType = (
  options?:
    | MutationFunctionOptions<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        OperationVariables,
        DefaultContext,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ApolloCache<any>
      >
    | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<any>;

/**
 * Reset the states of the upload form
 *
 * @param {Function} setData - function to set the data state
 * @param {Function} setTouched - function to set the touched state
 * @param {Function} setError - function to set the error state
 * @returns {void}
 */
export const resetUploadStates = (
  setData: (value: React.SetStateAction<dataStateType>) => void,
  setTouched: (value: React.SetStateAction<touchedStateType>) => void,
  setError: (value: React.SetStateAction<errorStateType>) => void
): void => {
  setData({
    category: '',
    filename: '',
    file: null,
    info: '',
    accept: acceptedFileTypes,
  });
  setTouched({
    category: false,
    file: false,
    info: false,
  });
  setError({
    category: false,
    file: false,
    info: false,
    size: false,
  });
};

/**
 * Reset the upload form
 *
 * @param {TFunction} text - translation function
 */
export const resetUploadForm = (text: TFunction) => {
  // clear input fields
  const fileCategory = document.querySelector(
    'div#FileCategory>div.text'
  ) as HTMLInputElement;
  const fileUploadButton = document.getElementById(
    'FileUploadButton'
  ) as HTMLInputElement;
  const fileAdditionalInfo = document.getElementById(
    'FileAdditionalInfo'
  ) as HTMLInputElement;
  const fileUploadInput = document.getElementById(
    'FileUploadInput'
  ) as HTMLInputElement;

  if (fileCategory) fileCategory.placeholder = text('pleaseSelectType');
  if (fileUploadButton) fileUploadButton.innerText = text('pleaseSelectFile');
  if (fileAdditionalInfo) fileAdditionalInfo.value = '';
  if (fileUploadInput) fileUploadInput.value = '';
};

/**
 * Handle the file change event
 *
 * @param {React.ChangeEvent<HTMLInputElement>} event - the file change event
 * @param {Function} setData - function to set the data state
 * @param {Function} setTouched - function to set the touched state
 * @param {Function} setError - function to set the error state
 */
export const onFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setData: (value: React.SetStateAction<dataStateType>) => void,
  setTouched: (value: React.SetStateAction<touchedStateType>) => void,
  setError: (value: React.SetStateAction<errorStateType>) => void
) => {
  const file = event.target.files?.[0];

  setTouched((prevTouched) => {
    return { ...prevTouched, file: true };
  });

  if (file) {
    setData((prevData) => {
      return { ...prevData, filename: file?.name || '', file };
    });

    if (file.size > 300 * 1024 * 1024) {
      setError((prevError) => {
        return { ...prevError, file: false, size: true };
      });
    } else {
      setError((prevError) => {
        return { ...prevError, file: false, size: false };
      });
    }

    const fileUploadButton = document.getElementById(
      'FileUploadButton'
    ) as HTMLInputElement;
    if (fileUploadButton) fileUploadButton.innerText = file?.name || '';
  }
};

/**
 * Handle the category click event
 *
 * @param {Function} setTouched - function to set the touched state
 */
export const onCategoryClick = (
  setTouched: (value: React.SetStateAction<touchedStateType>) => void
) => {
  setTouched((prevTouched) => {
    return { ...prevTouched, category: true };
  });
};

/**
 * Handle the category change event
 *
 * @param {SyntheticEvent<HTMLElement, Event>} _ - the category change event
 * @param {DropdownProps} inputData - the dropdown props
 * @param {Function} setData - function to set the data state
 * @param {Function} setError - function to set the error state
 * @param {{}} error - the error state
 * @param {boolean} error.category - the category error state
 * @param {boolean} error.file - the file error state
 * @param {boolean} error.info - the info error state
 * @returns {void}
 */
export const onCategoryChange = (
  _: SyntheticEvent<HTMLElement, Event>,
  inputData: DropdownProps,
  setData: (value: React.SetStateAction<dataStateType>) => void,
  setError: (value: React.SetStateAction<errorStateType>) => void,
  error: errorStateType
) => {
  setData((prevData) => {
    return {
      ...prevData,
      category: inputData.value as string,
      accept: inputData.options?.find((option) => {
        return option.value === inputData.value;
      })?.accept,
    };
  });
  if (error.category === true) {
    setError((prevError) => {
      return {
        ...prevError,
        category: false,
      };
    });
  }
};

/**
 * Handle the upload button click event
 *
 * @param {Function} setError - function to set the error state
 * @param {{}} data - the data state
 * @param {string} data.category - the category state
 * @param {string} data.filename - the filename state
 * @param {string | ArrayBuffer | null} data.file - the file state (base64)
 * @param {string} data.info - the info state
 * @param {string} data.accept - the accept state (file types)
 * @param {{}} error - the error state
 * @param {boolean} error.category - the category error state
 * @param {boolean} error.file - the file error state
 * @param {boolean} error.info - the info error state
 * @param {Function} persistFileToArtifactory - the persistFileToArtifactory mutation function
 * @param {string} versionId - the versionId
 * @returns {void}
 */
export const onUploadButtonClick = (
  setError: (value: React.SetStateAction<errorStateType>) => void,
  data: dataStateType,
  error: errorStateType,
  persistFileToArtifactory: persistToArtifactoryMutationType,
  versionId: string
) => {
  // 1) check if category and file contain valid data, set error if not
  // info is optional, no need to check
  if (data.category === '' && !error.category) {
    setError((prevError) => {
      return { ...prevError, category: true };
    });
  }
  if ((data.file === undefined || data.file === null) && !error.file) {
    setError((prevError) => {
      return { ...prevError, file: true };
    });
  }
  // 2) start artifactory mutation with data if there are no errors
  if (!Object.values(error).some((err) => err === true)) {
    persistFileToArtifactory({
      variables: {
        versionId,
        filename: data.filename,
        file: data.file,
        type: data.category,
        info: data.info,
      },
    });
  }
};
/**
 * Handle the text input event
 *
 * @param {string} inputText - the input text
 * @param {Function} setData - function to set the data state
 */
export const onTextAreaInput = (
  inputText: string,
  setData: (value: React.SetStateAction<dataStateType>) => void
) => {
  setData((prevData) => {
    return { ...prevData, info: inputText };
  });
};
