/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect, useCallback, useState } from 'react';

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: any[]) => void;

// Function overloads for useDebounce
function useDebounce(input: SomeFunction, delay?: number): SomeFunction;
function useDebounce(
  fc: string,
  delay?: number
): [string, (value: string) => void];

/**
 * A hook that debounces a function or a string.
 *
 * @param {SomeFunction | string} input - The function or string to debounce.
 * @param {number} [delay=1000] - The debounce delay in milliseconds.
 * @returns {SomeFunction | [string, (value: string) => void]} - The debounced function or an array containing the debounced value and a setter function.
 */
function useDebounce(
  input: SomeFunction | string,
  delay = 1000
): SomeFunction | [string, (value: string) => void] {
  const timer = useRef<Timer>();
  const isFunction = typeof input === 'function';

  const [debouncedValue, setDebouncedValue] = useState<string>(
    isFunction ? '' : (input as string)
  );

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const debouncedFunction = useCallback(
    (...args: any[]) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        (input as SomeFunction)(...args);
      }, delay);
    },
    [input, delay]
  );

  const setValue = (value: string) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
  };

  if (isFunction) {
    return debouncedFunction as SomeFunction;
  }
  return [debouncedValue, setValue] as [string, (value: string) => void];
}

export default useDebounce;
