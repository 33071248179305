import { ApolloError } from '@apollo/client';
import {
  BrandDirectory,
  useGetEmailsByBrandQuery,
  useProductTransferDataQuery,
  useProductTypeQuery,
} from '../../../../../graphql/generated/graphql';
import { mapStringToBrandEnum } from './logics';

interface BrandOSOEmailResult {
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  uniqueIdentifier: string;
  transferContact: string;
  productName: string;
  loading: boolean;
  error: ApolloError | undefined;
}

/**
 * Hook that collects data needed for group transfer email
 *
 * @param {string} versionId versionId
 * @returns {BrandOSOEmailResult} email, loading & error
 */
const useBrandOSOEmail = (versionId: string): BrandOSOEmailResult => {
  const {
    data: productTypeData,
    loading: productTypeLoading,
    error: productTypeError,
  } = useProductTypeQuery({
    variables: { id: versionId },
  });
  const productType = productTypeData?.Version?.product?.type;

  const {
    data: transferData,
    loading: transferDataLoading,
    error: transferDataError,
  } = useProductTransferDataQuery({
    variables: { id: versionId },
  });

  const groupCompany = transferData?.Version?.product?.transfer.groupCompany;
  const uniqueIdentifier = transferData?.Version?.product?.transfer.externalId;
  const transferContact =
    transferData?.Version?.product?.transfer.contactPerson;
  const productName = transferData?.Version?.product?.name;
  const brand = mapStringToBrandEnum(groupCompany);

  const {
    data: directoryEmailsData,
    loading: directoryEmailsLoading,
    error: directoryEmailsError,
  } = useGetEmailsByBrandQuery({
    variables: { versionId, brand: brand as BrandDirectory, productType },
    skip: !brand || !productType,
  });

  // Combine loading and error states from all queries
  const loading =
    productTypeLoading || transferDataLoading || directoryEmailsLoading;
  const error = productTypeError || transferDataError || directoryEmailsError;

  return {
    email: directoryEmailsData?.GetEmailsByBrand?.emailBrandOSO,
    uniqueIdentifier: uniqueIdentifier ?? '',
    transferContact: transferContact ?? '',
    productName: productName ?? '',
    id: directoryEmailsData?.GetEmailsByBrand?.id,
    name: directoryEmailsData?.GetEmailsByBrand?.name,
    loading,
    error,
  };
};

export default useBrandOSOEmail;
