import React from 'react';
import { Outlet } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import LicenseList from '../../components/licenses/LicenseList';
import { LicenseDetailsProvider } from '../../context/LicenseContext';

/**
 * LicenseView
 * needs to be filled.
 *
 * @returns {JSX.Element} a new beginning.
 */
const LicenseView = (): JSX.Element => {
  return (
    <LicenseDetailsProvider>
      <Segment id="LicenseView">
        <LicenseList />
        <Outlet />
      </Segment>
    </LicenseDetailsProvider>
  );
};

export default LicenseView;
