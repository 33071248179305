import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import WizardContext from '../../context/WizardContext';
import WizardTeamResetButton from './fifthStep-Team/WizardTeamResetButton';
import WizardTeamTakeOverButton from './fifthStep-Team/WizardTeamTakeOverButton';
import useWizardCreate from './useWizardSave';
import Toast from '../ui/Toast';

/**
 * Footbar for the create new Product Wizard.
 *
 * @returns {JSX.Element} WizardFooter.
 */
const WizardFooter = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const {
    setStepComplete,
    stepComplete,
    currentStep,
    currentPart,
    wizardInput,
    partValidated,
    identicalIntendedUse,
    setIdenticalTeam,
    setIdenticalIntendedUse,
    identicalTeam,
    setPartValidated,
    setCurrentStep,
    setCurrentPart,
    productStructure,
  } = useContext(WizardContext);

  const navigate = useNavigate();
  // 2 hooks in one-considering if a product is with/ without Parts!
  const [createNewProduct, error] = useWizardCreate(productStructure);
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setToastVisible(true);
    }
  }, [error]);

  const handleToastClose = () => {
    setToastVisible(false);
  };

  return (
    <div id="WizardFooter" className="WizardFooter">
      <Button
        secondary
        icon="x"
        id="WizardFooterCancelButton"
        floated="left"
        style={{
          marginTop: 5,
        }}
        content={t('wizardFooter.cancel')}
        onClick={() => navigate('/dashboard')}
      />

      {currentStep === 5 && (
        <>
          <WizardTeamResetButton />
          <WizardTeamTakeOverButton />
        </>
      )}

      {currentStep === 2 && productStructure === 1 && (
        <Button
          primary
          disabled={!stepComplete}
          icon
          id="WizardFooterSaveEmptyProductButton"
          floated="right"
          style={{
            marginTop: 5,
          }}
          onClick={() => createNewProduct()}
        >
          {t('wizardFooter.save')}
          <Icon id="WizardSaveEmptyProductIcon" name="save outline" />
        </Button>
      )}

      {currentStep === 2 && productStructure !== 1 && (
        <Button
          disabled={!partValidated && !stepComplete}
          primary
          id="WizardFooterWorkflowbuttonNoParts"
          floated="right"
          style={{
            marginTop: 5,
          }}
          onClick={() => {
            if (productStructure === 2) {
              setCurrentStep(4);
              setIdenticalIntendedUse(true);
              setIdenticalTeam(true);
              setStepComplete(false);
              setPartValidated(false);
            } else {
              setCurrentStep(3);
              setStepComplete(false);
              setPartValidated(false);
            }
          }}
        >
          {t('wizardFooter.next')}{' '}
          <Icon id="WizardNextIconNoParts" name="long arrow alternate right" />
        </Button>
      )}
      {currentStep < 5 && currentStep !== 2 && (
        <Button
          disabled={!partValidated && !stepComplete}
          primary
          id="WizardFooterWorkflowbutton"
          floated="right"
          style={{
            marginTop: 5,
          }}
          onClick={() => {
            if (partValidated) {
              if (currentPart === wizardInput.partInput.length) {
                setCurrentStep(currentStep + 1);
                setStepComplete(false);
                setPartValidated(false);
                setCurrentPart(1);
              } else setCurrentPart(currentPart + 1);
              setPartValidated(false);
            }
            if (stepComplete) {
              setCurrentStep(currentStep + 1);
              setPartValidated(false);
              setStepComplete(false);
            }
          }}
        >
          {t('wizardFooter.next')}{' '}
          <Icon id="WizardNextIcon" name="long arrow alternate right" />
        </Button>
      )}
      {currentStep === 5 &&
        currentPart < wizardInput.partInput.length &&
        !identicalTeam && (
          <Button
            disabled={!partValidated}
            primary
            id="WizardFooterWorkflowbuttonStep5"
            floated="right"
            style={{
              marginTop: 5,
            }}
            onClick={() => {
              if (partValidated) {
                if (currentPart === wizardInput.partInput.length) {
                  setCurrentStep(currentStep + 1);
                  setStepComplete(false);
                  setPartValidated(false);
                  setCurrentPart(1);
                } else setCurrentPart(currentPart + 1);
                setPartValidated(false);
              }
            }}
          >
            {t('wizardFooter.next')}{' '}
            <Icon id="WizardNextIconStep5" name="long arrow alternate right" />
          </Button>
        )}
      {currentStep >= 5 &&
        (currentPart === wizardInput.partInput.length || identicalTeam) && (
          <Button
            primary
            disabled={!partValidated || toastVisible}
            icon
            id="WizardFooterSaveButton"
            floated="right"
            style={{
              marginTop: 5,
            }}
            onClick={() => createNewProduct()}
          >
            {t('wizardFooter.save')}{' '}
            <Icon id="WizardSaveIcon" name="save outline" />
          </Button>
        )}
      {currentStep > 1 && (
        <Button
          primary
          basic
          icon="long arrow alternate left"
          id="WizardFooterBackButton"
          floated="right"
          style={{
            marginTop: 5,
          }}
          content={t('wizardFooter.back')}
          onClick={() => {
            if (currentPart > 1) {
              if (currentStep === 4 && !identicalIntendedUse) {
                setCurrentPart(currentPart - 1);
              } else if (currentStep === 5 && !identicalTeam) {
                setCurrentPart(currentPart - 1);
              } else {
                setCurrentStep(currentStep - 1);
              }
            } else if (currentStep === 4 && productStructure === 2) {
              setCurrentStep(2); // jumping backwards skipping 3. step
            } else {
              if (currentStep === 5 && !identicalIntendedUse) {
                setCurrentPart(wizardInput.partInput.length);
              }
              setPartValidated(false);
              setCurrentStep(currentStep - 1);
            }
          }}
        />
      )}

      <Toast
        visible={toastVisible}
        type="error"
        header={error?.name}
        content={error?.message}
        onClose={handleToastClose}
        position="absolute bottom right"
        offset={{ y: -42 }}
      />
    </div>
  );
};

export default WizardFooter;
