import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product, Version } from '../../../../../graphql/generated/graphql';
import { commentCountForProduct } from '../helpers/logics';
import OverflowPopup from './OverflowPopup';

type PropsType = {
  product: Product | undefined;
  version: Version | undefined;
};

/**
 * basically only returns an element with the time till targetDate
 * nothing too fancy
 *
 * @param {PropsType} props takes in targetDate
 * @returns {JSX.Element} a span with text
 */
const CommentCountElement = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('dashboard.tableElements');
  const { product, version } = props;

  const commentCount = product
    ? commentCountForProduct(product)
    : version?.comments.length || 0;

  return (
    <OverflowPopup
      content={
        commentCount > 1
          ? `${commentCount} ${text('comments')}`
          : `${commentCount} ${text('comment')}`
      }
    />
  );
};

export default CommentCountElement;
