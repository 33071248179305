import React from 'react';
import { makeVar } from '@apollo/client';
import {
  ConflictRule,
  LicenseConflictRule,
  ToDo,
} from '../../../graphql/generated/graphql';
import { LicenseAggregations, LicenseFeatureItem } from './types';

/**
 * Calculates how many items can fit inside a container.
 *
 * @param {number} containerHeight - The height of the container
 * @param {number} itemHeight - The height of each item..
 * @returns {number} The maximum number of items that can fit.
 */
const calculateItemsPerPage = (containerHeight: number, itemHeight: number) => {
  return Math.floor(containerHeight / itemHeight);
};

export default calculateItemsPerPage;

/**
 * Formats the date to the human friendly format
 *
 * @param {string} dateString - ISO 8601 string date format
 * @param {string} locale - locale string
 * @returns {string} formatted string date
 */
export const formatDate = (dateString: string, locale: string): string => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(new Date(dateString));
};

/**
 * Type guard function to check if an item is a ToDo
 *
 * @param {any} item - item in licence feature array
 * @returns {boolean} indicating if item exists and is of type ToDo
 */
export const isToDo = (item: LicenseFeatureItem): item is ToDo => {
  return item !== null && item.__typename === 'ToDo';
};

/**
 * Apollo Reactive Variable to maintain aggregation state
 */
export const aggregationDataVar = makeVar({
  totalAmount: 0,
  verifiedCount: 0,
  unverifiedCount: 0,
});

/**
 * Highlights searchString in text
 *
 * @param {string} text - destination text
 * @param {string} highlight - search string
 * @returns {JSX.Element} -  element
 */
export const generateHighlightedText = (
  text: string,
  highlight: string
): JSX.Element => {
  // Escape regex characters in the highlight string
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const escapedHighlight = escapeRegExp(highlight);
  const parts = text?.split(new RegExp(`(${escapedHighlight})`, 'gi'));

  return (
    <span>
      {parts?.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span
            key={`${index + 1}`}
            style={{ fontWeight: 'bold', color: '#0F97C7' }}
          >
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
};

/**
 * Check if its ConflictRule
 *
 * @param {object} rule - rule
 * @returns {JSX.Element} -  boolean
 */
export const isConflictRule = (
  rule: LicenseFeatureItem
): rule is ConflictRule => {
  return rule?.__typename === 'ConflictRule';
};

/**
 * Check if its LicenseConflictRule
 *
 * @param {object} rule - rule
 * @returns {JSX.Element} -  boolean
 */
export const isLicenseConflictRule = (
  rule: LicenseFeatureItem
): rule is LicenseConflictRule => {
  return rule?.__typename === 'LicenseConflictRule';
};

/**
 * total amount based on the active filters.
 *
 * @param {[]} activeFilters active filters
 * @param {LicenseAggregations} aggs aggregations
 * @returns {boolean} -  totalAMount
 */
export const getTotalAmount = (
  activeFilters: Array<{ [x: string]: boolean }>,
  aggs: LicenseAggregations
) => {
  const { verifiedCount, totalAmount } = aggs;
  if (activeFilters.some((filter) => filter.verified === true)) {
    return verifiedCount;
  }
  if (activeFilters.some((filter) => filter.verified === false)) {
    return totalAmount - verifiedCount;
  }
  return totalAmount;
};
