import React from 'react';
import { convertISOtoLocalDate } from '../../../../helpers/logics';
import i18n from '../../../../../../../i18n/i18n';

type Props = {
  date: string | undefined | null;
};

/**
 *
 * @param {object} props contains date as unreadable string.
 * @returns {JSX.Element} DateElement.
 */
const DateElement = (props: Props) => {
  const { date } = props;
  let readableDate = '';
  if (date) readableDate = convertISOtoLocalDate(date, i18n.language);
  return <span>{readableDate}</span>;
};

export default DateElement;
