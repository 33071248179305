import { gql } from '@apollo/client';

/**
 *  Adding a directory/Brand and directory Role as access to it for the user
 */
const ADD_USER_BRAND_ACCESS = gql`
mutation AddUserBrandAccess($userId: ID!, $directoryId: ID!, $role: DirectoryRole! ) {
    AddUserBrandAccess(userId: $userId, directoryId: $directoryId, role: $role) {
      id
      legals { id name }
      osos { id name }
  }
}
`;
export default ADD_USER_BRAND_ACCESS;

/**
 *  Removing  the directory and its access
 */
export const REMOVE_USER_BRAND_ACCESS = gql`
  mutation RemoveUserBrandAccess($userId: ID!, $directoryId: ID!, $role: DirectoryRole! ) {
    RemoveUserBrandAccess(userId: $userId, directoryId: $directoryId, role: $role) {
      id
      legals { id name }
      osos { id name }
  }
}
`;

/**
 *  Removing  the directory and its access
 */
export const UPDATE_USER_BRAND_ACCESS = gql`
mutation UpdateUserBrandAccess($userId: ID!, $directoryAccess:[DirectoryAccessInput!] ) {
  UpdateUserBrandAccess(userId: $userId , directoryAccess: $directoryAccess) 
}
`;