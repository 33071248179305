import { gql } from '@apollo/client';

/**
 * User Login mutation document.
 *
 * @param {string} email the users email address
 * @param {string} password the users password
 */
export const USER_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    Login(email: $email, password: $password)
  }
`;

/**
 * Request Refresh token
 */
export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    RefreshToken
  }
`;

/**
 * User Data  Update mutation document. Expanding wiht brand(April 2023)
 */
export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String
    $password: String
    $name: String
    $status: UserStatus
    $brand: BrandDirectory
    $roles: [Role!]
  ) {
    UpdateUser(
      id: $id
      email: $email
      password: $password
      name: $name
      status: $status
      brand: $brand
      roles: $roles
    ) {
      id
      name
    }
  }
`;

/**
 * Create User mutation document. Expanding wiht brand(April 2023)
 */
export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $name: String!
    $brand: BrandDirectory!
    $roles: [Role!]!
  ) {
    CreateUser(
      email: $email
      password: $password
      name: $name
      brand: $brand
      roles: $roles
    ) {
      id
      name
    }
  }
`;
