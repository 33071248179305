import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, List, MenuItem, Modal, Popup } from 'semantic-ui-react';
import TermsOfUse from './TermsOfUse';
import PublicDownloadElement from '../downloadLinkElements/PublicDownloadElement';

/**
 *@returns {JSX.Element} Imprint and Legal page with Links
 */
const ImprintAndLegal = (): JSX.Element => {
  const [t] = useTranslation('imprintAndLegal');
  const [openTermsModal, setOpenTermsModal] = useState(false);

  return (
    <>
      <Modal
        id="ImprintAndLegalTermsOfUseModal"
        onClose={() => setOpenTermsModal(false)}
        onOpen={() => setOpenTermsModal(true)}
        open={openTermsModal}
        closeIcon
      >
        <Modal.Content>
          <TermsOfUse />
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="ImprintAndLegalTermsOfUseModalCloseBtn"
            basic
            primary
            onClick={() => {
              setOpenTermsModal(false);
            }}
          >
            <Icon
              id="ImprintAndLegalTermsOfUseModalCloseIcon"
              name="close"
              color="red"
            />
            {t('close')}
          </Button>
        </Modal.Actions>
      </Modal>
      <Popup
        id="SideBarMenuImprintUndLegal"
        on="click"
        hoverable
        trigger={
          <MenuItem
            id="SideBarMenuImprintUndLegalMenuItem"
            icon="file alternate outline"
          />
        }
        inverted
        positionFixed
        size="large"
        wide="very"
      >
        <List id="SideBarMenuImprintUndLegalList">
          <List.Item>
            <a
              id="ImprintAndLegalImprint"
              href="https://www.audi.com/de/legal-notice.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('impressum')}
            </a>
          </List.Item>
          <List.Item>
            <PublicDownloadElement
              filename="LicensesAndCopyrights.txt"
              elementName="lAndC"
            />
          </List.Item>
          <List.Item as="a" onClick={() => setOpenTermsModal(true)}>
            {t('termsOfUse')}
          </List.Item>
          <List.Item>
            <a
              id="ImprintAndLegalLegal"
              href="https://www.audi.com/de/legal.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('copyright')}
            </a>
          </List.Item>

          <List.Item>
            <a
              id="ImprintAndLegalDataProtection"
              href="https://www.audi.com/de/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('dataProtection')}
            </a>
          </List.Item>
        </List>
      </Popup>
    </>
  );
};

export default ImprintAndLegal;
