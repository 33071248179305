import React, { ChangeEvent, useContext } from 'react';
import { Form, Header, Item, Select } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { DistributionIds, OptionTypes } from '../WizardConstants';

import WizardFormFieldLabel from '../WizardFormFieldLabel';
import WizardContext from '../../../context/WizardContext';
import useWizardPartInputForIntendedUse from './useWizardPartInputForIntendedUse';

/**
 *
 *@returns {JSX.Element} First column with distribution selection fields
 */
const WizardIntendedUseDistribution = (): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const IntendedUseUpdate = useWizardPartInputForIntendedUse(); // Function that populates the WizardInput -partInput properties

  const {
    wizardInput,
    chosenDist,
    setChosenDist,
    currentPart,
    identicalIntendedUse,
  } = useContext(WizardContext);
  const wizPart = wizardInput.partInput;

  /**
   * selecting IntendedUse Distribution type
   *
   */
  const distOptions = [
    {
      key: 'binaryCode',
      text: t('intendedUsePart.distribution-binaryCode'),
      value: 'binaryCode',
    },

    {
      key: 'noDistribution',
      text: t('intendedUsePart.distribution-noDistribution'),
      value: 'noDist',
    },
    {
      key: 'distSource',
      text: t('intendedUsePart.distribution-distSource'),
      value: 'distSource',
    },
  ];

  const sourceRecipients = [
    {
      key: 'internalSourceRecipient',
      text: t('intendedUsePart.recipient-internal'),
      value: DistributionIds.DISTRIBUTIONSOURCEINTERNAL,
    },
    {
      key: 'externalSourceRecipient',
      text: t('intendedUsePart.recipient-external'),
      value: DistributionIds.DISTRIBUTIONSOURCEEXTERNAL,
    },
  ];

  const binaryRecipients = [
    {
      key: 'internalBinary',
      text: t('intendedUsePart.recipient-internal'),
      value: DistributionIds.DISTRIBUTIONBINARYINTERNAL,
    },
    {
      key: 'externalBinary',
      text: t('intendedUsePart.recipient-external'),
      value: DistributionIds.DISTRIBUTIONBINARYEXTERNAL,
    },
  ];

  const serverUsage = [
    {
      key: 'serverYes',
      text: t('intendedUsePart.server-yes'),
      value: DistributionIds.SERVERUSAGE,
    },
    {
      key: 'serverNo',
      text: t('intendedUsePart.server-no'),
      value: DistributionIds.NODISTRIBUTION,
    },
  ];

  return (
    <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
      <Item id="WizardProductDetailsInput.General">
        <Item.Content>
          <Item.Header>{t('intendedUsePart.intendedUseTitle')}</Item.Header>
          <Item.Extra
            style={{
              fontSize: '18px',
              display: 'inline',
            }}
          >
            {t('intendedUsePart.distribution')}
          </Item.Extra>
          {!identicalIntendedUse && (
            <Header size="small">
              {`${
                wizardInput.partInput[currentPart - 1]?.name || ''
              } ${currentPart} / ${wizardInput.partInput.length}`}
            </Header>
          )}
          <Item.Description>
            <Form
              id="WizardIntendedUse.Distribution"
              style={{ marginTop: '40px' }}
            >
              <WizardFormFieldLabel
                nameText={t('intendedUsePart.distribution')}
                infoText={t('intendedUsePart.distribution-info')}
                htmlFor="distribution"
              />
              <Form.Field
                id="distribution"
                control={Select}
                options={distOptions}
                defaultOpen={false}
                value={chosenDist[currentPart - 1]?.dist || ''}
                placeholder={t('pleaseSelect')}
                onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                  IntendedUseUpdate('', 'distribution'); // clears distribution from useContext to be certain the field is validated when this is changed
                  setChosenDist([
                    ...chosenDist.slice(0, currentPart - 1),
                    { ...chosenDist[currentPart - 1], dist: value },
                    ...chosenDist.slice(currentPart),
                  ]);
                }}
                style={{ width: '65%' }}
              />
              {chosenDist[currentPart - 1]?.dist === 'noDist' && (
                <>
                  <WizardFormFieldLabel
                    nameText={t('intendedUsePart.serverUsage')}
                    infoText={t('intendedUsePart.serverUsage-info')}
                    htmlFor="serverUsage"
                  />
                  <Form.Field
                    id="serverUsage"
                    name="distribution"
                    control={Select}
                    options={serverUsage}
                    value={wizPart[currentPart - 1]?.distribution || ''}
                    placeholder={t('pleaseSelect')}
                    onChange={(
                      _e: ChangeEvent,
                      { value, name }: OptionTypes
                    ) => {
                      IntendedUseUpdate(value, name);
                    }}
                    style={{ width: '65%' }}
                  />
                </>
              )}
              {chosenDist[currentPart - 1]?.dist === 'distSource' && (
                <>
                  <WizardFormFieldLabel
                    nameText={t('intendedUsePart.sourceRecipients')}
                    infoText={t('intendedUsePart.sourceRecipients-info')}
                    htmlFor="sourceRecipients"
                  />
                  <Form.Field
                    id="sourceRecipients"
                    control={Select}
                    options={sourceRecipients}
                    name="distribution"
                    value={wizPart[currentPart - 1]?.distribution || ''}
                    placeholder={t('pleaseSelect')}
                    onChange={(
                      _e: ChangeEvent,
                      { value, name }: OptionTypes
                    ) => {
                      IntendedUseUpdate(value, name);
                    }}
                    style={{ width: '65%' }}
                  />
                </>
              )}
              {chosenDist[currentPart - 1]?.dist === 'binaryCode' && (
                <>
                  <WizardFormFieldLabel
                    nameText={t('intendedUsePart.binaryRecipients')}
                    infoText={t('intendedUsePart.binaryRecipients-info')}
                    htmlFor="binaryRecipients"
                  />
                  <Form.Field
                    id="binaryRecipients"
                    name="distribution"
                    control={Select}
                    options={binaryRecipients}
                    value={wizPart[currentPart - 1]?.distribution || ''}
                    placeholder={t('pleaseSelect')}
                    onChange={(
                      _e: ChangeEvent,
                      { value, name }: OptionTypes
                    ) => {
                      IntendedUseUpdate(value, name);
                    }}
                    style={{ width: '65%' }}
                  />
                </>
              )}
            </Form>
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default WizardIntendedUseDistribution;
