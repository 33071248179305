import React, { ChangeEvent, useState } from 'react';
import { Form, Input, Icon, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ChangeEventProps } from '../../../fossDependencyList/helpers/DependencyListTypes';

type UpdateInputFieldProp = {
  newCopyright: string;
  copyrights: string[];
  setNewCopyright: (name: string) => void;
  addCopyright: () => void;
};

/**
 *@returns {JSX.Element} Input field for new copyright
 @param {UpdateInputFieldProp} props Props
 */
const ProductLicenseDetailsUpdateInputField = (
  props: UpdateInputFieldProp
): JSX.Element => {
  const [text] = useTranslation('productDetails');
  const { newCopyright, copyrights, setNewCopyright, addCopyright } = props;
  const [duplicate, setDuplicate] = useState(false); // checking duplicates!

  // checking if inputed copyright perhaps already exists!
  const checkDuplicates = (name: string) => {
    const trimedOf = name.trim(); // securing no white space gets in
    if (copyrights.includes(trimedOf)) {
      return setDuplicate(true);
    }
    return setDuplicate(false);
  };

  return (
    <>
      {' '}
      <Form id="LicensesElementModalCopyrightsInput">
        <Form.Field
          className="LicensesElementModalCopyrightsInputField"
          width={8}
          inline
        >
          <Input
            id="LicensesElementModalCopyrightsInputField"
            value={newCopyright}
            onChange={(_e: ChangeEvent, { value }: ChangeEventProps) => {
              checkDuplicates(value);
              setNewCopyright(value);
            }}
          />
          {duplicate && (
            <Label
              id="LicensesElementModalCopyrightsInputFieldError"
              color="red"
              pointing="left"
              content={text('dependencyList.licenseModal.existingNameErr')}
            />
          )}
          <Icon
            id="LicensesElementModalCopyrightsInputIcon"
            className="LicensesElementModalCopyrightsInputIcon"
            disabled={newCopyright.length < 1 || duplicate}
            name="add circle"
            color="blue"
            size="large"
            onClick={addCopyright}
          />
        </Form.Field>
      </Form>
    </>
  );
};

export default ProductLicenseDetailsUpdateInputField;
