/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, List } from 'semantic-ui-react';
import { RequirementInfo } from '../../../../graphql/generated/graphql';
import CommentsModalWindow from '../comments/CommentsModalWindow';
import { CommentsModalContext } from './helper/Constants';
import { OpenCommentsModalState } from '../comments/helper/types';

interface Props {
  reqIndex: number;
  requirementInfo: RequirementInfo;
}

/**
 * @returns {JSX.Element} The Requirement  Component
 * @param {Props} props { reqIndex, requirementInfo }
 */
const RequirementsListItem = (props: Props): JSX.Element => {
  const { i18n, t } = useTranslation('requirements');
  const { requirementInfo, reqIndex } = props;
  const { name_en, name_de } = requirementInfo.requirement;
  const [openComments, setOpenComments] = useState<OpenCommentsModalState>([
    false,
    null,
  ]);
  const requirementName = i18n.language === 'de' ? name_de : name_en;

  return (
    <>
      <CommentsModalWindow
        openCommentsModal={openComments}
        setOpenCommentsModal={setOpenComments}
        elementName={requirementName}
        refersToId={requirementInfo.requirement.id}
        requirementInfo={requirementInfo}
      />
      <List.Item>
        <List.Content className="requirement-list-item">
          <Label id="RequirementIndex" circular content={reqIndex + 1} basic />
          <span>{requirementName}</span>
          <Button
            id="OpenRequirementButton"
            className="open-requirement-button"
            onClick={() =>
              setOpenComments([true, CommentsModalContext.Requirements])
            }
          >
            <Label
              id="AffectedComponentsNumber"
              content={requirementInfo.dependencyCount}
            />
            <span>{t('affectedComponents')}</span>
            <div>
              <Icon
                id="OpenRequirementIcon"
                name="arrow alternate circle right outline"
                color="blue"
                size="large"
              />
            </div>
          </Button>
        </List.Content>
      </List.Item>
    </>
  );
};
export default RequirementsListItem;
