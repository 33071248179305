import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Item, Message } from 'semantic-ui-react';
import WizardContext from '../../../context/WizardContext';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
import { WizardTeamModalProps } from './helper/types';

/**
 *@param {object} usersOptions allUsers as options for a Dropdown selection
 * @param {[]} usersOptions.usersOptions type
 * @returns {JSX.Element} Team manager column
 */
const WizardTeamSupplier = ({
  usersOptions,
}: WizardTeamModalProps): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const { wizardInput, setOpenTeamModal, currentPart } =
    useContext(WizardContext);
  const [visible] = useState(true);
  const supplierTeam = wizardInput.partInput[currentPart - 1]?.supplier;

  return (
    <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
      <Item id="WizardTeamSupplierTitle">
        <Item.Content>
          <Item.Header>{t('wizardsTeam.teamTitle')}</Item.Header>
          <Item.Extra
            style={{
              fontSize: '18px',
              display: 'inline',
            }}
          >
            {t('wizardsTeam.supplier')}
          </Item.Extra>
          <Item.Description
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              marginTop: '40px',
            }}
          >
            <Form id="WizardTeamSupplierForm">
              <WizardFormFieldLabel
                optional
                nameText={t('wizardsTeam.supplier')}
                infoText={t('wizardsTeam.supplier-info')}
                htmlFor="WizardTeamSupplierAddbtn"
              />
            </Form>
            <Button
              id="WizardTeamSupplierAddbtn"
              circular
              icon="plus"
              size="mini"
              onClick={() => {
                setOpenTeamModal([true, 'Supplier']);
              }}
            />
          </Item.Description>
          {supplierTeam &&
            supplierTeam
              .filter((uID) => uID !== '')
              .map((uID) => usersOptions.find((user) => user.value === uID))
              .map((user) => user?.text)
              .map((supp) => <div key={`${supp}+supplier`}>{supp}</div>)}
          {supplierTeam && supplierTeam?.length > 0 && visible === true && (
            <Message
              id="WizardTeamSupplierWarning"
              warning
              icon="exclamation triangle"
              header={t('wizardsTeam.supplierNote-header')}
              content={t('wizardsTeam.supplierNote')}
            />
          )}
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default WizardTeamSupplier;
