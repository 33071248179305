import React, { Fragment } from 'react';
import { Columns } from '../../../../productsList/listComponents/ProductListTypes';
import { FileData } from '../../../../../../graphql/generated/graphql';
import StorageListElement from './StorageListElement';
import { SetAdditionalData } from '../customHooks/useTableSorting';

type PropsType = {
  versionId: string;
  columns: Columns;
  files: Array<FileData>;
  dispatch: React.Dispatch<SetAdditionalData>;
};
/**
 *
 * @param {object} props contains columns, files and versionId and dispatch, which is only passed to children.
 * @returns {JSX.Element} StorageListBody.
 */
const StorageListBody = (props: PropsType) => {
  const { columns, files, versionId, dispatch } = props;
  return (
    <>
      {files.map((file: FileData) => {
        return (
          <Fragment key={file.created}>
            <StorageListElement
              file={file}
              columns={columns}
              versionId={versionId}
              dispatch={dispatch}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default StorageListBody;
