import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';
import StandardSoftwareModal from './StandardSoftwareModal';

/**
 * @returns {JSX.Element}  Standard Software Component for Info Box
 */
const StandardSoftwareItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const [openModal, setOpenModal] = useState(false);
  const { inLegalCheckOrDone } = useContext(ProductDetailsContext);
  const { version, versionInput, editing } = useContext(ProductInfoBoxContext);
  const standardSoftware =
    versionInput.standardSoftware ?? version?.product?.standardSoftware;

  return (
    <>
      <StandardSoftwareModal open={openModal} setOpen={setOpenModal} />
      <Item id="standardSoftwareContent">
        <Item.Content>
          <Item.Meta>
            {/* ICON INFO IN CIRCLE */}

            {t('softwareType')}
            <InfoIconTooltip infoText={t('softwareType-info')} />
            {editing && !inLegalCheckOrDone && (
              <Item.Extra
                className="edit-link"
                onClick={() => setOpenModal(true)}
              >
                {t('editStandardSoftware', { ns: 'buttons' })}
              </Item.Extra>
            )}
          </Item.Meta>
          <Item.Description style={{ color: 'black', fontWeight: 'bold' }}>
            {t(standardSoftware ? 'standardSoftware' : 'individualSoftware')}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
};

export default StandardSoftwareItem;
