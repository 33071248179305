import React from 'react';
import { Grid, Placeholder, Segment } from 'semantic-ui-react';

/**
 * Header Bar Placeholder for the Product Details pages.
 *
 * @returns {JSX.Element} HeaderPlaceholder
 */
const HeaderPlaceholder = (): JSX.Element => {
  const multiply = () => {
    const tagElements = [];
    const menuLinks = [];
    // eslint-disable-next-line
    for (let i = 0; i < 4; i++) {
      tagElements.push(
        <Grid.Column key={`tag${i}`}>
          <Placeholder className="tag-placeholder">
            <Placeholder.Line length="full" />
          </Placeholder>
        </Grid.Column>
      );
      menuLinks.push(
        <Grid.Column key={`link${i}`}>
          <Placeholder>
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
      );
    }
    return {
      tagElements,
      menuLinks,
    };
  };

  return (
    <Segment
      id="ProductDetailsHeader"
      basic
      raised
      style={{
        padding: '1rem 2rem 0.5rem 2rem',
      }}
    >
      <Grid columns={16} style={{ minHeight: '25vh' }}>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column width={4} floated="left">
            <Placeholder fluid>
              <Placeholder.Header>
                <Placeholder.Line length="very short" />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={8}>
            <Placeholder fluid>
              <Placeholder.Header>
                <Placeholder.Line length="full" />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={3} />
          <Grid.Column width={1}>
            <Placeholder fluid>
              <Placeholder.Header>
                <Placeholder.Line length="full" />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width={6} />
          {multiply().tagElements}
          <Grid.Column width={4} />
          <Grid.Column width={2}>
            <Placeholder fluid>
              <Placeholder.Header>
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div style={{ position: 'absolute', bottom: 12, width: '30%' }}>
        <Grid verticalAlign="bottom" columns={4}>
          {multiply().menuLinks}
        </Grid>
      </div>
    </Segment>
  );
};

export default HeaderPlaceholder;
