import { useQuery } from '@apollo/client';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Icon,
  Label,
  Menu,
  Message,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import {
  ALL_REQUIREMENTS_COMMENTS,
  ALL_TODOS_COMMENTS,
} from '../../../../../graphql/queries/CommentQuerys';
import ErrorAndLoadingMessage from './ErrorAndLoadingMessage';
import Discussions, { DiscussionsReset } from '../Discussions';
import GenericClosedSegmentSkeleton from '../../skeletons/GenericClosedSegmentSkeleton';
import { Comment } from '../../../../../graphql/generated/graphql';
import {
  extractFeaturesComments,
  filterReferences,
  getReferenceDetails,
} from '../helper/logics';

/**
 *@returns {JSX.Element} Questions Comments Segment returns an overview of all Questions comments of a version/product
 */
const QuestionsCommentsSegment = (): JSX.Element => {
  const [t, i18n] = useTranslation(['comments']);
  const i18nLang = i18n.language;
  const { versionId } = useContext(ProductDetailsContext);
  const [open, setOpen] = useState(false);

  const ref = useRef<DiscussionsReset>(null);

  const resetAndClose = () => {
    ref?.current?.reset();
    setOpen(!open);
  };

  // Query for requirements comments
  const {
    data: requirementsData,
    loading: requirementsLoading,
    error: requirementsError,
  } = useQuery(ALL_REQUIREMENTS_COMMENTS, {
    variables: { versionId },
    fetchPolicy: 'cache-and-network',
  });

  // Query for todo comments
  const {
    data: todosData,
    loading: todosLoading,
    error: todosError,
  } = useQuery(ALL_TODOS_COMMENTS, {
    variables: { versionId },
    fetchPolicy: 'cache-and-network',
  });

  const loading = requirementsLoading || todosLoading;
  const error = requirementsError || todosError;

  let allQuestionComments: Comment[] = [];
  if (requirementsData && todosData) {
    allQuestionComments = [...requirementsData.Comments, ...todosData.Comments];
  }

  if (loading && !open) {
    <GenericClosedSegmentSkeleton
      image
      lines={1}
      labels="all"
      linesLength="long"
    />;
  }

  if (requirementsData || todosData) {
    const hasComments = allQuestionComments.length > 0;
    const majorComments = allQuestionComments.filter(
      (comment: Comment) => comment.replied === null
    );

    return (
      <Segment id="QuestionsCommentsSegment">
        <Menu id="QuestionsCommentsSegmentMenu" secondary compact>
          <Menu.Item>
            <Icon
              id="QuestionsCommentsSegmentMenuAccordion"
              onClick={resetAndClose}
              name={open ? 'chevron up' : 'chevron down'}
              color={open ? 'blue' : 'black'}
            />
          </Menu.Item>
          <Menu.Item
            content={t('onQuestions')}
            style={{ fontWeight: 'bold', fontSize: '16px' }}
          />
          <Menu.Item>
            <Label id="CommentsNumberLabel" circular style={{ margin: 0 }}>
              {allQuestionComments.length}
            </Label>
            <Icon // eslint-disable-line
              size="large"
              name="question circle"
              id={`onQuestionsCommentGuideIcon-${i18nLang}`}
              style={{
                marginLeft: '0.5rem',
                display: 'none',
                // TODO: delete display none when guide is ready
              }}
            />
          </Menu.Item>
        </Menu>
        {/* Comments View -name of the question is displayed and an Icon with Popup with description */}
        {open && (
          <div className="AllCommentsSegmentCommentsOverview">
            {hasComments ? (
              filterReferences(majorComments).map((efs) => {
                return (
                  <Fragment
                    key={`AllCommentsSegmentCommentsOverviewRequirementName${efs}`}
                  >
                    <div
                      style={{ width: 'fit-content', margin: '2em 0 0 1em' }}
                    >
                      <span className="AllCommentsSegmentCommentsOverviewComponentTitle">
                        {getReferenceDetails(
                          extractFeaturesComments(
                            efs as string,
                            majorComments
                          )[0]
                        )}
                      </span>
                      <Popup
                        id="QuestionsCommentsOverviewSegmentDescriptionPopup"
                        content={getReferenceDetails(
                          extractFeaturesComments(
                            efs as string,
                            majorComments
                          )[0],
                          true
                        )}
                        position="top center"
                        trigger={
                          <Icon
                            id="QuestionsCommentsOverviewSegmentDescriptionIcon"
                            style={{ cursor: 'pointer' }}
                            name="info circle"
                            color="blue"
                          />
                        }
                      />
                      <Divider fitted />
                    </div>
                    {/* here we list the comments which together comming from above listed refered component  */}
                    <Discussions
                      ref={ref}
                      comments={extractFeaturesComments(
                        efs as string,
                        allQuestionComments
                      )}
                      refersToId={efs}
                      refetch={[ALL_REQUIREMENTS_COMMENTS, ALL_TODOS_COMMENTS]}
                    />
                  </Fragment>
                );
              })
            ) : (
              <Message
                id="QuestionsCommentsSegmentNoCommentsYet"
                info
                icon="info circle"
                header={t('noQuestionsCommentsYet')}
                content={t('noQuestionsCommentsCreate')}
              />
            )}
          </div>
        )}
      </Segment>
    );
  }
  if (error || loading) {
    return (
      <ErrorAndLoadingMessage
        error={error}
        loading={loading}
        source="onQuestions"
      />
    );
  }
  return <div />;
};

export default QuestionsCommentsSegment;
