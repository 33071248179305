import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../container/Dashboard';
import ProductDetails from '../container/ProductDetails';
import { AuthorizationsContext } from '../context/UserContext';
import Directories from '../container/views/Directories';
import LicenseView from '../container/views/LicenseView';
import FeatureGroups from '../container/views/FeatureGroups';
import NewProductWizard from '../container/NewProductWizard';
import LicenseDetail from '../components/licenses/LicenseDetail';
// import { LicenseDetailsProvider } from '../context/LicenseContext';

/**
 * React Router Routes.
 *
 * @returns {JSX.Element}  All routes in the tool.
 */
const Router = (): JSX.Element => {
  const authorizations = useContext(AuthorizationsContext);
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      {(authorizations.includes('CreateProduct') ||
        authorizations.includes('CreateWizardProduct')) && (
        <Route path="/new-product" element={<NewProductWizard />} />
      )}
      {authorizations.includes('directories') && (
        <Route path="/directories" element={<Directories />} />
      )}
      {authorizations.includes('productDetails') && (
        <Route path="/product-details/:id" element={<ProductDetails />} />
      )}
      {authorizations.includes('licenses') && (
        <Route path="/licenses" element={<LicenseView />}>
          <Route path=":licenseId" element={<LicenseDetail />} />
        </Route>
      )}
      {authorizations.includes('featureGroups') && (
        <Route path="/feature-groups" element={<FeatureGroups />} />
      )}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default Router;
