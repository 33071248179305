import React from 'react';
/**
 * Sanitize the text area change event input
 *
 * @param {React.ChangeEvent<HTMLInputElement>} event - the text area change event
 * @param {number} maxCharLimit - the maximum character limit for the text area input
 * @returns {React.SetStateAction<string>} validatedText - validated description string
 */
export const sanitizeTextAreaChange = (
  event: React.ChangeEvent<HTMLTextAreaElement>,
  maxCharLimit?: number
) => {
  const sanitizeInput = (input: string, maxEmptyLines = 2): string => {
    // allows max 2 empty lines
    const regex = new RegExp(`(\\n\\s*){${maxEmptyLines + 1},}`, 'g');
    return input.replace(regex, '\n'.repeat(maxEmptyLines));
  };

  let newText = event.target.value;

  if (maxCharLimit && newText.length > maxCharLimit) {
    newText = newText.substring(0, maxCharLimit);
  }

  const sanitizedText = sanitizeInput(newText);
  return sanitizedText;
};

/**
 * Handle the text area change event
 *
 * @param {React.ChangeEvent<HTMLInputElement>} event - the text area change event
 * @param {React.Dispatch<React.SetStateAction<string>>} setText - function to set the data state
 * @param {React.SetStateAction<number>} setCharCount - function to set the text area value
 * @param {number} maxCharLimit - the maximum character limit for the text area input
 * @returns {React.SetStateAction<string>} validatedText - validated description string
 */
const onTextAreaChange = (
  event: React.ChangeEvent<HTMLTextAreaElement>,
  setText: React.Dispatch<React.SetStateAction<string | null | undefined>>,
  setCharCount: React.Dispatch<React.SetStateAction<number>>,
  maxCharLimit?: number
) => {
  const sanitizedText = sanitizeTextAreaChange(event, maxCharLimit);
  setText(sanitizedText);
  setCharCount(sanitizedText.length);
  return sanitizedText;
};
export default onTextAreaChange;
