import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';
import {
  ChangeCategory,
  ChangeType,
  Maybe,
} from '../../../../../graphql/generated/graphql';
import {
  useGetChangeDetails,
  usePairPropertyChanges,
} from '../../helpers/customHooks';
import OverflowPopup from '../../../productsList/listComponents/columnComponents/OverflowPopup';
import HistoryElementDetailsModal from './HistoryElementDetailsModal';

type PropsType = {
  changeType: ChangeType;
  changeCategory?: ChangeCategory;
  refNode?: Maybe<string>;
  createdAt?: Maybe<string>;
};

/**
 * returns an element with the details of NodeChange type
 *
 * @param {PropsType} props takes NodeChange type as parameter, optionally refNode
 * @returns {JSX.Element} a span with text
 */
const NodeChangeElement = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('history');
  const { changeType, changeCategory, refNode, createdAt } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const changeDetails = useGetChangeDetails(refNode, changeCategory);
  const dependencyHistory = usePairPropertyChanges(
    changeDetails?.dependency?.legalResult?.history,
    createdAt
  );
  const shouldOpenModal = changeCategory === ChangeCategory.DEPENDENCY_CHANGE;
  const depName = `${changeDetails?.dependency?.componentName} ${changeDetails?.dependency?.componentVersion}`;

  if (
    (!dependencyHistory || dependencyHistory.length === 0) &&
    changeCategory === ChangeCategory.DEPENDENCY_CHANGE
  ) {
    return <span> {text(`tableBody.operations.noDepHistory`)}</span>;
  }

  switch (changeType) {
    case ChangeType.JSON_UPLOAD:
      return (
        <span className="changeType">
          {text(`tableBody.operations.${changeType}`)}
        </span>
      );
    case ChangeType.RESOLVER_START:
      return (
        <span className="changeType">
          {text(`tableBody.operations.${changeType}`)}
        </span>
      );
    case ChangeType.RESOLVER_CANCEL:
      return (
        <span className="changeType">
          {text(`tableBody.operations.${changeType}`)}
        </span>
      );
    case ChangeType.RESOLVER_END:
      return (
        <span className="changeType">
          {text(`tableBody.operations.${changeType}`)}
        </span>
      );
    case ChangeType.UPDATE:
      return (
        <>
          <OverflowPopup
            contentClassName="overflow-popup-history-content"
            isHistoryWithModal={shouldOpenModal}
            position="top left"
            content={
              <List id="NodeChange" horizontal>
                <List.Item className="changeType">
                  <List.Content>
                    <List.Header
                      className="property"
                      onClick={shouldOpenModal ? toggleModal : undefined}
                    >
                      {text(`tableBody.operations.${changeCategory}`, {
                        depName,
                      })}
                    </List.Header>
                  </List.Content>
                </List.Item>
                {shouldOpenModal && (
                  <List.Item
                    as={shouldOpenModal ? 'a' : ''}
                    onClick={shouldOpenModal ? toggleModal : undefined}
                    className="show-more-history-log"
                  >
                    {text('buttons:showMore')}
                  </List.Item>
                )}
              </List>
            }
          />
          <HistoryElementDetailsModal
            open={isModalOpen}
            setOpen={toggleModal}
            changeCategory={changeCategory}
            dependencyHistory={dependencyHistory}
            changeDetails={changeDetails}
          />
        </>
      );
    default:
      break;
  }

  return <span>-</span>;
};

export default NodeChangeElement;
