import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

type LicenseFilterPillProps = {
  verifiedAmount: number;
  notVerifiedAmount: number;
  activeFilters: { [x: string]: boolean }[];
  setActiveFilters: React.Dispatch<
    React.SetStateAction<{ [x: string]: boolean }[]>
  >;
};

/**
 * LicenseFilterPills
 *
 * @param {LicenseFilterPillProps} props takes in licenses
 * @returns {JSX.Element} LicenseFilterPills
 */
const LicenseFilterPills = (props: LicenseFilterPillProps): JSX.Element => {
  const { verifiedAmount, notVerifiedAmount, activeFilters, setActiveFilters } =
    props;
  const { t } = useTranslation('licenseList');

  const toggleFilter = (isVerified: boolean) => {
    const isCurrentlyActive = activeFilters.some(
      (filter) => filter.verified === isVerified
    );
    if (activeFilters.length && isCurrentlyActive) {
      setActiveFilters([]);
    } else {
      setActiveFilters([{ verified: isVerified }]);
    }
  };

  return (
    <div id="LicenseFilterPills">
      <Button
        id="FilterPillVerified"
        size="small"
        active={activeFilters.some((filter) => filter.verified === true)}
        onClick={() => toggleFilter(true)}
      >
        <Icon id="IconVerifiedPill" name="circle" size="small" />
        {`${t('license-list.pills.verified')} (${verifiedAmount})`}
      </Button>
      <Button
        id="FilterPillUnverified"
        size="small"
        active={activeFilters.some((filter) => filter.verified === false)}
        onClick={() => toggleFilter(false)}
      >
        <Icon id="IconUnverifiedPill" name="circle" size="small" />
        {`${t('license-list.pills.notVerified')} (${notVerifiedAmount})`}
      </Button>
    </div>
  );
};

export default LicenseFilterPills;
