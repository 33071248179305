import { useTranslation } from 'react-i18next';
import {
  Form,
  Grid,
  Header,
  Icon,
  Input,
  InputOnChangeData,
  Item,
  Popup,
} from 'semantic-ui-react';
import React from 'react';
import IconCheckbox from '../WizardIconCheckbox';
import { PartForSelection } from '../WizardConstants';
import InfoIconTooltip from '../../ui/InfoIconTooltip';

type Props = {
  parts: PartForSelection[];
  isValidPart: (part: PartForSelection) => boolean;
  isValidPartName: (name: string) => boolean;
  onClickCheckbox: (part: PartForSelection) => void;
  onChangePartName: (part: PartForSelection, data: InputOnChangeData) => void;
  onClickAddPart: () => void;
};

/**
 * Parts | For all Parts Column of WizardPartsSegment
 *
 * @param {object} props onChangeTeamOption, onChangeUseOption
 *@returns {JSX.Element} Parts | For all Parts - Grid.Column
 */
const WizardPartsGeneralColumn = (props: Props): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const {
    parts,
    isValidPart,
    isValidPartName,
    onClickCheckbox,
    onChangePartName,
    onClickAddPart,
  } = props;

  /* Checks */
  const showPartError = (part: PartForSelection): boolean => {
    return !isValidPartName(part.name) && part.checked;
  };

  return (
    <Grid.Column
      style={{ height: '100%', padding: '0 0 0 1rem', overflow: 'auto' }}
    >
      <Item.Group relaxed="very">
        <Item id="General" style={{ margin: '0' }}>
          <Item.Content>
            <Item.Header>{t('parts.parts')}</Item.Header>
            <span
              style={{
                fontSize: '18px',
                display: 'inline',
              }}
            >
              {t('parts.general')}
            </span>
            <Item.Meta>{t('parts.description.general')}</Item.Meta>
          </Item.Content>
        </Item>
      </Item.Group>
      <Item.Group relaxed="very">
        <Item id="IncludedParts">
          <Item.Content>
            <InfoIconTooltip
              infoText={t('parts.popup.included.parts')}
              on="click"
            >
              <Header as="h4" style={{ marginBottom: '0.5rem' }}>
                {t('parts.included.parts')}
                <span style={{ color: 'red' }}> *</span>
              </Header>
            </InfoIconTooltip>
            <Form id="IncludedParts.Form">
              {parts.map((part, index) => {
                return (
                  <Popup
                    id="Popup"
                    key={part.id}
                    position="top left"
                    hoverable
                    open={showPartError(part)}
                    trigger={
                      <Form.Field
                        id={`IncludedParts.Form.Field.${part.id}`}
                        error={showPartError(part)}
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                          margin: '1rem 0 0 0',
                        }}
                      >
                        {/* Part Checkbox */}
                        <IconCheckbox
                          id={`Checkbox${part.id}`}
                          checked={part.checked}
                          valid={isValidPart(part)}
                          size="large"
                          onClick={() => onClickCheckbox(part)}
                        />
                        {/* Part Name Input */}
                        <Input
                          key={`Input${part.id}`}
                          id={`Input${index}`}
                          transparent
                          inverted
                          value={part.name}
                          style={
                            isValidPart(part)
                              ? { color: 'black', margin: '0 0 0 0.75rem' }
                              : { color: 'lightgray', margin: '0 0 0 0.75rem' }
                          }
                          onChange={(event, data) =>
                            onChangePartName(part, data)
                          }
                        />
                      </Form.Field>
                    }
                  >
                    {/* Warning Popup */}
                    <Icon
                      name="exclamation triangle"
                      id="ErrorIcon"
                      color="red"
                    />
                    {t('parts.warning.min.name.length')}
                  </Popup>
                );
              })}
              {/* Add Part Button-Icon */}
              <Icon
                id="IncludedParts.AddButtonIcon"
                name="plus square outline"
                size="large"
                color="blue"
                style={{ margin: '1rem 0 0 0', textAlign: 'left' }}
                onClick={() => onClickAddPart()}
              />
            </Form>
          </Item.Content>
        </Item>
      </Item.Group>
    </Grid.Column>
  );
};

export default WizardPartsGeneralColumn;
