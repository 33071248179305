import { DocumentNode, useMutation, useQuery } from '@apollo/client';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Message } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';
import {
  ProductInfoBoxQuery,
  ProductInfoBoxQueryVariables,
  ProjectInfoBoxQuery,
  ProjectInfoBoxQueryVariables,
  UpdateVersionInput,
  UpdateVersionMutation,
  UpdateVersionMutationVariables,
} from '../../../../../graphql/generated/graphql';
import { UPDATE_VERSION } from '../../../../../graphql/mutations/VersionMutations';
import {
  PRODUCT_INFOBOX,
  PROJECT_INFOBOX,
} from '../../../../../graphql/queries/VersionQuerys';
import InfoboxPlaceholder from '../../skeletons/InfoboxSkeleton';
import usePartialState from '../helper/customHooks';

interface Props {
  children: JSX.Element;
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  query: typeof PRODUCT_INFOBOX | typeof PROJECT_INFOBOX;
  refetchQueries: DocumentNode[];
}

/**
 * @param {Props} props {children, editing, setEditing, query, refetchQueries}
 * @returns {JSX.Element} Info Box Component
 */
const InfoBoxProvider = (props: Props): JSX.Element => {
  const { children, editing, setEditing, query, refetchQueries } = props;
  const [versionInput, setVersionInput, setPartialVersionInput] =
    usePartialState<UpdateVersionInput>({});

  const { versionId, processStatusAsString } = useContext(
    ProductDetailsContext
  );

  useEffect(() => {
    setEditing(false);
  }, [processStatusAsString, setEditing]);

  const { data, loading, error } = useQuery<
    ProductInfoBoxQuery | ProjectInfoBoxQuery,
    ProductInfoBoxQueryVariables | ProjectInfoBoxQueryVariables
  >(query, {
    variables: { id: versionId },
  });

  const [updateVersionMutation, updateVersionMutationResult] = useMutation<
    UpdateVersionMutation,
    UpdateVersionMutationVariables
  >(UPDATE_VERSION, {
    variables: {
      versionId,
      versionInput,
    },
    refetchQueries,
  });

  const productInfoBoxContext = useMemo(() => {
    return {
      editing,
      version: data?.Version,
      versionInput,
      setEditing,
      setVersionInput,
      setPartialVersionInput,
      updateVersionMutation,
      updateVersionMutationResult,
    };
  }, [
    editing,
    data?.Version,
    versionInput,
    setEditing,
    setVersionInput,
    setPartialVersionInput,
    updateVersionMutation,
    updateVersionMutationResult,
  ]);

  if (error)
    return (
      <Message
        id="InfoBoxError"
        error
        icon="times circle"
        content={`Error ${error.message}`}
      />
    );

  if (loading) return <InfoboxPlaceholder />;

  if (data) {
    return (
      <ProductInfoBoxContext.Provider value={productInfoBoxContext}>
        {children}
      </ProductInfoBoxContext.Provider>
    );
  }
  return <div />;
};

export default InfoBoxProvider;
