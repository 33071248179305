import {
  ApolloError,
  ApolloQueryResult,
  DocumentNode,
  OperationVariables,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import {
  AdditionalLicenseFeature,
  ConflictRule,
  GetAllAdditionalLicenseFeaturesQuery,
  GetAllAdditionalLicenseFeaturesQueryVariables,
  GetAllConflictRulesQuery,
  GetAllConflictRulesQueryVariables,
  GetAllLicenseConflictRulesQuery,
  GetAllLicenseConflictRulesQueryVariables,
  GetAllLicenseFeaturesQuery,
  GetAllLicenseFeaturesQueryVariables,
  GetAllTodosQuery,
  GetAllTodosQueryVariables,
  LicenseConflictRule,
  LicenseFeature,
  LicenseTodosByIdQuery,
  LicenseTodosByIdQueryVariables,
  SortDirection,
  ToDo,
  User,
} from '../../../graphql/generated/graphql';
import { USER_BY_ID } from '../../../graphql/queries/UserQuerys';
import {
  FeatureType,
  FeatureTypeName,
  useLicenseContext,
} from '../../../context/LicenseContext';
import { LicenseFeatureArray } from './types';
import useDebounce from '../../../hooks/useDebounce';
import {
  LICENSE_ADDITIONAL_LICENSE_FEATURES_BY_ID,
  LICENSE_CONFLICT_RULES_BY_ID,
  LICENSE_LICENSE_CONFLICT_RULES_BY_ID,
  LICENSE_LICENSE_FEATURES_BY_ID,
  LICENSE_TODOS_BY_ID,
} from '../../../graphql/queries/LicenseQuery';
import {
  GET_ALL_ADDITIONAL_LICENSE_FEATURES,
  GET_ALL_CONFLICT_RULES,
  GET_ALL_LICENSE_CONFLICT_RULES,
  GET_ALL_LICENSE_FEATURES,
  GET_ALL_TODOS,
} from '../../../graphql/queries/FeatureQueries';

/**
 * @description A custom React Hook that handles refetching the list upon a sorting or filtering request.
 * @param {() => Promise<ApolloQueryResult<any>>} refetch The refetch function from useQuery.
 * @param {Array} activeFiltersArray An array of objects that hold the current active filters.
 * @param {number} activePageState The current active page number.
 * @param {number} itemsPerPage The number of items per page.
 * @param {string} searchString The current search string.
 * @returns {void}
 */
export const useRefetchLicenseList = (
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<unknown>>,
  activeFiltersArray: Array<{ [x: string]: unknown }>,
  activePageState: number,
  itemsPerPage: number,
  searchString: string
): void => {
  // used to prevent the hook from running on the initial mount
  const isInitialMount = useRef(true);
  const latchRefetch = useRef(false);
  const previousFilterString = useRef(JSON.stringify(activeFiltersArray));
  const previousSearchString = useRef(searchString);
  const previousActivePage = useRef(activePageState);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const currentFilterString = JSON.stringify(activeFiltersArray);
    const filtersChanged =
      latchRefetch.current ||
      previousFilterString.current !== currentFilterString;
    const searchStringChanged =
      latchRefetch.current || previousSearchString.current !== searchString;
    const pageChanged = previousActivePage.current !== activePageState;

    if (filtersChanged || searchStringChanged) {
      // latch to make sure no other refetch is being triggered
      latchRefetch.current = true;
      refetch({
        options: {
          sort: [{ name: 'ASC' }],
          limit: itemsPerPage,
          offset: (activePageState - 1) * itemsPerPage,
        },
        where: {
          AND: [
            {
              OR: [...activeFiltersArray],
            },
            {
              OR: [
                { name_CONTAINS: searchString },
                { spdxId_CONTAINS: searchString },
              ],
            },
          ],
        },
        shouldFetchAggregation: true,
      }).finally(() => {
        latchRefetch.current = false;
      });
    } else if (pageChanged && !latchRefetch.current) {
      refetch({
        options: {
          sort: [{ name: 'ASC' }],
          limit: itemsPerPage,
          offset: (activePageState - 1) * itemsPerPage,
        },
        shouldFetchAggregation: false,
      });
    }

    // set current values
    previousFilterString.current = currentFilterString;
    previousSearchString.current = searchString;
    previousActivePage.current = activePageState;
  }, [
    refetch,
    activeFiltersArray,
    activePageState,
    itemsPerPage,
    searchString,
  ]);
};

interface UserDetailsHookResult {
  user: User | null;
  loading: boolean;
  error: ApolloError | undefined;
}

/**
 * A custom hook to fetch user details by userID.
 *
 * @param {string} userId - The ID of the user to fetch.
 * @returns {User} The user data and query status.
 */
const useUserDetails = (userId?: string): UserDetailsHookResult => {
  const { data, loading, error } = useQuery(USER_BY_ID, {
    variables: { userId },
    skip: !userId, // Skip the query if no userId is provided
  });

  const user = data?.User.find((usr: User) => usr.id === userId) ?? null;

  return {
    user,
    loading,
    error,
  };
};

export default useUserDetails;

type useGetFeaturesReturnType = {
  data: FeatureType[] | [];
  loading: boolean;
  error: ApolloError | undefined;
};

type QueryConfig<TQuery, TVariables> = {
  query: DocumentNode;
  variablesType: TVariables;
  resultType: TQuery;
};

type QueryMapType = {
  ToDo: QueryConfig<GetAllTodosQuery, GetAllTodosQueryVariables>;
  LicenseFeature: QueryConfig<
    GetAllLicenseFeaturesQuery,
    GetAllLicenseFeaturesQueryVariables
  >;
  LicenseConflictRule: QueryConfig<
    GetAllLicenseConflictRulesQuery,
    GetAllLicenseConflictRulesQueryVariables
  >;
  ConflictRule: QueryConfig<
    GetAllConflictRulesQuery,
    GetAllConflictRulesQueryVariables
  >;
  AdditionalLicenseFeature: QueryConfig<
    GetAllAdditionalLicenseFeaturesQuery,
    GetAllAdditionalLicenseFeaturesQueryVariables
  >;
};

const QUERY_MAP: QueryMapType = {
  ToDo: {
    query: GET_ALL_TODOS,
    variablesType: {} as GetAllTodosQueryVariables,
    resultType: {} as GetAllTodosQuery,
  },
  LicenseFeature: {
    query: GET_ALL_LICENSE_FEATURES,
    variablesType: {} as GetAllLicenseFeaturesQueryVariables,
    resultType: {} as GetAllLicenseFeaturesQuery,
  },
  LicenseConflictRule: {
    query: GET_ALL_LICENSE_CONFLICT_RULES,
    variablesType: {} as GetAllLicenseConflictRulesQueryVariables,
    resultType: {} as GetAllLicenseConflictRulesQuery,
  },
  ConflictRule: {
    query: GET_ALL_CONFLICT_RULES,
    variablesType: {} as GetAllConflictRulesQueryVariables,
    resultType: {} as GetAllConflictRulesQuery,
  },
  AdditionalLicenseFeature: {
    query: GET_ALL_ADDITIONAL_LICENSE_FEATURES,
    variablesType: {} as GetAllAdditionalLicenseFeaturesQueryVariables,
    resultType: {} as GetAllAdditionalLicenseFeaturesQuery,
  },
};

/**
 * A custom hook to fetch all features based on type.
 *
 * @param {FeatureTypeName} type - type of feature.
 * @param {string} searchString - search string.
 * @param {string} language - lang
 * @returns {useGetFeaturesReturnType} data, loading, refetch.
 */
export const useGetFeatures = (
  type: FeatureTypeName,
  searchString: string,
  language: string
): useGetFeaturesReturnType => {
  const { query } = QUERY_MAP[type];
  const nameField = language === 'en' ? 'name_en' : 'name_de';

  const variables = {
    options: {
      sort: [
        { category: SortDirection.ASC },
        { [nameField]: SortDirection.ASC },
      ],
    },
    where: {
      category_CONTAINS: searchString,
      ...(language === 'en'
        ? { name_en_CONTAINS: searchString }
        : { name_de_CONTAINS: searchString }),
    },
  };

  const { data, loading, error } = useQuery<
    typeof QUERY_MAP[typeof type]['resultType'],
    typeof QUERY_MAP[typeof type]['variablesType']
  >(query, {
    variables,
  });

  let featureData: FeatureType[] | [] = [];

  if (data?.GetAllFeatures) {
    switch (type) {
      case 'ToDo':
        featureData = (data.GetAllFeatures as { ToDo?: ToDo[] }).ToDo ?? [];
        break;
      case 'LicenseFeature':
        featureData =
          (data.GetAllFeatures as { LicenseFeature?: LicenseFeature[] })
            .LicenseFeature ?? [];
        break;
      case 'LicenseConflictRule':
        featureData =
          (
            data.GetAllFeatures as {
              LicenseConflictRule?: LicenseConflictRule[];
            }
          ).LicenseConflictRule ?? [];
        break;
      case 'ConflictRule':
        featureData =
          (data.GetAllFeatures as { ConflictRule?: ConflictRule[] })
            .ConflictRule ?? [];
        break;
      case 'AdditionalLicenseFeature':
        featureData =
          (
            data.GetAllFeatures as {
              AdditionalLicenseFeature?: AdditionalLicenseFeature[];
            }
          ).AdditionalLicenseFeature ?? [];
        break;
      default:
        featureData = [];
    }
  }

  return {
    data: featureData ?? [],
    loading,
    error,
  };
};

/**
 * A custom hook that makes sure the feature counter is handled properly.
 *
 * @param {boolean} isEditMode - edit mode boolean
 * @param {string} name - name of the feature
 * @param {LicenseFeatureArray} featureArray - feature array
 * @param {(name: string) => number} countFeatures - counter of the context array
 * @param {boolean} wasSaved - determines whether editMode was left via save or cancel
 * @returns {number} featureCounter
 */
export const useFeatureCounter = (
  isEditMode: boolean,
  name: string,
  featureArray: LicenseFeatureArray,
  countFeatures: (name: string) => number,
  wasSaved: boolean
): number => {
  const [featureCounter, setFeatureCounter] = useState(featureArray.length);

  // Debounced counter, to validate the optimistic value
  const debouncedCounter = useDebounce((newCounter: number) => {
    setFeatureCounter(newCounter);
  }, 1500);

  useEffect(() => {
    if (isEditMode) {
      setFeatureCounter(countFeatures(name));
    }
    if (wasSaved) {
      // Optimistically update the counter after saving the changes
      setFeatureCounter(countFeatures(name));
    }
  }, [isEditMode, name, countFeatures, wasSaved]);

  useEffect(() => {
    if (!isEditMode && !wasSaved) {
      setFeatureCounter(featureArray.length);
    }
    // Revalidate counter with real data after refetch
    if (!isEditMode && wasSaved) {
      debouncedCounter(featureArray.length);
    }
  }, [featureArray, isEditMode, name, wasSaved, debouncedCounter]);

  return featureCounter;
};

/**
 * A custom hook that fetches chosen features from chosen license.
 *
 * @param {string} selectedLicense - Licesne ID
 * @param {FeatureTypeName} type - name of the feature
 * @returns {void} void
 */
export const useGetLicenseFeatures = (
  selectedLicense: string | null,
  type: FeatureTypeName
) => {
  const {
    setTodos,
    setLicenseFeatures,
    setConflictRules,
    setLicenseConflictRules,
    setAdditionalLicenseFeatures,
  } = useLicenseContext();

  const [fetchTodos] = useLazyQuery<
    LicenseTodosByIdQuery,
    LicenseTodosByIdQueryVariables
  >(LICENSE_TODOS_BY_ID, {
    onCompleted: (data) => {
      const license = data?.License?.[0];
      const todos = license?.toDos?.filter(
        (todo): todo is NonNullable<typeof todo> => todo !== null
      );
      if (license && todos && todos.length > 0) {
        setTodos(todos as ToDo[]);
      }
    },
  });

  const [fetchLicenseFeatures] = useLazyQuery(LICENSE_LICENSE_FEATURES_BY_ID, {
    onCompleted: (data) => {
      const license = data?.License?.[0];
      if (license && license.licenseFeatures) {
        setLicenseFeatures(license.licenseFeatures);
      }
    },
  });
  const [fetchConflictRules] = useLazyQuery(LICENSE_CONFLICT_RULES_BY_ID, {
    onCompleted: (data) => {
      const license = data?.License?.[0];
      if (license && license.conflictRules) {
        setConflictRules(license.conflictRules);
      }
    },
  });
  const [fetchLicenseConflictRules] = useLazyQuery(
    LICENSE_LICENSE_CONFLICT_RULES_BY_ID,
    {
      onCompleted: (data) => {
        const license = data?.License?.[0];
        if (license && license.licenseConflictRules) {
          setLicenseConflictRules(license.licenseConflictRules);
        }
      },
    }
  );
  const [fetchAdditionalLicenseFeatures] = useLazyQuery(
    LICENSE_ADDITIONAL_LICENSE_FEATURES_BY_ID,
    {
      onCompleted: (data) => {
        const license = data?.License?.[0];
        if (license && license.additionalLicenseFeatures) {
          setAdditionalLicenseFeatures(license.additionalLicenseFeatures);
        }
      },
    }
  );

  useEffect(() => {
    if (selectedLicense) {
      switch (type) {
        case 'ToDo':
          fetchTodos({ variables: { licenseId: selectedLicense } });
          break;
        case 'LicenseFeature':
          fetchLicenseFeatures({ variables: { licenseId: selectedLicense } });
          break;
        case 'ConflictRule':
          fetchConflictRules({ variables: { licenseId: selectedLicense } });
          break;
        case 'LicenseConflictRule':
          fetchLicenseConflictRules({
            variables: { licenseId: selectedLicense },
          });
          break;
        case 'AdditionalLicenseFeature':
          fetchAdditionalLicenseFeatures({
            variables: { licenseId: selectedLicense },
          });
          break;
        default:
          break;
      }
    }
  }, [
    selectedLicense,
    type,
    fetchTodos,
    fetchLicenseFeatures,
    fetchConflictRules,
    fetchLicenseConflictRules,
    fetchAdditionalLicenseFeatures,
  ]);
};
