/* eslint-disable func-names */
(function (w, d, s) {
  const a = d.getElementsByTagName('head')[0];
  const r = d.createElement('script');
  r.async = true;
  r.src = s;
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = '0192cc9c619fa861bde84b793dcfa8c6';
  a.appendChild(r);
})(window, document, 'https://www.usetiful.com/dist/usetiful.js');
