import React, { Fragment } from 'react';
import { ChangeLog } from '../../../../graphql/generated/graphql';
import { Columns } from '../../productsList/listComponents/ProductListTypes';
import ProductHistoryListElement from './ProductHistoryListElement';

type PropsType = {
  columns: Columns;
  elements: Array<ChangeLog>;
};

/**
 * content of body element of the product history list
 *
 * @param {PropsType} props takes in an array of change logs
 * sticks together the ListElements
 * @returns {JSX.Element} all rows belonging to the table bosy of the ProductsList
 */
const ProductHistoryListBody = (props: PropsType): JSX.Element => {
  const { columns, elements } = props;
  return (
    <>
      {elements.map((element: ChangeLog) => {
        return (
          <Fragment key={element.id}>
            <ProductHistoryListElement
              key={`ProductHistoryListElement${element.id}`}
              element={element}
              columns={columns}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default ProductHistoryListBody;
