import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from 'semantic-ui-react';

interface EditUserDetailsProps {
  onClick: MouseEventHandler;
}

/**
 * Edit Button for Editing User Profile. Additionally a popup for better UX
 * Semantic UI Button with edit mutation.
 *
 * @returns {JSX.Element} Edit Button element
 * @param {EditUserDetailsProps} setOpen Modal props
 */
const EditUserDetailsButton = ({
  onClick,
}: EditUserDetailsProps): JSX.Element => {
  const { t } = useTranslation('buttons');
  return (
    <Popup
      id="EditUserDetailsButtonPopup"
      size="tiny"
      inverted
      position="bottom right"
      trigger={
        <Button
          type="button"
          id="EditUserDetailsButton"
          size="mini"
          icon="edit outline"
          color="blue"
          onClick={onClick}
        />
      }
      content={t('editUser')}
    />
  );
};

export default EditUserDetailsButton;
