import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import NutzungsbedingungenPDF from './NutzungsbedingungenPDF.pdf';

/**
 *  Terms of use
 * WHEN UPDATING THIS DOCUMENTATION-IMPORTANT TO CHANGE THE DATE !!!!!!!!!!!!
 *  1) Please put Date in this format ->  dd-mm-yyyy  in the lastUpdatedOn variable  in **!!
 */
export const lastUpdatedOn = '03-12-2023'; // on update -please Don't forget!

/**
 *@returns {JSX.Element} The terms of Use
 */
const TermsOfUse = (): JSX.Element => {
  const [t] = useTranslation('imprintAndLegal');

  return (
    <Segment
      id="TermsOfUse"
      raised
      style={{
        height: '80vh',
        overflow: 'hidden',
        paddingRight: 0,
      }}
    >
      <object
        data={`${NutzungsbedingungenPDF}#toolbar=0&navpanes=0&scrollbar=0`}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p id="Paragraph">
          <a
            id="Anchor"
            href={`${NutzungsbedingungenPDF}#toolbar=0&navpanes=0&scrollbar=0`}
          >
            {t('termsOfUse')}
          </a>
        </p>
      </object>
    </Segment>
  );
};
export default TermsOfUse;
