import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Icon,
  Label,
  Menu,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../context/ProductContext';
import {
  FileData,
  useBrowseVersionArtifactoryQuery,
} from '../../../../../graphql/generated/graphql';
import Search from '../../../listComponents/Search';
import CustomPagination from '../../../listComponents/pagination/CustomPagination';
import ItemsPerPage from '../../../listComponents/pagination/ItemsPerPage';
import LoadingTable from '../../../productsList/listComponents/LoadingTable';
import {
  Column,
  Columns,
} from '../../../productsList/listComponents/ProductListTypes';
import { useKeepCurrentPageInBounds } from '../../../productsList/listComponents/helpers/costumHooks';
import useTableSorting from './customHooks/useTableSorting';
import { searchFiles } from './helper/logics';
import getDefaultColumns from './helper/types';
import StorageListBody from './listComponents/StorageListBody';
import StorageListHeader from './listComponents/StorageListHeader';

type Props = {
  show: boolean;
};

/**
 * Named collapsible Segment.
 *
 * @param {object} props show if it should not be collapsed by default.
 * @returns {JSX.Element} StorageList.
 */
const StorageList = (props: Props): JSX.Element => {
  const { show = false } = props;
  const { versionId } = useContext(ProductDetailsContext);
  const [text] = useTranslation('productStorage');
  const [open, setOpen] = useState(show);
  const { error, loading, data } = useBrowseVersionArtifactoryQuery({
    variables: { versionId },
    fetchPolicy: 'cache-and-network',
  });

  const [columns /* setColumns */] = useState<Columns>(getDefaultColumns());
  const [activePageState, setActivePageState] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchString, setSearchString] = useState('');
  const [lastUpdatedData, setLastUpdatedData] = useState('');

  const filteredFiles = searchFiles(
    data?.BrowseVersionArtifactory?.files as FileData[],
    searchString
  );

  const [sortState, dispatch, updateData] = useTableSorting(
    filteredFiles || []
  );

  useEffect(() => {
    // Conditional check to optimize sorting/filtering only when data set changes.
    const serializedFilteredFiles = JSON.stringify(filteredFiles);
    if (serializedFilteredFiles !== lastUpdatedData) {
      updateData(filteredFiles || []);
      setLastUpdatedData(serializedFilteredFiles);
    }
    // eslint-disable-next-line
  }, [filteredFiles]);

  const fileAmount = filteredFiles?.length || 0;
  const totalPages = Math.ceil(fileAmount / itemsPerPage);

  const currentPageData = sortState.data.slice(
    (activePageState - 1) * itemsPerPage,
    activePageState * itemsPerPage
  );

  const handleColumnClick = (column: string) => {
    dispatch({ type: 'CHANGE_SORT', column });
  };

  useKeepCurrentPageInBounds(totalPages, activePageState, setActivePageState);

  return (
    <Segment id="StorageListSegment">
      {/* QA TODO: Statt eines Menuitems denke ich, ist hier ein H2/3-Element mit Icon besser. */}
      <Grid columns="equal">
        <Grid.Column width={4}>
          <Menu id="ProductDependencyMenuTab" secondary compact>
            <Menu.Item>
              <Icon
                id="FileUploadAccordion"
                name={open ? 'chevron up' : 'chevron down'}
                color={open ? 'blue' : 'black'}
                onClick={() =>
                  setOpen((prevOpen) => {
                    return !prevOpen;
                  })
                }
              />
            </Menu.Item>
            <Menu.Item style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {text('files')}
              <Label circular id="CountLabel" content={fileAmount} />
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column />
        <Grid.Column width={4}>
          {open && data && (
            <Search
              loading={loading}
              error={error}
              id="StorageListSearch"
              onSearchChange={(searchInput: string) => {
                setSearchString(searchInput);
              }}
            />
          )}
        </Grid.Column>
      </Grid>
      {open && (
        <>
          {error && (
            <Message
              id="StorageListError"
              error
              icon="times circle"
              header={error.name}
              content={error.message}
            />
          )}
          {!error && fileAmount === 0 && !searchString && (
            <Message
              id="StorageListNoFiles"
              warning
              icon="exclamation triangle"
              content={text('noFiles')}
            />
          )}
          {!error && (fileAmount !== 0 || searchString) && (
            <>
              <Table
                style={{ width: '100%' }}
                fixed
                singleLine
                selectable
                sortable
                id="StorageList"
              >
                <Table.Header>
                  <StorageListHeader
                    columns={columns}
                    direction={sortState.direction}
                    sortedColumn={sortState.column}
                    onColumnClick={handleColumnClick}
                  />
                </Table.Header>
                <Table.Body>
                  {data && !(error || loading) && (
                    <StorageListBody
                      versionId={versionId}
                      files={currentPageData}
                      columns={columns}
                      dispatch={dispatch}
                    />
                  )}
                  {loading && (
                    <LoadingTable
                      rowAmount={itemsPerPage}
                      columnAmount={
                        columns.filter((col: Column) => col.permitted === true)
                          .length
                      }
                    />
                  )}
                  {filteredFiles.length === 0 && searchString && (
                    <Table.Row>
                      <Table.Cell width={16} colspan="100%">
                        <Message
                          id="StorageListNotFound"
                          warning
                          icon="exclamation triangle"
                          content={text('notFound')}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
              <Grid columns="equal" className="tableFooter">
                <Grid.Column />
                <Grid.Column width={6}>
                  {data && fileAmount > 0 && (
                    <CustomPagination
                      loading={loading}
                      error={error}
                      totalPages={totalPages}
                      activePage={activePageState}
                      setActivePage={setActivePageState}
                    />
                  )}
                </Grid.Column>
                <Grid.Column>
                  {data && fileAmount > 0 && (
                    <ItemsPerPage
                      loading={loading}
                      error={error}
                      itemsPerPage={itemsPerPage}
                      setItemsPerPage={setItemsPerPage}
                      itemAmount={fileAmount}
                      activePage={activePageState}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </>
          )}
        </>
      )}
    </Segment>
  );
};

export default StorageList;
