import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Item } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  ProductInfoBoxContext,
} from '../../../../../context/ProductContext';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';
import TypeDropdown from './TypeDropdown';

/**
 * @returns {JSX.Element}  Product Type Component for Info Box
 */
const ProductTypeItem = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { inLegalCheckOrDone } = useContext(ProductDetailsContext);
  const { version, versionInput, editing } = useContext(ProductInfoBoxContext);
  const productType = versionInput.types || version?.product?.type;

  return (
    <Item id="ProductType">
      <Item.Content>
        {editing && !inLegalCheckOrDone ? (
          <TypeDropdown />
        ) : (
          <>
            <Item.Meta>
              {t('productType')}
              <InfoIconTooltip infoText={t('productType-info')} />
            </Item.Meta>
            <Item.Header
              style={{
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              <Icon
                style={{ marginRight: '10px' }}
                id="ProductType.CloudIcon"
                name="cloud"
              />
              {productType?.join(' | ')}
            </Item.Header>
          </>
        )}
      </Item.Content>
    </Item>
  );
};

export default ProductTypeItem;
