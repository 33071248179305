import { useState, useEffect, Dispatch, SetStateAction } from 'react';

/**
 * A type representing a function that can be used to update a state variable.
 *
 * @template T The type of the state variable that this function updates.
 */
export type SetValue<T> = Dispatch<SetStateAction<T>>;

// A wrapper for "JSON.parse()"" to support "undefined" value
const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    return value === null || value === 'undefined'
      ? undefined
      : JSON.parse(value);
  } catch {
    console.error('parsing error on', { value }); // eslint-disable-line no-console
    return undefined;
  }
};

/**
 * A custom hook that provides a stateful value stored in the browser's local storage.
 *
 * @template T - The type of the value being stored in local storage.
 * @param {string} key - The key under which the value will be stored in local storage.
 * @param {T} defaultValue - The default value to use if the stored value is not available.
 * @returns {[T, Dispatch<SetStateAction<T>>]} - A tuple containing the current value and a setter function to update it.
 */
const useLocalStorage = <T>(key: string, defaultValue: T): [T, SetValue<T>] => {
  const [value, setValue] = useState<T>(
    parseJSON(localStorage.getItem(key)) ?? defaultValue
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};
export default useLocalStorage;
