import React from 'react';
import { Icon } from 'semantic-ui-react';

type PropsType = {
  modified: boolean;
};

/**
 * basically only returns an icon, according to the modified status
 * nothing too fancy
 *
 * @param {PropsType} props takes in modified as boolean
 * @returns {JSX.Element} a span with an icon
 */
const ModifiedElement = (props: PropsType): JSX.Element => {
  const { modified } = props;

  return (
    <span
      style={{
        textAlign: 'center',
        display: 'inline-block',
        width: '100%',
      }}
    >
      {modified ? <Icon id="MopdifiedStatusIcon" name="checkmark" /> : '-'}
    </span>
  );
};

export default ModifiedElement;
