import React from 'react';

type PropsType = {
  overallResultString: string | undefined;
};

/**
 * @param {PropsType} props takes in the overallResultString of either the product or version.
 * @returns {JSX.Element} returns the overallResult in color.
 */
const ResultElement = (props: PropsType): JSX.Element => {
  const { overallResultString = 'UNKNOWN' } = props;
  return (
    <span
      style={{
        fontWeight: 'bold',
      }}
      className={overallResultString}
    >
      {overallResultString}
    </span>
  );
};

export default ResultElement;
