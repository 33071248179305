import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Item, Message } from 'semantic-ui-react';
import { UserContext } from '../../../context/UserContext';
import WizardContext from '../../../context/WizardContext';
import { CreateProductPart } from '../WizardConstants';
import WizardFormFieldLabel from '../WizardFormFieldLabel';
import { Role } from '../../../graphql/generated/graphql';
import { WizardTeamModalProps } from './helper/types';

/**
 * @param {object} usersOptions allUsers as options for a Dropdown selection
 * @param {[]} usersOptions.usersOptions type
 * @returns {JSX.Element} Team manager column
 */
const WizardTeamManager = ({
  usersOptions,
}: WizardTeamModalProps): JSX.Element => {
  const { t } = useTranslation('newProductWizard');
  const {
    wizardInput,
    setOpenTeamModal,
    setWizardInput,
    setPartValidated,
    currentPart,
  } = useContext(WizardContext);
  //  input for Manager* - the Requester/current User is set as default for every part
  const { id, roles } = useContext(UserContext); // current Users ID & roles -destructured !
  const [showWarning, setShowWarning] = useState(false);
  const managerTeam = wizardInput.partInput[currentPart - 1]?.manager;
  useEffect(() => {
    if (
      roles.includes(Role.oso) &&
      (managerTeam === undefined || managerTeam.length === 0)
    ) {
      setShowWarning(true);
      setPartValidated(false);
    } else {
      if (managerTeam === undefined) {
        setWizardInput({
          productInput: {
            ...wizardInput.productInput,
          },
          partInput: wizardInput.partInput.map((part: CreateProductPart) => {
            return { ...part, manager: [id] }; // user.id
          }),
        });
      }
      if (managerTeam?.length > 0) {
        setPartValidated(true);
      }
      setShowWarning(false);
    }
  }, [
    wizardInput,
    setWizardInput,
    id,
    currentPart,
    setPartValidated,
    roles,
    managerTeam,
  ]);

  return (
    <Item.Group relaxed="very" style={{ paddingTop: '20px' }}>
      <Item id="WizardTeamManager.Title">
        <Item.Content>
          <Item.Header>{t('wizardsTeam.teamTitle')}</Item.Header>
          <Item.Extra
            style={{
              fontSize: '18px',
              display: 'inline',
            }}
          >
            {t('wizardsTeam.manager')}
          </Item.Extra>
          <Item.Description
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              marginTop: '40px',
            }}
          >
            <Form id="WizardTeamManager.Form">
              <WizardFormFieldLabel
                nameText={t('wizardsTeam.manager')}
                infoText={t('wizardsTeam.manager-info')}
                htmlFor="WizardTeamSupplier.Addbtn"
              />
            </Form>
            <Button
              id="WizardTeamSupplier.Addbtn"
              circular
              icon="plus"
              size="mini"
              onClick={() => {
                setOpenTeamModal([true, 'Manager']);
              }}
            />
          </Item.Description>
          {managerTeam !== undefined &&
            managerTeam
              .filter((uID) => uID !== '')
              .map((uID) => usersOptions.find((us) => us.value === uID))
              .map((us) => us?.text)
              .map((man) => <div key={`${man}+manager`}>{man}</div>)}
          {showWarning && (
            <Message
              id="WizardTeamManagerError"
              error
              icon="times circle"
              content={t('wizardsTeam.requiredArr.manager')}
            />
          )}
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default WizardTeamManager;
