import React, { useState } from 'react';
import { Grid, Placeholder, Segment } from 'semantic-ui-react';
import InfoBoxHeader from '../infoBox/components/InfoBoxHeader';

/**
 * @returns {JSX.Element} Info Box Placeholder Component
 */
const InfoboxPlaceholder = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Segment id="InfoBox" raised>
      <InfoBoxHeader open={open} setOpen={setOpen} />
      {open && (
        <Grid relaxed padded>
          {/* First Row */}
          <Grid.Row>
            <Grid.Column width={10}>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="full" />
                </Placeholder.Header>
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={6} textAlign="center">
              <Placeholder style={{ height: '30px', width: '220px' }}>
                <Placeholder.Image />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          {/* Second Row */}
          <Grid.Row columns="equal" stretched>
            <Grid.Column>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="full" />
                </Placeholder.Header>
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>
            <Grid.Column>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="full" />
                </Placeholder.Header>
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>
            <Grid.Column>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length="full" />
                </Placeholder.Header>
                <Placeholder.Line />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Segment>
  );
};

export default InfoboxPlaceholder;
