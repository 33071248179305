import React from 'react';
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ConflictRule, ToDo } from '../../graphql/generated/graphql';
import i18n from '../../i18n/i18n';

type Props = {
  rule: ConflictRule | ToDo;
};

/**
 * List of all Conditions and Requirements(only for Conflict Rules) that are attached to Conflict Rule or ToDo.
 *
 * @param {Props} props rule: Conflict Rule to trigger the info Popup
 * @returns {JSX.Element} LicenseConflictRuleInfo
 */
const LicenseFeatureConditionsInfo = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { rule } = props;

  // For ConflictRule, check the `active` property
  // For ToDo, consider it always "active"
  const isActive = rule.__typename === 'ConflictRule' ? rule.active : true;
  const hasConditions =
    rule.conditions &&
    rule.conditions_OR &&
    (rule.conditions.length > 0 || rule.conditions_OR.length > 0);

  return isActive ? (
    <List id="ConflictRuleConditionsandRequirements">
      <>
        {rule.__typename === 'ToDo' && !hasConditions ? (
          <span>{t('dependencyList.licenseModal.noConditions')}</span>
        ) : (
          <>
            {rule.conditions && rule.conditions.length > 0 && (
              <List.Item key="conditionsList">
                <b id="conditions">
                  {t('dependencyList.licenseModal.conditions')}
                </b>
                <List id="conditionsList" bulleted>
                  {rule.conditions.map((c) => (
                    <List.Item
                      key={`LicenseElementModalsLicenseFeaturesConflict${c?.id}`}
                      content={
                        <span>
                          <b id="typeBoldconditionsList">{c.__typename}</b>
                          {i18n.language === 'de'
                            ? `: ${c?.name_de}`
                            : `: ${c?.name_en}`}
                        </span>
                      }
                    />
                  ))}
                </List>
              </List.Item>
            )}
            {rule.conditions_OR && rule.conditions_OR.length > 0 && (
              <List.Item key="conditionsOrList">
                <b id="conditionsOr">
                  {t('dependencyList.licenseModal.conditionsOr')}
                </b>
                <List id="conditionsOrList" bulleted>
                  {rule.conditions_OR.map((c) => (
                    <List.Item
                      key={`LicenseElementModalsLicenseFeaturesConflict${c?.id}`}
                      content={
                        <span>
                          <b id="typeBoldconditionsOrList">{c.__typename}</b>
                          {i18n.language === 'de'
                            ? `: ${c?.name_de}`
                            : `: ${c?.name_en}`}
                        </span>
                      }
                    />
                  ))}
                </List>
              </List.Item>
            )}
          </>
        )}
        {rule?.__typename === 'ConflictRule' && (
          <>
            {rule.requirements.length > 0 && (
              <List.Item key="requirementsList">
                <b id="requirements">
                  {t('dependencyList.licenseModal.requirements')}
                </b>
                <List id="requirementsList" bulleted>
                  {rule.requirements.map((c) => (
                    <List.Item
                      key={`LicenseElementModalsLicenseFeaturesConflict${c?.id}`}
                      content={
                        <span>
                          <b id="typeBoldrequirements">{c.__typename}</b>
                          {i18n.language === 'de'
                            ? `: ${c?.name_de}`
                            : `: ${c?.name_en}`}
                        </span>
                      }
                    />
                  ))}
                </List>
              </List.Item>
            )}
            {rule.requirements_OR.length > 0 && (
              <List.Item key="requirementsOrList">
                <b id="requirementsOr">
                  {t('dependencyList.licenseModal.requirementsOr')}
                </b>
                <List id="requirementsOrList" bulleted>
                  {rule.requirements_OR.map((c) => (
                    <List.Item
                      key={`LicenseElementModalsLicenseFeaturesConflict${c?.id}`}
                      content={
                        <span>
                          <b id="typeBoldrequirementsOr">{c.__typename}</b>
                          {i18n.language === 'de'
                            ? `: ${c?.name_de}`
                            : `: ${c?.name_en}`}
                        </span>
                      }
                    />
                  ))}
                </List>
              </List.Item>
            )}
          </>
        )}
      </>
    </List>
  ) : (
    <span style={{ color: 'red' }}>
      {t('dependencyList.licenseModal.inactive')}
    </span>
  );
};

export default LicenseFeatureConditionsInfo;
