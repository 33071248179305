import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';
import { Maybe } from '../../../../graphql/generated/graphql';

type PropsType = {
  elementId: Maybe<string>;
};

/**
 * CopyPopup
 * small popup that informs the user that they just copied something
 * and selfdestructs after a seconds
 *
 * @param {PropsType} props takes in ...
 * @returns {JSX.Element} Popup
 */
const CopyPopup = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('dashboard');
  const { elementId } = props;
  const [openCopyPopup, setOpenCopyPopup] = useState(false);

  useEffect(() => {
    if (openCopyPopup) {
      setTimeout(() => {
        setOpenCopyPopup(false);
      }, 1000);
    }
  }, [openCopyPopup]);

  return (
    <Popup
      id={`ProductsList.CopyPopup.${elementId}`}
      trigger={
        <Icon
          id="ProductsList.CopyPopup.Trigger"
          onClick={() => {
            if (elementId) navigator.clipboard.writeText(elementId);
            return null;
          }}
          name="copy outline"
        />
      }
      content={
        elementId
          ? text('tableElements.copyPopup')
          : text('tableElements.copyPopupInvalid')
      }
      on="click"
      open={openCopyPopup}
      onClose={() => setOpenCopyPopup(false)}
      onOpen={() => setOpenCopyPopup(true)}
      position="top center"
    />
  );
};

export default CopyPopup;
