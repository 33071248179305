import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeDiffFormatter } from '../helpers/formatters';
import { timeDiffCalc } from '../helpers/logics';
import OverflowPopup from './OverflowPopup';

type PropsType = {
  targetDate: string | Date;
};

/**
 * basically only returns an element with the time till targetDate
 * nothing too fancy
 *
 * @param {PropsType} props takes in targetDate
 * @returns {JSX.Element} a span with text
 */
const TargetDateElement = (props: PropsType): JSX.Element => {
  const [text] = useTranslation('dashboard');
  const { targetDate } = props;

  return targetDate ? (
    <OverflowPopup
      content={timeDiffFormatter(
        timeDiffCalc(
          typeof targetDate === 'string' ? targetDate : targetDate.toISOString()
        ),
        text
      )}
    />
  ) : (
    <span>-</span>
  );
};

export default TargetDateElement;
