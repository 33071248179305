/* eslint-disable enforce-ids-in-jsx/unique-ids */ // it does not recognize that the ids are actually unique 🤯
import React, { useState } from 'react';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import ToDoPartPopupContent from './ToDoPartPopupContent';

interface TodosInfoPopupProps {
  clickable?: boolean;
  versionId: string | undefined;
  toDoId: string;
}

type PopupPosition = 'top left' | 'top right';

/**
 * TodosInfoPopup Component
 *
 * This component renders a Popup with an information icon as its trigger.
 * The popup content is retrieved from `ToDoPartPopupContent`.
 *
 * @param {boolean} [clickable] - Determines if the popup is activated by both hover and click or just hover.
 * @returns {JSX.Element} The rendered `TodosInfoPopup` component.
 */
const ToDoInfoPopup = ({
  clickable = false,
  versionId,
  toDoId,
}: TodosInfoPopupProps): JSX.Element => {
  const [popupPosition, setPopupPosition] =
    useState<PopupPosition>('top right');

  const handlePopupOpen = (e: React.MouseEvent<HTMLElement>) => {
    const triggerElement = e.currentTarget;
    const halfScreen = window.innerWidth / 2;
    // Calculate the position based on the trigger element
    const rect = triggerElement.getBoundingClientRect();
    const isCloseToRightEdge = window.innerWidth - rect.right < halfScreen;

    if (isCloseToRightEdge) {
      setPopupPosition('top right');
    } else {
      setPopupPosition('top left');
    }
  };

  return (
    <Popup
      id={`EditableToDopartPopup_${toDoId}`}
      className="todo-part-popup"
      hoverable
      flowing
      position={popupPosition}
      offset={[popupPosition === 'top left' ? -10 : 10, 0]}
      onOpen={handlePopupOpen}
      size="tiny"
      content={<ToDoPartPopupContent toDoId={toDoId} versionId={versionId} />}
      on={clickable ? ['hover', 'click'] : ['hover']}
      trigger={
        clickable ? (
          <Checkbox
            id={`EditableToDosChildrenInfoIcon_${toDoId}`}
            label={
              <Icon
                id={`EditableToDosChildrenMouseOverIcon_${toDoId}`}
                name="info circle"
                className="info_icon"
              />
            }
            style={{ verticalAlign: 'middle', padding: '0 5px' }}
          />
        ) : (
          <Icon
            id={`EditableToDoMouseOverIcon_${toDoId}`}
            name="info circle"
            className="info_icon"
          />
        )
      }
    />
  );
};

export default ToDoInfoPopup;
