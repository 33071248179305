import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/ProductContext';
import {
  useDownloadDisclaimerMutation,
  useGetLatestDisclaimerFileQuery,
} from '../../../../graphql/generated/graphql';
import Toast from '../../../ui/Toast';
/**
 * Button for downloading the licenses & copyrights.
 *
 * @returns {JSX.Element} LCDownloadButton.
 */
const LCDownloadButton = (): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { versionId } = useContext(ProductDetailsContext);
  const [toastVisible, setToastVisible] = useState(false);
  const [errorState, setErrorState] = useState<ApolloError | null>(null);

  const disclaimer = useGetLatestDisclaimerFileQuery({
    variables: { versionId },
  });

  const [downloadDisclaimer] = useDownloadDisclaimerMutation({
    variables: {
      versionId,
      url: disclaimer.data?.GetLatestDisclaimerFile.file?.path || '',
    },
    onCompleted: (data) => {
      if (!data || !disclaimer.data?.GetLatestDisclaimerFile.file?.filename)
        return;
      const filename = disclaimer.data.GetLatestDisclaimerFile.file?.filename;
      const extension = filename.slice(filename.lastIndexOf('.') + 1);
      const mimetype = extension === 'pdf' ? 'application/pdf' : 'text/plain';
      const link = document.createElement('a');
      link.href = `data:${mimetype};base64,${data.DownloadDisclaimer}`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    onError: (error) => {
      setErrorState(error);
      setToastVisible(true);
    },
  });

  const handleToastClose = () => {
    setToastVisible(false);
    setErrorState(null);
  };

  if (disclaimer.data?.GetLatestDisclaimerFile.file) {
    return (
      <>
        <Toast
          visible={toastVisible}
          type="error"
          header={errorState?.name}
          content={errorState?.message}
          onClose={handleToastClose}
          position="absolute bottom right"
          offset={{ y: -42 }}
        />
        <Button
          primary
          basic
          id="LCDownloadButton"
          icon="download"
          disabled={toastVisible}
          content={t('LCModal.trigger')}
          floated="right"
          onClick={() => {
            downloadDisclaimer();
          }}
        />
      </>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default LCDownloadButton;
