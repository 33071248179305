import React from 'react';
import {
  Dependency,
  Maybe,
} from '../../../../../../../../../graphql/generated/graphql';
import DependencyTreeBranch from './DependencyTreeBranch';

/**
 * Props for the DependencyTree component.
 *
 * @interface DependencyTreeProps
 * @property {Dependency[]} deps - An array of dependency objects.
 */

interface DependencyTreeProps {
  deps: Dependency[];
  sourceDepId?: Maybe<string>;
  targetDepId?: Maybe<string>;
  setDependencyCount: (value: number | ((prevValue: number) => number)) => void;
  onComponentClick?: (name: string) => void;
}

/**
 * A component that renders a list (tree) of dependencies.
 *
 * @param {DependencyTreeProps} props - The props for the component.
 * @returns {JSX.Element} A JSX element representing the list of dependencies.
 */
const DependencyTree = (props: DependencyTreeProps): JSX.Element => {
  const {
    deps,
    sourceDepId,
    targetDepId,
    setDependencyCount,
    onComponentClick,
  } = props;

  const nthElement = 0;
  const currentDep = deps[nthElement];
  const nextDep = deps.length > nthElement ? deps[nthElement + 1] : null;

  return (
    <div className="tree">
      <ul>
        <DependencyTreeBranch
          key={deps[0].id}
          deps={deps}
          nthElement={nthElement}
          currentDep={currentDep}
          nextDep={nextDep}
          targetDepId={targetDepId}
          currentPathLength={deps.length - 1 || 0}
          setDependencyCount={setDependencyCount}
          sourceDepId={sourceDepId}
          onComponentClick={onComponentClick}
          isRoot
        />
      </ul>
    </div>
  );
};

export default DependencyTree;
