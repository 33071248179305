import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Version } from '../../../../../../graphql/generated/graphql';
import NameElement from '../commonListComponents/columnComponents/NameElement';
import { DIRECT_PRODUCT_VERSION_DEPEPENDENCIES_BY_VERSION_ID } from '../../../../../../graphql/queries/VersionQuerys';
import ProjectStatusElement from '../commonListComponents/columnComponents/ProjectStatusElement';
import TargetDateElement from '../commonListComponents/columnComponents/TargetDateElement';
import CommentCountElement from '../commonListComponents/columnComponents/CommentElement';
import CopyPopup from '../commonListComponents/columnComponents/CopyPopup';
import {
  Column,
  Columns,
} from '../../../../productsList/listComponents/ProductListTypes';
import ToDosElement from '../commonListComponents/columnComponents/ToDosElement';
import PVResolverStatusElement from '../commonListComponents/columnComponents/PVResolverStatusElement';
import PriorityElement from '../../../../../ui/PriorityElement';

type PropsType = {
  columns: Columns;
  pVDep: Version;
  parentElementId?: string;
  onClick: (id: string) => void;
  open: string[];
};

/**
 * list element of dependency list
 *
 * @param {PropsType} props takes in direct or transitive dependency
 * Checks which one came in and delivers the according table row.
 * @returns {JSX.Element} table row containing table cells with the according values.
 */
const PVDependencyListElement = (props: PropsType): JSX.Element => {
  const { columns, pVDep, onClick, open, parentElementId } = props;
  const [text] = useTranslation('dashboard');
  const { data } = useQuery(
    DIRECT_PRODUCT_VERSION_DEPEPENDENCIES_BY_VERSION_ID,
    {
      variables: { id: pVDep.id },
    }
  );
  const pvTransDeps = data?.DirectProductVersionDependenciesByVersionId;
  const elementId = parentElementId
    ? `${parentElementId}-${pVDep.id}`
    : pVDep.id;

  return (
    <>
      <Table.Row
        id={`PVDependencyListRow${elementId}`}
        key={`PVDependencyListRow${elementId}`}
        className={parentElementId ? 'child' : ''}
      >
        {columns.map((column: Column) => {
          if (column.permitted && column.name !== 'completeness') {
            // TODO: Soll tatsächlich die Completeness des Elternproduktes angezeigt werden?
            // TODO: Aktuell ist completeness ohnehin nicht implementiert.
            return (
              <Table.Cell
                key={`DependencyListCell${elementId}${column.name}`}
                width={column.width}
                className={column.name === 'name' ? 'nameCell' : column.name}
              >
                {pVDep &&
                  (() => {
                    switch (column.name) {
                      case 'priority':
                        return (
                          <PriorityElement priority={pVDep.priority || null} />
                        );
                      case 'projectStatus':
                        return (
                          <ProjectStatusElement
                            status={pVDep.processStatusAsString}
                          />
                        );
                      case 'projectID':
                        return (
                          <>
                            <CopyPopup elementId={pVDep.id} />
                            <span>{pVDep.id}</span>
                          </>
                        );
                      case 'resolverStatus':
                        return (
                          <PVResolverStatusElement
                            overallResultNr={pVDep.overallResult?.statusNr}
                          />
                        );
                      case 'name':
                        return (
                          <NameElement
                            name={pVDep.name}
                            productName={pVDep.product?.name}
                            onClick={onClick}
                            elementId={elementId}
                            explicitVersionId={pVDep.id}
                            open={open}
                            hasDependency={pvTransDeps?.length > 0}
                          />
                        );
                      case 'targetDate':
                        return (
                          <TargetDateElement targetDate={pVDep.targetDate} />
                        );
                      case 'todos':
                        return (
                          <ToDosElement
                            numberOfDirectDependencies={
                              pVDep.numberOfDirectDependencies
                            }
                            numberOfTransitiveDependencies={
                              pVDep.numberOfTransitiveDependencies
                            }
                            numberOfDependenciesToProof={
                              pVDep.numberOfDependenciesToProof
                            }
                          />
                        );
                      // TODO: Distribution und StandardSoftware - warte auf Ticketabschluss bzw. Klärung bzgl. Distribution und Standardsoftware
                      case 'requestor':
                        return `${
                          pVDep.createdBy.name || text('tableElements.noInfo')
                        }`;
                      case 'comments':
                        return <CommentCountElement version={pVDep} />;

                      default:
                        return null;
                    }
                  })()}
              </Table.Cell>
            );
          }
          return null;
        })}
      </Table.Row>
      {open.includes(elementId) &&
        pvTransDeps &&
        pvTransDeps.length > 0 &&
        pvTransDeps.map((pvTransDep: Version) => {
          return (
            <PVDependencyListElement
              key={`PVDepListRecursive${pvTransDep.id}`}
              columns={columns}
              pVDep={pvTransDep}
              parentElementId={elementId}
              onClick={onClick}
              open={open}
            />
          );
        })}
    </>
  );
};

PVDependencyListElement.defaultProps = {
  parentElementId: null,
};

export default PVDependencyListElement;
