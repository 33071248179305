import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { Maybe } from '../../../../../graphql/generated/graphql';
import {
  ChangeDetails,
  PairedDependencyHistoryEntry,
} from '../../helpers/customHooks';

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
  property?: Maybe<string>;
  prevValue?: Maybe<string>;
  newValue?: Maybe<string>;
  changeCategory?: Maybe<string>;
  dependencyHistory?: PairedDependencyHistoryEntry[];
  changeDetails?: ChangeDetails;
}

/**
 * Modal for showing details of history entry, either property change or node change
 *
 *@param {object} props {open, setOpen, property, prevValue, newValue, changeCategory, changeDetails}
 *@returns {JSX.Element} modal
 */
const HistoryElementDetailsModal = (props: Props): JSX.Element => {
  const [t, i18n] = useTranslation('history');
  const {
    open,
    setOpen,
    property,
    prevValue,
    newValue,
    changeCategory,
    dependencyHistory,
    changeDetails,
  } = props;

  const lang = i18n.language.startsWith('de') ? 'de' : 'en';
  const isDescription = property === 'description';
  const nameToDoReq =
    changeDetails?.todo?.[`name_${lang}`] ||
    changeDetails?.requirement?.[`name_${lang}`];
  const descToDoReq =
    changeDetails?.todo?.[`description_${lang}`] ||
    changeDetails?.requirement?.[`description_${lang}`];
  const depName = `${changeDetails?.dependency?.componentName} ${changeDetails?.dependency?.componentVersion}`;

  return (
    <Modal
      size="tiny"
      id="HistoryElementDetailsModal"
      open={open}
      onClose={() => setOpen(false)}
      closeOnDimmerClick
      closeOnDocumentClick={false}
    >
      {property ? (
        <>
          <Modal.Header>
            {t(`tableBody.properties.${property}`)}
            <Icon
              id="iconClose2"
              name="close"
              link
              floated="right"
              onClick={() => setOpen(false)}
            />
          </Modal.Header>
          <Modal.Content>
            {isDescription ? (
              <>
                <Modal.Description>
                  <strong>{t('elementDetailsModal.from')}:</strong>
                  <br /> {prevValue || '-'}
                </Modal.Description>
                <Divider />
                <Modal.Description>
                  <strong>{t('elementDetailsModal.to')}:</strong>
                  <br /> {newValue || '-'}
                </Modal.Description>
              </>
            ) : (
              <Segment id="HistoryModalSegmentPropertyChange" basic>
                <Header as="h3">
                  <span className="modal-header-change">
                    {t('elementDetailsModal.from')}
                    <span>{` ${prevValue} `}</span>
                    {t('elementDetailsModal.to').toLowerCase()}
                    <span>{` ${newValue} `}</span>
                  </span>
                </Header>
                <Header as="h4" className="modal-header-name">
                  {nameToDoReq || ''}
                </Header>
                {descToDoReq && (
                  <>
                    <Divider section />
                    {descToDoReq}
                  </>
                )}
              </Segment>
            )}
          </Modal.Content>
          <Modal.Actions />
        </>
      ) : (
        <>
          <Modal.Header>
            {t(`elementDetailsModal.header.${changeCategory}`)}
            <Icon
              id="iconClose3"
              name="close"
              link
              floated="right"
              onClick={() => setOpen(false)}
            />
          </Modal.Header>
          <Modal.Content scrolling>
            {dependencyHistory && dependencyHistory[0].status && (
              <Segment id="HistoryModalSegmentNodeChange" basic>
                <Header as="h3" className="modal-header-name">
                  {depName || ''}
                </Header>
                <Header as="h4">
                  {t('elementDetailsModal.depStatus')}
                  <span className="modal-header-change">
                    {t('elementDetailsModal.from').toLowerCase()}
                    <span
                      className={
                        dependencyHistory[0].status?.prevValue as string
                      }
                    >{` ${dependencyHistory[0].status?.prevValue} `}</span>
                    {t('elementDetailsModal.to').toLowerCase()}
                    <span
                      className={
                        dependencyHistory[0].status?.newValue as string
                      }
                    >{` ${dependencyHistory[0].status?.newValue} `}</span>
                  </span>
                </Header>

                <Divider section />
                <Header as="h4" className="modal-header-name">
                  {t('elementDetailsModal.depNote')}
                  {!dependencyHistory[0].note && (
                    <Header.Subheader>
                      {t('elementDetailsModal.notChanged')}
                    </Header.Subheader>
                  )}
                </Header>
                {dependencyHistory && dependencyHistory[0].note ? (
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h4">{t('elementDetailsModal.from')}</Header>
                        {dependencyHistory[0].note.prevValue || '-'}
                      </Grid.Column>
                      <Grid.Column>
                        <Header as="h4">{t('elementDetailsModal.to')}</Header>
                        {dependencyHistory[0].note.newValue || '-'}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ) : (
                  <Grid columns={1}>
                    <Grid.Row>
                      <Grid.Column>
                        {changeDetails?.dependency?.legalResult?.note || ''}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </Segment>
            )}
          </Modal.Content>
          <Modal.Actions />
        </>
      )}
    </Modal>
  );
};

export default HistoryElementDetailsModal;
