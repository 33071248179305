import React from 'react';
import { List, SemanticICONS } from 'semantic-ui-react';

interface Props {
  step: boolean;
  success: boolean;
  error: boolean;
  canceled: boolean;
  text: string;
}

/**
 * @param {Props} props Status and text
 * @returns {JSX.Element}   item for import status
 */
const DependencyImportStatusItem = (props: Props): JSX.Element => {
  const { success, error, canceled, step, text } = props;

  let icon: SemanticICONS = 'ban';
  let style = { color: '#767676', fontWeight: 'normal' };

  if (step) {
    if (error) {
      icon = 'bolt';
      style = { color: '#DB2828', fontWeight: 'bold' };
    } else if (canceled) {
      icon = 'cancel';
      style = { color: '#000000', fontWeight: 'normal' };
    } else {
      icon = 'spinner';
      style = { color: '#000000', fontWeight: 'bold' };
    }
  } else if (success) {
    icon = 'check';
    style = { color: '#27AE60', fontWeight: 'normal' };
  }

  return (
    <List.Item id="Item" style={style}>
      <List.Icon
        id="Icon"
        name={icon}
        loading={step && !error && !canceled}
        style={
          success || error || step
            ? { minWidth: '3em' }
            : { minWidth: '3em', visibility: 'hidden' }
        }
      />
      <List.Content as="li" id="Content">
        {text}
      </List.Content>
    </List.Item>
  );
};

export default DependencyImportStatusItem;
