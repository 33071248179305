/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

/**
 * Resolve Version Dependencies
 */
export const RESOLVE_VERSION = gql`
  mutation CheckVersionDependencies($versionId: ID!) {
    CheckVersionDependencies(versionId: $versionId)
  }
`;

/**
 * Cancel Resolver
 */
export const CANCEL_RESOLVER = gql`
  mutation CancelResolver($versionId: ID!) {
    CancelResolver(versionId: $versionId)
  }
`;
