import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Modal, Segment } from 'semantic-ui-react';
import {
  CreateUserMutationVariables,
  DirectoryAccess,
} from '../../../graphql/generated/graphql';
import AddNewUserDetailsForm from './AddNewUserDetailsForm';
import UserDataMenu from './helpers/types';

import AddNewUserDirectoriesAccess from './AddNewUserDirectoriesAccess';

import UserDetailsRolesAndBrands from './UserDetailsRolesAndBrands';

type AddNewUserContentContainerProps = {
  newUserDetails: CreateUserMutationVariables;
  setCreateUserDetails: (newUserDetails: CreateUserMutationVariables) => void;
  repeatPass: {
    pass: string;
    enabled: boolean;
  };
  setRepeatPass: (repeatPass: { pass: string; enabled: boolean }) => void;
  directoryAccessArr: DirectoryAccess[];
  setDirectoryAccessArr: React.Dispatch<
    React.SetStateAction<DirectoryAccess[]>
  >;
};

/**
 *@returns {JSX.Element}  When creating a user-Element where there is 2 Parts divided by Menu Tabs.One for personal infos and other for access
 *@param {AddNewUserContentContainerProps} props props
 */
const AddNewUserContentContainer = (
  props: AddNewUserContentContainerProps
): JSX.Element => {
  const {
    newUserDetails,
    setCreateUserDetails,
    repeatPass,
    setRepeatPass,
    directoryAccessArr,
    setDirectoryAccessArr,
  } = props;
  const [t] = useTranslation('users');

  const [activeProfileTab, setActiveTab] = useState(UserDataMenu.profile);

  return (
    <>
      <Menu id="AddNewUserMenu" pointing secondary size="large" widths={2}>
        <Menu.Item
          name={t('modal.userProfile')}
          active={activeProfileTab === UserDataMenu.profile}
          onClick={() => setActiveTab(UserDataMenu.profile)}
        />
        <Menu.Item
          name={t('modal.userDirectories')}
          active={activeProfileTab === UserDataMenu.access}
          onClick={() => setActiveTab(UserDataMenu.access)}
        />
      </Menu>
      <Modal.Content>
        {/* FORM FOR ADDING NEW USER DATA */}
        {activeProfileTab === UserDataMenu.profile && (
          <AddNewUserDetailsForm
            newUserDetails={newUserDetails}
            setCreateUserDetails={setCreateUserDetails}
            setRepeatPass={setRepeatPass}
            repeatPass={repeatPass}
          />
        )}

        {activeProfileTab === UserDataMenu.access && (
          <Segment
            id="AddNewUserRolesBrandsAndBrandAccessDirectoriesSegment"
            raised
          >
            <UserDetailsRolesAndBrands
              userDetails={newUserDetails}
              setUpdateDetails={setCreateUserDetails}
            />
            <AddNewUserDirectoriesAccess
              usersGlobalRoles={newUserDetails.roles}
              directoryAccessArr={directoryAccessArr}
              setDirectoryAccessArr={setDirectoryAccessArr}
            />
          </Segment>
        )}
      </Modal.Content>
    </>
  );
};

export default AddNewUserContentContainer;
