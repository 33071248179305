import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { LICENSE_LIST } from '../../graphql/queries/LicenseQuery';
import {
  useGetLicenseFeatures,
  useRefetchLicenseList,
} from './helpers/customHooks';
import {
  LicenseListQuery,
  LicenseListQueryVariables,
  SortDirection,
} from '../../graphql/generated/graphql';
import { FeatureTypeName } from '../../context/LicenseContext';
import useDebounce from '../../hooks/useDebounce';

type DropdownOptions = {
  key: string;
  text: string;
  value: string;
};

type Props = {
  type: FeatureTypeName;
};

/**
 * SelectLicenseFeatures component, fetches todos of selected license
 *
 * @param {Props} props type of feature
 * @returns {JSX.Element} - JSX.Element
 */
const SelectLicenseFeatures = (props: Props): JSX.Element => {
  const { type } = props;
  const { t } = useTranslation('licenseList');
  const [searchString, setSearchString] = useState('');
  const itemsPerPage = 0; // lists all
  const [debouncedSearchString, setDebouncedSearchString] = useDebounce(
    '',
    750
  );

  const {
    data: licenseData,
    loading: licenseLoading,
    refetch,
  } = useQuery<LicenseListQuery, LicenseListQueryVariables>(LICENSE_LIST, {
    variables: {
      options: {
        sort: [{ name: SortDirection.ASC }],
        offset: 0,
        limit: itemsPerPage,
      },
      where: {
        // needs to be extracted
        AND: [
          {
            OR: [
              {
                verified: true,
              },
            ],
          },
          {
            OR: [
              {
                name_CONTAINS: debouncedSearchString,
              },
              {
                spdxId_CONTAINS: debouncedSearchString,
              },
            ],
          },
        ],
      },
    },
  });

  const [selectedLicense, setSelectedLicense] = useState<string | null>(null);

  useGetLicenseFeatures(selectedLicense, type);

  useRefetchLicenseList(
    refetch,
    [{ verified: true }],
    1,
    itemsPerPage,
    debouncedSearchString
  );

  const handleSearchChange = (
    e: React.SyntheticEvent<HTMLElement>,
    { searchQuery }: DropdownProps
  ) => {
    setSearchString(searchQuery as string);
    setDebouncedSearchString(searchQuery as string);
  };

  const handleChange = (e: React.SyntheticEvent, { value }: DropdownProps) => {
    setSelectedLicense(value as string);
    setSearchString('');
    setDebouncedSearchString('');
  };

  const handleBlur = () => {
    if (selectedLicense) {
      setSearchString('');
      setDebouncedSearchString('');
    }
  };

  const licenses: DropdownOptions[] =
    licenseData?.LicenseList?.licenses
      ?.map((license) => {
        if (license && license.name) {
          return {
            key: license.id,
            text: license.spdxId || license.name,
            value: license.id,
          };
        }
        return null;
      })
      .filter((item): item is DropdownOptions => item !== null) || [];

  return (
    <>
      <span>{t(`license-detail.selectLicenseFeatures.label.${type}`)}</span>
      <Dropdown
        id="SelectLicenseFeatures"
        placeholder={t(
          `license-detail.selectLicenseFeatures.placeholder.${type}`
        )}
        fluid
        search
        selection
        options={licenses}
        loading={licenseLoading}
        upward={false}
        onSearchChange={handleSearchChange}
        searchQuery={searchString}
        onChange={handleChange}
        onBlur={handleBlur}
        value={selectedLicense || ''}
        noResultsMessage={t('license-detail.noResults')}
      />
    </>
  );
};

export default SelectLicenseFeatures;
