import { useQuery } from '@apollo/client';
import React from 'react';
import { Loader, Message, Table } from 'semantic-ui-react';
import { TO_DO_PARTS_MOUSEOVER } from '../../../../graphql/queries/ToDoQueries';

interface ToDoParts {
  toDoId: string;
  versionId: string | undefined;
}

interface ToDoPartTypes {
  componentName: string;
  componentVersion: string;
  licenseId: string;
  licenseName: string;
  licenseSpdxId: string;
}
/**
 *@returns {JSX.Element} Popup containing To-Do Parts when hovering Over a To-Do or Task info icon.
 * @param { string } toDoId The ID of the To-Do to display
 */
const ToDoPartPopupContent = ({
  toDoId,
  versionId,
}: ToDoParts): JSX.Element => {
  const {
    loading: mouseOverLoading,
    data: mouseOverData,
    error: mouseOverError,
  } = useQuery(TO_DO_PARTS_MOUSEOVER, {
    variables: {
      toDoId,
      versionId,
    },
  });
  const toDoPartData = mouseOverData?.ToDoSourceDetails;

  return (
    <div>
      <Table
        id={`PopupContent_${toDoId}`}
        basic
        compact
        className="todo_info_popup_content"
      >
        <Table.Body>
          {mouseOverLoading && (
            <Table.Row>
              <Table.Cell>
                <Loader active inline="centered" size="tiny" />
              </Table.Cell>
            </Table.Row>
          )}
          {mouseOverError && (
            <Table.Row>
              <Table.Cell>
                <Message
                  id="ToDoPartPopupContentError"
                  error
                  icon="times circle"
                  content={`Error ${mouseOverError.message}`}
                />
              </Table.Cell>
            </Table.Row>
          )}
          {toDoPartData &&
            toDoPartData.map((toDoPart: ToDoPartTypes) => (
              <Table.Row
                key={`${toDoPart.licenseId}_${toDoPart.componentName}`}
              >
                <Table.Cell collapsing>
                  <span style={{ fontWeight: 'bold' }}>
                    {toDoPart.componentName}
                  </span>
                </Table.Cell>
                <Table.Cell collapsing>
                  <span>{toDoPart.licenseSpdxId}</span>
                </Table.Cell>
                <Table.Cell>
                  <span>{toDoPart.componentVersion}</span>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  );
};
export default ToDoPartPopupContent;
