import { CSSProperties, RefObject } from 'react';

/**
 *
 * @param {RefObject<HTMLDivElement>} triggerRef triggerRef, i.e. element on which we applies positioning styles.
 * @param {string} position position prop from Toast
 * @param {number} toastHeight the toast height
 * @param {number} x horizontal offset value
 * @param {number} y vertical offset value
 * @returns {CSSProperties} style
 */
const getToastStyle = (
  triggerRef: RefObject<HTMLDivElement>,
  position: string,
  toastHeight: number,
  x: number,
  y: number
): CSSProperties => {
  const style: CSSProperties = {
    position:
      triggerRef.current && position.startsWith('relative')
        ? 'absolute'
        : 'fixed',
    zIndex: 1000,
    maxWidth: '300px',
    width: '300px',
  };

  if (triggerRef.current && position.startsWith('relative')) {
    const rect = triggerRef.current.getBoundingClientRect();
    switch (position) {
      case 'relative top':
        style.top = rect.top + window.scrollY - toastHeight - 16 + y;
        style.left = rect.left + window.scrollX + rect.width / 2 - 150 + x;
        break;
      case 'relative right':
        style.top =
          rect.top + window.scrollY + rect.height / 2 - toastHeight / 2 + y;
        style.left = rect.left + window.scrollX + rect.width + 16 + x;
        break;
      case 'relative bottom':
        style.top = rect.top + window.scrollY + rect.height + 16 + y;
        style.left = rect.left + window.scrollX + rect.width / 2 - 150 + x;
        break;
      case 'relative left':
        style.top =
          rect.top + window.scrollY + rect.height / 2 - toastHeight / 2 + y;
        style.left = rect.left + window.scrollX - 300 - 16 + x;
        break;
      default:
        break;
    }
  } else if (position.startsWith('absolute')) {
    switch (position) {
      case 'absolute top left':
        style.top = `calc(1rem + ${y}px)`;
        style.left = `calc(1rem + ${x}px)`;
        break;
      case 'absolute top right':
        style.top = `calc(1rem + ${y}px)`;
        style.right = `calc(1rem - ${x}px)`;
        break;
      case 'absolute bottom left':
        style.bottom = `calc(1rem - ${y}px)`;
        style.left = `calc(1rem + ${x}px)`;
        break;
      case 'absolute bottom right':
        style.bottom = `calc(1rem - ${y}px)`;
        style.right = `calc(1rem - ${x}px)`;
        break;
      default:
        style.bottom = `calc(1rem - ${y}px)`;
        style.right = `calc(1rem - ${x}px)`;
        break;
    }
  }
  return style;
};

export default getToastStyle;
